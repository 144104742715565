import {useState, useCallback, useEffect, useMemo} from 'react';
import classNames from 'classnames';
import ilib from 'ilib/lib/ilib';
import LocaleInfo from 'ilib/lib/LocaleInfo';
import Spottable from '@enact/spotlight/Spottable';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import AcquiredGraph from '../../../components/AcquiredGraph';
import css from './TCalendar.module.less';
import DatePicker from './DatePicker';
import * as Utils from '../../../utils/common';
import {$L} from '../../../utils/common';
import Spotlight from '@enact/spotlight';

const DEFAULT_WEEKDAYS = [$L('Sun'), $L('Mon'), $L('Tue'), $L('Wed'), $L('Thu'), $L('Fri'), $L('Sat')];
const li = new LocaleInfo(ilib.getLocale());
//todo
const FIRST_DATY_OF_WEEK = li.getFirstDayOfWeek();
const WEEKDAYS = [];
for (let i = 0; i < DEFAULT_WEEKDAYS.length; i++) {
    WEEKDAYS[i] = DEFAULT_WEEKDAYS[(i + FIRST_DATY_OF_WEEK) % 7];
}
/*
    data : {year, month, date, dark}
*/
const SpottableComponent = Spottable('div');
const Container = SpotlightContainerDecorator({enterTo: 'last-focused'}, 'div');
// selectedDate: "2022-04-29"

const TCalendarItem = ({data, selectedDate = '', today, onClick, ...rest}) => {
    const dataPercent = useMemo(() => {
        let steps = 0,
            kcal = 0,
            time = 0;
        if (data.history) {
            const keys = Object.keys(data.history);
            if (keys && keys.length > 0) {
                //last history percentage
                const lastItem = data.history[keys[keys.length - 1]];
                steps = lastItem.dailyLifitCandy * 100;
                kcal = lastItem.dailyLifitCalorie * 100;
                time = lastItem.dailyLifitTime * 100;
            }
        }
        return [steps, kcal, time];
    }, [data]);
    const selected = useMemo(() => {
        const dateStr = Utils.convertDateToString(data.year, data.month, data.date);
        return data.spotlight && dateStr === selectedDate;
    }, [data, selectedDate]);

    const defaultDate = useMemo(()=>{
        const dateStr = Utils.convertDateToString(data.year, data.month, data.date);
        return data.spotlight && dateStr === today;
    },[data, today])

    const _onClick = useCallback(() => {
        if (onClick && data.spotlight) {
            onClick(Utils.convertDateToString(data.year, data.month, data.date));
        }
    }, [onClick, data]);

    return (
        <SpottableComponent {...rest} className={classNames(css.calendarItem, selected && css.selected, defaultDate && css.defaultDate)} spotlightDisabled={!data.spotlight} onClick={_onClick}>
            <div className={classNames(css.title, data.dark && css.dark)}>{data.date}</div>
            {onClick && (
                <div className={css.historylayer}>
                    <AcquiredGraph className={css.graph} data={dataPercent} disabled={!data.spotlight} />
                </div>
            )}
        </SpottableComponent>
    );
};

const NOW = new Date();
const TCalendar = ({className, candyActivities, onChange}) => {
    const [year, setYear] = useState(NOW.getFullYear());
    const [month, setMonth] = useState(NOW.getMonth() + 1); //january is 1
    const [selectedDate, setSelectedDate] = useState(Utils.convertDateToString(NOW.getFullYear(), NOW.getMonth() + 1, NOW.getDate()));
    const today = Utils.convertDateToString(NOW.getFullYear(), NOW.getMonth() + 1, NOW.getDate());

    useEffect(() => {
        if (onChange) {
            onChange(year, month, selectedDate);
        }
    }, [year, month, selectedDate, onChange]);


    const dateList = useMemo(() => {
        const date = [];
        const now = new Date(year, month - 1);
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        const lastDayOfPrevMonth = new Date(firstDayOfMonth);
        lastDayOfPrevMonth.setDate(0);

        /* prevMonth date */
        for (let i = 0; i < firstDayOfMonth.getDay() - FIRST_DATY_OF_WEEK; i++) {
            date.push({year: lastDayOfPrevMonth.getFullYear(), month: lastDayOfPrevMonth.getMonth() + 1, date: lastDayOfPrevMonth.getDate(), dark: true, spotlight: false});
            lastDayOfPrevMonth.setDate(lastDayOfPrevMonth.getDate() - 1);
        }
        date.reverse();
        /* this month date */
        const lastDate = lastDayOfMonth.getDate();

        for (let i = 0; i < lastDate; i++) {
            const dateStr = Utils.convertDateToString(year, month, firstDayOfMonth.getDate());
            date.push({year: year, month: month, date: firstDayOfMonth.getDate(), dark: false, history: candyActivities[dateStr], spotlight: true});
            firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
        }
        /* next month date */
        for (let i = date.length; i < 42; i++) {
            date.push({year: firstDayOfMonth.getFullYear(), month: firstDayOfMonth.getMonth() + 1, date: firstDayOfMonth.getDate(), dark: true, spotlight: false});
            firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
        }
        return date;
    }, [year, month, candyActivities]);

    const onSelectMonth = useCallback(
        ({first}) => {
            const date = new Date(first);
            const newYear = date.getFullYear();
            const newMonth = date.getMonth() + 1;
            if (year !== newYear || month !== newMonth) {
                setYear(newYear);
                setMonth(newMonth);
            }
        },
        [year, month]
    );
    const onSelectDate = useCallback((evt) => {
        setSelectedDate(evt);
    }, []);
    const onSpotlightDown = useCallback((ev) => {
        console.log('onSpotlightDown', ev);
        ev.stopPropagation();
        if(ev.preventDefault){
            ev.preventDefault();
            Spotlight.focus('datelayer');
        }
    }, []);
    return (
        <Container className={classNames(css.calendar, className)}>
            <Container className={css.pickerlayer}>
                <DatePicker mode='monthly' onSelect={onSelectMonth} onSpotlightDown={onSpotlightDown}/>
            </Container>
            <Container className={css.dateLayer} spotlightId='datelayer'>
                <div className={css.weekdays}>
                    {WEEKDAYS.map((item, index) => (
                        <div key={index} className={css.weekdayItem}>
                            {item}
                        </div>
                    ))}
                </div>
                <div className={css.datelist}>
                    {dateList.map((item, index) => (
                        <TCalendarItem key={'date' + index} data={item} selectedDate={selectedDate} today={today} onClick={onSelectDate} />
                    ))}
                </div>
            </Container>
        </Container>
    );
};

export default TCalendar;
