import {useCallback, useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import {on, off} from '@enact/core/dispatcher';
import Icon from '@enact/sandstone/Icon';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import TButton from '../../../components/TButton';
import TIconButton from '../../../components/TIconButton';
import Spotlight from '@enact/spotlight';
import {$L} from '../../../utils/common';
import * as Utils from '../../../utils/common';
import TPanel from '../../../components/TPanel/TPanel';
import * as Config from '../../../data/Config';
import SpotlightIds from '../../../data/SpotlightIds';
import * as PanelActions from '../../../actions/panelActions';
import * as CandyActions from '../../../actions/candyActions';
import {PANEL_NAME} from '../../../actions/panelActions';
import css from './CodeLoginPanel.module.less';
import { InputField } from '@enact/sandstone/Input';
import * as CommonActions from '../../../actions/commonActions';

const Container = SpotlightContainerDecorator({ enterTo: 'default-element', spotlightRestrict: 'self-first'}, 'div');
const SIX_ARRAY = Array.from({length: 6}, () => 0);
const KEYPAD_ARRAY = Array.from({length: 10}, (v,i) => String((i+1)%10));

const TInputField = ({codes, checked, isValidEmail}) => {
	return (
		<div className={css.inputField}>
			{
				SIX_ARRAY.map((item, index) => (
					<div key={index} className={classNames(css.inputBox, codes[index] ? css.inserted:"")}>
							{codes[index] ? codes[index]: "#"}
					</div>
				))
			}
		</div>
	);
};
const TNumberField = ({onClick, disabled=false}) => {
	const _onClick = useCallback((key)=>() => {
		onClick(key);
	}, [onClick]);
	return (
		<Container className={css.numberField} spotlightId={'pressCodeBtn'}>
			{
				KEYPAD_ARRAY.map((item,index) => (
					<TButton size='tiny' color='dark' disabled={disabled} key={index} className={css.button} onClick={_onClick(item)}>
						{item}
					</TButton>
				))
			}
			<TButton size='tiny' color='dark' key={'Backspace'} disabled={disabled} className={css.button}  onClick={_onClick('Backspace')}>
				<Icon className={css.icon}>backspace</Icon>
			</TButton>
		</Container>
	);
};
const CodeLoginPanel = ({...props}) => {
	const dispatch = useDispatch();
	const [codes, setCodes] = useState([]);
	const debugKey = useRef([]);
	const [emailInput, setEmailInput] = useState(null);
	const [isValidEmail, setIsValidEmail] = useState(false);
	const panelInfo = useSelector(state => state.panels.filter(info => info.name === 'codelogin'));
	const keepEmail = panelInfo ? (panelInfo[0]?.panelInfo?.email) : null;

	const updateInput = useCallback((key) => {
		if(debugKey.current.length >= Config.DEBUG_KEY.length){
			debugKey.current.shift();
		}
		debugKey.current.push(String(key));
		if(debugKey.current.join('') === Config.DEBUG_KEY){
			dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.debugpanel));
		}else if(debugKey.current.join('') === Config.TESTPANEL_KEY){
			dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.testpanel));
		}

		const current = Spotlight.getCurrent();
		if(current && (current.getAttribute('data-spotlight-id') === 'emailInput' || current.tagName === 'INPUT') || !isValidEmail){
			return;
		}
		if(key >= '0' && key <='9'){
			if(codes.length < 6){
				const newArray = [...codes];
				newArray.push(key);
				setCodes(newArray);
			}
		}else if(key === 'Backspace'){
			if(codes.length > 0){
				const newArray = codes.slice(0,codes.length-1);
				setCodes(newArray);
			}
		}
	}, [dispatch, codes, isValidEmail]);

	useEffect(()=>{
		if(keepEmail){
			let exp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
			setEmailInput(keepEmail);
			const emailCheckResult = exp.test(keepEmail);
			setIsValidEmail(emailCheckResult && !!keepEmail);
		}
	},[keepEmail]);

	useEffect(()=>{
		if(!isValidEmail){
			setCodes([]);
		}
	},[isValidEmail]);

	const handleKeydown = useCallback((ev) => {
		if(ev && ev.key){
			updateInput(ev.key);
		}
	}, [updateInput]);

	useEffect(()=>{
		on('keydown', handleKeydown);
		return () => {
			off('keydown', handleKeydown);
		};
	},[handleKeydown])

	const onClickNumber = useCallback((ev) => {
		updateInput(ev);
	}, [updateInput]);

	const loginCallback = useCallback((success, error) => {
		if(success){
			dispatch(PanelActions.popPanel());
			dispatch(CandyActions.getUserAgreement());
		}else{
			Spotlight.focus('emailInput');
			if(error && error.message !== 'Network Error'){
				dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.errorpanel, {reason: "login"}));
			}
		}
	}, [dispatch]);

	const onClickLogin = useCallback(() => {
		console.log('onClickLogin',codes.join(""), emailInput);
		dispatch(CandyActions.loginWithCode(emailInput, codes.join(""), loginCallback));
		dispatch(PanelActions.updatePanel(PANEL_NAME.codelogin, {email: emailInput}));
	}, [dispatch, codes, emailInput, loginCallback]);

	useEffect(() => {
		if(!props.hideChildren){
			Spotlight.focus("emailInput");
		}
	}, [props.hideChildren]);

	const onEmailChange = useCallback((e)=>{
		let exp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
		const emailCheckResult = exp.test(e.value);
		console.log('emailcheck result', emailCheckResult, e.value);
		setIsValidEmail(emailCheckResult && !!e.value);
		setEmailInput(e.value);
	},[]);

	const onExit = useCallback(() => {
		Utils.windowClose();
	},[]);

	const spotToEmail = useCallback((ev) => {
		if(Spotlight.focus("emailInput")){
			ev.stopPropagation();
		}
	}, []);
	const onDeactivateInput = useCallback((e) => {
		console.log('onDeactivateInput',e,',',emailInput,',',isValidEmail);
		if(!isValidEmail && emailInput){
			dispatch(CommonActions.alertToast($L("유효하지 않은 이메일입니다. 다시 입력해주세요.")));
		}
	}, [isValidEmail, emailInput]);

	return (
		<TPanel {...props} spotlightId={PANEL_NAME.codelogin} className={css.tpanel}>
			<TIconButton className={css.exitBtn} spotlightId={SpotlightIds.MAIN_EXITBTN} onSelect={onExit} onSpotlightDown={spotToEmail}/>
			<div className={css.title}>{$L("코드 로그인")}</div>
			<div className={css.subtitle}>{$L("Candy 모바일 앱에서 '설정>인증번호'에 표시되는 인증번호 6자리를 하단에 입력해주세요.")}</div>
			<div className={css.emailInputBox}>
				<InputField
					className={css.emailInput}
					spotlightId={"emailInput"}
					type={"text"}
					autoFocus
					dismissOnEnter
					value={emailInput}
					onChange={onEmailChange}
					placeholder={$L('이메일')}
					onDeactivate={onDeactivateInput}
				/>
			</div>
			<TInputField codes={codes} />
			<TNumberField onClick={onClickNumber} disabled={!isValidEmail}/>
			<div className={css.buttonWrap}>
				<TButton className={css.button} spotlightId={'loginButton'} disabled={codes.length!==6} onClick={onClickLogin}>
					{$L('로그인')}
				</TButton>
			</div>
			<div className={css.bottomtitle}>{$L("* 회원가입은 Candy 모바일 앱에서 진행하실 수 있습니다.")}</div>
		</TPanel>
	);
};

export default CodeLoginPanel;
