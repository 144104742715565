/**
 * TopButton
 *
 * @module TopButton
 */

import css from './TopButton.module.less';
import classNames from 'classnames';
import {Job} from '@enact/core/util';
import {$L} from '../../../utils/common';
import {useState, useCallback, useMemo, useRef} from 'react';
import {VoiceControlDecorator} from '@enact/webos/speech';
import Spottable from '@enact/spotlight/Spottable';
import Spotlight from '@enact/spotlight';
import SpotlightIds from '../../../data/SpotlightIds';


const SpottableComponent = Spottable('div');
const VoiceComp = VoiceControlDecorator(SpottableComponent);
const TopButton = ({isOnTop=true, className,onSelect, spotlightId, selected, extended=true,...rest}) => {
	const focused = useRef(false);
	const onFocus = useCallback(() => {
		focused.current = true;
	}, []);
	const onBlur = useCallback(() => {
		focused.current = false;
	}, []);

	const buttonInfo = useMemo(() => {
		const info = {title: 'Unknown', css: null, showUnderBar: false, voiceIntent: '', voiceLabel: ''};
		switch(spotlightId){
			case SpotlightIds.MAIN_CATEGORY_BTN:
				info.title = $L("Category").toUpperCase();
				info.css = css.category;
				info.showUnderBar = true;
				info.voiceIntent = "Select";
				info.voiceLabel = $L("Category");
				break;
			case  SpotlightIds.MAIN_HOME_BTN:
				info.title = $L("Workout").toUpperCase();
				info.css = css.home;
				info.showUnderBar = true;
				info.voiceIntent = "Select";
				info.voiceLabel = $L("Workout");
				break;
			case SpotlightIds.MAIN_DASHBOARD_BTN:
				info.title = $L("History").toUpperCase();
				info.css = css.dashboard;
				info.showUnderBar = true;
				info.voiceIntent = "Select";
				info.voiceLabel = $L("History");
				break;
		}
		return info;
	}, [spotlightId]);
	const [pressed, setPressed] = useState(false);

	const onClick = useCallback((ev) => {
		console.log('onClick ev...', ev);
		setPressed(true);
		new Job(() => {
			setPressed(false);
			if (onSelect) {
				onSelect(spotlightId, rest['data-index']);
			}
		}, 200).start();
	}, [spotlightId, onSelect, rest, setPressed]);
	const onVoiceHandle = useCallback((ev) => {
		Spotlight.focus(ev.target);
		onClick();
		ev.preventDefault();
	}, [onClick]);

	const childProps = { ...rest };
	delete childProps.inline;
	delete childProps.onClick;
	return (
		<VoiceComp
			{...childProps}
			spotlightId={spotlightId}
			className={classNames(css.buttonContainer, buttonInfo.css, focused.current ? css.focused : "", selected ? css.selected: null
				, pressed ? css.pressed: null, css.extended, className)}
			onClick={onClick}
			data-webos-voice-intent={isOnTop ? buttonInfo.voiceIntent: null}
			data-webos-voice-label={isOnTop ? buttonInfo.voiceLabel: null}
			onVoice={onVoiceHandle}
			onFocus={onFocus}
			onBlur={onBlur}
		>
			<div className={css.name}>{buttonInfo.title}</div>
			<div className={css.focusBg}/>
			<div className={css.button}/>
		</VoiceComp>
	);
};

export default TopButton;
