import {types} from '../actions/actionTypes';
import * as Config from '../data/Config';
import * as Utils from '../utils/common';
import SpotlightIds from '../data/SpotlightIds';

const accountInfo = (state = {accountId: '', workoutStyleId: Config.UNKNOWN_WORKOUTSTYLEID}, action) => {
	switch (action.type) {
		case types.GET_ACCOUNT_INFO:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};

const categoriesTable = (state = [], action) => {
	switch (action.type) {
		case types.GET_CATEGORIES_TABLE:
			return action.payload;
		default:
			return state;
	}
};
const categoriesTableSelected = (state = '', action) => {
	switch (action.type) {
		case types.GET_CATEGORIES_TABLE_SELECTED:
			return action.payload;
		default:
			return state;
	}
};
const contentsOfCategory = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTENTS_CATEGORY:
			if(action.append){
				return state.concat(action.payload);
			}else{
				return action.payload;
			}
		default:
			return state;
	}
};
const defaultHomeGroup = [SpotlightIds.LIST_RECENTLY_WATCHED, SpotlightIds.LIST_MYFAVORITE
	, SpotlightIds.LIST_RECOMMANDED, SpotlightIds.LIST_MYWORKOUTROUTINE, SpotlightIds.LIST_RECENTLY_ADDED];
const homeGroup = (state = defaultHomeGroup, action) => {
	switch (action.type) {
		case types.GET_HOME_GROUP:
			return action.payload;
		default:
			return state;
	}
};
const contentsRecentlyWatched = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTENTS_RECENTLY_WATCHED:
			return action.payload;
		default:
			return state;
	}
};

const contentsRecentlyAdded = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTENTS_RECENTLY_ADDED:
			return action.payload;
		default:
			return state;
	}
};
const contentsRelated = (state=[], action) => {
	switch (action.type) {
		case types.GET_CONTENTS_RELATED:
			return action.payload;
		case types.CLEAR_CONTENTS_RELATED:
			return [];
		default:
			return state;
	}
};
const orderedTagList = (state = {}, action) => {
	switch (action.type) {
		case types.GET_ORDERED_TAG_LIST:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};
const contentsOfTag = (state = {}, action) => {
	switch (action.type) {
		case types.GET_CONTENTS_TAG:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
}

const contentInfos = (state = {}, action) => {
	switch (action.type) {
		case types.GET_CONTENT_INFO:
			state[action.payload.key] = action.payload.content;
			return state;
		default:
			return state;
	}
}
const contentsOfKeyword = (state = { keyword: "", data: [] }, action) => {
	switch (action.type) {
		case types.GET_CONTENTS_KEYWORD:
			return action.payload;
		case types.GET_CONTENTS_KEYWORD_RESET:
			return { keyword: "", data: [] }
		default:
			return state;
	}
};

const workoutSummary = (state = {}, action) => {
	switch (action.type) {
		case types.GET_WORKOUT_SUMMARY:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};
const workoutSummaryWeek = (state = {}, action) => {
	switch (action.type) {
		case types.GET_WORKOUT_SUMMARY_WEEK:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};

const contentUsages = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTENT_USAGES:
			if(action.append){
				return state.concat(action.payload);
			}else{
				return action.payload;
			}
		default:
			return state;
	}
};

const selectContentInfo = (state = {}, action) =>{
	switch (action.type) {
		case types.SELECT_CONTENT_INFO:
			return action.payload;
		default:
			return state;
	}
};

const termsModification = (state = [], action) => {
	switch (action.type) {
		case types.GET_TERMS_MODIFICATION:
			return action.payload;
		default:
			return state;
	}
};

const noticeList = (state = [], action) => {
	switch (action.type) {
		case types.GET_NOTICE_LIST:
			if(action.append) {
				return state.concat(action.payload);
			}else {
				return action.payload;
			}
		default:
			return state
	}
}
const candyActivities = (state = {}, action) => {
	switch (action.type) {
		case types.GET_CANDY_ACTIVITIES_BY_MONTH:{
				// const keys = Object.keys(state);
				// keys.forEach(element => {
				// 	if(element.startsWith(action.yyyymm)){
				// 		delete state[element];
				// 	}
				// });
				for(let i=0; i<action.payload.length; i++){
					const item = action.payload[i];
					const date = new Date(item.appDatetime);
					const dateKey = Utils.convertDateToString(date.getFullYear(), date.getMonth()+1, date.getDate());
					if(!state[dateKey]){
						state[dateKey] = {};
					}
					state[dateKey][item.appDatetime] = item;
				}
				return Object.assign({}, state);
			}
		default:
			return state
	}
}

const cmsReducer = {
	accountInfo,
	categoriesTableSelected,
	contentsOfCategory,
	categoriesTable,
	homeGroup,
	contentsRecentlyWatched,
	contentsRecentlyAdded,
	contentsRelated,
	orderedTagList,
	contentsOfTag,
	contentInfos,
	contentsOfKeyword,
	workoutSummary,
	workoutSummaryWeek,
	contentUsages,
	selectContentInfo,
	termsModification,
	noticeList,
	candyActivities
};

export default cmsReducer;
