import css from './TPanel.module.less';
import classNames from 'classnames';
import {Panel} from '@enact/sandstone/Panels';
import {Cancelable} from '@enact/ui/Cancelable';

const CancelablePanel = Cancelable({modal: true, onCancel: 'handleCancel'}, Panel);

const TPanel = ({className,children, ...rest}) => {
	delete rest.panelInfo;
	delete rest.initService;
	return (
		<CancelablePanel {...rest}
		//style={bgStyle}
		className={classNames(css.tpanelmain,className)}>
			{children}
		</CancelablePanel>
	);
};

export default TPanel;
