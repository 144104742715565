import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {$L} from '../../../../../utils/common';
import css from './SubscribeSetting.module.less';
import * as Utils from '../../../../../utils/common';

const SubscribeSetting = () => {
    const userPaymentInfo = useSelector(state => state.userPaymentInfo);
    const payment = useMemo(()=>{
        let status= null, stateText="", nextRenewingDate="";
        switch(userPaymentInfo.subscriptionState){
            case 'freePass':
                stateText = $L("<span>무제한권</span>을 이용중입니다.");
                break;
            case 'subscribed':
                stateText = $L("<span>월 정기 구독</span>을 이용중입니다.");
                break;
            case 'subscriptionCancellationReserved':
                stateText = $L("<span>구독해지 예약상태</span>입니다.");
                break;
            case 'general':
                stateText = $L("이용중인 구독 서비스가 없습니다.");
                break;
        }
        status = userPaymentInfo.subscriptionState;
        if(userPaymentInfo.nextAutoRenewingDatetimes){
            nextRenewingDate = Utils.timeToISO8601DateStr(new Date(userPaymentInfo.nextAutoRenewingDatetimes));
        }
        return {status, stateText, nextRenewingDate}
    }, [userPaymentInfo]);

	return (
        <div className={css.contentContainer}>
            <div className={css.header}>{$L('구독 관리')}</div>
            <div className={css.monthContainer}>
                {payment.stateText &&
                    <div className={css.month} dangerouslySetInnerHTML={{__html: payment.stateText}}/>
                }
                {payment.nextRenewingDate&&
                    <div className={css.nextDate}>{$L('다음 결제 예정일 → {date}').replace('{date}', payment.nextRenewingDate)}</div>
                }
                <div className={css.title}>{$L("정기구독 해지")}</div>
                <div className={css.detail}>{$L("- 서비스 이용 기간이 남아있는 경우 다음 결제 예정일에 정기구독이 해지됩니다.")}</div>
                <div className={css.detail}>{$L("- 다음 결제일까지 현재 서비스를 계속 이용할 수 있습니다.")}</div>
            </div>
        </div>
	);
};

export default SubscribeSetting;