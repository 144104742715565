/**
 * TSwitchItem
 *
 * @module TSwitchItem
 */

import classNames from 'classnames';
import css from './TSwitchItem.module.less';
import SwitchItem from '@enact/sandstone/SwitchItem';


const TSwitchItem = ({className, children, selected, disabled, ...rest}) => {

	return (
		<SwitchItem
				className={classNames(className, css.switch, css.tswitchitem, selected ? css.selected : null, disabled && css.disabled)}
				{...rest}
				selected={selected}
			>
			{children}
		</SwitchItem>
	);
};

export default TSwitchItem;
