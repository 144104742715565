import classNames from 'classnames';
import {useCallback, useEffect } from 'react';
import Alert from '@enact/sandstone/Alert';
import TButton from '../../../../components/TButton/TButton';
import css from './NoticeAlert.module.less';
import Spotlight from '@enact/spotlight';
import Scroller from '@enact/sandstone/Scroller';
import Spottable from '@enact/spotlight/Spottable';
import Icon from '@enact/sandstone/Icon';
import Marquee from '@enact/sandstone/Marquee';

const SpottableComponent = Spottable('div');

const NoticeAlert = ({ title, date, message, currentIndex, lastIndex, button1text, onClick, onClose, ...rest }) => {

	useEffect(() => {
		setTimeout(() => {
			Spotlight.focus('button1');
		}, 0);
	}, []);

	// const focusEvent = useMemo(() => {
	// 	if (currentIndex === 0 || lastIndex === true) {
	// 		Spotlight.focus('button1');
	// 	}
	// }, [currentIndex, lastIndex]);

	const onClickBtn1 = useCallback((ev) => {
		if(onClick){
			onClick(0, ev);
		}
    }, [onClick]);
	const onClickBtn2 = useCallback((ev) => {
        if (onClick) {
			onClick(1, ev);
		}
	}, [onClick]);
	const onClickBtn3 = useCallback((ev) => {
		if(onClick){
			onClick(2, ev);
		}
	}, [onClick]);
    const _onClose = useCallback((ev) => {
        console.log('onClose', ev);
		if(onClose){
			onClose(ev);
		}
	}, [onClose]);
	const onHandleScroller = useCallback((ev) => {
		if (ev.key === 'ArrowLeft') {
			Spotlight.focus('button1');
			Spotlight.focus('button2');
		}
	});

	return (
		<Alert {...rest}
			className={classNames(css.infopopup)}
			open
			onClose={_onClose}
			type={"overlay"}
		>
			<div className={classNames(css.titlelayer)}>
				<Marquee className={classNames(css.title)} marqueeOn={"render"}>
					{title}
				</Marquee>
				<div className={classNames(css.date)}>
					{date}
				</div>
			</div>
			<div className={css.scrollerlayer}>
				<Scroller
					className={css.scroller}
					scrollMode="translate"
					focusableScrollbar
					direction={'vertical'}
					onKeyDown={onHandleScroller}
					overscrollEffectOn={{
						arrowKey: false,
						drag: false,
						pageKey: false,
						track: false,
						wheel: false
					}}
				>
					{message}
				</Scroller>
			</div>
			<div className={css.buttonlayer}>
				{currentIndex !== 0 &&
					<SpottableComponent className={classNames(css.leftButton)} spotlightId={'button2'} onClick={onClickBtn2}>
						<Icon size={"large"}>arrowlargeleft</Icon>
					</SpottableComponent>
				}
				{!lastIndex &&
					<SpottableComponent className={classNames(css.rightButton)} spotlightId={'button3'} onClick={onClickBtn3}>
						<Icon size={"large"}>arrowlargeright</Icon>
					</SpottableComponent>
				}
				<div className={classNames(css.checkButton)}>
					<TButton spotlightId={'button1'} onClick={onClickBtn1}>{button1text} </TButton>
				</div>
			</div>
		</Alert>
	);
};

export default NoticeAlert;
