import * as Config from '../data/Config';
import {types} from './actionTypes';
import * as Utils from '../utils/common';
import * as CommonActions from './commonActions';
import * as BleActions from './bleActions';
import {TAxios, tokenRefresh} from './TAxios';

import dummyFinishedWatching from '../../assets/mock/dummyFinishedWatching.json';
import dummyCategoryTable from '../../assets/mock/dummyCategoryTable.json';
import dummyShows from '../../assets/mock/dummyShows.json';
import dummyEpisodes from '../../assets/mock/dummyEpisodes.json';
import dummyEpisodeProducts from '../../assets/mock/dummyEpisodeProducts.json';
import dummySearchResult from '../../assets/mock/dummySearchResult.json';
import dummySeasonList from '../../assets/mock/dummySeasonList.json';
import dummyNews from '../../assets/mock/dummyNews.json';
import dummyUserInfo from '../../assets/mock/dummyUserInfo.json';

export const AUTHORIZATION = {	headers: {"x-api-key": Config.X_API_KEY}};

export const getUserInfo = () => (dispatch, getState) => {
	const onSuccess = (response) => {
		if(response.data){
			dispatch({type:types.GET_USER_INFO, payload:response.data});
		}
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			const response = dummyUserInfo;
			onSuccess(response);
		}
	 };
	 TAxios(dispatch, getState, "get", Config.GET_USER_INFO, {}, onSuccess, onFail);
};
//for local test
export const putUserInfoTest = () => (dispatch) => {
	const params={
		"realName": "kim",
		"nickName": "myNickName",
		"birthday": "1970-12-12",
		"height": "180",
		"weight": "65",
		"gender": "male",
		"phoneNumber": "+821024921111"
	}
	const preParams={
		"goalCalorie": "200",
		"goalWorkoutTime": "1200",
		"goalStepCount": "1000"
	}
	dispatch(updateUserInfo(params));
	dispatch(updateUserPreferences(preParams));
}
export const updateUserInfo = (params) => (dispatch, getState) => {
	const onSuccess = () => {
		dispatch(getUserInfo());
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			dispatch(getUserInfo());
		}
	};
	 TAxios(dispatch, getState, "put", Config.GET_USER_INFO, params, onSuccess, onFail);
}

//goal preference
export const getUserPreferences = () => (dispatch, getState) => {
	const onSuccess = (response) => {
		if(response.data){
			dispatch({type:types.GET_USER_PREFERENCES, payload:response.data});
		}
	};
	 TAxios(dispatch, getState, "get", Config.GET_USER_PREFERENCES, {}, onSuccess);
}
export const updateUserPreferences = (params) => (dispatch, getState) => {
	const useQAServerURL = getState().localSettings.useQAServerURL;
	const url = Config.GET_CANDY_URL(Config.GET_USER_PREFERENCES, useQAServerURL);
	console.log('updateUserPreferences url', url, params);
	const onSuccess = (response) => {
		console.log('updateUserPreferences response',response);
		dispatch(getUserPreferences());
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			dispatch(getUserPreferences());
		}
	};
	 TAxios(dispatch, getState, "put", Config.GET_USER_PREFERENCES, params, onSuccess, onFail);
}
export const login = (email, password, callback) => (dispatch, getState) => {
	const webOSVersionReal = getState().appStatus.webOSVersionReal;
	const deviceId = getState().appStatus.deviceId;
	const gattConnectingStatusAddress= getState().gattConnectingStatus.address;
	//test
	//DEV
	//email='yonghyon@t-win.kr';
	//password dev = 'Twindebug1!';
	//email='ella@t-win.kr';
	//password dev = 'Tjdrud1234!';

	//email='blackmisthj@t-win.kr';
	//password dev = '1Q2w3e4r!!';

	//QA
	//'blackmisthj@t-win.kr'	'Twindebug1!';
	//'bridge139@gmail.com' 'Twindebug1!';
	//'seunghoon@t-win.kr' 'Twindebug1!';
	//'blackmisthj@gmail.com'  'Twindebug1!'
	//'haneul2244@t-win.kr' 'Twindebug1!'  하늘
	//'zlrz002@t-win.kr 'Twindebug1!'  상철
	//'cloudlat@t-win.kr' 은채

	//figaxi9058@imdutex.com    test
	const params={
		email: email,
		password : password,
		deviceInfo: {
			"deviceId": deviceId,
			"deviceName": "deviceName01",
			"os": "webos",
			"model": webOSVersionReal,
			"serialNumber": "xxxx-xxxx-xxxx-xxxx"
		}
	}
	const onSuccess = (response) => {
		dispatch({type: types.GET_ACCOUNT_STATUS, payload: response.data.status}); // 약관동의시 필요
		AUTHORIZATION.headers.authorization = response.data.authenticationResult.AccessToken;
		dispatch(CommonActions.changeLocalSettings({email, password, userId: response.data.userId, isFirstLaunched: false,
			authenticationResult:response.data.authenticationResult, tokenRequiredTime: new Date()}));
		if(callback){
			callback(true, response.data);
		}
		if(response.data.termsToAgree){
			let matchedTerms = [];
			for ( let index=0; index<response.data.termsToAgree.length; index++) {
				const terms = response.data.termsToAgree[index];
				if(terms.exposureStatus === 'exposed'){
					matchedTerms.push(terms);
				}
			}
			if(matchedTerms){
				dispatch({type: types.GET_TERMS_DOC, payload: matchedTerms});
			}
		}
		if(response.data.status === 'needToAgree'){
			dispatch(CommonActions.changeAppStatus({showTermsPopup: true}));
		}
		if(response.data.status === 'newUser' && webOSVersionReal === 'local'){
			setTimeout(()=>{
				dispatch(putUserInfoTest());
			}, 0);
		}
		setTimeout(()=>{
			dispatch(getCommonLists());
			dispatch(getUserInfo());
			dispatch(getUserPreferences());
		}, 0);
		if(gattConnectingStatusAddress !==''){
			dispatch(BleActions.gattAutoConnect());
		}
	};
	const onFail = (res) => {
		const error = res && res.toJSON ? res.toJSON() : {};
		console.error('login ', error);
		setTimeout(()=>{
			if(callback){
				callback(false, error);
			}
		}, 0);
	};
	TAxios(dispatch, getState, "put", Config.EMAIL, params, onSuccess, onFail, true);
};
export const loginWithCode = (email, code, callback) => (dispatch, getState) => {
	const webOSVersionReal = getState().appStatus.webOSVersionReal;
	const deviceId = getState().appStatus.deviceId;
	const gattConnectingStatusAddress= getState().gattConnectingStatus.address;

	const params={
		email: email,
		key : code,
		appType: 'webos',
		deviceInfo: {
			"deviceId": deviceId,
			"deviceName": "deviceName01",
			"os": "webos",
			"model": webOSVersionReal,
			"serialNumber": "xxxx-xxxx-xxxx-xxxx"
		}
	}
	const onSuccess = (response) => {
		// dispatch({type: types.GET_ACCOUNT_STATUS, payload: response.data.status}); // 약관동의시 필요
		AUTHORIZATION.headers.authorization = response.data.authenticationResult.AccessToken;
		dispatch(CommonActions.changeLocalSettings({email, userId: response.data.userId, isFirstLaunched: false,
			authenticationResult:response.data.authenticationResult, tokenRequiredTime: new Date()}));
		if(callback){
			callback(true, response.data);
		}
		console.log('loginWithCode onSuccess', response);
		setTimeout(()=>{
			dispatch(getCommonLists());
			dispatch(getUserInfo());
			dispatch(getUserPreferences());
		}, 0);
		if(gattConnectingStatusAddress !==''){
			dispatch(BleActions.gattAutoConnect());
		}
	};
	const onFail = (res) => {
		const error = res && res.toJSON ? res.toJSON() : {};
		console.error('loginWithCode onFail', error);
		setTimeout(()=>{
			if(callback){
				callback(false, error);
			}
		}, 0);
	};
	TAxios(dispatch, getState, "put", Config.CODE_LOGIN, params, onSuccess, onFail, true);
};

export const logout = () => (dispatch) => {
	dispatch(CommonActions.changeLocalSettings({email:"", password:"", userId: "", authenticationResult: {}}));
}

export const getUserAgreement = () => (dispatch, getState) => {
	const params ={}
	const onSuccess = (response) => {
		dispatch({type: types.GET_TERMS_DOC, payload: response.data});
		console.log('response.data.termsToAgree', response)
		for(let i=0; i<response.data.length; i++){
			if(response.data[i].reconsentYesNo === 'yes'){
				dispatch(CommonActions.changeAppStatus({showTermsPopup: true}));
				break
			}
		}
	};
	const onFail = () => {
		console.log('getUserAgreement failed')
	};
	TAxios(dispatch, getState, "get", Config.GET_TERMS_DOC, params, onSuccess, onFail);
};

export const setUserAgreement = (terms) => (dispatch, getState) => {

	const onSuccess = () => {
		dispatch(CommonActions.changeAppStatus({showTermsPopup: false}));
	};
	const onFail = () => {
	};
	TAxios(dispatch, getState, "put", Config.GET_TERMS_DOC, terms, onSuccess, onFail);
};

export const handleCategoriTableSelected = (listIndex) => (dispatch) => {
	console.log('handleCategoriTableSelected',listIndex);
	dispatch({type: types.GET_CATEGORIES_TABLE_SELECTED, payload: listIndex});
};

export const getCategoriesTable =  () => (dispatch, getState) => {
	const categoriesTableSelected = getState().categoriesTableSelected;
	const onSuccess = (response) => {
		response.data.sort((a, b) => { // 오름차순
			return a.orderNumber - b.orderNumber;
		});
		dispatch({type: types.GET_CATEGORIES_TABLE, payload: response.data});
		if(categoriesTableSelected === '' && response.data[0]){
			dispatch({type: types.GET_CATEGORIES_TABLE_SELECTED, payload: response.data[0].id });
		}
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			const response = dummyCategoryTable;
			onSuccess(response);
		}
	};
	 TAxios(dispatch, getState, "get", Config.GET_CATEGORIES, {}, onSuccess, onFail);
}

export const getShowsInfo = ({categoryId = null}) => (dispatch, getState) => {
	const categoriesTableSelected = getState().categoriesTableSelected;
	const query = {
		includeSeasonsYesNo: categoryId ? "Yes": null,
		"categoryId": categoryId
	};
	const onSuccess = (response) => {
		const categoryContents = [];
		for(let i=0;i<response.data.length;i++){
			if(response.data[i].seasons){
				dispatch({type:types.UPDATE_SEASON_INFOS, payload:response.data[i].seasons});
			}
			if(response.data[i].categoryId === categoriesTableSelected){
				categoryContents.push(response.data[i]);
			}
		}
		if(categoryId){
			dispatch({type: types.GET_CONTENTS_CATEGORY, payload: categoryContents});
		}
		dispatch({type: types.UPDATE_SHOW_INFOS, payload:response.data});
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			const response = dummyShows;
			if(categoryId){
				for(let i=0;i<response.data.length;i++){
					response.data[i].categoryId = categoryId;
				}
			}
			onSuccess(response);
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_ALL_SHOWS, query, onSuccess, onFail);
}

export const getContentsOfCategory = (categoryId) => (dispatch) => {
	dispatch(getShowsInfo({categoryId}));
};

export const forceTokenRefresh = (autoLogin = false, callback) => async (dispatch, getState) => {
	await tokenRefresh(dispatch, getState, autoLogin, callback);
}
export const getNews = () => (dispatch, getState) => {
	const onSuccess = (response) => {
		if(response.data){
			dispatch({type:types.GET_CONTENTS_NEWS, payload:response.data});
		}
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			const response = dummyNews;
			onSuccess(response);
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_CONTENTS_NEWS, {}, onSuccess, onFail);
}

export const getSeasonList = (showId) => (dispatch, getState) => {
	const query = {
		showId: showId
	};
	const onSuccess = (response) => {
		dispatch({type:types.UPDATE_SEASON_LIST, payload:response.data, showId: showId});
		dispatch({type:types.UPDATE_SEASON_INFOS, payload:response.data});
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			const response = dummySeasonList;
			if(showId){
				for(let i=0;i<response.data.length;i++){
					response.data[i].showId = showId;
				}
			}
			onSuccess(response);
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_SEASONS, query, onSuccess, onFail);
}

export const getEpisodeList = (seasonId) => (dispatch, getState) => {
	const query = {
		seasonId: seasonId
	};
	const onSuccess = (response) => {
		dispatch({type:types.UPDATE_EPISODE_LIST, payload:response.data, seasonId: seasonId});
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			const response = dummyEpisodes;
			onSuccess(response);
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_EPISODES, query, onSuccess, onFail);
}
//시즌의 준비물 정보 조회
export const getSeasonProducts = (seasonId) => (dispatch, getState) => {
	const query = {
		seasonId: seasonId
	};
	const onSuccess = (response) => {
		dispatch({type:types.UPDATE_SEASON_PRODUCTS, payload:response.data, seasonId: seasonId});
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			const response = dummyEpisodeProducts;
			onSuccess(response);
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_SEASON_PRODUCTS, query, onSuccess, onFail);
}
//에피소드의 준비물 정보 조회
export const getEpisodeProducts = (episodeId) => (dispatch, getState) => {
	const query = {
		episodeId: episodeId
	};
	const onSuccess = (response) => {
		dispatch({type:types.UPDATE_EPISODE_PRODUCTS, payload:response.data, episodeId: episodeId});
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			const response = dummyEpisodeProducts;
			onSuccess(response);
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_EPISODE_PRODUCTS, query, onSuccess, onFail);
}
export const getContentsRecommended = () => (dispatch, getState) => {
	const onSuccess = (response) => {
		if (response && response.data) {
			if(response.data.length > 0){
				dispatch({type: types.GET_CONTENTS_RECOMMENDED, payload: response.data});
				dispatch({type: types.UPDATE_SEASON_INFOS, payload:response.data});
			}else{
				dispatch({type: types.GET_CONTENTS_RECOMMENDED, payload: []});
			}
		}
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			const response = dummySeasonList;
			onSuccess(response);
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_CONTENTS_RECOMMENDED, {}, onSuccess, onFail);
};

export const getContentsSpecial = () => (dispatch, getState) => {
	const onSuccess = (response) => {
		if (response && response.data) {
			dispatch({type: types.GET_CONTENTS_SPECIAL, payload: response.data});
			dispatch({type: types.UPDATE_SHOW_INFOS, payload:response.data});
		}
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			const response = dummyShows;
			onSuccess(response);
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_CONTENTS_SPECIAL, {}, onSuccess, onFail);
};
export const getContentsPopular = () => (dispatch, getState) => {
	const onSuccess = (response) => {
		if (response && response.data) {
			dispatch({type: types.GET_CONTENTS_POPULAR, payload: response.data});
			dispatch({type: types.UPDATE_SEASON_INFOS, payload:response.data});
		}
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			const response = dummySeasonList;
			onSuccess(response);
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_SEASON_POPULAR, {}, onSuccess, onFail);
};
export const getContentsOfFavorites = () => (dispatch, getState) => {
	const onSuccess = (response) => {
		if (response && response.data) {
			dispatch({type: types.GET_CONTENTS_MY_FAVORITES, payload: response.data});
		}else if(response && response.data === ''){
			dispatch({type: types.GET_CONTENTS_MY_FAVORITES, payload: []});
		}
	};
	const onFail = () => {
		dispatch({type: types.GET_CONTENTS_MY_FAVORITES, payload: []});
	};
	TAxios(dispatch, getState, "get", Config.GET_FAVORITE, {}, onSuccess, onFail);
};
export const addFavorite = (seasonId) => (dispatch, getState) => {
	const params = {
		seasonId: seasonId
	};
	const onSuccess = () => {
		dispatch(getContentsOfFavorites());
	};
	TAxios(dispatch, getState, "post", Config.GET_FAVORITE, params, onSuccess);
};
export const removeFavorite = (seasonId) => (dispatch, getState) => {
	const params = {
		seasonId: seasonId
	};
	const onSuccess = () => {
		dispatch(getContentsOfFavorites());
	};
	TAxios(dispatch, getState, "delete", Config.GET_FAVORITE, params, onSuccess);
};

export const getCandyActivities = (year, month, date) => (dispatch, getState) => {
	const yearMonthStr = Utils.convertDateToString(year, month, date);
	const query = {
		appDatetime: yearMonthStr
	};
	const onSuccess = (response) => {
		response.data.sort((a, b) => { // 오름차순
			return a.appDatetime - b.appDatetime;
		});
		dispatch({type: types.GET_CANDY_ACTIVITIES_BY_MONTH, payload: response.data, yyyymm: yearMonthStr});
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			const response = dummyFinishedWatching;
			onSuccess(response);
		}
	};
	TAxios(dispatch, getState, "get", Config.FINISHED_WATCHING, query, onSuccess, onFail);
}

export const getSeasonPreference = (seasonId) => (dispatch, getState) => {
	const query = {
		seasonId: seasonId
	};
	const onSuccess = (response) => {
		if (response && response.data) {
			dispatch({type: types.GET_SEASON_PREFERENCE, payload: response.data});
		}else if(response && response.data === ''){
			dispatch({type: types.GET_SEASON_PREFERENCE, payload: {seasonId: seasonId, preference: ''}});
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_PREFERENCE, query, onSuccess);
};
export const updateSeasonPreference = (showId, seasonId, state) => (dispatch, getState) => {
	const params ={
		showId: showId,
		seasonId:seasonId,
		preference: state
	};
	const onSuccess = () => {
		dispatch(getSeasonPreference(seasonId));
	};
	TAxios(dispatch, getState, "put", Config.GET_PREFERENCE, params, onSuccess);
}
export const removeSeasonPreference = (seasonId) => (dispatch, getState) => {
	const params = {
		seasonId: seasonId
	};
	const onSuccess = () => {
		dispatch(getSeasonPreference(seasonId));
	};
	TAxios(dispatch, getState, "delete", Config.GET_PREFERENCE, params, onSuccess);
};

export const getRelatedKeywords  = (keyWord) => (dispatch, getState) => {
	const query = {
		keywords: keyWord
	};
	const onSuccess = (response) => {
		if (response && response.data) {
			dispatch({type: types.GET_RELATED_KEYWORDS, keyword: keyWord, data: response.data});
		}else {
			dispatch({type: types.GET_RELATED_KEYWORDS, keyword: keyWord, data: []});
		}
	};
	const onFail = () => {
		dispatch({type: types.GET_RELATED_KEYWORDS, keyword: keyWord, data: []});
	};
	TAxios(dispatch, getState, "get", Config.GET_RELATED_KEYWORDS, query, onSuccess, onFail);
};

export const getSearchResult = (keyWord) => (dispatch, getState) => {
	const query = {
		searchKeywords: keyWord
	};
	dispatch(CommonActions.changeAppStatus({keywordSearching: true}));
	const onSuccess = (response) => {
		if (response && response.data) {
			dispatch({type: types.GET_SHOW_SEARCH, payload: {keyword: keyWord, data: response.data}});
		}else {
			dispatch({type: types.GET_SHOW_SEARCH, payload: {keyword: keyWord, data: []}});
		}
		dispatch(getRelatedKeywords(keyWord));
		dispatch(CommonActions.changeAppStatus({keywordSearching: false}));
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			const response = dummySearchResult;
			onSuccess(response);
		}
		dispatch(CommonActions.changeAppStatus({keywordSearching: false}));
	};
	if(keyWord){
		TAxios(dispatch, getState, "get", Config.GET_CONTENTS_SEARCH, query, onSuccess, onFail);
	}else{
		dispatch(CommonActions.changeAppStatus({keywordSearching: false}));
	}
};

export const getPopularShows = () => (dispatch, getState) => {
	const onSuccess = (response) => {
		if (response && response.data) {
			dispatch({type: types.GET_POPULAR_SHOWS, payload: response.data});
		}else {
			dispatch({type: types.GET_RELATED_KEYWORDS, payload: []});
		}
	};
	const onFail = () => {
		dispatch({type: types.GET_RELATED_KEYWORDS, payload: []});
	};
	TAxios(dispatch, getState, "get", Config.GET_CONTENTS_POPULAR, {}, onSuccess, onFail);
};

export const getStartWatching = () => (dispatch, getState) => {
	const query = {
		distinctEpisodesYesNo: 'Yes'
	};
	const onSuccess = (response) => {
		if (response && response.data) {
			dispatch({type: types.GET_CONTENTS_WATCHING, payload: response.data});
		}else if(response && response.data === ''){
			dispatch({type: types.GET_CONTENTS_WATCHING, payload: []});
		}
	};
	TAxios(dispatch, getState, "get", Config.START_WATCHING, query, onSuccess);
};

export const postStartWatching = (seasonId,episodeId) => (dispatch, getState) => {
	const seasonInfos = getState().seasonInfos;
	let showId = "";
	if(seasonInfos[seasonId]){
		showId = seasonInfos[seasonId].showId;
	}
	if(!showId){
		console.log('postStartWatching invalid item no showid');
		return;
	}
	const params = {
		showId,
		seasonId,
		episodeId,
		appDatetime: Utils.timeToISO8601StrWithTimeZone(new Date())
	};
	const onSuccess = () => {
		dispatch(getStartWatching());
	};
	TAxios(dispatch, getState, "post", Config.START_WATCHING, params, onSuccess);
};
export const finishWatching = (episodeId, data) => (dispatch, getState) => {
	const {workoutTime, candy, calorieConsumption, averageHeartRate, dailyLifitTime, dailyLifitCandy, dailyLifitCalorie} = data;

	const params = {
		episodeId,
		appDatetime: Utils.timeToISO8601StrWithTimeZone(new Date()),
		workoutTime: String(workoutTime),
		candy: String(candy),
		calorieConsumption: String(calorieConsumption),
		averageHeartRate: String(averageHeartRate),
		dailyLifitTime: String(dailyLifitTime),
		dailyLifitCandy: String(dailyLifitCandy),
		dailyLifitCalorie: String(dailyLifitCalorie),
		"score": "8" //dummy
	};
	const onSuccess = () => {
		const date = new Date();
		dispatch(getCandyActivities(date.getFullYear(), date.getMonth()+1, date.getDate()));
	};
	TAxios(dispatch, getState, "post", Config.FINISHED_WATCHING, params, onSuccess);
};

export const getUserPaymentInfo = () => (dispatch, getState) => {
	const onSuccess = (response) => {
		dispatch({type: types.GET_USER_PAYMENT_INFO, payload: response.data});
	};
	TAxios(dispatch, getState, "get", Config.GET_USER_PAYMENT_INFO, {}, onSuccess);
};

export const getCommonLists = () => async (dispatch) => {
	dispatch(getCategoriesTable());
	dispatch(getNews());
	dispatch(getShowsInfo({})); //all shows
	dispatch(getContentsOfFavorites());
	dispatch(getStartWatching());
	dispatch(getContentsRecommended());
	dispatch(getContentsSpecial());
	dispatch(getContentsPopular());
}
