import css from './SearchingCandy.module.less';
import {$L} from '../../../../../../../utils/common';


const SearchingCandy = ({...rest}) => {
	return (
        <div className={css.container} {...rest}>
            <div className={css.title}>{$L('Candy Band를 찾고 있습니다.')}</div>
            <div className={css.detail}>{$L('잠시만 기다려주세요.')}</div>
            <div className={css.imgStyle} />
        </div>
    )
};

export default SearchingCandy;


