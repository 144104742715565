import css from './StartPagePanel.module.less';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PANEL_NAME} from '../../../actions/panelActions';
import * as Config from '../../../data/Config';
import {$L} from '../../../utils/common';
import TPanel from '../../../components/TPanel/TPanel';
import TButton from '../../../components/TButton';
import * as PanelActions from '../../../actions/panelActions';
import Spotlight from '@enact/spotlight';


const StartPagePanel = props => {
	const dispatch = useDispatch();
	useEffect(() => {
		if(!props.hideChildren){
            Spotlight.focus(PANEL_NAME.startpage);
        }
	}, [props]);
	const onClikckLogin = useCallback((ev) => {
		console.log('onClick ev...', ev);
		dispatch(PanelActions.popPanel());
	}, [dispatch]);

	return (
		<TPanel {...props} spotlightId={PANEL_NAME.startpage} className={css.startpage}>
			<div className={css.logo}/>
			<div className={css.guideText}>{$L("Beyond Fitness, Sweet Life!")}</div>
			<TButton className={css.button} type='normal' onClick={onClikckLogin}>
				{$L('시작하기')}
			</TButton>
		</TPanel>
	);
};

export default StartPagePanel;
