import { useCallback, useEffect, useState , useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import css from './TermsDetail.module.less';
import {$L} from '../../../../../utils/common';
import TButton from '../../../../../components/TButton/TButton';
import Spotlight from '@enact/spotlight';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import TermsServiceDetail from './TermsServiceDetail/TermsServiceDetail';

const Container = SpotlightContainerDecorator({enterTo: 'last-focused'}, 'div');
const TermsDetail = ({spotlightId, ...rest}) => {
	const getTermsDoc = useSelector(state => state.getTermsDoc);
	const dispatch = useDispatch();
	const [selectedId, setSelectedId] = useState('terms');

	// useEffect(() => {
	// 	if(Object.keys(getTermsDoc).length <= 0){
	// 		// dispatch(CmsActions.getTermsDoc());
	// 	}
	// }, [getTermsDoc, dispatch]);

	// useEffect(() => {
	// 	Spotlight.focus('termsBtn');
	// }, []);

	const groupList = useMemo(() => {
		let list = [];
		list.push({ id: 'terms', children: $L('이용약관') });
		list.push({ id: 'privacyPolicy', children: $L('개인정보 처리방침') });
		list.push({ id: 'collectPrivacyInfo', children: $L('개인정보 수집 및 이용 동의서') });
		list.push({ id: 'collectSensitiveInfo', children: $L('민감 정보의 수집 및 이용') });
		list.push({ id: 'marketingInfo', children: $L('[선택] 마케팅정보 수신동의') });
		list.push({ id: 'deleteUserId', children: $L('회원 탈퇴 안내') });
		return list;
	}, []);

	const onSelectMenu = useCallback((id)=>() => {
		setSelectedId(id);
	}, []);

	return (
		<div {...rest} className={css.termsContainer}>
			<Container spotlightId={spotlightId}>
				<div className={css.header}>{$L('이용 약관')}</div>
				<Container className={css.tabLayout}>
					{
						groupList.map(menu => (
							<TButton
								key={menu.id}
								spotlightId={menu.id}
								value={menu.id}
								className={classNames(css.tab, menu.id===selectedId ? css.selected : null)}
								type={'tabBtnHorizon'}
								onClick={onSelectMenu(menu.id)}
								selected={menu.id===selectedId}
								withMarquee={menu.id!==selectedId || menu.id===selectedId}
							>
								{menu.children}
							</TButton>
						))
					}
				</Container>
				<TermsServiceDetail id={selectedId}/>
			</Container>
		</div>
	);
};

export default TermsDetail;