/**
 * HomeView
 *
 * @module HomeView
 */
import classNames from 'classnames';
import Scroller from '@enact/sandstone/Scroller';

import platform from '@enact/core/platform';
import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {Job} from '@enact/core/util';
import Spotlight from '@enact/spotlight';
import {on, off} from '@enact/core/dispatcher';
import * as CommonActions from '../../actions/commonActions';
import * as PanelActions from '../../actions/panelActions';
import usePrevious from '../../utils/usePrevious';
import HomeBanner from '../../components/HomeBanner';
import MediaList from '../../components/MediaList';
import VerticalScrollGuide from '../../components/VerticalScrollGuide';
import * as Config from '../../data/Config';

import {$L} from '../../utils/common';
import * as ContentType from '../../data/ContentType';
import * as Utils from '../../utils/common';
import SpotlightIds from '../../data/SpotlightIds';
import css from './HomeView.module.less';

const calculateVisibleListJob = new Job((func) => {
	func();
},2000);

const HomeView = ({isOnTop}) => {
	const dispatch = useDispatch();
	const homeBannerSize = useSelector(state => state.appStatus.homeBannerSize, shallowEqual);
	const cursorVisible = useSelector(state => state.appStatus.cursorVisible, shallowEqual);
	const contentsRecommended = useSelector(state => state.contentsRecommended, shallowEqual);
	const contentsSpecial = useSelector(state => state.contentsSpecial, shallowEqual);
	const contentsMyFavorites  = useSelector(state => state.contentsMyFavorites, shallowEqual);
	const contentsWatching  = useSelector(state => state.contentsWatching, shallowEqual);
	const showLoadingView = useSelector(state => state.showLoadingView.show, shallowEqual);
	const panels = useSelector(state => state.panels, shallowEqual);
	const [scrollOnBottom, setScrollOnBottom] = useState(false);
	const [visibleList, setVisibleList] = useState([]);
	const scrollTo = useRef(null);
	const panelsRef = useRef([]);
	const isOnTopRef = useRef(isOnTop);
	const currentBannerSize = useRef('medium');
	const scrollTop = useRef(0);
	const debugKey = useRef([]);

	useEffect(() => {
		panelsRef.current = panels;
	}, [panels]);
	useEffect(() => {
		isOnTopRef.current = isOnTop;
	}, [isOnTop]);

	useEffect(() => {
		setBigBanner();
		on('wheel', handleWheel);
		on('keydown', handleKeydown);
		Spotlight.focus(SpotlightIds.MAIN_HOME_BTN);
		Spotlight.focus(SpotlightIds.BANNER_PLAYBTN);
		return () => {
			off('wheel', handleWheel);
			off('keydown', handleKeydown);
			if(calculateVisibleListJob) calculateVisibleListJob.stop();
		};
	}, []);

	const calculateVisibleList= useCallback(() => {
		const scroller = document.querySelector(`[data-spotlight-id="${SpotlightIds.HOME_SCROLLER}"]`);
		const listArray = [];
		if(scroller){
			for(let index in scroller.children[0].children){
				if(!isNaN(index)){
					const list = scroller.children[0].children[index];
					if(list.offsetTop >= scrollTop.current && (list.offsetTop+list.offsetHeight)<= scrollTop.current+scroller.offsetHeight){
						listArray.push(list.getAttribute('data-spotlight-id'));
					}
				}
			}
		}
		setVisibleList(listArray);
	}, []);

	useEffect(() => {
		currentBannerSize.current = homeBannerSize;
		if( scrollTo && scrollTo.current && homeBannerSize==='medium'){
			scrollTo.current({align: 'top', animate: false});
		}
		calculateScrollBtnShow();
	}, [homeBannerSize]);

	const onListFocus = useCallback((ev) => {
		if(!Spotlight.getPointerMode()){
			try{
				const scroller = document.querySelector(`[data-spotlight-id="${SpotlightIds.HOME_SCROLLER}"]`);
				const firstList = scroller.children[0].children[0];
				if(firstList && firstList.getAttribute){
					dispatch(CommonActions.changeAppStatus({homeBannerSize: 'small'}));
				}
			}catch(e){
				console.warn('HomeView onListFocus warning',e);
			}
		}
	}, [dispatch]);
	const setBigBanner = useCallback(() => {
		dispatch(CommonActions.changeAppStatus({homeBannerSize: 'medium'}));
		dispatch(CommonActions.clearFocusedBannerItem());
		if(!cursorVisible){
			Spotlight.focus(SpotlightIds.BANNER_PLAYBTN);
		}
	}, [dispatch, cursorVisible]);

	const getScrollTo = useCallback((cbScrollTo) => {
		scrollTo.current = cbScrollTo;
	}, []);

	const calculateScrollBtnShow = useCallback(()=>{
		const scroller = document.querySelector(`[data-spotlight-id="${SpotlightIds.HOME_SCROLLER}"]`);
		if(!scroller){
			return;
		}
		// console.log('calculateScrollBtnShow ', scrollTop.current, scroller.offsetHeight, scroller.children[0].scrollHeight)
		if(scrollTop.current > 0 && scrollTop.current + scroller.offsetHeight + 10/*dummy*/ > scroller.children[0].scrollHeight){
			setScrollOnBottom(true);
			// console.log('calculateScrollBtnShow setScrollOnBottom true');
		}else{
			setScrollOnBottom(false);
			// console.log('calculateScrollBtnShow setScrollOnBottom false');
		}
		// console.log('calculateScrollBtnShow homeBannerSize', homeBannerSize, scroller.children[0].children.length);
		if(homeBannerSize === 'small' &&  scroller.children[0].children.length <=3){ //2+1(Dummy)
			setScrollOnBottom(true);
		}
		calculateVisibleListJob.start(calculateVisibleList);
	}, [homeBannerSize]);
	//not working with hook
	const onScrollStop = useCallback((ev) => {
		scrollTop.current = Math.round(ev.scrollTop);
		calculateScrollBtnShow();
		calculateVisibleListJob.start(calculateVisibleList);
	}, [calculateVisibleList, calculateScrollBtnShow]);

	const moveBottom = useCallback((wheel = false, forceTop=false) => {
		if(scrollTo.current !== null){
			const scroller = document.querySelector(`[data-spotlight-id="${SpotlightIds.HOME_SCROLLER}"]`);
			let listArray = scroller.children[0].children;
			if(!wheel){
				const currentItem = Spotlight.getCurrent();
				const nextItem = currentItem && currentItem.nextElementSibling;
				if(currentItem && nextItem && nextItem !== listArray[listArray.length-1]){ //check dummy
					const offsetBottom = nextItem.offsetTop+nextItem.offsetHeight;
					Spotlight.focus(nextItem);
					if(offsetBottom < (scrollTop.current+scroller.offsetHeight)){ //contain
						return true;
					}else{
						const targetOffset = (forceTop) ? nextItem.offsetTop: currentItem.offsetTop;
						scrollTop.current = targetOffset;
						scrollTo.current({position:{y: targetOffset}, animate: true});
						return true;
					}
				}
				return false;
			}
			for (let i = 0; i < listArray.length; i++){
				const nextItem = listArray[i].nextElementSibling;
				if(scrollTop.current < listArray[i].offsetTop){
					if(i < (listArray.length-2)){ //ignore dummy, lastitem
						Spotlight.focus(listArray[i]);
						scrollTop.current = listArray[i].offsetTop;
						scrollTo.current({position:{y: listArray[i].offsetTop}, animate: true});
					}
					return true;
				}
			}
		}
	}, []);
	const moveTop = useCallback((wheel = false) => {
		if(scrollTo.current !== null){
			const scroller = document.querySelector(`[data-spotlight-id="${SpotlightIds.HOME_SCROLLER}"]`);
			let listArray = scroller.children[0].children;
			if(!wheel){
				const currentItem = Spotlight.getCurrent();
				let nextItem = currentItem && currentItem.previousElementSibling;
				if(currentItem && nextItem){
					try{
						Spotlight.focus(nextItem);
					}catch(e){
						console.warn('HomeView moveTop -----',e);
					}
					if(nextItem.offsetTop === scrollTop.current){ //contain
						return true;
					}else{
						scrollTop.current = nextItem.offsetTop;
						scrollTo.current({position:{y: nextItem.offsetTop}, animate: true});
						return true;
					}
				}
				return false;
			}
			for(let i=listArray.length-1; i>=0;i--){
				if(listArray[i].offsetTop < scrollTop.current){
					let nextItem = listArray[i].nextElementSibling;
					if (i === 0) {
						dispatch(CommonActions.changeAppStatus({homeBannerSize: 'medium'}));
					}
					if (i !== 0 && nextItem && (listArray[i].getBoundingClientRect().height < nextItem.getBoundingClientRect().height)) {
						nextItem = listArray[i].previousElementSibling;
						const targetOffset = nextItem.offsetTop;
						scrollTop.current = targetOffset;
						scrollTo.current({position:{y: targetOffset}, animate: true});
						return true;
					}
					scrollTop.current = listArray[i].offsetTop;
					scrollTo.current({position:{y:listArray[i].offsetTop},animate: true});
					Spotlight.focus(listArray[i]);
					return true;
				}
			}
		}
	}, [dispatch]);

	const onClickTopGuide = useCallback((ev, bannerSize) => {
		if(!bannerSize){
			bannerSize = currentBannerSize.current;
		}
		if(bannerSize === 'medium'){
			setBigBanner();
		}else if(bannerSize === 'small'){
			if(scrollTop.current === 0){
				const scroller = document.querySelector(`[data-spotlight-id="${SpotlightIds.HOME_SCROLLER}"]`);
				dispatch(CommonActions.changeAppStatus({homeBannerSize: 'medium'}));
			}
			moveTop(true);
		}
	}, [ moveTop, dispatch, setBigBanner]);
	const onClickBottomGuide = useCallback((ev, bannerSize) => {
		if(!bannerSize){
			bannerSize = currentBannerSize.current;
		}
		if(bannerSize === 'medium'){
			dispatch(CommonActions.changeAppStatus({homeBannerSize: 'small'}));
			Spotlight.focus(SpotlightIds.LIST_RECOMMANDED);
			Spotlight.focus(SpotlightIds.LIST_MYFAVORITE);
			try{
				const scroller = document.querySelector(`[data-spotlight-id="${SpotlightIds.HOME_SCROLLER}"]`);
				const firstList = scroller.children[0].children[0];
				if(firstList && firstList.getAttribute){
					Spotlight.focus(firstList.getAttribute('data-spotlight-id'));
				}
			}catch(e){
				console.warn('onClickBottomGuide warning',e);
			}
		}else{
			moveBottom(true);
		}
	}, [moveBottom, dispatch]);

	const onKeyDown = useCallback((ev) => {
		const isDown = (ev.key === 'ArrowDown'|| ev.key === 'PageDown');
		const isUp = (ev.key === 'ArrowUp'|| ev.key === 'PageUp');
		if(currentBannerSize.current === 'medium' && isDown){
			return moveBottom(false, true);
		}else if(ev.key === 'ArrowDown'){
			return moveBottom(false);
		}else if(currentBannerSize.current === 'small' && isUp){
			return moveTop(false);
		}
		return false;
	}, [moveTop, moveBottom]);

	const handleWheel = useCallback((ev) => {
		if(panelsRef.current && panelsRef.current.length > 0){
			return;
		}
		if(!isOnTopRef.current){
			return;
		}
		if(ev.deltaY < 0){
			onClickTopGuide(null, currentBannerSize.current);
		}else{
			onClickBottomGuide(null, currentBannerSize.current);
		}
	}, [onClickTopGuide, onClickBottomGuide]);

	const handleKeydown = useCallback((ev) => {
		if(panelsRef.current && panelsRef.current.length > 0){
			return;
		}
		if(ev && (ev.key >= 0 && ev.key <=9)){
			if(debugKey.current.length >= Config.DEBUG_KEY.length){
				debugKey.current.shift();
			}
			debugKey.current.push(String(ev.key));
			if(debugKey.current.join('') === Config.DEBUG_KEY){
				dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.debugpanel));
			}else if(debugKey.current.join('') === Config.TESTPANEL_KEY){
				dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.testpanel));
			}
		}
		if(ev.key === 'PageDown' || ev.key === 'PageUp'){
			ev.stopPropagation();
			ev.preventDefault();
		}
		if(ev.key === 'PageUp'){
			onClickTopGuide(null, currentBannerSize.current);
		}else if(ev.key === 'PageDown'){
			onClickBottomGuide(null, currentBannerSize.current);
		}
	}, [onClickTopGuide, onClickBottomGuide, onKeyDown, dispatch]);

	const renderList = useCallback(({title, btnTitle, spotlightId, viewList, contentType, ...rest}) => {
		const vList = (visibleList.indexOf(spotlightId) >= 0 && isOnTop && panels.length <=0 ) ? visibleList: [];
		if(viewList && viewList.length >0){
			return (<MediaList {...rest} visibleList={vList} title={title} btnTitle={btnTitle} spotlightId={spotlightId} viewList={viewList}
				contentType={contentType} onKeyDown={onKeyDown} onListFocus={onListFocus}/>);
		}
		return null;
	}, [isOnTop, panels, onKeyDown, onListFocus, visibleList]);

	const contentFav = useMemo(()=>{
		const contents = contentsWatching.concat(contentsMyFavorites);
		contents.sort((a, b) => {
			const timeA = a.appDatetime ? a.appDatetime : a.createDatetime;
			const timeB = b.appDatetime ? b.appDatetime : b.createDatetime;
			if(timeA < timeB){
				 return 1;
			}else if(timeA > timeB){
				return -1;
			}else if(timeA === timeB){
				return 0;
			}else{
				return -1;
			}
		});
		const seasonIds = [];
		const newArray = [];
		for (let i = 0; i < contents.length; i++) {
			if(newArray.length >=Config.MAX_HOME_FAV){
				break;
			}
			if(!seasonIds.includes(contents[i].seasonId)){
				seasonIds.push(contents[i].seasonId);
				newArray.push(contents[i]);
			}
		}
		return newArray;
	}, [contentsWatching, contentsMyFavorites]);

	const onTitleBtnClick = useCallback((listId)=>() => {
		if(listId === SpotlightIds.LIST_MYFAVORITE){
			dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.extendedlist, {title: $L('My Favorite'), listId:SpotlightIds.LIST_MYFAVORITE}));
		}else if(listId === SpotlightIds.LIST_SPECIAL){
			dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.extendedlist, {title: $L('Candy Special'), listId:SpotlightIds.LIST_SPECIAL}));
		}
	}, [dispatch]);
	return (
		<div className={css.bodyarea}>
			<HomeBanner isOnTop={isOnTop} spotlightId={SpotlightIds.HOME_BANNER}/>
			{!showLoadingView &&
				<Scroller
					cbScrollTo={getScrollTo}
					className={classNames(css.scroller, css[homeBannerSize])}
					spotlightId={SpotlightIds.HOME_SCROLLER}
					direction="vertical"
					horizontalScrollbar="hidden"
					verticalScrollbar="hidden"
					scrollMode="translate"
					noScrollByWheel
					noScrollByDrag
					onScrollStop={onScrollStop}
					overscrollEffectOn={{
						arrowKey: false,
						drag: false,
						pageKey: false,
						track: false,
						wheel: false
					}}
				>
					{ contentFav && contentFav.length > 0 &&
						renderList({btnTitle: true, onTitleBtnClick: onTitleBtnClick(SpotlightIds.LIST_MYFAVORITE), title:$L("My Favorite"), spotlightId: SpotlightIds.LIST_MYFAVORITE, viewList: contentFav, contentType: ContentType.SEASON})
					}
					{
						renderList({title:$L("Recommended"), spotlightId: SpotlightIds.LIST_RECOMMANDED, viewList: contentsRecommended, contentType: ContentType.SEASON})
					}
					{
						renderList({btnTitle: true, onTitleBtnClick: onTitleBtnClick(SpotlightIds.LIST_SPECIAL), title:$L("Candy Special"), spotlightId: SpotlightIds.LIST_SPECIAL, viewList: contentsSpecial, contentType: ContentType.SHOW})
					}
					<div className={css.dummy}/>
				</Scroller>
			}
			{homeBannerSize !== 'medium' && (cursorVisible || platform.touchscreen) &&
				<VerticalScrollGuide type={"top"} className={classNames(css.topBottomScrollArea, css[homeBannerSize], css.topScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide: null)} onClick={onClickTopGuide}/>
			}
			{ (cursorVisible || platform.touchscreen)  && !scrollOnBottom &&
				<VerticalScrollGuide type={"bottom"} className={classNames(css.topBottomScrollArea, css.bottomScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide: null)} onClick={onClickBottomGuide}/>
			}
		</div>
	);
};
export default React.memo(HomeView, shallowEqual);
