import classNames from 'classnames';
import React from 'react';
import css from './TPanel.module.less';
import TButton from '../TButton';
import SpotlightIds from '../../data/SpotlightIds';


const THeader = ({className, title, onClick, onKeyDown, noBackButton, reoderMode, ...rest}) => {
	return (
		<div {...rest} onKeyDown={onKeyDown} className={classNames(css.tHeader,className)}>
			{ !noBackButton &&
				<TButton disabled={reoderMode} spotlightId={SpotlightIds.BACK_BUTTON} type={'back'} onClick={onClick} className={css.backBtn}/>
			}
			<div className={classNames(css.title)}>{title}</div>
		</div>
	);
};

export default THeader;
