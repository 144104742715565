import * as lunaSend from '../lunaSend';
import {types} from '../actions/actionTypes'
import {Job} from '@enact/core/util';
import dummyBleConnect from '../../assets/mock/dummyBleConnect.json';
import dummyBleGattConnect from '../../assets/mock/dummyBleGattConnect.json'
import * as CommonActions from './commonActions';

const BLE_JOB_TIME = 10000;

export const CONNECTION_STATUS={
    idle: 'idle',
    searching: 'searching',
    searchingFailed: 'searchingFailed',
    connecting: 'connecting',
    connected: 'connected',
    failed: 'failed'
}

const gattLunaActionTimeOutJob = new Job((_onFail) => {
    console.log('gattLunaActionTimeOutJob ',_onFail);
	_onFail();
}, BLE_JOB_TIME);

let getDeviceHandle = null;
const updateDevicesJob = new Job((dispatch, list) => {
	dispatch({type: types.GET_BLE_DEVICE_LIST, payload: list});
}, 1000);

export const cancelGetDevice = () => () => {
    if(getDeviceHandle){
		getDeviceHandle.cancel();
		getDeviceHandle = null;
	}
}
let scanHandle = null;
export const cancelScan = () => () => {
    if(scanHandle){
		scanHandle.cancel();
		scanHandle = null;
	}
}
let getDeviceResponsed = false;
export const getDevice = ()  => (dispatch, getState) => {
    const webOSVersion = getState().appStatus.webOSVersion;
    dispatch(cancelGetDevice());
    getDeviceResponsed = false;
    const onSuccess = (res) => {
        const listCandy = [];
        if(res.devices && res.devices.length > 0){
            for(let i=0; i<res.devices.length; i++){
                if(res.devices[i].name && res.devices[i].name.indexOf('CANDY') >=0){
                    listCandy.push(res.devices[i]);
                }
            }
        }
        updateDevicesJob.throttle(dispatch, listCandy);
        if(!getDeviceResponsed){
            getDeviceResponsed = true;
            gattLunaActionTimeOutJob.stop();
            console.log('getDevice gattLunaActionTimeOutJob stop 1', res)
            dispatch({type: types.GATT_CONNECT_STATUS, payload:{connectStatus: CONNECTION_STATUS.idle}});
        }
    };
    const onFail = (err) => {
        console.log("getDevice Failure ",err);
        gattLunaActionTimeOutJob.stop();
        console.log('gattLunaActionTimeOutJob stop 2')
        dispatch({type: types.GATT_CONNECT_STATUS, payload:{connectStatus: CONNECTION_STATUS.searchingFailed}});
        if(webOSVersion === 'local'){
            const res = dummyBleConnect;
            onSuccess(res);
        }
        dispatch(cancelScan());
        dispatch(cancelGetDevice());
    }
    gattLunaActionTimeOutJob.start(onFail);
    console.log('gattLunaActionTimeOutJob start 1')
    getDeviceHandle = lunaSend.getDevice({
        onSuccess: onSuccess,
        onFailure: onFail
    });
};

let getDeviceCalled = false;
export const scan = ()  => (dispatch, getState) => {
    const webOSVersion = getState().appStatus.webOSVersion;
    dispatch(cancelScan());
    console.log("scan Call");
    dispatch({type: types.GATT_CONNECT_STATUS, payload:{connectStatus: CONNECTION_STATUS.searching, clientId: 1}});
    getDeviceCalled = false;
    const onSuccess = (res) =>{
        gattLunaActionTimeOutJob.stop();
        if(!getDeviceCalled){
            console.log("scan onSuccess", res);
            dispatch(getDevice());
            getDeviceCalled = true;
        }
    }
    const onFail = (err) =>{
        console.log("scan onFailure ", err);
        gattLunaActionTimeOutJob.stop();
        dispatch(cancelScan());
        console.log('gattLunaActionTimeOutJob stop 3')
        if(webOSVersion === 'local'){
            const res = dummyBleConnect;
            onSuccess(res);
            return;
        }
        dispatch({type: types.GATT_CONNECT_STATUS, payload:{connectStatus: CONNECTION_STATUS.searchingFailed}});
    }
    gattLunaActionTimeOutJob.start(onFail);
    console.log('gattLunaActionTimeOutJob start 2')
	scanHandle = lunaSend.scan({
		onSuccess: onSuccess,
		onFailure: onFail
	});
};

export const cancelScanAndGetDevice = () => (dispatch, getState) => {
    const connectStatus= getState().gattConnectingStatus.connectStatus;
    dispatch(cancelScan());
    dispatch(cancelGetDevice());
    if(connectStatus === 'searching'){
        dispatch({type: types.GATT_CONNECT_STATUS, payload:{connectStatus: CONNECTION_STATUS.idle}});
    }
}

export const gattDiscoverServices = (address) => () =>{
    console.log("gattDiscoverServices call", address);
    lunaSend.gattDiscoverServices(address, {
        onSuccess: (res) => {
            console.log("gattDiscoverServices success", res);
        },
        onFailure: (err) => {
            console.log("gattDiscoverServices error", err);
        }
    })
}

let gattConnectHandle = null;
export const cancelGattConnect = () => (dispatch, getState) => {
    const connectStatus= getState().gattConnectingStatus.connectStatus;
    if(gattConnectHandle){
		gattConnectHandle.cancel();
		gattConnectHandle = null;
	}
    if(connectStatus === 'connecting'){
        dispatch({type: types.GATT_CONNECT_STATUS, payload:{connectStatus: CONNECTION_STATUS.idle}});
    }
}

export const gattConnect = (address, callback) => (dispatch, getState) => {
    const webOSVersion = getState().appStatus.webOSVersion;
    const deviceName = 'CANDY-'+address.substr(12).toUpperCase();
    dispatch(cancelScanAndGetDevice());
    dispatch(cancelGattConnect());
    console.log('gattConnect call', address);
    dispatch({type: types.GATT_CONNECT_STATUS, payload:{connectStatus: CONNECTION_STATUS.connecting}});
    const onSuccess = (res) => {
        console.log('gattConnect success', res);
        gattLunaActionTimeOutJob.stop();
        dispatch({type: types.GATT_CONNECT_STATUS, payload:{connectStatus: CONNECTION_STATUS.connected, address: res.address, clientId: res.clientId, name: deviceName}});
        dispatch(CommonActions.changeLocalSettings({lastConnectedBleAddress: address}));
        dispatch(gattDiscoverServices(address));
        if(callback){
            callback(CONNECTION_STATUS.connected, res.clientId);
        }
    }
    const onFail = (err) => {
        gattLunaActionTimeOutJob.stop();
        console.log("gattConnect Failure ",err);
        if(webOSVersion === 'local'){
            const res = dummyBleGattConnect;
            onSuccess(res);
        }else{
            dispatch({type: types.GATT_CONNECT_STATUS, payload:{connectStatus: CONNECTION_STATUS.failed, address: address, clientId: 0, name: deviceName}});
            if(callback){
                callback(CONNECTION_STATUS.failed);
            }
        }
    };
    gattLunaActionTimeOutJob.start(onFail);
    gattConnectHandle = lunaSend.gattConnect(address, {
        onSuccess: onSuccess,
        onFailure: onFail
    });
}

export const gattDisconnect = () => (dispatch) => {
    const onSuccess = (res) => {
        console.log('gattDisconnect success', res);
        dispatch({type: types.GATT_CONNECT_STATUS, payload: {connectStatus: CONNECTION_STATUS.idle, address:'', clientId: ''}});
        dispatch(CommonActions.changeLocalSettings({lastConnectedBleAddress: ""}));
    };
    onSuccess();
}

/**
 *
 * @param {status: CONNECTION_STATUS, cliendId} callback
 * @returns
 */
export const gattAutoConnect = (callback) => (dispatch, getState) => {
    console.log('gattAutoConnect');
    const connectStatus= getState().gattConnectingStatus.connectStatus;
    const lastConnectedBleAddress = getState().localSettings.lastConnectedBleAddress;
    if(lastConnectedBleAddress){
        console.log('gattAutoConnect......');
        dispatch(gattConnect(lastConnectedBleAddress, callback));
    }else{
        if(callback){
            callback(connectStatus);
        }
    }
};

export const gattWriteCharacteristicValue = (gattConnectedClientId) => (dispatch, getState) =>{
    const connectStatus= getState().gattConnectingStatus.connectStatus;
    if(!gattConnectedClientId){
        gattConnectedClientId = connectStatus.clientId;
    }
    console.log("gattWriteCharacteristicValue call", gattConnectedClientId);
    lunaSend.gattWriteCharacteristicValue(gattConnectedClientId, {
        onSuccess: (res) => {
            console.log("gattWriteCharacteristicValue success", res);
        },
        onFailure: (err) => {
            console.log("gattWriteCharacteristicValue error", err);
        }
    })
}

export const gattWriteDescriptorValue = (gattConnectedClientId) => (dispatch, getState) => {
    const connectStatus= getState().gattConnectingStatus.connectStatus;
    if(!gattConnectedClientId){
        gattConnectedClientId = connectStatus.clientId;
    }
    console.log("gattWriteDescriptorValue call", gattConnectedClientId);
    lunaSend.gattWriteDescriptorValue(gattConnectedClientId, {
        onSuccess: (res) => {
            console.log("gattWriteDescriptorValue", res);
        },
        onFailure: (err) => {
            console.log("gattWriteDescriptorValue", err);
        }
    })
}

export const gattWriteDescriptorValueOff = (gattConnectedClientId) => (dispatch, getState) => {
    const connectStatus= getState().gattConnectingStatus.connectStatus;
    if(!gattConnectedClientId){
        gattConnectedClientId = connectStatus.clientId;
    }
    console.log("gattWriteDescriptorValueOff call", gattConnectedClientId);
    lunaSend.gattWriteDescriptorValueOff(gattConnectedClientId, {
        onSuccess: (res) => {
            console.log("gattWriteDescriptorValueOff", res);
        },
        onFailure: (err) => {
            console.log("gattWriteDescriptorValueOff", err);
        }
    })
}

export const gattMonitorCharacteristics = (gattConnectedClientId) => (dispatch, getState) => {
    const connectStatus= getState().gattConnectingStatus.connectStatus;
    if(!gattConnectedClientId){
        gattConnectedClientId = connectStatus.clientId;
    }
    console.log("gattMonitorCharacteristics call", gattConnectedClientId);
    lunaSend.gattMonitorCharacteristics(gattConnectedClientId, {
        onSuccess: (res) => {
            // console.log("gattMonitorCharacteristics success", res);
            if(res.changed){
                // console.log("gattMonitorCharacteristics success changed", res.changed.value.bytes);
                const getValue = res.changed.value.bytes;
                const getValueFirst = getValue[0];
                const getValueSecond = getValue[1];
                if(getValueFirst === 42 && getValueSecond === 3){
                    dispatch({type: types.GATT_EXERCISE_RESULT_VALUE, payload:{heartRate: getValue[3]}});
                }else if(getValueFirst === 42 && getValueSecond === 4){
                    dispatch({type: types.GATT_EXERCISE_RESULT_VALUE, payload:{steps: getValue[3] * 256 + getValue[4], calorie: getValue[5] * 256 + getValue[6]}});
                }else if(getValueFirst === 26 && getValueSecond === 6){
                    dispatch({type: types.GATT_EXERCISE_RESULT_VALUE, payload:{battery: getValue[3]}});
                }
            }
        },
        onFailure: (err) => {
            console.log("gattMonitorCharacteristics error", err);
        }
    })
}

export const workoutStart = (gattConnectedClientId) => (dispatch, getState) =>{
    dispatch(gattWriteCharacteristicValue(gattConnectedClientId));
    dispatch({type: types.GATT_EXERCISE_RESULT_VALUE, payload:{steps: 0, calorie: 0, heartRate: 0}});
    const connectStatus= getState().gattConnectingStatus.connectStatus;
    if(!gattConnectedClientId){
        gattConnectedClientId = connectStatus.clientId;
    }
    console.log("workoutStart call", gattConnectedClientId);
    lunaSend.workoutStart(gattConnectedClientId, {
        onSuccess: (res) => {
            console.log("workoutStart success", res);
            dispatch(gattWriteDescriptorValue(gattConnectedClientId));
            dispatch(gattMonitorCharacteristics(gattConnectedClientId));
        },
        onFailure: (err) => {
            console.log("workoutStart Failure ", err);
            dispatch({type: types.GATT_CONNECT_STATUS, payload:{connectStatus: CONNECTION_STATUS.failed}});
        }
    })
}

export const workoutPause = (gattConnectedClientId) => (dispatch, getState) =>{
    const clientId= getState().gattConnectingStatus.clientId;
    if(!gattConnectedClientId){
        gattConnectedClientId = clientId;
    }
    console.log("workoutPause call", gattConnectedClientId);
    lunaSend.workoutPause(gattConnectedClientId, {
        onSuccess: (res) => {
            console.log("workoutPause success", res);
        },
        onFailure: (err) => {
            console.log("workoutPause error", err);
        }
    })
}

export const workoutResume = (gattConnectedClientId) => (dispatch, getState) =>{
    const clientId= getState().gattConnectingStatus.clientId;
    if(!gattConnectedClientId){
        gattConnectedClientId = clientId;
    }
    console.log("workoutResume call", gattConnectedClientId);
    lunaSend.workoutResume(gattConnectedClientId, {
        onSuccess: (res) => {
            console.log("workoutResume success", res);
        },
        onFailure: (err) => {
            console.log("workoutResume error", err);
        }
    })
}

export const workoutStop = (gattConnectedClientId) => (dispatch, getState) =>{
    const clientId= getState().gattConnectingStatus.clientId;
    console.log('workoutStop', clientId);
    if(!gattConnectedClientId){
        gattConnectedClientId = clientId;
    }
    console.log("workoutStop call", gattConnectedClientId);
    lunaSend.workoutStop(gattConnectedClientId, {
        onSuccess: (res) => {
            console.log("workoutStop success", res);
            dispatch(gattWriteDescriptorValueOff(gattConnectedClientId));
        },
        onFailure: (err) => {
            console.log("workoutStop error", err);
        }
    })
}


