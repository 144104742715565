import classNames from 'classnames';
import {useState, useCallback, useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import css from './SettingPanel.module.less';
import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/TPanel/THeader';
import TButton from '../../../components/TButton/TButton';
import {$L} from '../../../utils/common';
import * as PanelActions from '../../../actions/panelActions';
import * as CandyActions from '../../../actions/candyActions';
import OpenSourceDetail from './SettingsTab/OpenSource/OpenSourceDetail';
import TermsDetail from './SettingsTab/TermsService/TermsDetail';
import AccountSetting from './SettingsTab/AccountSetting/AccountSetting';
import SubscribeSetting from './SettingsTab/SubscibeSetting/SubscribeSetting'
import ExerciseSetting from './SettingsTab/ExerciseSetting/ExerciseSetting'
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import Spottable from '@enact/spotlight/Spottable';
import Spotlight from '@enact/spotlight';

const Container = SpotlightContainerDecorator({enterTo: "last-focused", preserveId: true}, Spottable('div'));

const SettingPanel = ({panelInfo, ...rest}) => {
	const [selectedId, setSelectedId] = useState('subscribe');
	const dispatch = useDispatch();

	useEffect(() => {
		Spotlight.focus('subscribe');
		dispatch(CandyActions.getUserPaymentInfo());
	}, [dispatch]);

	useEffect(() => {
		if(panelInfo.selectedTabId){
			setSelectedId(panelInfo.selectedTabId)
		}
	}, [panelInfo]);
	const groupList = useMemo(() => {
		let list = [];
		list.push({ id: 'subscribe', children: $L('구독 관리') });
		list.push({ id: 'exerciseSetting', children: $L('운동 설정') });
		list.push({ id: 'accountSetting', children: $L('계정 설정') });
		list.push({ id: 'terms', children: $L('이용 약관')});
		list.push({ id: 'openSource', children:$L('오픈소스 고지')});
		return list;
	}, []);

	const currentTab = useMemo(() => {
		switch (selectedId) {
			case 'subscribe': {
				return <SubscribeSetting/>;
			}
			case 'exerciseSetting':{
				return <ExerciseSetting panelInfo={panelInfo}/>;
			}
			case 'accountSetting': {
				return <AccountSetting/>;
			}
			case 'terms':{
				return <TermsDetail/>;
			}
			case 'openSource':{
				return <OpenSourceDetail/>;
			}
			default:{
				return <SubscribeSetting/>;
			}
		}
	}, [selectedId, panelInfo]);

	const onSelectMenu = useCallback((ev) => {
		if(ev.currentTarget.dataset.spotlightId){
			setSelectedId(ev.currentTarget.dataset.spotlightId);
		}
	}, []);

	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		dispatch(PanelActions.popPanel());
	}, [dispatch]);

	return (
		<TPanel {...rest} className={css.panel} handleCancel={onBack}>
			<THeader title={$L('Settings')} onClick={onBack}/>
			<div className={css.tabLayout}>
				<Container>
				{
					groupList.map(menu => (
						<TButton
							className={classNames(css.tab)}
							onClick={onSelectMenu}
							type={"tabBtnVertical"}
							data-webos-voice-label={menu.voicelabel? menu.voicelabel: menu.children}
							key={menu.id} spotlightId={menu.id}
							selected={menu.id===selectedId}
						>
							{menu.children}
						</TButton>
							))
					}
				</Container>
				{currentTab}
			</div>
		</TPanel>
	);
};


export default SettingPanel;
