import { useCallback, useEffect, useMemo } from 'react';
import Scroller from '@enact/sandstone/Scroller';
import Spotlight from '@enact/spotlight';
import { useDispatch, useSelector } from 'react-redux';
import TButton from '../../components/TButton';
import TPanel from '../../components/TPanel';
import {$L} from '../../utils/common';
import * as Utils from '../../utils/common';
import css from './TermsModification.module.less';
import * as PanelActions from '../../actions/panelActions';
import {PANEL_NAME} from '../../actions/panelActions';

const TermsModification = ({hideChildren}) => {
	const dispatch = useDispatch();
	const termsModification = useSelector(state => state.termsModification);

	const termsDateInfo = useMemo(() => {
		let _termsDateInfo = {descriptionDate:"", effectiveDate:""};
		const date = new Date(termsModification.applicationDate).toLocaleDateString("default", { year: 'numeric', month: 'short', day: 'numeric' });
		let descriptionDate = $L("These will take effect on {date}.");
		descriptionDate = descriptionDate.replace('{date}', date);
		_termsDateInfo.descriptionDate = descriptionDate;
		let effectiveDate = $L("The changed terms and conditions are effective as of {date}.");
		effectiveDate = effectiveDate.replace('{date}', date);
		_termsDateInfo.effectiveDate = effectiveDate;
		return _termsDateInfo;
	}, [termsModification]);

	const termsModificationInfo = useMemo(() => {
		let _termsModificationInfo = [];
		if (termsModification) {
			if (termsModification.clause) {
				for (let i = 0; i < termsModification.clause.length; i++) {
					let termsInfo = { title: "", oldTerms: "", newTerms: "" };
					termsInfo.oldTerms = Utils.convertNormalStr(termsModification.clause[i].beforeClause);
					termsInfo.newTerms = Utils.convertNormalStr(termsModification.clause[i].afterClause);
					termsInfo.title = Utils.convertNormalStr(termsModification.clause[i].title);
					_termsModificationInfo.push(termsInfo);
				}
			}
		}
		return _termsModificationInfo;
	}, [termsModification]);

	useEffect(() => {
		if(!hideChildren){
			Spotlight.focus("button1");
		}
	}, [hideChildren]);

	const onClickButton0 = useCallback(() => {
		dispatch(PanelActions.popPanel(PANEL_NAME.termsConditions));
	}, [termsModification, dispatch]);

	const onClickButton1 = useCallback(() => {
		dispatch(PanelActions.popPanel(PANEL_NAME.termsConditions));
	}, [dispatch]);

	return (
		<TPanel className={css.panel}>
			<div className={css.header}>
				<div className={css.mainTilte}>{termsModification.title}</div>
				<div className={css.descriptionInfo}>{termsModification.description}</div>
				<div className={css.descriptionDate}>{termsDateInfo.descriptionDate}</div>
			</div>
				<div className={css.textBoxTitle}>{$L("The key updates")}</div>
				<div className={css.scrollerWrap}>
					<Scroller
						scrollMode="translate"
						focusableScrollbar
						className={css.scroller}
						direction={'vertical'}
						overscrollEffectOn={{
							arrowKey: false,
							drag: false,
							pageKey: false,
							track: false,
							wheel: false
						}}
					>
					{termsModificationInfo && termsModificationInfo.map(item =>
						<>
							<div className={css.termsTitle} >{item.title}</div>
							<div className={css.termsDetail}>
								<div className={css.termsDetailTitle}>{$L("Old Terms")}</div>
								<div className={css.termsDetailInfo}>{item.oldTerms}</div>
							</div>
							<div className={css.termsDetail}>
								<div className={css.termsDetailTitle}>{$L("New Terms")}</div>
								<div className={css.termsDetailInfo}>{item.newTerms}</div>
							</div>
						</>
					)}
					</Scroller>
				</div>
				<div className={css.effective}>
					<div className={css.effectiveTitle}>{$L("Effective")}</div>
					<div className={css.effectiveDate}>{termsDateInfo.effectiveDate}</div>
				</div>
				<div className={css.termsAgreeInfo}>{$L("If you do not express your intention of refusal by the date of application of the revised terms after receiving this notice, you will be deemed to have agreed to the revised terms and conditions and the changed terms and conditions and personal information handling (consent to collection and use) policy will be applied.")}</div>
			<div className={css.bottomBtnLayer}>
				<TButton className={css.nomalBtn} spotlightId={"button0"} onClick={onClickButton0}>{$L("Don't show again")}</TButton>
				<TButton className={css.nomalBtn} spotlightId={"button1"} onClick={onClickButton1}>{$L("OK")}</TButton>
			</div>
		</TPanel>
	);
};

export default TermsModification;