import {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import css from './ConnectSuccess.module.less';
import {$L} from '../../../../../../../utils/common';
import TButton from '../../../../../../../components/TButton/TButton';
import * as BleActions from '../../../../../../../actions/bleActions'
import Spotlight from '@enact/spotlight';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';

const Container = SpotlightContainerDecorator({enterTo: 'last-focused', preserveId: true}, 'div');

const ConnectSuccess = ({onDisconnected, ...props}) => {
    const dispatch = useDispatch();
    const gattConnectingStatus = useSelector(state => state.gattConnectingStatus)

    useEffect(()=>{
        Spotlight.focus("disconnectBtn");
    },[])

    const onClickDisconnect = useCallback(()=>{
        dispatch(BleActions.gattDisconnect(gattConnectingStatus.clientId));
        dispatch(BleActions.gattWriteDescriptorValueOff(gattConnectingStatus.clientId));
        if(onDisconnected){
            onDisconnected();
        }
    }, [dispatch, onDisconnected, gattConnectingStatus])

    const candyBand = useMemo(()=>{
        return $L('<span>Candy Band</span>에 연결되어 있습니다.')
    }, [])

	return (
        <div>
            <Container className={css.container} {...props}>
                <div className={css.title} dangerouslySetInnerHTML={{__html: candyBand}}/>
                <div className={css.nameBox}>{gattConnectingStatus.name}</div>
                <div className={css.candyImgStyle} />
                <TButton size="small" spotlightId={'disconnectBtn'} onClick={onClickDisconnect}>{$L('해제')}</TButton>
            </Container>
        </div>
    )
};

export default ConnectSuccess;