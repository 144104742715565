import React, {useMemo} from 'react';
import classNames from 'classnames';
import HistoryData from '../History/HistoryData';
import css from './MyGoal.module.less';
import {useSelector} from 'react-redux';
import {$L} from '../../../utils/common';

const MyGoal = ({className}) => {
    const userPreference = useSelector((state) => state.userPreference);
    const data = useMemo(() => {
        const ret = {time: 0, kcal: 0, walk: 0};
        ret.time = userPreference.goalWorkoutTime;
        ret.kcal = userPreference.goalCalorie;
        ret.step = userPreference.goalStepCount;
        return ret;
    }, [userPreference]);
    return (
        <div className={classNames(css.myGoal, className)}>
            <div className={css.goalLayer}>
                <div className={css.h2}>{$L('운동 목표')}</div>
                <HistoryData className={css.historyData} {...data} />
            </div>
        </div>
    );
};

export default React.memo(MyGoal);
