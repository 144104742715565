import {render} from 'react-dom';
import {Provider} from 'react-redux';
import App from './App';
import configureStore from './store';
import css from './style/CommonStyle.module.less';
import { lunaTest } from './lunaSend/ble';
import * as Config from './data/Config';
import * as CandyActions from './actions/candyActions';

const store = configureStore();

let appElement = (
	<Provider store={store}>
		<App className={css.root}/>
	</Provider>
);

if (typeof window === 'object') {
	window.store = store;
  	window.lunaTest = (service, method, subscribe, parameters) => lunaTest(service, method, subscribe, parameters);
	window.XMLHttpRequest.prototype.open = (function(open){
		return function(method,url) {
			open.apply(this,arguments);
			if(method === 'GET' && (url.endsWith('.m3u8') || url.endsWith('.ts') || url.endsWith('.vtt'))){
				this.setRequestHeader('x-api-key', Config.X_API_KEY);
				if(CandyActions.AUTHORIZATION.headers.authorization){
					this.setRequestHeader('authorization', CandyActions.AUTHORIZATION.headers.authorization);
				}
			}
		}
	  })(window.XMLHttpRequest.prototype.open);

	render(
		appElement,
		document.getElementById('root')
	);

	appElement = null;
}

export default appElement;
