/**
 * Item Module of contents list
 *
 * @module MediaItem
 */
import React, {useCallback, useEffect, useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import classNames from 'classnames';
import Spottable from '@enact/spotlight/Spottable';
import {Cell, Column} from '@enact/ui/Layout';
import compose from 'ramda/src/compose';


import {Image} from '@enact/sandstone/Image';
import {Marquee, MarqueeController} from '@enact/sandstone/Marquee';
import {VoiceControlDecorator} from '@enact/webos/speech';


import css from './MediaItem.module.less';

import SpotlightIds from '../../data/SpotlightIds';
import {$L} from '../../utils/common';
import * as ContentType from '../../data/ContentType';
import * as Utils from '../../utils/common';
import AnimationFocus from '../AnimationFocus';
import * as Config from '../../data/Config';
import CustomImage from '../CustomImage';
import dummyImage from '../../../assets/dummyImage.png';

const MediaItemBase = ({caption, contentType,
	onItemClick, forceFocus, listspotlightid, style,
	itemInfo, playing, ...rest}) => {

	const showInfos = useSelector(state => state.showInfos);

	const updatedShowInfos = useSelector(state => state.updatedShowInfos, (newState) => {
		if(!itemInfo || !newState){
			return true;
		}
		if(contentType === ContentType.SEASON && newState[itemInfo.showId]){
			return false;
		}
		return true;//it's same item. Will not update.
	});

	const itemDescription = useMemo(() => {
		let res= {title: "", subTitle: "", thumbnail: ""};
		if(itemInfo){
			if(contentType === ContentType.SHOW){
				res.title = Utils.convertUtf8ToUni(itemInfo.showTitle);
				res.subTitle = (itemInfo && itemInfo.seasons) ? itemInfo.seasons.length +' '+$L('Seasons') : "";
				res.thumbnail = itemInfo.showThumbnailUrl;
			}else if(contentType === ContentType.SEASON){
				if(showInfos[itemInfo.showId]){
					res.title = Utils.convertUtf8ToUni(showInfos[itemInfo.showId].showTitle);
				}
				res.subTitle = Utils.convertUtf8ToUni(itemInfo.seasonTitle);
				res.thumbnail = itemInfo.seasonThumbnailUrl;
			}else if(contentType === ContentType.EPISODE){
				res.title= Utils.convertUtf8ToUni(itemInfo.episodeTitle);
				res.subTitle= Utils.convertUtf8ToUni(itemInfo.instructors && itemInfo.instructors[0].title);
				res.thumbnail = itemInfo.episodeThumbnailUrl;
				res.playtime = Utils.transSecToText(itemInfo.episodeRunningTime);
			}else if(contentType === ContentType.PRODUCT){
				res.title= Utils.convertUtf8ToUni(itemInfo.productBrandTitle);
				res.subTitle= Utils.convertUtf8ToUni(itemInfo.productTitle);
				res.thumbnail = itemInfo.productThumbnail;
			}
			if( res.title.indexOf('\n') >=0){
				res.title = res.title.replace('\n', "");
			}
			if( res.subTitle.indexOf('\n') >=0){
				res.subTitle = res.subTitle.replace('\n', "");
			}
			if(Config.DEMO && res.thumbnail && res.thumbnail.indexOf('dummyImage')>=0){
				res.thumbnail = dummyImage;
			}
		}
		return res;
	}, [contentType, itemInfo, showInfos, updatedShowInfos]);

	const onClick = useCallback((ev) => {
		console.log('onClick..', ev);
		if(onItemClick){
			onItemClick(ev);
		}
		ev.stopPropagation();
		ev.preventDefault();
	}, [onItemClick]);

	const captionContainer = useCallback((_isSpecialButton) => {
		const marqueeAlign = 'left';
		if(!itemInfo){
			return null;
		}
		return (<Cell align="center" key="captionContainer" className={classNames(css.captionContainer)} >
			<Marquee marqueeDisabled={!forceFocus} alignment={marqueeAlign} key="title" className={classNames(css.caption,css.title, contentType === ContentType.SEASON ? css.reverse: null)} marqueeOn={forceFocus ? "render" : undefined}>{Utils.replaceHtmlEntites(itemDescription.title)}</Marquee>
			<Marquee marqueeDisabled={!forceFocus} alignment={marqueeAlign} key="subtitle" className={classNames(css.caption,css.subtitle, contentType === ContentType.SEASON ? css.reverse: null)} marqueeOn={forceFocus ? "render" : undefined}>{itemDescription.subTitle}</Marquee>
		</Cell>
		);
	}, [itemInfo, itemDescription,forceFocus, contentType]);

	const isMoreButton = itemInfo && itemInfo.contentType === ContentType.MORE;
	const isSpecialButton = isMoreButton;
	const playtime = itemInfo && itemInfo.playtime ? Utils.transSecToText(itemInfo.playtime) : null;

	delete rest.itemSize;
	return (
		// <AnimationFocus focused={forceFocus} spotlightId={rest['data-spotlight-id']}>
			<div {...rest}
					className={classNames(forceFocus ? css.forceFocus: null, css.mediaImage)} style={style}
					onClick={onClick}
				>
				<Column className={classNames(css.imageItem)}>
					<Cell key="thumbContainer" className={classNames(css.thumbContainer,isSpecialButton? css.specialBtnContainer: null)}>
						{itemDescription.thumbnail &&
							<CustomImage className={css.image} src={itemDescription.thumbnail} delay={0} animationSpeed={"fast"}/>
						}
						{captionContainer(isSpecialButton)}
						{!forceFocus && itemDescription.playtime &&
							<div className={css.subtime}>{itemDescription.playtime}</div>
						}
						{forceFocus && contentType === ContentType.EPISODE &&
							<div className={css.playIcon}/>
						}
					</Cell>
				</Column>
				<div className={classNames(forceFocus ? css.effectFocus: null)}/>
		</div>
		// </AnimationFocus>
	);
};

const ImageItemDecorator = compose(
	MarqueeController({marqueeOnFocus: true}),
	VoiceControlDecorator,
	Spottable
);
const MediaItem = ImageItemDecorator(MediaItemBase);
export default MediaItem;
