import {useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import css from './GoalSetting.module.less';
import $L from '@enact/sandstone/internal/$L';
import TButton from '../../../../../../components/TButton/TButton';
import Spottable from '@enact/spotlight/Spottable';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import TimeSetting from './TimeSetting/TimeSetting';
import CalorieSetting from './CalorieSetting/CalorieSetting';
import WalkSetting from './WalkSetting/WalkSetting';
import Spotlight from '@enact/spotlight';

const Container = SpotlightContainerDecorator({enterTo: 'last-focused', preserveId: true}, Spottable('div'));
const GoalSetting = ({spotlight}) => {
	const userPreference = useSelector(state => state.userPreference);
    const [timeSetting, setTimeSetting] = useState(false);
	const [calorieSetting, setCaloreiSetting] = useState(false);
	const [walkSetting, setWalkSetting] = useState(false);

	const transSecToHourMinute = (sec) => {
		if(isNaN(sec)){
			sec = 0;
		}
		const hh = Math.floor(sec/3600);
		const mm = Math.floor(Math.round(sec-hh*3600)/60);
		const strHH =  String(hh).length === 1 ? "0"+String(hh) : String(hh);
		const strMM =  String(mm).length === 1 ? "0"+String(mm) : String(mm);
		return (strHH+" 시간"+" "+strMM+" 분")
	};

	useEffect(()=>{
		Spotlight.focus(spotlight);
	},[])

	const onOpenTimeSetting = useCallback(() => {
		console.log("onOpenTimeSetting")
		setTimeSetting(true);
	}, []);

	const onOpenCalorieSetting = useCallback(() => {
		setCaloreiSetting(true);
	}, []);

	const onOpenWalkSetting = useCallback(() => {
		setWalkSetting(true);
	}, []);

	const onCloseTimeSetting = useCallback(() => {
		setTimeSetting(false);
		// Spotlight.focus('timeSetting');
	}, []);

	const onCloseCalorieSetting = useCallback(() => {
		setCaloreiSetting(false);
		// Spotlight.focus('calorieSetting');
	}, []);
	const onCloseWalkSetting = useCallback(() => {
		setWalkSetting(false);
		// Spotlight.focus('walkSetting');
	}, []);

	return (
        <Container className={css.container}>
            <div className={css.title}> {$L('운동 시간')}</div>
            <TButton onClick={onOpenTimeSetting} type="grayLineBtn" spotlightId={"timeSetting"} className={css.setting}>
				{transSecToHourMinute(userPreference.goalWorkoutTime)}
			</TButton>
			<TimeSetting onClose={onCloseTimeSetting} open={timeSetting}/>
            <div className={css.title}> {$L('칼로리')}</div>
            <TButton onClick={onOpenCalorieSetting} type="grayLineBtn" spotlightId={"calorieSetting"} className={css.setting}>
				{userPreference.goalCalorie}
			</TButton>
			<CalorieSetting onClose={onCloseCalorieSetting} open={calorieSetting}/>
            <div className={css.title}> {$L('걸음 수')}</div>
            <TButton onClick={onOpenWalkSetting} type="grayLineBtn" spotlightId={"walkSetting"} className={css.setting}>
				{userPreference.goalStepCount}
			</TButton>
			<WalkSetting onClose={onCloseWalkSetting} open={walkSetting}/>
        </Container>
    )
};

export default GoalSetting;