import classNames from 'classnames';
import {useState, useCallback, useEffect, useMemo } from 'react';
import {useDispatch} from 'react-redux';
import css from './ExerciseSetting.module.less';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import {$L} from '../../../../../utils/common';
import TButton from '../../../../../components/TButton/TButton';
import DeviceSetting from './DeviceSetting/DeviceSetting';
import GoalSetting from './GoalSetting/GoalSetting';
import PlaySetting from './PlaySetting/PlaySetting';
import * as BleActions from '../../../../../actions/bleActions';
import SubscribeSetting from './../SubscibeSetting/SubscribeSetting'
import Spottable from '@enact/spotlight/Spottable';
import Spotlight from '@enact/spotlight';

const Container = SpotlightContainerDecorator({enterTo: 'last-focused'}, 'div');
const ExerciseSetting = ({spotlightId, panelInfo, ...rest}) => {
	const dispatch = useDispatch();
	const [selectedId, setSelectedId] = useState('goalSetting');
	const [spotlight, setSpotlight] = useState('');

	useEffect(() => {
		if(panelInfo.selectedTabId === 'exerciseSetting'){
			setSelectedId('deviceSetting');
		}
	}, [panelInfo]);

	const groupList = useMemo(() => {
		let list = [];
		list.push({ id: 'goalSetting', children: $L('목표 설정') });
		list.push({ id: 'deviceSetting', children: $L('기기 설정') });
		list.push({ id: 'playSetting', children: $L('재생 관리') });
		return list;
	}, []);

	const onSelectMenu = useCallback((ev) => {
		console.log('onSelectMenu1', ev.currentTarget.dataset.spotlightId, ev);
		if(ev.currentTarget.dataset.spotlightId){
			setSelectedId(ev.currentTarget.dataset.spotlightId);
			console.log('onSelectMenu2', ev.currentTarget.dataset.spotlightId, ev);
			if(ev.currentTarget.dataset.spotlightId === 'deviceSetting'){
				dispatch(BleActions.gattAutoConnect());
			}else if(ev.currentTarget.dataset.spotlightId === 'goalSetting'){
				setSpotlight('timeSetting');
			}
		}
	}, [dispatch]);

	const currentTab = useMemo(() => {
		switch (selectedId) {
			case 'goalSetting': {
				return <GoalSetting spotlight={spotlight}/>;
			}
			case 'deviceSetting':{
				return <DeviceSetting/>;
			}
			case 'playSetting': {
				return <PlaySetting/>;
			}
			default:{
				return <SubscribeSetting/>;
			}
		}
	}, [selectedId]);

	return (
		<div {...rest} className={css.exerciseContainer}>
			<Container spotlightId={spotlightId}>
				<div className={css.header}>{$L('운동 설정')}</div>
				<Container className={css.tabLayout}>
					{
						groupList.map(menu => (
							<TButton
								key={menu.id}
								spotlightId={menu.id}
								value={menu.id}
								className={classNames(css.tab)}
								type={'tabBtnHorizon'}
								onClick={onSelectMenu}
								selected={menu.id===selectedId}
							>
							{menu.children}
							</TButton>
						))
					}
				</Container>
				{currentTab}
			</Container>
		</div>
	);
};


export default ExerciseSetting;
