import { useCallback, useEffect, useState, useMemo } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import css from './TermsPopup.module.less';
import Alert from '@enact/sandstone/Alert';
import TAlert from '../../components/TAlert';
import TButton from '../../components/TButton';
import TCheckBox from '../../components/TCheckBox';
import * as CandyActions from '../../actions/candyActions';
import * as Utils from '../../utils/common';
import {$L} from '../../utils/common';
import Spotlight from '@enact/spotlight';
import Spottable from '@enact/spotlight/Spottable';
import TermsPopupDetail from '../TermsPopup/TermsPopupDetail';
import usePrevious from '../../utils/usePrevious';
import * as CommonActions from '../../actions/commonActions';
import * as PanelActions from '../../actions/panelActions';

const Container = SpotlightContainerDecorator({enterTo: "last-focused", preserveId: true}, Spottable('div'));

const TermsPopup = ({onClosePopup}) => {
	const getTermsDoc = useSelector(state => state.getTermsDoc);
	const [checked, setChecked] = useState(new Array(getTermsDoc.length));
	const [detailIndex, setDetailIndex] = useState(0);
	const dispatch = useDispatch();
	const [checkPopupOpen, setCheckPopupOpen] = useState({open: false, target: ""});
	const [showDetail, setShowDetail] = useState(false);
	const prevShowDetail = usePrevious(showDetail);

	useEffect(() => {
		Spotlight.focus('termsItemLayer');
	}, []);
	useEffect(() => {
		if(!showDetail && prevShowDetail === true){
			Spotlight.focus('termsItemLayer');
		}
	}, [showDetail, prevShowDetail]);
	const onClickTermBtn = useCallback((index)=>() => {
		setShowDetail(true);
		setDetailIndex(index);
    }, []);

	const currentTerms = useMemo(()=>{
		return getTermsDoc[detailIndex];
	},[getTermsDoc, detailIndex]);

	const termsAccepted = useMemo(()=>{
		for(let i=0; i < checked.length; i++){
			if(!checked[i]){
				return false;
			}
		}
		return true;
	},[checked]);

	const onBack = useCallback((ev) => {
		dispatch(CommonActions.changeAppStatus({showTermsPopup: false}));
		dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.codelogin));
		dispatch(PanelActions.popPanel(PanelActions.PANEL_NAME.testpanel));
	},[] );

	const onClickAgree = useCallback(() => {
		// if(!termsAccepted){
		// 	setTimeout(() => {
		// 		Utils.windowClose();
		// 	}, 3000);
		// }else
		const terms = [];
			for(let i=0; i<getTermsDoc.length;i++){
				terms.push({termsId: getTermsDoc[i].termsId, version: getTermsDoc[i].version});
			}
		dispatch(CandyActions.setUserAgreement(terms));
		dispatch(CommonActions.changeAppStatus({showTermsPopup: false}));
		// }
    	// return;
	}, [dispatch, termsAccepted, getTermsDoc]);

	const onCheckpopupCancel = useCallback(() => {
		setCheckPopupOpen({open: false});
		Spotlight.focus('cancelBtn');
	},[]);

	const onCloseCheckPopup = useCallback((ev) => {
		if(ev === 0){
			dispatch(CandyActions.logout());
			setTimeout(() => {
					Utils.windowClose();
			}, 1000);
		}else{
			onCheckpopupCancel();
		}
	}, [onCheckpopupCancel, dispatch]);

	const onToggleTermsAgree = useCallback((index)=>({selected}) => {
		const newChecked = [...checked];
		newChecked[index] = selected;
		setChecked(newChecked);
	},[checked]);

	const _onClose = useCallback((ev) => {
		if (onClosePopup) {
			onClosePopup();
		}
	}, [onClosePopup]);

	// const alertMsg = $L('If you withdraw from the terms and conditions, you will not be able to use the service. Would you like to continue?')

	return (
		<Alert className={css.termsPopup} open type={"overlay"} onClose={_onClose}>
			<div className={css.title}>{$L('약관 동의')}</div>
			<div className={css.subTitle}>{$L('가입을 환영합니다. 약관에 동의해주세요.')}</div>
			<Container className={css.termsItemsLayer} spotlightId={"termsItemLayer"}>
				{Array.isArray(getTermsDoc) && getTermsDoc.map((item, index) => {
					return (
						<div key={index} className={css.termsItemsRow}>
							<TCheckBox className={css.checkbox} spotlightId={"checkbox1"} selected={checked[index]} onToggle={onToggleTermsAgree(index)}/>
							<TButton className={css.button} withMarquee alignment={'left'} type={"tabBtnVertical"} onClick={onClickTermBtn(index)}>{item.importantChanges}</TButton>
						</div>
					)
				})}
			</Container>
			<div className={css.notice}>{$L('※ 동의를 거부할 시, 서비스 이용이 불가능합니다.')}</div>
			<Container className={css.termsItemsRow} spotlightId={"termsBtnLayer"}>
				<TButton className={css.nomalBtn} size={"small"} disabled={!termsAccepted} spotlightId={"agreeBtn"} onClick={onClickAgree} >
					{$L("동의하고 시작하기")}
				</TButton>
				<TButton className={css.nomalBtn} size={"small"} color={"dark"} spotlightId={"cancelBtn"} onClick={onBack} >
					{$L("동의 안 함")}
				</TButton>
			</Container>
			{showDetail && <TermsPopupDetail title={currentTerms.importantChanges} message={currentTerms.contents} setShowDetail={setShowDetail} />}
			{/* {checkPopupOpen.open && <TAlert message={alertMsg} className={css.checkPopup} button1text={$L('OK')} button2text={$L('Cancel')} onClick={onCloseCheckPopup} onClose={onCheckpopupCancel}/>} */}
		</Alert>
	);
};

export default TermsPopup;