import {useCallback, useEffect, useState, useRef} from 'react';
import css from './EmailLoginPanel.module.less';
import {$L} from '../../../utils/common';
import { InputField } from '@enact/sandstone/Input';
import {useDispatch} from 'react-redux';
import {on, off} from '@enact/core/dispatcher';
import TButton from '../../../components/TButton';
import TPanel from '../../../components/TPanel/TPanel';
import Marquee from '@enact/sandstone/Marquee';
import Spotlight from '@enact/spotlight';
import * as CandyActions from '../../../actions/candyActions'
import* as PanelActions from '../../../actions/panelActions';
import * as Config from '../../../data/Config';
import * as Utils from '../../../utils/common';


const EmailLoginPanel = (props) => {
	const dispatch = useDispatch();
	const [emailInput, setEmailInput] = useState('');
	const [pwInput, setPwInput] = useState('');
	const debugKey = useRef([]);

	const handleKeydown = useCallback((ev) => {
		if(ev && (ev.key >= 0 && ev.key <=9)){
			if(debugKey.current.length >= Config.DEBUG_KEY.length){
				debugKey.current.shift();
			}
			debugKey.current.push(String(ev.key));
			if(debugKey.current.join('') === Config.DEBUG_KEY){
				dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.debugpanel));
			}else if(debugKey.current.join('') === Config.TESTPANEL_KEY){
				dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.testpanel));
			}
		}
	}, [dispatch]);

	useEffect(()=>{
		Spotlight.focus("emailInput");
		on('keydown', handleKeydown);
		return () => {
			off('keydown', handleKeydown);
		};
	},[handleKeydown])

	const loginCallback = useCallback((success) => {
		if(success){
			// dispatch(CommonActions.alertToast($L("로그인 성공")));
			dispatch(PanelActions.popPanel());
		}else{
			Spotlight.focus('emailInput');
			dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.errorpanel, {reason: "login"}));
		}
	}, [dispatch]);

	const onLogin = useCallback((e) => {
		if(e.key === "Enter" || e.type === "click"){
			const encrypted = Utils.encryptPassword(pwInput);
			dispatch(CandyActions.login(emailInput, encrypted, loginCallback));
		}
	}, [dispatch, emailInput, pwInput, loginCallback]);

	const onDummyNext = useCallback(() => {
		dispatch(PanelActions.popPanel());
		dispatch(CandyActions.getCommonLists());
	}, [dispatch]);

	const onEmailChange = useCallback((e)=>{
		setEmailInput(e.value)
	},[]);

	const onPwChange = useCallback((e)=>{
		setPwInput(e.value)
	},[]);

	const onEmailClick = useCallback((e)=>{
		if(e.key === 'Enter') Spotlight.focus('pwInput');
	},[]);

	return (
		<TPanel {...props} spotlightId={'emailloginpanel'} className={css.tpanel}>
			<div className={css.infoArea}>
				<Marquee className={css.title}>{$L('로그인')}</Marquee>
				<div className={css.detail}>{$L('이메일 주소와 비밀번호를 입력해주세요.')}</div>
				<div className={css.inputBox}>
					<InputField
						className={css.inputField}
						spotlightId={"emailInput"}
						autoFocus
						onChange={onEmailChange}
						onKeyDown={onEmailClick}
						placeholder={$L('이메일')} />
				</div>
				<div className={css.inputBox}>
					<InputField
						className={css.inputField}
						spotlightId={"pwInput"}
						autoFocus
						type='password'
						onChange={onPwChange}
						onKeyDown={onLogin}
						placeholder={$L('비밀번호')} />
				</div>
				<div className={css.buttonWrap}>
					<TButton size="small" className={css.button} spotlightId={'loginButton'} onClick={onLogin}>
						{$L('로그인')}
					</TButton>
					{Config.USE_DUMMY &&
						<TButton size="small" className={css.button} onClick={onDummyNext}>
							Next(Dummy)
						</TButton>
					}
				</div>
				<div className={css.desc}>{$L('*회원가입은 Candy 모바일 앱에서 진행하실 수 있습니다.')}</div>
			</div>
		</TPanel>
	);
};

export default EmailLoginPanel;
