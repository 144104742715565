//Sequired domain
export const CANDY_BASEURL_PRD = "https://api.fitnesscandy.co/";
export const CANDY_BASEURL_QA = "https://api.demo.fitnesscandy.co/";
export const CANDY_BASEURL_DEV = "https://api.dev.fitnesscandy.co/";

/* Login */
export const EMAIL = 'auth/email';
export const CODE_LOGIN = 'auth/key';

/* Access Token */
export const TOKEN_REFRESH = 'auth/refresh';

/* Shows & Categories*/
export const GET_ALL_SHOWS = 'shows';
export const GET_CATEGORIES = 'categories';
export const GET_SEASONS = 'seasons';
export const GET_EPISODES = 'episodes';
export const GET_EPISODE_PRODUCTS = 'episodes/products';
export const GET_SEASON_PRODUCTS = 'seasons/products';
export const GET_SEASON_POPULAR = 'seasons/popular';

export const WORKSTYLES = 'workoutStyles';
export const ACCOUNT = 'accounts';
export const GET_ACTIVE_BANNERS = 'banners';

/* Notice */
export const GET_NOTICE = 'notice';
export const GET_NOTICE_LIST = 'notice/all';

/* Contents */
export const UNKNOWN_WORKOUTSTYLEID=1;
export const GET_USER_INFO = 'user';
export const GET_USER_PREFERENCES = 'user/preferences';
export const GET_USER_PAYMENT_INFO = 'user/payment/info';
export const GET_CONTENTS_OF_CATEGORY = 'contents/category';
export const GET_CONTENTS_RECOMMENDED = 'user/recommendation';
export const GET_CONTENTS_NEWS = 'news';
export const GET_CONTENTS_SPECIAL = 'shows/special';
export const GET_RELATED_KEYWORDS = 'relatedkeywords';
export const GET_CONTENTS_SEARCH = 'shows/search';
export const GET_CONTENTS_POPULAR = 'shows/popular';
export const GET_FAVORITE = 'user/scrap';
export const GET_PREFERENCE = 'user/seasonpreference';

export const GET_CONTENTS_RECENTLY_ADDED = 'contents/recently';
export const GET_CONTENTS_OF_TAG = 'contents/tag';
export const GET_CONTENTINFO = 'contents';
export const GET_CONTENTS_RECENTLYWATCHED = 'contents/watched';
export const GET_RELATEDCONTENT_TAG_CATEGORY = 'contents/related/tagAndCategory';
export const GET_RELATEDCONTENT_TAG = 'contents/related/tag';
export const GET_RELATEDCONTENT_CATEGORY = 'contents/related/category';
export const PLAYLOG = 'playLog';
export const GET_ORDEREDTAGLIST = 'tagpositions';
export const GET_CONTENTS_OF_KEYWORD = 'contents/search';

export const GET_HOME_GROUP = 'home';

export const GET_CONTENT_USAGES = 'contentUsages';

/* Favorite */
export const GET_DEFAUILT_FAVORITE_OF_ACCOUNT = 'favorites/default';
export const MY_FAVORITES = 'favorites';

/* */
export const LOGIN = 'logAction/login';
export const LOGOUT = 'logAction/logout';

/* Terms */
export const GET_TERMS_DOC = 'user/terms2';
export const TERMSCONDITION = 'terms';
export const GET_TERMSCONDITION_DOCS = 'terms/docs';
export const GET_TERMSMODIFICATION = 'termsmodification';

export const HOMEGYM_SUPPORT_COUNTRYS = {US:'en', KR:'ko'};
export const CMS_AUTHORIZATION = 'Bearer lckfjdojkvgdmfkfjkdflfjksd';
export const X_API_KEY = 'FlUeOgHWBK6oF2PJfPCLK1hoUWvFZjNn1FuH8Ysd';

/* Dashboard */
export const FINISHED_WATCHING = 'user/finishedwatching';
export const START_WATCHING = 'user/startwatching';

export const GET_CANDY_URL = (endStr, useQAServerURL) => {
	let base ="";
	if(useQAServerURL === 'qa'){
		base = CANDY_BASEURL_QA;
	}else if (useQAServerURL === 'dev') {
		base = CANDY_BASEURL_DEV;
	}else{
		base = CANDY_BASEURL_PRD;
	}
	return base+endStr;
};
/* debug */
export const DEMO = false;
export const USE_DUMMY= false;
export const DEFAULT_SERVERTYPE='qa';
export const SHOW_TOUCH_GUIDE=false;

export const DEBUG_KEY="5286";
export const TESTPANEL_KEY="5325";

export const CMS_SIZE_LIMIT=30; //max 30
export const HLIST_HOME_MAX=15;

export const DEFAULT_LOGIN_TYPE='code'; //email, code
//local window test
export const DEBUG_WINDOW_COUNTRY = "KR"; //KR,US

export const DEBUG_WINDOW_DEVICEID = "twintestdeviceid";
export const DEBUG_WINDOW_ACCOUNT_DISPLAYNAME = "twin@t-win.kr";
// virtual permission for tv
//export const DEBUG_VIRTUAL_PERMISSION = false; //DEBUG_WINDOW_COUNTRY, DEBUG_WINDOW_DEVICEID
export const DEBUG_VIDEO_SUBTITLE_TEST = false;

export const HOME_PREVIEW_LIMIT_SEC = 30;
export const AUTO_SCROLL_DELAY = 600;
export const CALORIE_ACHIEVE_RATE = 0.8;
export const USE_HLS_VIDEO_PLAYER = true;
export const START_WATCH_MIN_TIME = 120;//sec
export const MAX_HOME_FAV = 10;
export const THRESHOLD_AUTH_REFRESH = 0.2; // Percent
export const MAX_SEARCH_KEYWORDS = 8;