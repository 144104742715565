/**
 * VideoPlayerSubtitle
 *
 * @module VideoPlayerSubtitle
 */
import React from 'react';
import css from './VideoPlayerSubtitle.module.less'

const VideoPlayerSubtitle = ({ subtitle, ...rest }) => {
	return (
		<div className={css.subtitleWrap}>
			<div className={css.subtitle}> {subtitle}</div>
		</div>
	);
};

export default VideoPlayerSubtitle;