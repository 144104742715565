import React, {useState, useCallback, useEffect, useMemo, useRef} from 'react';
// import Panels from '@enact/sandstone/Panels';
import {Panels} from '@enact/sandstone/Panels';
import platform from '@enact/core/platform';
import Spotlight from '@enact/spotlight';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import Repeater from '@enact/ui/Repeater';
import PreloadImage from '../../components/PreloadImage';
import css from './MainView.module.less';
import LoadingPanel from '../LoadingPanel';
import MainPanel from '../MainPanel';
import PlayerPanel from '../PlayerPanel';
import PlayerCompletePanel from '../PlayerCompletePanel';
import MoreListPanel from '../MoreListPanel';
import ExtendedListPanel from '../ExtendedListPanel';
import DebugPanel from '../Settings/DebugPanel';
import TestPanel from '../Settings/TestPanel';
//settingPanel import
import SettingPanel from '../Settings/SettingPanel';
import StartPagePanel from '../Settings/StartPagePanel/StartPagePanel';
import CodeLoginPanel from '../Settings/CodeLoginPanel/CodeLoginPanel';
import EmailLoginPanel from '../Settings/EmailLoginPanel/EmailLoginPanel';
import * as PanelActions from '../../actions/panelActions';
import {PANEL_NAME} from '../../actions/panelActions';
import * as CommonActions from '../../actions/commonActions';

import SearchView from '../SearchView/SearchView';
import TermsConditions from '../TermsConditions/TermsConditions';
import TermsConditionsDetail from '../TermsConditions/TermsConditionsDetail';

import TAlert from '../../components/TAlert';
import * as Utils from '../../utils/common';
import {$L} from '../../utils/common';
import SpotlightIds from '../../data/SpotlightIds';
import TermsPopup from '../TermsPopup/TermsPopup'

// preloadImages
import RED_BG from '../../../assets/banner/red_bg.png';
import VIOLET_BG from '../../../assets/banner/violet_bg.png';
import violet_bannerCoverBig from '../../../assets/banner/violet_videocover_big.png';
import violet_bannerCoverMedium from '../../../assets/banner/violet_videocover_medium.png';
import violet_bannerCoverSmall from '../../../assets/banner/violet_videocover_small.png';
import red_bannerCoverBig from '../../../assets/banner/red_videocover_big.png';
import red_bannerCoverMedium from '../../../assets/banner/red_videocover_medium.png';
import red_bannerCoverSmall from '../../../assets/banner/red_videocover_small.png';
import PREPARE_IMAGE_KO from '../../../assets/dashboard/dashboard_prepare_ko.png';
import PREPARE_IMAGE_EN from '../../../assets/dashboard/dashboard_prepare_en.png';
import TermsModification from '../TermsConditions/TermsModification';
import TToast from '../../components/TToast/TToast';
import NoticePanel from '../Settings/NoticePanel/NoticePanel';
import SeasonDetailPanel from '../SeasonDetailPanel';
import ErrorPanel from '../ErrorPanel';

const PanelMap = {
	[PANEL_NAME.loading]: LoadingPanel,
	[PANEL_NAME.startpage]: StartPagePanel,
	[PANEL_NAME.codelogin]: CodeLoginPanel,
	[PANEL_NAME.emaillogin]: EmailLoginPanel,
	[PANEL_NAME.main]: MainPanel,
	[PANEL_NAME.player]: PlayerPanel,
	[PANEL_NAME.playerCompletePanel]: PlayerCompletePanel,
	[PANEL_NAME.search]: SearchView,
	[PANEL_NAME.termsConditions] : TermsConditions,
	[PANEL_NAME.termsConditionsDetail]: TermsConditionsDetail,
	[PANEL_NAME.termsModification]: TermsModification,
	[PANEL_NAME.morelist]: MoreListPanel,
	[PANEL_NAME.extendedlist]: ExtendedListPanel,
	[PANEL_NAME.debugpanel]: DebugPanel,
	[PANEL_NAME.testpanel]: TestPanel,
	[PANEL_NAME.notice]: NoticePanel,
	[PANEL_NAME.seasonDetailPanel]: SeasonDetailPanel,
	[PANEL_NAME.errorpanel]: ErrorPanel,
	//settingPanel PANEL_NAME
	[PANEL_NAME.settingPanel]: SettingPanel
};

const preloadImages = [
	RED_BG,
	VIOLET_BG,
	violet_bannerCoverBig,
	violet_bannerCoverMedium,
	violet_bannerCoverSmall,
	red_bannerCoverBig,
	red_bannerCoverMedium,
	red_bannerCoverSmall,
	PREPARE_IMAGE_KO,
	PREPARE_IMAGE_EN
];

const MainView = ({initService,...props}) => {
	const dispatch = useDispatch();
	const previousFocus = useRef();
	const panels = useSelector(state => state.panels, shallowEqual);
	const launchAction = useSelector(state => state.launchAction, shallowEqual);
	const appStatus = useSelector(state => state.appStatus, shallowEqual);
	const showLoadingView = useSelector(state => state.showLoadingView.show, shallowEqual);

	const isOnTop = useMemo(() => {
		if(appStatus.showTermsPopup || appStatus.noticeAlert){
			return false;
		}
		return (panels.length <= 0 && !showLoadingView);
	}, [showLoadingView, panels, appStatus]);

	// const noAnimation = useMemo(() => {
	// 	if(!panels || !panels[panels.length - 1]){
	// 		return true;
	// 	}
	// 	if(panels[panels.length - 1].name === 'loading' || panels[panels.length - 1].name === 'termsConditions' || panels[panels.length - 1].name === 'player'
	// 		|| panels[panels.length - 1].name === 'playerCompletePanel' || panels[panels.length - 1].name === 'seasonDetailPanel'
	// 		|| panels[panels.length - 1].name === 'search'){
	// 		return true;
	// 	}
	// 	return false;
	// }, [panels]);

	const cursorStateChange = useCallback((ev) => {
		dispatch(CommonActions.changeAppStatus({cursorVisible: ev.visibility || ev.detail.visibility}));
	}, [dispatch]);

	useEffect(() => {
		document.addEventListener('cursorStateChange', cursorStateChange, false);
		if(platform.platformName !== 'webos'){//for debug
			dispatch(CommonActions.changeAppStatus({cursorVisible: true}));
		}else{
			if(typeof window == 'object'){
				dispatch(CommonActions.changeAppStatus({cursorVisible: window.cursorEvent && window.cursorEvent.visibility}));
			}
		}
		return () => {
			document.removeEventListener('cursorStateChange', cursorStateChange);
		};
	}, []);

	useEffect(() => {
		console.log('launchAction changed',launchAction);
		const panel = panels[panels.length-1];
		// only for player
		if(launchAction.type === "pause10sec" && (!panel || panel.name !== 'player')){
			dispatch(CommonActions.clearLaunchAction());
		}
	}, [dispatch, launchAction]);

	const onClickNoticeAlert = useCallback((ev) => {
		if (ev === 0) {
			// dispatch(CmsActions.getNoticeList());
			dispatch(CommonActions.changeAppStatus({ noticeAlert: false }));
			dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.notice, { onNoticeClick : true}));
		} else {
			dispatch(CommonActions.changeAppStatus({ noticeAlert: false }));
			Spotlight.focus(SpotlightIds.MAIN_PANEL);
			Spotlight.focus(previousFocus.current);
		}
	}, [dispatch, appStatus]);

	return (
		<div {...props}>
			{panels.length > 0 &&
				<Panels {...props} noCloseButton index={(panels.length - 1)} noAnimation={true}>
					{
						panels.map(item => {
							const Component= PanelMap[item.name];
							const init = item.name === PANEL_NAME.errorpanel ? initService: null;
							return <Component key={item.name} panelInfo={item.panelInfo} initService={init}/>;
						})
					}
				</Panels>
			}
			<MainPanel isOnTop={isOnTop}/>
			<Repeater className={css.repeater} childComponent={PreloadImage} >{preloadImages}</Repeater>
			{appStatus.toast &&
				<TToast children={appStatus.toastText} />
			}
			{appStatus.noticeAlert && panels.length === 0 &&
				<TAlert message={appStatus.noticeAlertText} button1text={$L("OK")} button2text={$L("Close")} onClick={onClickNoticeAlert}/>
			}
			{appStatus.showTermsPopup &&
				<TermsPopup/>
			}
			<LoadingPanel/>
		</div>
	);
};

export default MainView;
