import {Panel} from '@enact/sandstone/Panels';
import {useCallback, useEffect, useState} from 'react';
import css from './LoadingSpinner.module.less'
import Cancelable from '@enact/ui/Cancelable';
import { useDispatch, useSelector } from 'react-redux';
import { Job } from '@enact/core/util';
import * as CommonActions from '../../actions/commonActions';

const CancelablePanel = Cancelable({modal: true, onCancel: 'handleCancel'}, Panel);

const MIN_SHOWING_TIME= 300;
const MAX_SHOWING_TIME= 5000;
let maxShowingTimeJob;
let minShowingTimeJob;
const LoadingSpinner = ({ ...rest }) => {
	const dispatch = useDispatch();
	const appStatus = useSelector(state => state.appStatus);
	const [showSpinner, setShowSpinner] = useState(appStatus.keywordSearching);
	const handleCancel = useCallback((ev) => {
		ev.stopPropagation();
	}, []);

	useEffect(() => {
		maxShowingTimeJob = new Job(() => {
			dispatch(CommonActions.changeAppStatus({keywordSearching: false}));
		}, MAX_SHOWING_TIME);
		minShowingTimeJob = new Job(() => {
			setShowSpinner(false);
		}, MIN_SHOWING_TIME);
		return () => {
			if(maxShowingTimeJob)maxShowingTimeJob.stop();
			if(minShowingTimeJob)minShowingTimeJob.stop();
		};
	}, [dispatch]);

	useEffect(() => {
		if (appStatus.keywordSearching) {
			setShowSpinner(true);
			maxShowingTimeJob.start();
			minShowingTimeJob.stop();
		}else{
			maxShowingTimeJob.stop();
			minShowingTimeJob.start();
		}
	}, [appStatus, dispatch])

	if(showSpinner){
		return (
			<CancelablePanel {...rest} className={css.panel} handleCancel={handleCancel}>
				<div className={css.spinner} />
			</CancelablePanel>
		);
	}
	return null;

};

export default LoadingSpinner;