/**
 * AnimationFocus
 *
 * @module AnimationFocus
 */
import classNames from 'classnames';
import React, { useEffect , useRef, useCallback} from 'react';

//type : "imageitem", "circle"
 const AnimationFocus = ({children, type='imageitem', focused=false, spotlightId,...rest }) => {
    const refFocused = useRef(false);
     let x=0;
     const move = useCallback(() => {
         if(refFocused.current){
             x += 0.005;
             const listItem =  document.querySelector(`[data-spotlight-id=${spotlightId}]`);
             if(listItem){
                 if(type === 'imageitem'){
                    const target = listItem.lastElementChild;
                    target.style.background = `conic-gradient(from ${x}turn,rgba(234, 108, 129, 0.1) 0%,rgba(234, 108, 129, 1) 20%,rgba(234, 108, 129, 0.5) 60%, rgba(234, 108, 129, 0))`;
                 }else{ //circle
                    const target = listItem.firstElementChild;
                    target.style.background = `conic-gradient(from ${x}turn,rgba(234, 108, 129, 0.1) 0%,rgba(234, 108, 129, 0.8) 20%,rgba(234, 108, 129, 0.5) 60%, rgba(234, 108, 129, 0))`;
                 }
                 if(typeof window === 'object'){
                    window.requestAnimationFrame(move);
                 }
             }
         }
     }, []);

     const clearFocusAni = useCallback(() => {
         const listItem =  document.querySelector(`[data-spotlight-id=${spotlightId}]`);
         if(listItem){
             const target = listItem.lastElementChild;
             target.style.background = 'initial';
             x = 0;
         }
     }, []);

     useEffect(()=>{
		if(focused){
			refFocused.current = true;
            if(typeof window === 'object'){
			    window.requestAnimationFrame(move);
            }
		}else{
			refFocused.current = false;
			clearFocusAni();
		}
	 },[focused])

      return (
          <>
            {children}
          </>
      );
  };

export default AnimationFocus;
