import {types} from '../actions/actionTypes';
import * as BleActions from '../actions/bleActions';

const bleDeviceList = (state = [], action) => {
    switch (action.type) {
        case types.GET_BLE_DEVICE_LIST:
            return action.payload;
        default:
            return state;
    }
}

const initialBleSettings = {
    connectStatus: BleActions.CONNECTION_STATUS.idle,
    address: '',
    clientId: '',
    name:''
};
// bleActions CONNECTION_STATUS
const gattConnectingStatus = (state = initialBleSettings, action) => {
    switch (action.type) {
        case types.GATT_CONNECT_STATUS:{
            const newState = Object.assign({}, state, action.payload);
            return newState;
        }
        default:
            return state;
    }
}


const gattExerciseResultValue = (state = {steps: 0, calorie:0, battery: 0, heartRate: 0, totalHeartRate:[], averageHeartRate:0}, action) => {
    switch (action.type) {
        case types.GATT_EXERCISE_RESULT_VALUE:
            if (typeof window === 'object'){
                const panels = window.store.getState().panels;
                if(panels && panels.length > 0){
                    if(panels[panels.length-1].name === 'player'){
                        return Object.assign({}, state, action.payload);
                    }
                }
            }
            return {steps: 0, calorie:0, battery: 0, heartRate: 0, totalHeartRate:[], averageHeartRate:0};
        default:
            return state;
    }
}


const bleReducer = {
    bleDeviceList,
    gattConnectingStatus,
    gattExerciseResultValue
};

export default bleReducer;
