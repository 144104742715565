import { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import css from './TermsPopupDetail.module.less';
import {$L} from '../../utils/common';
import Alert from '@enact/sandstone/Alert';
import TButton from '../../components/TButton';
import TScroller from '../../components/TScroller/TScroller';
import Spotlight from '@enact/spotlight';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import Marquee from '@enact/sandstone/Marquee';

const Container = SpotlightContainerDecorator({enterTo: "default-element", preserveId: true}, ('div'));

const TermsPopupDetail = ({title, message,...props}) => {

	useEffect(() => {
	    Spotlight.focus("BackBtn");
	},[]);

	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		props.setShowDetail(false);
	},[props] );

	return (
		<Alert className={classNames(css.popup)} open type={"overlay"}>
			<Container>
				<div className={css.popupContent}>
				<div className={css.header}>
					<TButton type={"back"} spotlightId={"BackBtn"} className={css.cancelBtn} onClick={onBack}/>
					{title && <Marquee  className={css.title} marqueeOn='render' alignment='center'>{title}</Marquee>}
				</div>
				<div className={css.detail}>
					<TScroller className={css.scroller}>
						{message && <div dangerouslySetInnerHTML={{ __html: message}} />}
					</TScroller>
				</div>
				<div className={css.bottomBtnLayer}>
					<TButton className={css.nomalBtn} spotlightId={"BackBtn1"} onClick={onBack}>{$L('돌아가기')}</TButton>
				</div>
			</div>
			</Container>
		</Alert>
	);
};

export default TermsPopupDetail;