import { useCallback, useEffect  , useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Spotlight from '@enact/spotlight';
import platform from '@enact/webos/platform';
import css from './DebugPanel.module.less';
import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/TPanel/THeader';
import * as Config from '../../../data/Config';
import * as PanelActions from '../../../actions/panelActions';
import * as CommonActions from '../../../actions/commonActions';
import TSwitchItem from '../../../components/TSwitchItem/TSwitchItem';
import TRadioItem from '../../../components/TRadioItem/TRadioItem';
import {$L} from '../../../utils/common';
import appinfo from '../../../../webos-meta/appinfo.json';
import {PANEL_NAME} from '../../../actions/panelActions';

const DebugPanel = props => {
	const dispatch = useDispatch();
	const appStatus  = useSelector(state => state.appStatus);
	const localSettings = useSelector(state => state.localSettings);
	const accountInfo = useSelector(state => state.accountInfo);

	useEffect(() => {
		if(!props.hideChildren){
      		Spotlight.focus(PANEL_NAME.debugpanel);
        }
	}, [props]);

	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		dispatch(PanelActions.popPanel(PANEL_NAME.debugpanel));
	}, [dispatch]);

	const infos = useMemo(() => {
		let v = [];
		v.push({title: 'Version(App, Service)', value: appinfo.version});
		v.push({title: 'WEBOS', value: appStatus.webOSVersion+"("+appStatus.webOSVersionReal+")"});
		v.push({title: 'platformCode', value: appStatus.otaId});
		v.push({title: 'DeviceId', value: appStatus.deviceId});
		v.push({title: 'Language', value: appStatus.language});
		v.push({title: 'UserId', value: localSettings.userId});
		v.push({title: 'CMS_URL', value: Config.GET_CANDY_URL("", localSettings.useQAServerURL)});
		v.push({title: 'CameraDetected', value: String(appStatus.cameraDetected)});
		v.push({title: 'CameraName', value: appStatus.cameraDeviceName});
		v.push({title: 'CameraPermissionRequired', value: String(appStatus.cameraPermissionRequired)});
		v.push({title: ' ', value: ' '});
		return v;
	}, [appStatus, accountInfo, localSettings]);


	const onToggleTestServer = useCallback((type) => () => {
		dispatch(CommonActions.alertToast("Test Server:  "+ type));
		dispatch(CommonActions.changeLocalSettings({useQAServerURL: type}));
		setTimeout(() => {
			if(typeof window === 'object'){
				window.location.reload();
			}
		}, 0);
	},[dispatch]);
	const onToggleLoginType = useCallback((type) => () => {
		dispatch(CommonActions.alertToast("Login Type:  "+ type));
		dispatch(CommonActions.changeLocalSettings({loginType: type}));
		setTimeout(() => {
			if(typeof window === 'object'){
				window.location.reload();
			}
		}, 0);
	},[dispatch]);
	const switches = useMemo(() => {
		let v = [];
		v.push({title: 'Force Use PIP Camera(reboot)', value: localSettings.useForceCamera, func:() => {
			dispatch(CommonActions.alertToast("Force Use PIP Camera "+!localSettings.useForceCamera));
			dispatch(CommonActions.changeLocalSettings({useForceCamera: !localSettings.useForceCamera}));
			setTimeout(() => {
				if(typeof window === 'object'){
					window.location.reload();
				}
			}, 0);
		}});
		return v;
	}, [dispatch, appStatus, accountInfo, localSettings]);

	return (
		<TPanel {...props} spotlightId={'debugpanel'} handleCancel={onBack}>
			<THeader title={appinfo.id} onClick={onBack}/>
			<div className={css.panel}>
				{infos.map((item, index) =>
					{return(
						<div className={css.titleArea} key={index}>
							<div className={css.titleBox}>
								<div className={css.text}>{item.title}</div>
							</div>
							<div className={css.textArea}>
								<div className={css.text}>{item.value}</div>
							</div>
						</div>
					)
					}
				)}

				<div className={css.titleArea} >
					<div className={css.switchs}>
						<span className={css.switchTitle}>{'Test Server(reboot)'}</span>

						<TRadioItem
							selected={localSettings.useQAServerURL==='prd'}
							onToggle={onToggleTestServer('prd')}
							>
							{$L("PRD")}
						</TRadioItem>

						<TRadioItem
							selected={localSettings.useQAServerURL === 'qa'}
							onToggle={onToggleTestServer('qa')}
							>
							{$L("QA")}
						</TRadioItem>

						<TRadioItem
							selected={localSettings.useQAServerURL === 'dev'}
							onToggle={onToggleTestServer('dev')}
							>
							{$L("DEV")}
						</TRadioItem>
					</div>
				</div>
				<div className={css.titleArea} >
					<div className={css.switchs}>
						<span className={css.switchTitle}>{'Login Type(reboot)'}</span>

						<TRadioItem
							selected={localSettings.loginType==='email'}
							onToggle={onToggleLoginType('email')}
							>
							{$L("Email")}
						</TRadioItem>

						<TRadioItem
							selected={localSettings.loginType === 'code'}
							onToggle={onToggleLoginType('code')}
							>
							{$L("Code")}
						</TRadioItem>
					</div>
				</div>
				{switches.map((item, index) =>
					{return(
						<div className={css.titleArea} key={index}>
							<div className={css.switchs}>
								<span className={css.switchTitle}>{item.title}</span>
								<TSwitchItem selected={item.value} onToggle={item.func} className={css.switch}>{item.value ? $L('On') : $L('Off')}</TSwitchItem>
							</div>
						</div>
					)
					}
				)}
				{!platform.twinModified &&
					<div className={css.warning} >{"Patch is not applied...please rebuild source"}</div>
				}
			</div>
		</TPanel>
	);
};

export default DebugPanel;
