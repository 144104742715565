import classNames from 'classnames';
import React,{useRef, useEffect, useReducer, useState, useCallback } from 'react';
import {on, off} from '@enact/core/dispatcher';
import css from './CountDownTimer.module.less';
import {$L} from '../../utils/common';

const defaultString = $L('{sec}초 후에 다음 에피소드가 재생됩니다.');
const dummyCount = 1;
const CountDownTimer = ({istring=defaultString, count = 6, onTick, onFire, onCanceled, cancelDisabled, className}) => {
	const countdown = useRef(null);
	const remainCount = useRef(count + dummyCount);
	const [countDownWorking, setCountDownWorking] = useState(true);
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);
	useEffect(() => {
		countdown.current = setInterval(() => {
			const r = remainCount.current - 1;
			if (r < 0) {
				clearCount();
				if(onFire){
					onFire();
				}
			} else {
				if(onTick){
					onTick(r);
				}
				remainCount.current = r;
			}
			forceUpdate();
		}, 1000);
		if(onTick){
			onTick(remainCount.current);
		}
		if(typeof window === 'object'){
			on('keydown', onKeyDownAction, window);
			on('mousemove', onMouseMoveAction, window);
		}
		return () => {
			if (countdown.current) {
				if(typeof window === 'object'){
					off('keydown', onKeyDownAction, window);
					off('mousemove', onMouseMoveAction, window);
				}
				clearInterval(countdown.current);
			}
		};
	}, []);
	const clearCount = useCallback(() => {
		if (countdown.current) {
			if(typeof window === 'object'){
				off('keydown', onKeyDownAction, window);
				off('mousemove', onMouseMoveAction, window);
			}
			clearInterval(countdown.current);
			if (onCanceled && remainCount.current >= 0) {
				onCanceled();
			}
			countdown.current = null;
			remainCount.current = count;
			setCountDownWorking(false);
		}
	}, [count, onCanceled]);

	const onKeyDownAction = useCallback((evt) => {
		console.log('onKeyDownAction', evt);
		if (!evt || evt.key === 'Unidentified') {
			return;
		}
		if ( !cancelDisabled) {
			clearCount();
		}
	}, [cancelDisabled, clearCount]);
	const onMouseMoveAction = useCallback((evt) => {
		if ( !cancelDisabled) {
			clearCount();
		}
	}, [cancelDisabled, clearCount]);

	let timeoutText = "";
	if(istring){
		timeoutText = istring.replace('{sec}', remainCount.current);
		console.log('remainCount', remainCount.current);
	}
	if (countDownWorking) {
		return (
			<div className={classNames(className ? className : "", remainCount > count ? css.hide : null)}>
				{timeoutText}
			</div>
		);
	} else {
		return null;
	}
};

export default CountDownTimer;
