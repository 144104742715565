import {useState, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import css from './AccountSetting.module.less';
import classNames from 'classnames';
import {Image} from '@enact/sandstone/Image';
import {$L} from '../../../../../utils/common';
import video_default from '../../../../../../assets/list/video_default.png';
import TButton from '../../../../../components/TButton/TButton';
import AccountPopup from './AccountPopups/AccountPopup';
import TAlert from '../../../../../components/TAlert';
import * as CandyActions from '../../../../../actions/candyActions';
import * as PanelActions from '../../../../../actions/panelActions';
import Spotlight from '@enact/spotlight';
import Spottable from '@enact/spotlight/Spottable';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';


const Container = SpotlightContainerDecorator({enterTo: 'last-focused', preserveId: true}, Spottable('div'));
 const AccountSetting = ({props}) => {
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.userInfo);
    const localSettings = useSelector(state => state.localSettings);
    const [accountPopup, setAccountPopup] = useState(false);
    const [logoutPopup, setLogoutPopup] = useState(false);

	const thumbnail = useMemo(() => {
		return userInfo.profileImageUrl ? userInfo.profileImageUrl : video_default;
	}, [userInfo]);

    const onOpenNamePopup = useCallback(() => {
		setAccountPopup(true);
	}, []);

    const onOpenLogoutPopup = useCallback(() => {
        setLogoutPopup(true);
	}, []);


	const onCloseAccountPopup = useCallback(() => {
		setAccountPopup(false);
		Spotlight.focus('changeNameButton');
	}, []);


	const onClickLogoutAlert = useCallback((ev) => {
        if(ev === 0){
            setLogoutPopup(false);
            Spotlight.focus('logoutButton');
        }else{
            dispatch(CandyActions.logout());
            dispatch(PanelActions.popPanel());
            dispatch(PanelActions.pushPanel(localSettings.loginType === 'code' ? PanelActions.PANEL_NAME.codelogin : PanelActions.PANEL_NAME.emaillogin));
        }
	}, [dispatch, localSettings]);
    const updateUserName = useCallback((value) => {
		dispatch(CandyActions.updateUserInfo({nickName: value}));
	}, [dispatch]);

     return (
        <div className={css.contentContainer}>
			<div className={css.header}>{$L('계정 설정')}</div>
                <Container className={css.nameLayer}>
                    <Image className={css.profileImage} src={thumbnail}/>
                    <div {...props} className={css.name}>{userInfo.nickName}</div>
                    <div className={css.modifiedButtonWrap}>
                        <TButton className={classNames(css.nomalBtn, css.button, css.selected)} spotlightId={'changeNameButton'} size="small" onClick={onOpenNamePopup}>
                            {$L('이름변경')}
                        </TButton>
                        <AccountPopup setUserName={updateUserName} onClose={onCloseAccountPopup} open={accountPopup}/>
                    </div>
                    <div className={css.logoutButtonWrap}>
                        <TButton className={css.nomalBtn} spotlightId={'logoutButton'} size="small" color="dark" onClick={onOpenLogoutPopup}>
                            {$L('Logout')}
                        </TButton>
                        {logoutPopup &&
                            <TAlert className={css.logoutPopup} message={$L('정말 로그아웃 하시겠습니까?')} button1text={$L('돌아가기')} button2text={$L("Logout")} onClick={onClickLogoutAlert}/>
                        }
                    </div>
                </Container>
        </div>
     );
 };

 export default AccountSetting;