import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Icon from '@enact/sandstone/Icon';
import Spotlight from '@enact/spotlight';
import { InputField } from '@enact/sandstone/Input';
import { Job } from '@enact/core/util';
// import {on, off} from '@enact/core/dispatcher';
import {PANEL_NAME} from '../../actions/panelActions';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';

import css from './SearchView.module.less';
import {$L} from '../../utils/common';
import TPanel from '../../components/TPanel';
import THeader from '../../components/TPanel/THeader';
import TKeywordItemList from '../../components/TKeywordItemList/TKeywordItemList';
import SpotlightIds from '../../data/SpotlightIds';
import LoadingSpinner from './LoadingSpinner';
import * as PanelActions from '../../actions/panelActions';
import * as CandyActions from '../../actions/candyActions';
import * as CommonActions from '../../actions/commonActions';
import * as Config from '../../data/Config';
import VerticalMediaList from '../../components/MediaList/VerticalMediaList';

const Container = SpotlightContainerDecorator({ leaveFor: { left: '', right: ''}, enterTo: 'default-element', spotlightRestrict: 'self-only' }, 'div');

// const launchSearchJob = new Job((func, ev) => {
// 	func(ev);
// }, 1000);

// const calculateVisibleListJob = new Job((func) => {
// 	func();
// }, 2000);

const SearchView = ({ hideChildren, panelInfo}) => {
	const dispatch = useDispatch();
	const recentSearches = useRef([]);
	const contentsSearchList = useSelector(state => state.contentsSearchList);
	const contentsPopularShows = useSelector(state => state.contentsPopularShows);
	const relatedKeywords = useSelector(state => state.relatedKeywords);
	const keywordSearching = useSelector(state => state.appStatus.keywordSearching);
	const localSettings = useSelector(state => state.localSettings);
	const panels = useSelector(state => state.panels);

	const [keyword, setKeyword] = useState(panelInfo.keyword);

	const [contentFocus, setContentFocus] = useState();
	const [isInputFocus, setInputFocus] = useState(false);
	const [isTypingWord, setTypingWord] = useState(false);
	const [searchWord] = useState([]);
	const [searchExecution, setSearchExecution] = useState(false);
	const [, updateState] = useState();

	const scrollTo = useRef(null);
	const [restoreIndex, setRestoreIndex] = useState(panelInfo.restoreIndex); //media focus

	const delayFocus = new Job(() => {
		Spotlight.focus(SpotlightIds.LIST_SEARCH);
	}, 100);

const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		if (panels.length > 1) {
			dispatch(PanelActions.popPanel(PANEL_NAME.search));
			dispatch(PanelActions.popPanel(PANEL_NAME.player));
		} else {
			dispatch(PanelActions.popPanel(PANEL_NAME.search));
		}
	}, [dispatch, panelInfo, panels]);

	const addRecentSearch = useCallback((_keyword) => {
		let value = [];
		if(recentSearches){
			recentSearches.current.forEach(item => {
				value.push(item);
			});
		}
		if (_keyword.trim().length !== 0) {
			value.splice(0, 0, _keyword.trim());
			const set = new Set(value);
			let removedSameKeyword = [...set];
			removedSameKeyword = removedSameKeyword.length > 5 ? removedSameKeyword.slice(0, Config.MAX_SEARCH_KEYWORDS) : removedSameKeyword
			recentSearches.current = removedSameKeyword;
			dispatch(CommonActions.changeLocalSettings({recentSearch:removedSameKeyword}));
		}
	}, [dispatch]);

	const onHandleInput = useCallback((ev) => {
		if (keyword !== undefined) {
			if (ev.key === 'Enter') {
				const checkedKeyword = keyword.trim();
				setKeyword(checkedKeyword);
				if(checkedKeyword){
					setSearchExecution(true);
					dispatch(CandyActions.getSearchResult(checkedKeyword));
					addRecentSearch(checkedKeyword);
				}else{
					setSearchExecution(false);
				}
				dispatch(PanelActions.updatePanel(PANEL_NAME.search, {keyword: checkedKeyword}));
			}
		}
  }, [keyword, dispatch, addRecentSearch]);

	const onHandlebackBtn = useCallback((ev) => {
		if (ev.key === 'ArrowRight') {
			Spotlight.focus("inputBox");
			ev.stopPropagation();
			ev.preventDefault();
		}
	}, []);

	// const onItemClick = useCallback((ev) => {
	// 	previousFocus.current = Spotlight.getCurrent();
	// 	dispatch({ type: types.SELECT_CONTENT_INFO, payload: ev });
	// }, [dispatch]);

	const onKeyWordChange = useCallback((ev) => {
		setKeyword(ev.value);
		const value = ev.value !== "";
		// setSearchWord(searchList.filter(item => item.toLowerCase().indexOf(ev.value) !== -1).slice(0, 5));
		if (value) {
			setTypingWord(true);
		} else {
			setTypingWord(false);
		}
	}, []);
	// }, [searchList]);

	const onClickRecentSearch = useCallback((item) => () => {
		setKeyword(item);
		addRecentSearch(item);
		setInputFocus(false);
		setSearchExecution(true);
		dispatch(CandyActions.getSearchResult(item, false));
		dispatch(PanelActions.updatePanel(PANEL_NAME.search, {keyword: item}));
		Spotlight.focus("inputBox");
	}, [dispatch, addRecentSearch]);

	useEffect(() => {
		if (contentsSearchList && contentsSearchList.data.length !== 0) {
			setInputFocus(false);
			delayFocus.start();
		}
		if (scrollTo && scrollTo.current) {
			scrollTo.current({ position: { x: 0, y: 0 }, animate: true });
		}
	},[contentsSearchList]);

	const onDelete = useCallback((item) => () => {
		if(recentSearches){
			recentSearches.current = recentSearches.current.filter(items => items !== item.item);
		}
		dispatch(CommonActions.changeLocalSettings({recentSearch:recentSearches.current}));
		updateState({})
	}, [recentSearches, dispatch]);

	useEffect(() => {
		// searchWordList();
		if (!hideChildren) {
			if(recentSearches){
				recentSearches.current = localSettings.recentSearch;
			}
			if (panelInfo.init) {
				Spotlight.focus("inputBox");
				panelInfo.init = false;
			}
			setTimeout(() => {
				if(panelInfo.scrollTop && scrollTo.current){
					scrollTo.current({position:{y: panelInfo.scrollTop}, animate: true});
				}
			}, 100);
			setTimeout(() => {
				setRestoreIndex(-1);
			}, 500);

			if (panelInfo && panelInfo.keyword) {
				Spotlight.focus(SpotlightIds.BACK_BUTTON);
			}
			dispatch(CandyActions.getPopularShows());
		}
	}, [dispatch, hideChildren]);

	const onFocusedIndexChanged = useCallback((id, index) => {
		dispatch(PanelActions.updatePanel(PANEL_NAME.search, {restoreIndex: index}));
	}, [dispatch]);

	const showSearchContentsList = useCallback(() => {
		let list = [];
		if(searchExecution){
			list = (contentsSearchList && contentsSearchList.data) ? contentsSearchList.data : [];
		}else{
			list = contentsPopularShows;
		}
		if (list.length !== 0) {
			return (
				<>
				<div className={classNames(css.mediaListTitleWrap)}>
					<div className={classNames(css.mediaListTitle)}>
						{searchExecution ? "" : $L("인기 검색")}
					</div>
				</div>
				<VerticalMediaList
					className={css.medialistlayer}
					listSpotlightId={SpotlightIds.LIST_SEARCH}
					contentsList={list}
					onFocusedIndexChanged={onFocusedIndexChanged}
					onFocus={onContentFocus} onBlur={onContentBlur}
					withOutScrollLength={10}
				/>
				<div className={css.dummy}/>
				</>
			);
		} else {
			if (searchExecution && !keywordSearching) {
				return (
					<div className={css.noneResult}>{$L("No results for this keyword.")}</div>
				)
			}
		}
	}, [restoreIndex, contentsSearchList, contentsPopularShows, searchExecution, keywordSearching, onFocusedIndexChanged]);

	const onContentFocus = useCallback(() => {
		setContentFocus(true);
		setInputFocus(false);
	},[]);

	const onContentBlur = useCallback(() => {
		setContentFocus(false);
	},[]);

	const onFocusInputField = useCallback(() => {
		setInputFocus(true);
		if (keyword !== "" && keyword !==undefined) {
			setTypingWord(true);
		} else {
			setTypingWord(false);
		}
	}, [keyword]);

	const onHandleRecentSearchs = useCallback((ev) => {
		if(ev.key === 'ArrowUp') {
			Spotlight.focus('inputBox');
		}
	}, [])

	const showRelatedKeywords = useMemo(()=>{
		if(keyword){
			if(relatedKeywords[keyword] && relatedKeywords[keyword].length > 0 && panelInfo.keyword === keyword){
				return true;
			}
		}
		return false;
	}, [relatedKeywords,keyword, panelInfo]);
	return (
		<TPanel className={css.panel} handleCancel={onBack}>
			<THeader title={$L("Search")} onClick={onBack}/>
			<div className={css.inputBoxContainer} spotlightId={"inputBoxContainer"}>
				<InputField
					className={css.inputBox}
					spotlightId={"inputBox"}
					autoFocus
					value={keyword}
					dismissOnEnter
					onFocus={onFocusInputField}
					onChange={onKeyWordChange}
					onKeyDown={onHandleInput}
					placeholder={$L("Search for titles, category, and whatever else you want")} />
					<Icon className={css.searchIcon}>search</Icon>
			</div>
			<Container className={css.searchContainer} onKeyDown={onHandleRecentSearchs} spotlightId={"searchListContainer"}>
				{showRelatedKeywords &&
					<div className={classNames(css.title)}>{$L("연관 검색어")}</div>
				}
				{!showRelatedKeywords && !keyword &&
					<div className={classNames(css.title)}>{$L("최근 검색어")}</div>
				}
				<div  className={classNames(css.list)}>
					{showRelatedKeywords &&
						relatedKeywords[keyword] && relatedKeywords[keyword].map((item,index) =>
							<TKeywordItemList
								key={index}
								index={index}
								spotlightId={"recentSearchs"+index}
								onClickSearch={onClickRecentSearch(item)}
								type='autoSearchWord'
							>
								{item}
							</TKeywordItemList>)
					}
					{!showRelatedKeywords && !keyword &&
						recentSearches && recentSearches.current.map((item,index) =>
						<TKeywordItemList
							key={index}
							index={index}
							spotlightId={"recentSearchs"+index}
							onClickSearch={onClickRecentSearch(item)}
							onClickDelete={onDelete({ item })}
						>
							{item}
						</TKeywordItemList>)
					}
				</div>
			</Container>
			{showSearchContentsList()}
			<LoadingSpinner />
		</TPanel>
	);
};

export default SearchView;