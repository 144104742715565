import React, {useState, useCallback, useMemo, useEffect} from 'react';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import Spotlight from '@enact/spotlight';
import Cancelable from '@enact/ui/Cancelable';
import {Panel} from '@enact/sandstone/Panels';
import TButton from '../../components/TButton';
import TPanel from '../../components/TPanel/TPanel';
import * as PanelActions from '../../actions/panelActions';
import {Job} from '@enact/core/util';
import * as Utils from '../../utils/common';
import {$L} from '../../utils/common';
import * as Config from '../../data/Config';
import css from './ErrorPanel.module.less';

const ErrorPanel = ({initService, panelInfo, ...rest}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		Spotlight.focus('button2');
	}, []);

	const handleCancel = useCallback((ev) => {
		ev.stopPropagation();
	}, []);

	const onClickBtn = useCallback((ev)=>() => {
		if (ev === 0) {
			Utils.windowClose();
		}else{
			if(panelInfo.reason !== 'login' && initService){
				initService();
			}
			dispatch(PanelActions.popPanel(PanelActions.PANEL_NAME.errorpanel));
		}
	}, [dispatch, initService, panelInfo]);

	const textInfo = useMemo(()=> {
		const res = {title: "", subTitle: ""};
		if(panelInfo.reason === 'login'){
			res.title = $L('로그인에 실패 하였습니다.');
			res.subTitle = $L('인증번호를 확인 후 다시 시도 해주세요.');
		}else if(panelInfo.reason === '401'){
			res.title = $L('네트워크에 접속할 수 없습니다.');
			res.subTitle = $L('미승인 사용자입니다.');
		}else if(panelInfo.reason === '403'){
			res.title = $L('다른 기기에서 로그인 되었습니다.');
			res.subTitle = $L('다른 기기에서 동일한 계정으로 중복 로그인되어 강제 로그아웃 처리 되었습니다.');
		}else{
			res.title = $L('네트워크에 접속할 수 없습니다.');
			res.subTitle = $L('네트워크 연결 상태를 확인해 주세요.');
		}
		return res;
	}, [panelInfo]);
	return (
		<TPanel {...rest} handleCancel={handleCancel}>
			<div className={css.iconLayer}>
				<div className={css.erroricon}/>
			</div>
			<div className={css.textlayer}>
				<div className={css.title}>
					{textInfo.title}
				</div>
				<div className={css.subtitle}>
					{textInfo.subTitle}
				</div>
			</div>
			<div className={css.bottomBtnLayer}>
				<TButton spotlightId={'button1'}  size="small" onClick={onClickBtn(0)}>{$L("Exit")} </TButton>
				<TButton spotlightId={'button2'}  size="small" color="dark" onClick={onClickBtn(1)}>{$L("Retry")} </TButton>
				</div>
		</TPanel>
	);
};

export default ErrorPanel;
