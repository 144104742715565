const KeyCode = {
	POWER: 0,		// power key
	OK: 13,			// ok key
	VKB_ENTER: 13,	// enter key
	BACK: 461,		// remote back key
	LEFT: 37,		// left key
	UP: 38,			// up key
	RIGHT: 39,		// right key
	DOWN: 40,		// down key
	BACKSPACE: 8,	// keyboard backspace key
	REW: 412,		// rewind key
	STOP: 413,		// stop key
	PLAY: 415,		// play key
	FF: 417,			// fastforward key
	INFO: 457,		// info key
	PAUSE: 19,		// pause key
	SPACEBAR: 32,	// HID keyboard spacebar
	ESC: 27,			// HID keyboard ESC
	PREVSKIP: 424,	// prev skip (only japan remote key)
	NEXTSKIP: 425,	// next skip (only japan remote key)
	RED: 403,		// RED button.
	GREEN: 404,		// GREEN button.
	YELLOW: 405,		// YELLOW button.
	BLUE: 406,		// BLUE button.
	LIST: 1056,		// LIST button
	EXIT: 1001,		// EXIT button
	MORE_ACTION: 1062,	// More Action Button
	CH_UP: 33,		// CHANNEL UP button
	CH_DOWN: 34,		// CHANNEL DOWN button
	SUBTITLE: 460,
	NUM_0 : 48,
	NUM_1 : 49,
	NUM_2 : 50,
	NUM_3 : 51,
	NUM_4 : 52,
	NUM_5 : 53,
	NUM_6 : 54,
	NUM_7 : 55,
	NUM_8 : 56,
	NUM_9 : 57
};

export default KeyCode;
