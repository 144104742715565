import axios from 'axios';
import * as Config from '../data/Config';
import * as Utils from '../utils/common';
import * as CommonActions from './commonActions';
import * as CandyActions from './candyActions';
import * as PanelActions from './panelActions';

import {$L} from '../utils/common';

let tokenRefreshing = false;
export const tokenRefresh = async (dispatch, getState, autoLogin, callback) => {
	const useQAServerURL = getState().localSettings.useQAServerURL;
	const url = Config.GET_CANDY_URL(Config.TOKEN_REFRESH, useQAServerURL);
	const webOSVersionReal = getState().appStatus.webOSVersionReal;
	const {authenticationResult, tokenRequiredTime, userId} = getState().localSettings;
	const deviceId = getState().appStatus.deviceId;

	if(!userId){
		console.warn('tokenRefresh : not logged');
		if(callback){
			callback(false);
		}
		return;
	}
	const currentTime = new Date();
	const gap = (currentTime - tokenRequiredTime)/1000;
	const ExpiredIn = Number(authenticationResult.ExpiresIn);
	const limitTime = ExpiredIn - (ExpiredIn * Config.THRESHOLD_AUTH_REFRESH);
	if(!autoLogin && (gap < limitTime || tokenRefreshing)){
		if(callback){
			callback(false);
		}
		return;
	}
	const tokenInfo =
	{
		"authenticationResult": authenticationResult,
		deviceInfo: {
				"deviceId": deviceId,
				"deviceName": "deviceName01",
				"os": "webos",
				"model": webOSVersionReal,
				"serialNumber": "xxxx-xxxx-xxxx-xxxx"
			}
		, "userId" : userId
	};
	if(useQAServerURL !== 'prd'){
		console.log('tokenInfo...', tokenInfo, gap, ExpiredIn);
	}
	try{
		tokenRefreshing = true;
		const response = await axios.put(url, tokenInfo, CandyActions.AUTHORIZATION);
		console.log(' tokenRefresh response....', response);
		CandyActions.AUTHORIZATION.headers.authorization = response.data.authenticationResult.AccessToken;
		dispatch(CommonActions.changeLocalSettings({authenticationResult:response.data.authenticationResult, tokenRequiredTime: new Date()}));
		if(Config.DEMO){
			dispatch(CommonActions.alertToast($L("토큰갱신 완료!!!")));
		}
		if(callback){
			callback(true);
		}
	}catch(res) {
		const error = res && res.toJSON ? res.toJSON() : {};
		console.error('tokenRefresh', error, res);
		if(error.message ){
			if(error.message.indexOf('code 400')
			|| error.message.indexOf('code 401')
			|| error.message.indexOf('code 403')){
				dispatch(CandyActions.logout());
			}
		}
		if(callback){
			callback(false);
		}
	};
	tokenRefreshing = false;
}

/**
 * type: 'get', 'put', 'post', 'delete'
 */

 export const TAxios = async (dispatch, getState, type, baseUrl, params={}, onSuccess, onFail, noTokenRefresh = false) => {
	const {userId} = getState().localSettings;
	if (typeof window === 'object') {
		const useQAServerURL = getState().localSettings.useQAServerURL;
		const urlparams = Utils.createQueryString(params);
		let url = Config.GET_CANDY_URL(baseUrl, useQAServerURL);
		switch(type){
			case 'get':
				url +=  urlparams ? `?${urlparams}`: '';
				break;
		}
		if(useQAServerURL !== 'prd'){
			console.log('TAxios ', type+": " +url, params);
		}

		//tokenRefresh
		if(!noTokenRefresh){
			await tokenRefresh(dispatch, getState);
		}
		while(tokenRefreshing){
			await Utils.wait(100);
		}

		let axiosIns = null;
		switch(type){
			case 'get':
				axiosIns = axios[type](url, CandyActions.AUTHORIZATION);
				break;
			case 'put':
			case 'post':
				axiosIns = axios[type](url, params, CandyActions.AUTHORIZATION);
				break;
			case 'delete':{
				const auth = Object.assign({}, CandyActions.AUTHORIZATION, {data: params});
				axiosIns = axios[type](url, auth);
				break;
			}
		}
		if(axiosIns){
			axiosIns.then((res)=>{
				if(useQAServerURL !== 'prd'){
					console.log('TAxios response', url, res);
				}
				if(onSuccess){
					onSuccess(res);
				}
			}).catch((res)=>{
				const error = res && res.toJSON ? res.toJSON() : {};
				console.error('TAxios ', url, error);
				if(error.message === 'Network Error'){
					dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.errorpanel, {reason: "networkError"}));
				}else if(userId && error.message && error.message.indexOf('400')>=0){
					dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.errorpanel, {reason: "400"}));
				}else if(userId && error.message && error.message.indexOf('401')>=0){
					dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.errorpanel, {reason: "401"}));
				}else if(userId && error.message && error.message.indexOf('403')>=0){
					dispatch(CandyActions.logout());
					dispatch(PanelActions.resetPanels());
					dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.errorpanel, {reason: "403"}));
				}else{
					//todo
				}
				if(onFail){
					onFail(res);
				}
			});
		}else{
			console.warn('TAxios invalid case type ', type);
		}
	}
};