import { useCallback } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import css from './PlaySetting.module.less';
import {$L} from '../../../../../../utils/common';
import TSwitchItem from '../../../../../../components/TSwitchItem/TSwitchItem';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import * as CommonActions from '../../../../../../actions/commonActions';
import { useEffect } from 'react';
import Spotlight from '@enact/spotlight';

const Container = SpotlightContainerDecorator({enterTo: 'last-focused'}, 'div');

const PlaySetting = ({...props}) => {
    const localSettings = useSelector(state => state.localSettings);
    const dispatch = useDispatch();

    const onToggleSetting = useCallback((key) => ({selected}) => {
        dispatch(CommonActions.changeLocalSettings({[key]: selected}));
	},[dispatch]);

    useEffect(()=>{
        Spotlight.focus('switch1');
    },[])

	return (
        <Container className={css.container} {...props}>
            <div className={css.displayContainer}>
                <div className={css.title}>{$L('디스플레이 설정')}</div>
                <TSwitchItem
                    data-webos-voice-label={$L('심박수 측정')}
                    spotlightId={"switch1"}
                    onToggle={onToggleSetting('checkHeartRate')}
                    className={css.switch}
                    selected={localSettings.checkHeartRate}
                >{$L('심박수 측정')}
                </TSwitchItem>
                <TSwitchItem
                    data-webos-voice-label={$L('칼로리 표시')}
                    spotlightId={"switch2"}
                    onToggle={onToggleSetting('checkCalory')}
                    className={css.switch}
                    selected={localSettings.checkCalory}
                    >
                    {$L('칼로리 표시')}
                </TSwitchItem>
                <TSwitchItem
                    data-webos-voice-label={$L('걸음 수 표시')}
                    spotlightId={"switch3"}
                    onToggle={onToggleSetting('checkStep')}
                    className={css.switch}
                    selected={localSettings.checkStep}
                >
                    {$L('걸음 수 표시')}
                </TSwitchItem>
            </div>
        </Container>
    )
};


export default PlaySetting;
