import classNames from 'classnames';
import React, {useState, useCallback, useEffect, useRef, useMemo } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {on, off} from '@enact/core/dispatcher';
import {Job} from '@enact/core/util';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import {Marquee, MarqueeController} from '@enact/sandstone/Marquee';
import Spotlight from '@enact/spotlight';
import css from './SeasonDetailPanel.module.less';
import TPanel from '../../components/TPanel/TPanel';
import THeader from '../../components/TPanel/THeader';
import {$L} from '../../utils/common';
import * as PanelActions from '../../actions/panelActions';
import * as CandyActions from '../../actions/candyActions';
import * as CommonActions from '../../actions/commonActions';

import {PANEL_NAME} from '../../actions/panelActions';
import VerticalMediaList from '../../components/MediaList/VerticalMediaList';
import MediaList from '../../components/MediaList';
import TButton from '../../components/TButton';
import TIconButton from '../../components/TIconButton';
import TDropdown from '../../components/TDropdown';

import SpotlightIds from '../../data/SpotlightIds';
import * as ContentType from '../../data/ContentType';
import DetailPopup from '../../components/DetailPopup';
import CustomImage from '../../components/CustomImage';
import * as Config from '../../data/Config';
import dummyImage from '../../../assets/dummyImage.png';

const Container = SpotlightContainerDecorator({enterTo: 'last-focused'}, 'div');
const SeasonDetailPanel = ({hideChildren, panelInfo, ...props}) => {
	const dispatch = useDispatch();
	const showInfos = useSelector(state => state.showInfos);
	const seasonInfos = useSelector(state => state.seasonInfos);
	const seasonList = useSelector(state => state.seasonList);
	const episodeList = useSelector(state => state.episodeList);
	const [selectedSeasonId, setSelectedSeasonId] = useState(panelInfo.seasonId);

	useEffect(() => {
		dispatch(CandyActions.getSeasonList(panelInfo.showId));
	}, [panelInfo.showId]);

	const showInformation = useMemo(() => {
		let bgThumbnail = "", style = {}, showTitle="", seasonIds=[], dropDownMenu=[];
		if(showInfos[panelInfo.showId]){
			const showInfo = showInfos[panelInfo.showId];
			bgThumbnail = showInfo.showThumbnailUrl;
			if(Config.DEMO && bgThumbnail.indexOf('dummyImage')>=0){
				bgThumbnail = dummyImage;
			}
			style = {backgroundImage: `url(${bgThumbnail})`};
			showTitle = showInfo.showTitle;
			if(seasonList[panelInfo.showId] && seasonList[panelInfo.showId].length > 0){
				seasonList[panelInfo.showId].forEach(element => {
					seasonIds.push(element.seasonId);
					dropDownMenu.push($L('Season {num}').replace('{num}', element.seasonNumber));
				});
			}
		}
		if( showTitle.indexOf('\n') >=0){
			showTitle = showTitle.replace('\n', "");
		}
		return {bgThumbnail, showTitle, seasonIds, dropDownMenu};
	}, [showInfos,panelInfo.showId, seasonList]);

	const contentsList = useMemo(() => {
		if(episodeList[selectedSeasonId]){
			return episodeList[selectedSeasonId];
		}
		return [];
	}, [episodeList, selectedSeasonId]);

	const seasonTitle = useMemo(() => {
		let title = "";
		if(seasonInfos[selectedSeasonId]){
			title = seasonInfos[selectedSeasonId].seasonTitle;
		}
		if( title.indexOf('\n') >=0){
			title = title.replace('\n', "");
		}
		return title;
	}, [seasonInfos, selectedSeasonId]);


  	const [isDetailPopupOpen, setDetailPopupOpen] = useState(false);


	const openDetailPopup = useCallback(() => {
		setDetailPopupOpen(true);
	}, [selectedSeasonId]);

	const onCloseDetailPopup = useCallback(() => {
		setDetailPopupOpen(false);
		Spotlight.focus(SpotlightIds.MAIN_DetailSBTN);
	}, []);



	useEffect(() => {
		if(selectedSeasonId){
			dispatch(CandyActions.getEpisodeList(selectedSeasonId));
			dispatch(CandyActions.getSeasonProducts(selectedSeasonId));
			dispatch(CandyActions.getSeasonPreference(selectedSeasonId));
		}
	}, [selectedSeasonId]);

	// useEffect(() => {
	// 	if(!contentsList || contentsList.length <=0){
	// 		Spotlight.focus(SpotlightIds.BACK_BUTTON);
	// 	}
	// }, [contentsList]);

	useEffect(() => {
		if(!hideChildren){
			Spotlight.focus('play');
			// setTimeout(() => {
			// 	setRestoreIndex(-1);
			// }, 500);
		}
	}, [hideChildren]);

	const onSelectSeason = useCallback(({selected}) => {
		console.log('onSelectSeason ',selected);
		const seasonId = showInformation.seasonIds[selected];
		setSelectedSeasonId(seasonId);
		dispatch(PanelActions.updatePanel(PANEL_NAME.seasonDetailPanel, {seasonId: seasonId, showId: panelInfo.showId}));
	}, [dispatch, panelInfo, showInformation]);

	const onBack = useCallback((ev) => {
		if (ev) {
			dispatch(PanelActions.popPanel(PANEL_NAME.seasonDetailPanel));
		}
		ev.stopPropagation();
	}, [dispatch, panelInfo]);


	const onClickPlay = useCallback(() => {
		const season = seasonInfos[selectedSeasonId];
		dispatch(CommonActions.handlePlayItem(season, ContentType.SEASON));
	}, [dispatch, seasonInfos, selectedSeasonId]);

	// const onFocusedIndexChanged = useCallback((id, index) => {
	// 	dispatch(PanelActions.updatePanel(PANEL_NAME.seasonDetailPanel, {restoreIndex: index}));
	// }, [dispatch]);

	return (
		<TPanel {...props} className={css.panel} handleCancel={onBack}>
			<CustomImage className={css.panelBgImg} src={showInformation.bgThumbnail}/>
			<THeader onClick={onBack}/>
			<Marquee marqueeDisabled={false} className={css.showTitle} marqueeOn={"render"}>{showInformation.showTitle}</Marquee>
			<Marquee marqueeDisabled={false} className={css.seasonTitle} marqueeOn={"render"}>{seasonTitle}</Marquee>
			<Container className={css.buttonLayer}>
				<TButton
				type='normal'
				size="small"
				spotlightId="play"
				className={css.buttonArea}
				onClick={onClickPlay}
				>
					{$L('운동시작')}
				</TButton>
				{showInformation.dropDownMenu.length > 0 &&
					<TDropdown
						className={css.tDropdown}
						direction="below"
						size="small"
						width="small"
						selected={showInformation.seasonIds.indexOf(selectedSeasonId)}
						onSelect={onSelectSeason}
						>
						{showInformation.dropDownMenu}
					</TDropdown>
				}
				<TIconButton
				spotlightId={SpotlightIds.ICON_INFORMATION}
				className={css.informationBtn}
				onSelect={openDetailPopup}
				/>
				{isDetailPopupOpen &&
					<DetailPopup onClosePopup={onCloseDetailPopup} open={true} seasonId={selectedSeasonId}/>
				}
			</Container>
			<MediaList className={css.medialistlayer} spotlightId={SpotlightIds.LIST_EPISODES} viewList={contentsList}
				contentType={ContentType.EPISODE}/>
		</TPanel >
	);
};

export default SeasonDetailPanel;
