import LS2Request from '@enact/webos/LS2Request';

let request = LS2Request;
// if (process.env.NODE_ENV === 'development' && typeof window === 'object' && !window.PalmServiceBridge) {
// 	const mock = require('../../__mocks__/@enact/webos/LS2Request');
// 	const MockLS2Request = mock.default;
// 	request = MockLS2Request;
// }

export {request};
export default request;
