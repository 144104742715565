import * as Utils from '../utils/common';
import {types} from '../actions/actionTypes';
import * as Config from '../data/Config';

const initAppStatus = {
	checkingAccountId: true,
	isAppForeground: true,
	homeBannerSize: 'medium', //medium, small
	showCategoryDetail: false,
	webOSVersion:"",
	webOSVersionReal: "",
	deviceId: "",
	isInternetConnected: true,
	cursorVisible: false,
	captionEnable: false,
	cameraDeviceName: "",
	cameraDetected: false,
	showTermsPopup: false,
	keywordSearching: false
};
const initialLocalSettings = {
	isFirstLaunched: true,
	email: '',
	userId: '',
	password: '',
	authenticationResult: {},
	tokenRequiredTime: new Date(),
	//camera
	cameraActivated: true,
	cameraSize: 'small',
	cameraPosition: 'rt',
	//debug
	loginType: Config.DEFAULT_LOGIN_TYPE,
	useQAServerURL: Config.DEFAULT_SERVERTYPE,
	useForceCamera: false,

	//search
	recentSearch: [],
	//settings
	checkHeartRate: true,
	checkCalory: true,
	checkStep: true,
	checkSound: true,
	checkVoice: true,
	videoAutoPlay: true,
	preventBleNotiPopup: false,
	lastConnectedBleAddress: ''
};
const showLoadingView =  (state = {loadingArray: [], show: true}, action) => {
	switch (action.type) {
		case types.INIT_LOADING_STATUS:{
			return Object.assign({}, state, {loadingArray: []});
		}
		case types.ADD_LOADING_STATUS:
			state.loadingArray.push(action.payload);
			state.show = true;
			//console.log('yhcho showLoadingView',JSON.stringify(state));
			return Object.assign({}, state);
		case types.REMOVE_LOADING_STATUS:{
			const index = state.loadingArray.indexOf(action.payload);
			if(index >=0){
				state.loadingArray.splice(index,1);
			}
			if(state.loadingArray.length > 0){
				state.show = true;
			}else{
				state.show = false;
			}
			//console.log('yhcho showLoadingView REMOVE_LOADING_STATUS',action.payload, state);
			return Object.assign({}, state);
		}
		default:
			return state;
	}
};
const appStatus = (state = initAppStatus, action) => {
	switch (action.type) {
		case types.CHANGE_APP_STATUS:
			return Object.assign({}, state, action.status);
		default:
			return state;
	}
};

const getTermsStatus = (state = {}, action) => {
	switch (action.type) {
		case types.GET_TERMS_STATUS:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};

const getTermsDoc = (state = [], action) => {
	switch (action.type) {
		case types.GET_TERMS_DOC:
			return action.payload;
		default:
			return state;
	}
};

const focusedBannerItem = (state = null, action) => {
	switch (action.type) {
		case types.GET_FOCUSED_BANNER_ITEM:
			return action.payload;
		default:
			return state;
	}
};
const playListInfo = (state = {playList:[],playIndex:0}, action) => {
	switch (action.type) {
		case types.GET_PLAYER_INFO:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};

const videoPreviewThumbs = (state = {}, action) => {
	switch (action.type) {
		case types.GET_VIDEO_PROGRESS_THUMBNAIL:
			return Object.assign({}, state, {[action.payload.requestId]:action.payload.thumbnails[0]});
		case types.CLEAR_VIDEO_PROGRESS_THUMBNAIL:
			return {};
		default:
			return state;
	}
};

const launchAction = (state = {}, action) => {
	switch (action.type) {
		case types.GET_LAUNCH_ACTION:
			return Object.assign({}, state, action.payload);
		case types.CLEAR_LAUNCH_ACTION:
			return {};
		default:
			return state;
	}
};

const supportPIPCamera = (state = false, action) => {
	switch (action.type) {
		case types.CHANGE_APP_STATUS:
			if (typeof window === 'object'){
				const webOSVersion = window.store.getState().appStatus.webOSVersion;
				const useForceCamera = window.store.getState().localSettings.useForceCamera;
				const otaId = window.store.getState().appStatus.otaId;
				if(useForceCamera || Number(webOSVersion) >= 6.2 || webOSVersion === 'local'){
					return true;
				}
				//stand by me model
				if(otaId === "HE_DTV_N21D_AFAAATAA"){
					return true;
				}
			}
			return state;
		default:
			return state;
	}
};
const updateInitialLocalSettings = () => {
	const data = Utils.readLocalStorage('localSettings',initialLocalSettings);
	//test
	// Utils.writeLocalStorage('localSettings', initialLocalSettings);
	// return initialLocalSettings;
	return data;
};
const localSettings = (state = updateInitialLocalSettings(), action) => {
	switch (action.type) {
		case types.CHANGE_LOCAL_SETTINGS:{
			const newState = Object.assign({}, state, action.status);
			Utils.writeLocalStorage('localSettings', newState);
			return newState;
		}
		default:
			return state;
	}
};
const commonReducer = {
	appStatus,
	showLoadingView,
	focusedBannerItem,
	playListInfo,
	launchAction,
	videoPreviewThumbs,
	getTermsStatus,
	getTermsDoc,
	supportPIPCamera,
	localSettings
};

export default commonReducer;
