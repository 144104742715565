import css from './TimeSetting.module.less';
import classNames from 'classnames';
import {$L} from '../../../../../../../utils/common';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputField } from '@enact/sandstone/Input';
import Alert from '@enact/sandstone/Alert';
import * as Utils from '../../../../../../../utils/common';
import TButton from '../../../../../../../components/TButton/TButton';
import * as CommonActions from '../../../../../../../actions/commonActions';
import * as CandyActions from '../../../../../../../actions/candyActions' ;
import Spotlight from '@enact/spotlight';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';


const Container = SpotlightContainerDecorator({enterTo: "last-focused", preserveId: true}, 'div');

const TimeSetting = ({open, onClose}) => {
	const dispatch = useDispatch();
	const userPreference = useSelector(state => state.userPreference);
	const [hour, setHour] = useState(0);
	const [minute, setMinute] = useState(0);

	useEffect(() => {
		Spotlight.focus("BackBtn");
	},[]);
	const adjustHour = useCallback((value)=>{
		let isValid = true;
		let numValue = Number(value);
		if(numValue < 0){
			numValue = 0;
			isValid = false;
		}else if(numValue > 23){
			numValue = 23;
			isValid = false;
		}
		setHour(numValue);
		return isValid;
	}, []);
	const adjustMinute = useCallback((value)=>{
		let isValid = true;
		let numValue = Number(value);
		if(numValue < 0){
			numValue = 0;
			isValid = false;
		}else if(numValue > 59){
			numValue = 59;
			isValid = false;
		}else if(hour === 0 && numValue < 10){
			isValid = false;
		}
		setMinute(Utils.strTime(numValue));
		return isValid;
	}, [hour]);
	useEffect(() => {
		const h = Math.floor(userPreference.goalWorkoutTime/3600);
		const m = Math.floor((userPreference.goalWorkoutTime%3600)/60);
		adjustHour(h);
		adjustMinute(m);
	},[userPreference.goalWorkoutTime, open]);

	const onCancle = useCallback((e) => {
		console.log('onClose', e);
		if (onClose) {
			onClose();
		}
	}, [onClose]);

	const onHourChange = useCallback((e)=>{
		setHour(Math.floor(e.value));
	},[]);

	const onMinuteChange = useCallback((e)=>{
		setMinute(Math.floor(e.value));
	}, []);

	const onDeactivateInput = useCallback((type) => (e)=>{
		let isValid = true;
		if(type === 'hour'){
			isValid = adjustHour(hour);
		}else if(type === 'minute'){
			isValid = adjustMinute(minute);
		}
		if(!isValid){
			dispatch(CommonActions.alertToast($L("운동시간은 10분 이상 24시간 미만으로 입력해주세요.")));
		}else{
			if(type==='hour'){
				Spotlight.focus('Minute');
			}
			}
	}, [hour, minute, adjustHour, adjustMinute]);
	const onComplete = useCallback(() => {
		let sec = hour*3600 + minute*60;
		if(sec >= 600 && sec < 86400){
			dispatch(CandyActions.updateUserPreferences({goalWorkoutTime: sec}));
			if (onClose) {
				onClose();
			}
		}else{
			dispatch(CommonActions.alertToast($L("운동시간은 10분 이상 24시간 미만으로 입력해주세요.")));
		}
	}, [dispatch, onClose, hour, minute]);

	const onSpotlightLeft = useCallback((ev)=>{
		ev.preventDefault();
	});

	const onMinuteClick = useCallback((e)=>{
		if(e.key === 'Enter'|| e.type === "click"){
			Spotlight.focus('CompleteBtn');
		}
	},[]);

	return (
		<Alert open={open} onClose={onCancle} type={"overlay"} className={css.popup}>
			<div className={css.title}>{$L('운동 시간 설정')}</div>
			<div className={css.inputBox}>
				<InputField
					size={'small'}
					type={"number"}
					autoFocus
					dismissOnEnter
					value={hour}
					className={classNames(css.inputField)}
					onChange={onHourChange}
					onDeactivate={onDeactivateInput('hour')}
				/>
				<div className={css.letter}>{$L('시간')}</div>
				<InputField
					size={'small'}
					type={"number"}
					autoFocus
					dismissOnEnter
					spotlightId='Minute'
					onKeyDown={onMinuteClick}
					value={minute}
					className={classNames(css.inputField)}
					onSpotlightLeft={onSpotlightLeft}
					onChange={onMinuteChange}
					onDeactivate={onDeactivateInput('minute')}
				/>
				<div className={css.letter}>{$L('분')}</div>
			</div>
			<div className={css.bottomBtnLayer}>
				<TButton className={css.nomalBtn} spotlightId={"BackBtn"} size="small" onClick={onCancle}>{$L('취소')}</TButton>
				<TButton className={css.nomalBtn} spotlightId={"CompleteBtn"} size="small" color="dark" onClick={onComplete}>{$L('완료')}</TButton>
			</div>
		</Alert>
	);
};

export default TimeSetting;