/**
 * HistoryItem
 *
 * @module HistoryItem
 */
import React, {useMemo} from 'react';
import {shallowEqual} from 'react-redux';
import classNames from 'classnames';
import Marquee from '@enact/sandstone/Marquee';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import HistoryDetail from './HistoryDetail';
import css from './HistoryItem.module.less';

const Container = SpotlightContainerDecorator({enterTo: 'default-element'}, 'div');

const HistoryItem = ({className, listPerShow, forceFocus, marqueeAlign = 'left', ...props}) => {
    const historyInfo = useMemo(() => {
        let showTitle = '';
        const summary = {time: 0, kcal: 0, step: 0};
        const historyPerSeasons = {};
        listPerShow.forEach((activity) => {
            if (!showTitle) {
                showTitle = activity.showTitle;
            }
            summary.time += Number(activity.workoutTime);
            summary.kcal += Number(activity.calorieConsumption);
            summary.step += Number(activity.candy);
            if (!historyPerSeasons[activity.seasonId]) {
                historyPerSeasons[activity.seasonId] = {};
            }
            historyPerSeasons[activity.seasonId].seasonThumbnailUrl = activity.seasonThumbnailUrl;
            historyPerSeasons[activity.seasonId].seasonTitle = activity.seasonTitle;
            if (!historyPerSeasons[activity.seasonId].episodeTitles) {
                historyPerSeasons[activity.seasonId].episodeTitles = [];
            }
            if (historyPerSeasons[activity.seasonId].episodeTitles.indexOf(activity.episode.episodeTitle) < 0) {
                historyPerSeasons[activity.seasonId].episodeTitles.push(activity.episode.episodeTitle);
            }
        });
        return {showTitle, summary, historyPerSeasons};
    }, [listPerShow]);

    const strIndex = (s) => {
        return String(s).length === 1 ? '0' + String(s) : String(s);
    };

    return (
        <Container className={classNames(css.itemContainer, className)}>
            <div className={css.detail}>
                <div className={css.h2}>{historyInfo.showTitle}</div>
                <HistoryDetail {...historyInfo.summary} />
                {Object.keys(historyInfo.historyPerSeasons).map((seasonId, index) => {
                    const item = historyInfo.historyPerSeasons[seasonId];
                    return (
                        <div key={index} className={css.detailWrap}>
                            <img className={css.imgStyle} src={item.seasonThumbnailUrl} alt={item.seasonTitle} />
                            <div className={css.titleWrap}>
                                <Marquee alignment={marqueeAlign} key='caption' className={css.title}>
                                    {item.seasonTitle}
                                </Marquee>
                                <div className={css.episodeTitleContainer}>
                                    {item.episodeTitles.map((title, index) => {
                                        return (
                                            <Marquee key={index} alignment={marqueeAlign} className={css.episodeTitle}>
                                                {`${strIndex(index + 1)}. ${title}`}
                                            </Marquee>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </Container>
    );
};

export default React.memo(HistoryItem, shallowEqual);
