/**
 * TIconButton
 *
 * @module TIconButton
 */

 import {Job} from '@enact/core/util';
import css from './TIconButton.module.less';
import classNames from 'classnames';
import React, {useState, useCallback, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Spottable from '@enact/spotlight/Spottable';
import {VoiceControlDecorator} from '@enact/webos/speech';
import {$L} from '../../utils/common';
import * as Config from '../../data/Config';
import SpotlightIds from '../../data/SpotlightIds';
import Spotlight from '@enact/spotlight';

const SpottableComponent = Spottable('div');
const VoiceComp = VoiceControlDecorator(SpottableComponent);
const TIconButton = ({isOnTop=true, className, onSelect, spotlightId, selected, ...rest}) => {
	const buttonInfo = useMemo(() => {
		const info = {title: 'Unknown', css: null, voiceIntent: 'Select', voiceLabel: ''};
		switch(spotlightId){
			case SpotlightIds.MAIN_EXITBTN:
				info.css =  [css.topbutton, css.exit];
				info.voiceLabel = $L("Exit");
				break;
			case SpotlightIds.MAIN_SEARCHBTN:
				info.css = [css.topbutton, css.search];
				info.voiceLabel = $L("Search");
				break;
			case SpotlightIds.MAIN_SETTINGSBTN:
				info.css = [css.topbutton, css.settings];
				info.voiceLabel = $L("Settings");
				break;
			case SpotlightIds.ICON_INFORMATION:
				info.css = css.information;
				info.voiceLabel = $L("Information");
				break;
			case SpotlightIds.ICON_FAVORITE:
				info.css = css.favorite;
				info.voiceLabel = $L("Favorite");
				break;
			case SpotlightIds.ICON_LIKE:
				info.css = css.like;
				info.voiceLabel = $L("Like");
				break;
			case SpotlightIds.ICON_DISLIKE:
				info.css = css.dislike;
				info.voiceLabel = $L("Dislike");
				break;
		}
		return info;
	}, [spotlightId]);
	const [pressed, setPressed] = useState(false);

	const onClick = useCallback((ev) => {
		console.log('onClick ev...', ev);
		setPressed(true);
		new Job(() => {
			setPressed(false);
			if (onSelect) {
				onSelect(spotlightId, rest['data-index']);
			}
		}, 200).start();
	}, [spotlightId, onSelect, rest, setPressed]);
	const onVoiceHandle = useCallback((ev) => {
		Spotlight.focus(ev.target);
		onClick();
		ev.preventDefault();
	}, [onClick]);
	const childProps = { ...rest };
	delete childProps.inline;
	delete childProps.onClick;
	return (
		<VoiceComp
			{...childProps}
			spotlightId={spotlightId}
			className={classNames(css.button, buttonInfo.css, selected ? css.selected: null, className)}
			onClick={onClick}
			data-webos-voice-intent={isOnTop ? buttonInfo.voiceIntent: null}
			data-webos-voice-label={isOnTop ? buttonInfo.voiceLabel: null}
			onVoice={onVoiceHandle}
		/>
	);
};

export default TIconButton;
