import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {$L} from '../../../../../../../utils/common';
import { InputField } from '@enact/sandstone/Input';
import css from './WalkSetting.module.less';
import Alert from '@enact/sandstone/Alert';
import TButton from '../../../../../../../components/TButton/TButton';
import Spotlight from '@enact/spotlight';
import Spottable from '@enact/spotlight/Spottable';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import * as CommonActions from '../../../../../../../actions/commonActions';
import * as CandyActions from '../../../../../../../actions/candyActions' ;

const Container = SpotlightContainerDecorator({enterTo: "last-focused", preserveId: true}, Spottable('div'));

const WalkSetting = ({open, onClose}) => {
	const userPreference = useSelector(state => state.userPreference);
	const dispatch = useDispatch();
	const [changeSteps, setChangeSteps] = useState(0);

	useEffect(() => {
	    Spotlight.focus("BackBtn");
	},[]);

	const adjustGoalStep = useCallback((value)=>{
		let numValue = Number(value);
		let isValid = true;
		if(numValue < 10){
			isValid = false;
		}else if(numValue > 99999){
			numValue = 99999;
			isValid = false;
		}
		setChangeSteps(numValue);
		return isValid;
	},[])

	useEffect(()=>{
		const goalStepCount = userPreference.goalStepCount;
		adjustGoalStep(goalStepCount);
	},[userPreference.goalStepCount, open]);

    const onCancle = useCallback(() => {
		if (onClose) {
			onClose();
		}
	}, [onClose]);

    const onStepsChange = useCallback((e)=>{
		setChangeSteps(Math.floor(e.value));
	},[]);

	const onDeactivateInput = useCallback(() => {
		let isValid = true;
		isValid = adjustGoalStep(changeSteps);
		if(!isValid){
			dispatch(CommonActions.alertToast($L("걸음수는 10걸음 이상 100,000걸음 미만으로 입력해주세요.")))
		}else{
			Spotlight.focus('CompleteBtn');
		}
	}, [adjustGoalStep, changeSteps]);

	const onComplete = useCallback(() => {
		if(changeSteps > 9 && changeSteps < 100000){
			let _changeSteps = Number(changeSteps);
			dispatch(CandyActions.updateUserPreferences({goalStepCount: _changeSteps}));
			if (onClose) {
			onClose();
			}
		}else{
			dispatch(CommonActions.alertToast($L("걸음수는 10걸음 이상 100,000걸음 미만으로 입력해주세요.")))
		}
	}, [dispatch, onClose, changeSteps]);

	return (
		<Alert open={open} onClose={onCancle} type={"overlay"} className={css.popup}>
			<Container>
				<div className={css.title}>{$L('걸음 수 설정')}</div>
                <div className={css.inputBox}>
                    <InputField
						className={css.inputField}
                        spotlightId={"walkInput"}
						type={"number"}
						autoFocus
						dismissOnEnter
						onChange={onStepsChange}
						value={changeSteps}
						onDeactivate={onDeactivateInput}
					/>
                </div>
				<div className={css.bottomBtnLayer}>
					<TButton className={css.nomalBtn} spotlightId={"BackBtn"} size="small" onClick={onCancle}>{$L('취소')}</TButton>
                    <TButton className={css.nomalBtn} spotlightId={"CompleteBtn"} size="small" color="dark" onClick={onComplete}>{$L('완료')}</TButton>
				</div>
			</Container>
		</Alert>
	);
};

export default WalkSetting;