import { types } from './actionTypes';
import {Job} from '@enact/core/util';
import * as PanelActions from './panelActions';
import * as ContentType from '../data/ContentType';
import * as Utils from '../utils/common';
import * as lunaSend from '../lunaSend';
import appinfo from '../../webos-meta/appinfo.json';

export const changeAppStatus = (status) => ({type: types.CHANGE_APP_STATUS, status});
export const changeLocalSettings = (status) => ({type: types.CHANGE_LOCAL_SETTINGS, status});

export const initLoadingStatus = () => ({type: types.INIT_LOADING_STATUS});
export const addLoadingStatus = (status) => ({type: types.ADD_LOADING_STATUS, payload: status});
export const removeLoadingStatus= (status) => (dispatch) =>{
	setTimeout(() => {
		dispatch({type: types.REMOVE_LOADING_STATUS, payload: status});
	}, 100);
};

export const getSystemSettings = (onComplete)  => (dispatch, getState) => {
	const useQAServerURL = getState().localSettings.useQAServerURL;
	lunaSend.getSystemSettings({"category":"option", "keys": ["smartServiceCountryCode2", "cameraResourcePermission"]}, {
		onSuccess: (res) => {
			console.log("getSystemSettings onSuccess ",res);
		},
		onFailure: (err) => {
			console.log("getSystemSettings onFailure ",err);
		},
		onComplete: (res) => {
			console.log("getSystemSettings onComplete",res);
			let country=null;
			if(res && res.settings){
				if(res.settings.smartServiceCountryCode2){
					country = res.settings.smartServiceCountryCode2;
				}
				if(res.settings.cameraResourcePermission){
					for(let i=0; i<res.settings.cameraResourcePermission.length; i++){
						const data = res.settings.cameraResourcePermission[i];
						if(data.appId === appinfo.id){
							for(let j=0; j<data.permissions.length; j++){
								if( data.permissions[j].resource === 'camera'){
									dispatch(changeAppStatus({cameraPermissionRequired: data.permissions[j].permission}));
									break;
								}
							}
							break;
						}
					}
				}
			}
			if(country){
				dispatch(changeAppStatus({country: country, language: Utils.getLanguageCode(country)}));
			}else{
				dispatch(changeAppStatus({country: "US", language: Utils.getLanguageCode("US")}));
			}
			if(useQAServerURL === 'qa' || useQAServerURL === 'dev'){
				dispatch(changeAppStatus({country: "KR", language: Utils.getLanguageCode("KR")}));
			}
			if (onComplete)	onComplete();
		}
	});
};
export const getSystemSettings2 = ()  => (dispatch) => {
	lunaSend.getSystemSettings({"category":"caption", "keys": ["captionEnable"]}, {
		onSuccess: (res) => {
			console.log("getSystemSettings2 onSuccess ",res);
		},
		onFailure: (err) => {
			console.log("getSystemSettings2 onFailure ",err);
		},
		onComplete: (res) => {
			console.log("getSystemSettings2 onComplete",res);
			if(res && res.settings){
				if(typeof res.settings.captionEnable !== 'undefined'){
					dispatch(changeAppStatus({captionEnable: res.settings.captionEnable}));
				}
			}
		}
	});
};

let updateNetworkStateJob = new Job((dispatch, connected) => {
	dispatch(changeAppStatus({isInternetConnected: connected}));
});

export const getConnectionStatus = () => (dispatch) => {
	lunaSend.getConnectionStatus({
		onSuccess: (res) => {
			console.log(res);
			if(res.returnValue){
				const isInternet = ((res.wifi && res.wifi.onInternet === 'yes') || (res.wired && res.wired.onInternet === 'yes'));
				const isInternetConnected = ((res.wifi && res.wifi.state === 'connected') || (res.wired && res.wired.state === 'connected'));
				console.log('internetconnected.............',isInternet,isInternetConnected, res);
				const connected = isInternet&&isInternetConnected;
				updateNetworkStateJob.startAfter(connected ? 100 : 3000, dispatch, connected);
			}
		},
		onFailure: (err) => {
			console.log(err);
		},
		onComplete: (res) => {
			console.log('getConnectionStatus done', res);
		}
	});
};
export const getSystemInfo = (onComplete)  => (dispatch) => {
	lunaSend.getSystemInfo({"keys": ["sdkVersion", "otaId"]}, {
		onSuccess: (res) => {
			console.log(res);
			if(res.returnValue){
				const version = res.sdkVersion;
				if(version === 'local'){
					dispatch(changeAppStatus({webOSVersion: "local", webOSVersionReal: 'local', otaId: 'local'}));
				}else{
					dispatch(changeAppStatus({webOSVersion: version.substring(0,version.lastIndexOf('.')), webOSVersionReal: version, otaId: res.otaId}));
				}
			}
		},
		onFailure: (err) => {
			console.log(err);
		},
		onComplete: () => {
			console.log('getSystemInfo done');
			if (onComplete)	onComplete();
		}
	});
};
export const getDeviceId = (onComplete)  => (dispatch) => {
	lunaSend.getDeviceId({"idType": ["LGUDID"]}, {
		onSuccess: (res) => {
			console.log(res);
			if(res.returnValue){
				const deviceId = res.idList[0].idValue;
				dispatch(changeAppStatus({deviceId: deviceId}));
			}
		},
		onFailure: (err) => {
			console.log(err);
		},
		onComplete: () => {
			console.log('getDeviceId done');
			if (onComplete)	onComplete();
		}
	});
};
export const changePlayInfo = (info) => (dispatch) => {
	dispatch({type: types.GET_PLAYER_INFO, payload: info});
};
// banner, inapp search, favorite, nudge, deeplink, replay,
export const setPlayLaunchPath = (str) => (dispatch) => {
	dispatch(changeAppStatus({playerLaunchPath: str}));
};
export const handlePlayItem = (item, contentType) => async (dispatch, getState) => {
	let playList, playIndex = 0, hasRelated = false, listTitle = "";
	const episodeList = getState().episodeList;
	if(item){
		if(contentType === ContentType.EPISODE || contentType === ContentType.SEASON){
			playList = episodeList[item.seasonId];
			if(!playList){
				console.log('handlePlayItem: invalid item...........No EpisodeList', contentType, item);
				return;
			}
			for(let i=0; i<playList.length;i++){
				if(playList[i].episodeId === item.episodeId){
					playIndex = i;
					break;
				}
			}
		}else{
			console.log('handlePlayItem: invalid item...........', contentType, item);
			return;
		}
	}else{
		//todo
		console.log('handlePlayItem: invalid item...........', contentType, item);
		return;
	}
	const pInfo = {
			playList,
			playIndex,
			hasRelated,
			listTitle
	};
	console.log('handlePlayItem ',item, contentType, pInfo);
	dispatch(changePlayInfo(pInfo));
	dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.player));
};

export const cancelGetVideoThumbnail = () => () => {
	lunaSend.cancelGetVideoThumbnail({
		onSuccess: (res) => {
			console.log('cancelGetVideoThumbnail', res);
		},
		onFailure: (err) => {
			console.log('cancelGetVideoThumbnail', err);
		}
	});
};
export const getVideoThumbnail = (sourceUri, time) => (dispatch) => {
	lunaSend.getVideoThumbnail(sourceUri, time, {
		onSuccess: (res) => {
			console.log('getVideoThumbnail', res);
			dispatch({type: types.GET_VIDEO_PROGRESS_THUMBNAIL, payload: res});
		},
		onFailure: (err) => {
			console.log('getVideoThumbnail', err);
		}
	});
};
export const clearVideoThumbnails = () => (dispatch) => {
	dispatch({type: types.CLEAR_VIDEO_PROGRESS_THUMBNAIL});
};
const doItemFocus = async (dispatch, getState, item) => {
	const mainIndex = getState().mainIndex;
	if(mainIndex === PanelActions.MAIN_INDEX.HOME_VIEW){
		dispatch({type: types.GET_FOCUSED_BANNER_ITEM, payload: item});
	}
}
let itemFocusJob = new Job(doItemFocus, 500);
export const handleItemFocus = (item) => async (dispatch, getState) => {
	itemFocusJob.start(dispatch, getState, item);
}
export const clearFocusedBannerItem = () => async (dispatch) => {
	dispatch({type: types.GET_FOCUSED_BANNER_ITEM, payload: null});
}
export const alertToast = payload => (dispatch, getState) => {
	const webOSVersion = getState().appStatus.webOSVersion;
	if (webOSVersion === "local" && typeof window === 'object') {
		dispatch(changeAppStatus({toast: true, toastText:payload}));
	}else{
		lunaSend.alertToast(payload, {
			onSuccess: (res) => {
				console.log(res);
			},
			onFailure: (err) => {
				console.log(err);
			}
		});
	}
};

export const setLaunchAction = (info) => (dispatch) => {
	dispatch({type: types.GET_LAUNCH_ACTION, payload: info});
};
export const clearLaunchAction = () => (dispatch) => {
	dispatch({type: types.CLEAR_LAUNCH_ACTION});
};
