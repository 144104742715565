import { useEffect, useState, useRef, useCallback } from 'react';
import css from './TermsServiceDetail.module.less';
import TScroller from '../../../../../../components/TScroller/TScroller';
import dummyGetTermsDoc from '../../../../../../../assets/mock/dummyTerms.js'

const TermsServiceDetail = ({id}) => {
	const [terms, setTerms] = useState('');
	const scrollTo = useRef(null);

	const getScrollTo = useCallback((cbScrollTo) => {
        scrollTo.current = cbScrollTo;
    }, [id]);

	useEffect(()=>{
		setTerms(dummyGetTermsDoc[id])
		if (scrollTo && scrollTo.current) {
			scrollTo.current({position: {y: 0}, animate: false});
		}
	}, [id])

	return (
		<div className={css.detail}>
			<TScroller className={css.tScroller} cbScrollTo={getScrollTo} >
				<div className={css.terms}>
					{<div dangerouslySetInnerHTML={{ __html: terms}}></div>}
				</div>
			</TScroller>
		</div>
	);
};

export default TermsServiceDetail;