/**
* NoticeItem
*
* @module NoticeItem
*/

import React, { useCallback } from 'react';
import {shallowEqual} from 'react-redux';
import classNames from 'classnames';
import Spottable from '@enact/spotlight/Spottable';
import * as Utils from '../../../../utils/common';
import css from '../NoticePanel.module.less';

const SpottableComponent = Spottable('div');

const NoticeItem = ({ noticeData, index, onClick }) => {
	const openDay = Utils.convertUTCtoLocalTime(new Date(noticeData.openFrom));
	const dateStr = openDay.toLocaleDateString("default", { year: 'numeric', month: 'short', day: 'numeric' });

	const onClickList = useCallback(() => {
		onClick(index);
	},[]);

	if(noticeData){
		return (
			<SpottableComponent className={classNames(css.itemContainer, index % 2 === 0 ? css.odd : null)} spotlightId={"noticeList"} onClick={onClickList}>
				<div className={classNames(css.text, css.date)}>{dateStr}</div>
				<div  className={classNames(css.text, css.title)}>
					{Utils.replaceHtmlEntites(Utils.convertUtf8ToUni(noticeData.title))}
				</div>
			</SpottableComponent>
		);
	}
	return null;
};

export default React.memo(NoticeItem, shallowEqual);
