const SpotlightIds = {
	MAIN_PANEL: 'mainpanel',
	BACK_BUTTON: 'backButton',
	BANNER_PLAYBTN: 'bannerPlayerButton',
	BANNER_ADDFAVBTN: 'bannerAddFavButton',
	MAIN_SETTINGSBTN: 'settingsButton',
	MAIN_SEARCHBTN: 'searchButton',
	MAIN_EXITBTN: 'exitButton',
	MAIN_LOGINBTN: 'loginButton',
	MAIN_LOGOUTBTN: 'logoutButton',
	MAIN_CATEGORY_BTN: 'mainCategoryButton',
	MAIN_HOME_BTN: 'mainHomeButton',
	MAIN_DASHBOARD_BTN: 'mainDashboardButton',

	HOME_SCROLLER: 'home_scroller',
	HOME_BANNER: 'home_banner',
	LIST_CATEGORY: 'list_category',
	LIST_CATEGORY_TABLE: "list_category_table",
	LIST_WORKOUTSTYLE_TABLE: "list_workoutstyle_table",
	LIST_RECENTLY_WATCHED: "HOME_RECENTLY_WATHCHED",
	LIST_RECOMMANDED: "HOME_RECOMMEND",
	LIST_SPECIAL: "HOME_SPECIAL",
	LIST_MYFAVORITE: "HOME_MY_FAVORITE",
	LIST_RECENTLY_ADDED: "HOME_RECENTLY_ADDED",
	LIST_SEARCH: "list_search",
	LIST_EPISODES: 'list_episodes',
	LIST_MORE: 'list_more',
	LIST_EXTENDED: 'list_extended',
	LIST_TAG: 'list_tag',
	LIST_DUMMY: 'list_dummy',

	PLAYER_TITLE_LAYER: 'playerTitleLayer',
	PLAYER_SLIDER: 'playerslider',
	PLAYER_CONTROL: 'playerControl',
	LIST_PLAYER: "list_player",
	LIST_PLAYER2: "list_player2",

	RECENT_SEARCH: "recent_search",
	LOGIN_GUIDE_BUTTON: "LOGIN_GUIDE_BUTTON",
	DASHBOARD_SCROLLER: "DASHBOARD_SCROLLER",
	DASHBOARD_GOAL_BTN_GROUP: "DASHBOARD_GOAL_BTN_GROUP",

	HISTORY_UP_BUTTON: 'history_up_button',
	HISTORY_DOWN_BUTTON: 'history_down_button',

	/* icon button */
	ICON_INFORMATION: 'icon_information',
	ICON_LIKE: 'icon_like',
	ICON_DISLIKE: 'icon_dislike'
};

export default SpotlightIds;
