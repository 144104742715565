/**
 * TButton
 *
 * @module TButton
 */
import css from './TButton.module.less';
import classNames from 'classnames';
import {useCallback, useEffect, useState, useMemo} from 'react';
import Spottable from '@enact/spotlight/Spottable';
import Marquee from '@enact/sandstone/Marquee';

const SpottableComponent = Spottable('div');
/**
 *
 * @param {type} normal, withArrow
 * @returns
 */
const TYPES=["normal", "withArrow", "back", "tabBtnVertical", "tabBtnHorizon", "grayLineBtn", "candyBandBtn"];

/**
 *
 * @size tiny, small, large, huge
 * @color light, dark
 * @returns
 */
const TButton = ({type="normal", size='large', color="light", children, withMarquee=false, marqueeOn='focus', alignment='center', spotlightId, className, onClick, disabled, onDisabled, selected, fillDuration=0,...rest}) => {
	const [runFillAni, setRunFillAni] = useState(false);
	const [focused, setFocused] = useState(false);
	useEffect(() => {
		if(TYPES.indexOf(type) < 0 ){
			console.error('TButton type error');
		}
	}, [type]);
	useEffect(() => {
		setTimeout(() => {
			setRunFillAni(true);
		}, 0);
	}, [fillDuration]);

	const _onClick = useCallback((ev) => {
		if(disabled){
			return;
		}
		if (onClick) {
			onClick(ev, type);
		}
		ev.persist();
	}, [ onClick, disabled, type]);

	const onFocus = useCallback((ev) => {
		setFocused(true);
		if(rest.onFocus){
			rest.onFocus(ev);
		}
	}, [rest]);
	const onBlur = useCallback(() => {
		setFocused(false);
	}, []);
	const marqueeOnOption = useMemo(()=>{
		if(marqueeOn === 'focus'){
			if(focused){
				return 'render';
			}else{
				return 'focus';
			}
		}
		return marqueeOn;
	}, [marqueeOn,  focused]);

	const additionalClasses = [];
	let spotlightDisabled = false;

	let additionalStyle = runFillAni ? {"transition": "background-position "+fillDuration+"s ease-out", "backgroundPosition": 'left'}:{};
	if(rest.style){
		additionalStyle = Object.assign({}, additionalStyle, rest.style);
	}
	return (
		<SpottableComponent
			{...rest}
			onFocus={onFocus}
			onBlur={onBlur}
			style={additionalStyle}
			className={classNames(css.tButton, css[type], css[size], css[color], className ? className: null
				, additionalClasses, selected && css.selected, disabled ? css.disabled: null, fillDuration>0 ? css.fillAni:"")}
			onClick={_onClick}
			data-webos-voice-intent={'Select'}
			data-webos-voice-label={children}
			spotlightDisabled={spotlightDisabled}
			spotlightId={spotlightId}
		>
			{children &&
				withMarquee ?
				<Marquee  className={css.marquee} marqueeOn={marqueeOnOption} alignment={alignment}>
					{children}
				</Marquee>
				:
				<div className={css.text}>
					{children}
				</div>
			}
		</SpottableComponent>
	);
};

export default TButton;
