import {types} from '../actions/actionTypes';
import {MAIN_INDEX, PANEL_NAME} from '../actions/panelActions';
import SpotlightIds from '../data/SpotlightIds';
const loadingSpinnerState = (state = {showing: true}, action) => {
	switch (action.type) {
		case types.SHOW_HIDE_LOADING_SPINNER: {
			console.log('loadingSpinnerState action.showing ' + action.showing);
			return Object.assign({}, state, {showing: action.showing});
		}
		default:
			return state;
	}
};
const panels = (state = [], action ) => {
	const forceTopPanels=[PANEL_NAME.emaillogin, PANEL_NAME.codelogin,
		PANEL_NAME.termsConditions, PANEL_NAME.termsConditionsDetail, PANEL_NAME.termsModification,
		PANEL_NAME.debugpanel, PANEL_NAME.testpanel, PANEL_NAME.errorpanel, PANEL_NAME.startpage];
	switch (action.type) {
		case types.RESET_PANELS: {
			const newState = [];
			if(action.payload.withOutTop){
				const forceTopPanelsInfo=[];
				let forceTopIndex;
				for(let index in state){
					forceTopIndex = forceTopPanels.indexOf(state[index].name);
					if(forceTopIndex >=0){
						forceTopPanelsInfo[forceTopIndex] = state[index];
					}
				}
				for(let i=0; i<forceTopPanels.length;i++){
					if(forceTopPanelsInfo[i]){
						newState.push(forceTopPanelsInfo[i]);
					}
				}
			}
			return newState;
		}
		case types.UPDATE_PANEL: {
			let existIndex = -1;
			for(let index in state){
				if(state[index].name === action.payload.name){
					existIndex = index;
					break;
				}
			}
			if(existIndex >=0 && action.payload.panelInfo){
				state[existIndex].panelInfo = Object.assign({}, state[existIndex].panelInfo, action.payload.panelInfo);
			}
			console.log('UPDATE_PANEL state....',state);
			return state;
		}
		case types.PUSH_PANEL: {
			const forceTopPanelsInfo=[];
			const newState = [];
			let forceTopIndex;
			for(let index in state){
				forceTopIndex = forceTopPanels.indexOf(state[index].name);
				if(forceTopIndex >=0){
					forceTopPanelsInfo[forceTopIndex] = state[index];
				}else if(state[index].name !== action.payload.name){
					newState.push(state[index]);
				}
			}
			forceTopIndex = forceTopPanels.indexOf(action.payload.name);
			if(forceTopIndex >=0){
				forceTopPanelsInfo[forceTopIndex] = action.payload;
			}else{
				newState.push(action.payload);
			}
			for(let i=0; i<forceTopPanels.length;i++){
				if(forceTopPanelsInfo[i]){
					newState.push(forceTopPanelsInfo[i]);
				}
			}
			return newState;
		}
		case types.POP_PANEL:{
			let existIndex = -1;
			if(action.payload){
				for(let index=0; index<state.length;index++){
					if(state[index].name === action.payload){
						existIndex = index;
						break;
					}
				}
			}
			if (existIndex >= 0) { // exist
				return [...state.filter((value)=>value.name !== action.payload)];
			}else if(!action.payload){
				return [...state.slice(0, (state.length - 1))];
			}else{
				return state;
			}
		}
		default:
			return state;
	}
};
const mainIndex = (state = MAIN_INDEX.HOME_VIEW, action) => {
	switch (action.type) {
		case types.SET_MAINPANEL_INDEX:
			return action.payload;
		default:
			return state;
	}
};
const mainCurrentSpotlightId = (state = SpotlightIds.MAIN_HOME_BTN, action) => {
	switch (action.type) {
		case types.SET_MAINPANEL_CURRENTSPOT:
			return action.payload;
		default:
			return state;
	}
};

const panelReducer = {
	loadingSpinnerState,
	panels,
	mainIndex,
	mainCurrentSpotlightId
};

export default panelReducer;
