import classNames from 'classnames';
import React,{useCallback, useRef, useState, useEffect } from 'react';
import {on, off} from '@enact/core/dispatcher';
import Scroller from '@enact/sandstone/Scroller';
import Marquee from '@enact/sandstone/Marquee';
import {Image} from '@enact/sandstone/Image';
import Spottable from '@enact/spotlight/Spottable';
import Spotlight from '@enact/spotlight';
import QRCode from "react-qr-code";
import {$L} from '../../utils/common';
import css from './ProductList.module.less';
import VerticalScrollGuide from '../VerticalScrollGuide';
import * as Config from '../../data/Config';
import * as Utils from '../../utils/common';
// {(cursorVisible || platform.touchscreen) && contentsList.length > withOutScrollLength && !scrollOnTop &&
// 	<VerticalScrollGuide type={"top"} className={classNames(css.topBottomScrollArea, css.topScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide: null)} onClick={onClickTopGuide}/>
// }
// { (cursorVisible || platform.touchscreen) && contentsList.length > withOutScrollLength && !scrollOnBottom &&
// 	<VerticalScrollGuide type={"bottom"} className={classNames(css.topBottomScrollArea, css.bottomScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide: null)} onClick={onClickBottomGuide}/>
// }
const SpottableGuide = Spottable(VerticalScrollGuide);
const ProductItem = ({className, item,...props}) => {
	console.log('ProductItem ', item);
	return (
		<div className={classNames(css.productItem, className)}>
			<Image className={css.image} src={item.productThumbnail}/>
			<div className={css.textLayer}>
				<Marquee className={css.title} marqueeOn="render" alignment='left'>
					{item.productBrandTitle}
				</Marquee>
				<Marquee className={css.subtitle} marqueeOn="render" alignment='left'>
					{item.productTitle}
				</Marquee>
			</div>
			<QRCode className={css.qrcode} value={item.productPurchaseUrl} size={Utils.scaleW(120)}/>
		</div>
	)
}
const ProductList = ({className, products, ...rest}) => {
	const scrollTo = useRef(null);
	const scrollTop = useRef(0);
	const [scrollOnBottom, setScrollOnBottom] = useState(false);
	const [scrollOnTop, setScrollOnTop] = useState(true);

	useEffect(() => {
		if (scrollTo && scrollTo.current) {
			scrollTo.current({ position: { y: 0 }, animate: true });
		}
	}, [products]);

	useEffect(() => {
		on('wheel', handleWheel);
		return () => {
			off('wheel', handleWheel);
		};
	}, []);

	const moveBottom = useCallback((wheel = false) => {
		if(scrollTo.current !== null){
			const scroller = document.querySelector(`[data-spotlight-id="productlistScroller"]`);
			let listArray = scroller.children[0].children;
			for(let i=0; i<listArray.length;i++){
				if(scrollTop.current < (listArray[i].offsetTop)){
					scrollTop.current = listArray[i].offsetTop;
					scrollTo.current({position:{y: scrollTop.current}, animate: true});
					return true;
				}
			}
		}
	}, []);
	const moveTop = useCallback((wheel = false) => {
		if(scrollTo.current !== null){
			const scroller = document.querySelector(`[data-spotlight-id="productlistScroller"]`);
			let listArray = scroller.children[0].children;
			for(let i=listArray.length-1; i>=0;i--){
				if((listArray[i].offsetTop) < scrollTop.current){
					scrollTop.current = listArray[i].offsetTop; //apply margin
					scrollTo.current({position:{y:scrollTop.current},animate: true});
					return true;
				}
			}
		}
	}, []);

	const onClickTopGuide = useCallback(() => {
		moveTop(true);
	}, [moveTop]);

	const onClickBottomGuide = useCallback(() => {
			moveBottom(true);
	}, [moveBottom]);

	const handleWheel = useCallback((ev) => {
		const scroller = document.querySelector(`[data-spotlight-id="productlistScroller"]`);
		if(scroller && typeof window == 'object' && window.getComputedStyle(scroller).visibility !== 'visible'){
			// console.log('handleWheel not visiblie ',listSpotlightId);
			return;
		}
		if(ev.deltaY < 0){
			onClickTopGuide();
		}else{
			onClickBottomGuide()
		}
	}, [onClickTopGuide, onClickBottomGuide]);

	const getScrollTo = useCallback((cbScrollTo) => {
		scrollTo.current = cbScrollTo;
	}, []);
	const onScrollStop = useCallback((ev) => {
		scrollTop.current = Math.round(ev.scrollTop);
		const scroller = document.querySelector(`[data-spotlight-id="productlistScroller"]`);
		if(scrollTop.current + scroller.offsetHeight + 10/*dummy*/ > scroller.children[0].scrollHeight){
			setScrollOnBottom(true);
		}else{
			setScrollOnBottom(false);
		}
		if(scrollTop.current <=0){
			setScrollOnTop(true);
			setScrollOnBottom(false);
		}else{
			setScrollOnTop(false);
		}
	}, []);

	const onBlur = useCallback(() => {
		setTimeout(() => {
			console.log('onblur.......................');
			Spotlight.focus("topGuide");
			Spotlight.focus("bottomGuide");
		}, 0);
	}, []);
	return (
		<>
			<div className={css.header}>{$L("준비물")}</div>
			{products.length <= 0 &&
				<div className={css.noneResult}>{$L("목록이 없습니다.")}</div>
			}
			{products.length > 0 &&
				<Scroller
					className={classNames(css.productList, className)}
					cbScrollTo={getScrollTo}
					spotlightId={"productlistScroller"}
					direction="vertical"
					horizontalScrollbar="hidden"
					verticalScrollbar="hidden"
					scrollMode="translate"
					noScrollByWheel
					noScrollByDrag
					onScrollStop={onScrollStop}
					overscrollEffectOn={{
						arrowKey: false,
						drag: false,
						pageKey: false,
						track: false,
						wheel: false
					}}
				>
					{products.map((item, index) => {
						return (
							<ProductItem key={index} item={item}/>
						)
					})}
				</Scroller>
			}
			{!scrollOnTop && products.length > 1 &&
				<SpottableGuide spotlightId={"topGuide"} type={"top"} className={classNames(css.topBottomScrollArea, css.topScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide: null)} onClick={onClickTopGuide} onSpotlightDisappear={onBlur}/>
			}
			{!scrollOnBottom && products.length > 1 &&
				<SpottableGuide spotlightId={"bottomGuide"} type={"bottom"} className={classNames(css.topBottomScrollArea, css.bottomScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide: null)} onClick={onClickBottomGuide} onSpotlightDisappear={onBlur}/>
			}
		</>
	);
};

export default ProductList;
