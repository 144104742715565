/**
 * History
 *
 * @module History
 */
import React, {useCallback, useEffect, useRef, useMemo} from 'react';
import {shallowEqual} from 'react-redux';
import classNames from 'classnames';
import TScroller from '../../../components/TScroller/TScroller';
import css from './History.module.less';
import HistoryItem from './HistoryItem';
import TotalHistoryData from './TotalHistoryData';
import $L from '@enact/sandstone/internal/$L';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';

const Container = SpotlightContainerDecorator(
    {
        enterTo: 'last-focused',
    },
    'div'
);

const History = ({className, activities = [], dateStr}) => {
    const scrollTo = useRef(null);

    const totalHistory = useMemo(() => {
        console.log('totalHistory activitiyHistory ', activities, dateStr);
        let calorieConsumption = 0,
            workoutTime = 0,
            totalWalk = 0;
        activities.forEach((element) => {
            calorieConsumption += Number(element.calorieConsumption);
            workoutTime += Number(element.workoutTime);
            totalWalk += Number(element.candy);
        });
        console.log('totalHistory calorieConsumption ', calorieConsumption, workoutTime, totalWalk);
        return {calorieConsumption, workoutTime, totalWalk};
    }, [activities, dateStr]);

    const historyPerShow = useMemo(() => {
        const ret = {};
        activities.forEach((element) => {
            if (!ret[element.showId]) {
                ret[element.showId] = [];
            }
            ret[element.showId].push(element);
        });
        console.log('ret', ret);
        return ret;
    }, [activities]);

    const getScrollTo = useCallback((cbScrollTo) => {
        scrollTo.current = cbScrollTo;
    }, []);

    useEffect(() => {
        if (scrollTo && scrollTo.current) {
            scrollTo.current({position: {y: 0}, animate: false});
        }
    }, [dateStr]);

    return (
        <Container className={classNames(css.chart, className)}>
            <TotalHistoryData className={css.totalHistoryData} dateStr={dateStr} totalHistory={totalHistory} />
            <div className={css.chartInner}>
                <TScroller cbScrollTo={getScrollTo}>
                    <div className={css.chart}>
                        {Object.keys(historyPerShow).map((showId, index) => {
                            return <HistoryItem key={index} className={css.HistoryItem} listPerShow={historyPerShow[showId]} />;
                        })}
                    </div>
                </TScroller>
            </div>
            {activities.length <= 0 && (
                <div className={css.empty_comment}>
                    <div>{$L('운동 기록이 없습니다.')}</div>
                </div>
            )}
        </Container>
    );
};

export default React.memo(History, shallowEqual);
