import {LS2RequestSingleton} from './LS2RequestSingleton';

export * from './account';
export * from './common';
export * from './ble';

export const cancelReq = (instanceName) => {
	let r = LS2RequestSingleton.instance(instanceName);
	if (r) {
		r.cancel();
		r.cancelled = false;
		LS2RequestSingleton.deleteInstance(instanceName);
	}
};
