/**
 * Item Module of contents list
 *
 * @module CategoryListItem
 */
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import classNames from 'classnames';
import Spottable from '@enact/spotlight/Spottable';
import {Cell, Column} from '@enact/ui/Layout';
import compose from 'ramda/src/compose';
import {Image} from '@enact/sandstone/Image';
import {Marquee, MarqueeController} from '@enact/sandstone/Marquee';
import {VoiceControlDecorator} from '@enact/webos/speech';

import AnimationFocus from '../AnimationFocus';
import css from './CategoryListItem.module.less';

import SpotlightIds from '../../data/SpotlightIds';
import {$L} from '../../utils/common';
import CustomImage from '../CustomImage';
import * as Utils from '../../utils/common';


const CategoryListItemBase = ({caption,	onItemClick, forceFocus, listspotlightid, style, itemInfo, index,...rest}) => {
	const categoriesTableSelected = useSelector(state => state.categoriesTableSelected);
	const onClick = useCallback((ev) => {
		console.log('onClick..', ev);
		if(onItemClick){
			onItemClick(ev);
		}
		ev.stopPropagation();
		ev.preventDefault();
	}, [onItemClick]);

	const captionContainer = useCallback(() => {
		const marqueeAlign = 'center';
		if(!itemInfo){
			return null;
		}
		return (<Cell align="center" key="captionContainer" className={classNames(css.captionContainer)} >
			<Marquee marqueeDisabled={!forceFocus} alignment={marqueeAlign} key="caption" className={css.caption} marqueeOn={forceFocus ? "render" : undefined}>{Utils.replaceHtmlEntites(caption)}</Marquee>
		</Cell>
		);
	}, [itemInfo, caption,forceFocus]);

	return (
		// <AnimationFocus focused={forceFocus} spotlightId={rest['data-spotlight-id']}>
			<div
				{...rest}
				index={index}
				className={classNames(forceFocus ? css.forceFocus: null, css.mediaImage, (itemInfo.id === categoriesTableSelected) ? css.selected: null)} style={style}
				onClick={onClick}
				>
				<Column className={classNames(css.imageItem)}>
					<Cell key="thumbContainer" className={classNames(css.thumbContainer)}>
						{itemInfo.thumbnail &&
							<CustomImage className={css.image} src={itemInfo.thumbnail} delay={0} animationSpeed={"fast"}/>
						}
						{captionContainer()}
					</Cell>
				</Column>
				{/* <div className={classNames(forceFocus ? css.effectFocus: null)}/> */}
			</div>
		// </AnimationFocus>
	);
};

const ImageItemDecorator = compose(
	MarqueeController({marqueeOnFocus: true}),
	VoiceControlDecorator,
	Spottable
);
const CategoryListItem = ImageItemDecorator(CategoryListItemBase);
export default CategoryListItem;
