import {types} from '../actions/actionTypes';
import * as Config from '../data/Config';
const accountStatus = (state = {}, action) => {
	switch (action.type) {
		case types.GET_ACCOUNT_STATUS:
			return action.payload;
		default:
			return state;
	}
};
const seasonId =  (state = {}, action) => {
	switch (action.type) {
		case types.GET_SEASONID:
			return action.payload;
		default:
			return state;
	}
};
const showInfos = (state = {}, action) => {
	switch (action.type) {
		case types.UPDATE_SHOW_INFOS:
			for(let i=0;i<action.payload.length;i++){
				state[action.payload[i].showId] = action.payload[i];
			}
			return state;
		default:
			return state;
	}
};
const updatedShowInfos = (state = {}, action) => {
	switch (action.type) {
		case types.UPDATE_SHOW_INFOS:
			state = {};
			for(let i=0;i<action.payload.length;i++){
				state[action.payload[i].showId] = action.payload[i];
			}
			return state;
		default:
			return state;
	}
};
const seasonInfos = (state = [], action) => {
	switch (action.type) {
		case types.UPDATE_SEASON_INFOS:
			for(let i=0;i<action.payload.length;i++){
				state[action.payload[i].seasonId] = action.payload[i];
			}
			return state;
		default:
			return state;
	}
};
// {showId : []}
const seasonList = (state = {}, action) => {
	switch (action.type) {
		case types.UPDATE_SEASON_LIST:
			state[action.showId] = action.payload;
			return Object.assign({}, state);
		default:
			return state;
	}
};

// {seasonId : []}
const seasonPreferences = (state = {}, action) => {
	switch (action.type) {
		case types.GET_SEASON_PREFERENCE:
			state[action.payload.seasonId] = action.payload;
			return Object.assign({}, state);
		default:
			return state;
	}
};
// {seasonId : []}
const episodeList = (state = {}, action) => {
	switch (action.type) {
		case types.UPDATE_EPISODE_LIST:
			state[action.seasonId] = action.payload;
			return Object.assign({}, state);
		default:
			return state;
	}
};

const seasonProducts = (state = {}, action) => {
	switch (action.type) {
		case types.UPDATE_SEASON_PRODUCTS:
			state[action.seasonId] = action.payload;
			return Object.assign({}, state);
		default:
			return state;
	}
};

const episodeProducts = (state = {}, action) => {
	switch (action.type) {
		case types.UPDATE_EPISODE_PRODUCTS:
			state[action.episodeId] = action.payload;
			return Object.assign({}, state);
		default:
			return state;
	}
};

const contentsRecommended = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTENTS_RECOMMENDED:
			return action.payload;
		default:
			return state;
	}
};

const contentsSpecial = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTENTS_SPECIAL:
			return action.payload;
		default:
			return state;
	}
};
const contentsPopular = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTENTS_POPULAR:
			return action.payload;
		default:
			return state;
	}
};
const contentsNews = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTENTS_NEWS:
			return action.payload;
		default:
			return state;
	}
};

const contentsPopularShows = (state = [], action) => {
	switch (action.type) {
		case types.GET_POPULAR_SHOWS:
			return action.payload;
		default:
			return state;
	}
};
const relatedKeywords = (state = {} , action) => {
	switch (action.type) {
		case types.GET_RELATED_KEYWORDS:
			state[action.keyword] = action.data.slice(0, Config.MAX_SEARCH_KEYWORDS);
			return Object.assign({}, state);
		default:
			return state;
	}
};

const contentsSearchList = (state = { keyword: "", data: [] } , action) => {
	switch (action.type) {
		case types.GET_SHOW_SEARCH:
			return action.payload;
		case types.GET_SHOW_SEARCH_RESET:
			return { keyword: "", data: [] };
		default:
			return state;
	}
};
//seasons
const contentsMyFavorites = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTENTS_MY_FAVORITES:{
			action.payload.sort((a, b) => { // 내림차순
				const timeA = a.createDatetime;
				const timeB = b.createDatetime;
				if(timeA < timeB){
					 return 1;
				}else if(timeA > timeB){
					return -1;
				}else if(timeA === timeB){
					return 0;
				}else{
					return -1;
				}
			});
			const seasonIds = [];
			const newArray = [];
			for (let i = 0; i < action.payload.length; i++) {
				if(!seasonIds.includes(action.payload[i].seasonId)){
					seasonIds.push(action.payload[i].seasonId);
					newArray.push(action.payload[i]);
				}
			}
			return newArray;
		}
		default:
			return state;
	}
};
const contentsWatching = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTENTS_WATCHING:{
			action.payload.sort((a, b) => { // 내림차순
				const timeA = a.appDatetime;
				const timeB = b.appDatetime;
				if(timeA < timeB){
					 return 1;
				}else if(timeA > timeB){
					return -1;
				}else if(timeA === timeB){
					return 0;
				}else{
					return -1;
				}
			});
			const seasonIds = [];
			const newArray = [];
			for (let i = 0; i < action.payload.length; i++) {
				if(!seasonIds.includes(action.payload[i].seasonId)){
					seasonIds.push(action.payload[i].seasonId);
					newArray.push(action.payload[i]);
				}
			}
			return newArray;
		}
		default:
			return state;
	}
};

const userInfo = (state = {}, action) => {
	switch (action.type) {
		case types.GET_USER_INFO:
		{
			if(action.payload.birthday){
				const today = new Date();
				const birthDate = new Date(action.payload.birthday);
				let _age = today.getFullYear() - birthDate.getFullYear();
				const m = today.getMonth() - birthDate.getMonth();
				if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
					_age--;
				}
				action.payload.age = _age;
			}
			return action.payload;
		}
		default:
			return state;
	}
};

//goal info
const userPreference = (state = {goalCalorie: "200", goalStepCount: "1000", goalWorkoutTime: "1200"}, action) => {
	switch (action.type) {
		case types.GET_USER_PREFERENCES:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};

const userPaymentInfo = (state = {}, action) => {
	switch (action.type) {
		case types.GET_USER_PAYMENT_INFO:
			return action.payload;
		default:
			return state;
	}
};

const candyReducer = {
	accountStatus,
	seasonId,
	showInfos,
	updatedShowInfos,
	seasonInfos,
	seasonList,
	episodeList,
	seasonProducts,
	episodeProducts,
	contentsRecommended,
	contentsSpecial,
	contentsPopular,
	contentsNews,
	contentsMyFavorites,
	contentsWatching,
	seasonPreferences,
	contentsPopularShows,
	relatedKeywords,
	contentsSearchList,
	userInfo,
	userPreference,
	userPaymentInfo
};

export default candyReducer;
