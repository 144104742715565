import React from 'react';
import classNames from 'classnames';
import Touchable from '@enact/ui/Touchable';

import css from './HLSVideoPlayer.module.less';

const TouchableDiv = Touchable('div');

 const Overlay = ({ children, bottomControlsVisible, ...rest }) => {

	  return (
		  <TouchableDiv {...rest} className={classNames(css.overlay, bottomControlsVisible ? css.scrim: "")}>
			 {children}
		 </TouchableDiv>
	  );
  };

  export default Overlay;