import classNames from 'classnames';
import React, {useState, useCallback, useEffect, useRef, useMemo } from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {on, off} from '@enact/core/dispatcher';
import {Job} from '@enact/core/util';
import platform from '@enact/core/platform';
import Scroller from '@enact/sandstone/Scroller';
import {$L} from '../../utils/common';
import Spotlight from '@enact/spotlight';
import css from './ExtendedListPanel.module.less';
import TPanel from '../../components/TPanel/TPanel';
import THeader from '../../components/TPanel/THeader';
import * as Utils from '../../utils/common';
import * as Config from '../../data/Config';
import * as ContentType from '../../data/ContentType';
import TButton from '../../components/TButton/TButton';
import * as PanelActions from '../../actions/panelActions';
import * as CandyActions from '../../actions/candyActions';
import {PANEL_NAME} from '../../actions/panelActions';
import SpotlightIds from '../../data/SpotlightIds';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import VerticalMediaList from '../../components/MediaList/VerticalMediaList';


const Container = SpotlightContainerDecorator({enterTo: 'last-focused', preserveId: true}, 'div');

const ExtendedListPanel = ({hideChildren, panelInfo, ...props}) => {
	const dispatch = useDispatch();
	const scrollTo = useRef(null);
	const contentsWatching  = useSelector(state => state.contentsWatching, shallowEqual);
	const contentsMyFavorites  = useSelector(state => state.contentsMyFavorites, shallowEqual);
	const contentsPopular = useSelector(state => state.contentsPopular, shallowEqual);
	const contentsSpecial = useSelector(state => state.contentsSpecial, shallowEqual);
	const [restoreIndex, setRestoreIndex] = useState(panelInfo.restoreIndex); //media focus
	const [tabIndex, setTabIndex] = useState(panelInfo.tabIndex ? panelInfo.tabIndex: 0);

	useEffect(() => {
		if(panelInfo){
			if(panelInfo.listId === SpotlightIds.LIST_MYFAVORITE){
				dispatch(CandyActions.getStartWatching());
				dispatch(CandyActions.getContentsOfFavorites());
			}else if(panelInfo.listId === SpotlightIds.LIST_SPECIAL){
				dispatch(CandyActions.getContentsPopular());
				dispatch(CandyActions.getContentsSpecial());
			}
		}
	}, []);

	useEffect(() => {
        if(!hideChildren){
			Spotlight.focus(SpotlightIds.BACK_BUTTON);
			setTimeout(() => {
				Spotlight.focus(SpotlightIds.LIST_EXTENDED);
				if(panelInfo.scrollTop && scrollTo.current){
					scrollTo.current({position:{y: panelInfo.scrollTop}, animate: true});
				}
			}, 100);
			setTimeout(() => {
				setRestoreIndex(-1);
			}, 500);
		}
	}, [hideChildren]);

	const groupList = useMemo(() => {
		let list = [];
		if(panelInfo){
			if(panelInfo.listId === SpotlightIds.LIST_MYFAVORITE){
				list.push({children: $L('최근 한 운동'), contents: contentsWatching, contentType: ContentType.SEASON});
				list.push({children: $L('스크랩'), contents: contentsMyFavorites, contentType: ContentType.SEASON });
			}else if(panelInfo.listId === SpotlightIds.LIST_SPECIAL){
				list.push({children: $L('인기 콘텐츠'), contents: contentsPopular, contentType: ContentType.SEASON });
				list.push({children: $L('최신 콘텐츠'), contents: contentsSpecial, contentType: ContentType.SHOW });
			}
		}
		return list;
	}, [panelInfo, contentsWatching, contentsMyFavorites, contentsPopular, contentsSpecial]);

	const onBack = useCallback(() => {
        dispatch(PanelActions.popPanel(PANEL_NAME.extendedlist));
	}, [dispatch]);

	const onFocusedIndexChanged = useCallback((id, index) => {
		dispatch(PanelActions.updatePanel(PANEL_NAME.extendedlist, {restoreIndex: index}));
	}, [dispatch]);

	const onSelectMenu = useCallback((index)=>(ev) => {
		console.log('onSelectMenu', ev.currentTarget.dataset.spotlightId, ev);
		setTabIndex(index);
		dispatch(PanelActions.updatePanel(PANEL_NAME.extendedlist, {tabIndex: index}));
	}, []);

	return (
		<TPanel {...props} className={css.panel} handleCancel={onBack}>
			<THeader title={panelInfo.title} onClick={onBack}/>
				<Container className={css.tabLayout}>
					{
						groupList.map((menu, index) => (
							<TButton
								key={index}
								className={classNames(css.tab, index===tabIndex ? css.selected : null)}
								type={'tabBtnHorizon'}
								onClick={onSelectMenu(index)}
								selected={index===tabIndex}
							>
							{menu.children}
							</TButton>
						))
					}
				</Container>
			<VerticalMediaList
				className={css.medialistlayer}
				listSpotlightId={SpotlightIds.LIST_EXTENDED}
				contentsList={groupList[tabIndex].contents}
				onFocusedIndexChanged={onFocusedIndexChanged}
				restoreIndex={restoreIndex}
				contentType={groupList[tabIndex].contentType}
				// onFocus={onContentFocus} onBlur={onContentBlur}
				withOutScrollLength={10}
			/>
		</TPanel >
	);
};

export default ExtendedListPanel;
