/**
 * CategoryList
 *
 * @module CategoryList
 */
import {Job} from '@enact/core/util';
import css from './CategoryList.module.less';
import classNames from 'classnames';
import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import platform from '@enact/core/platform';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import Spottable from '@enact/spotlight/Spottable';
import Spotlight from '@enact/spotlight';
import * as CommonActions from '../../actions/commonActions';
import * as CandyActions from '../../actions/candyActions';

import SpotlightIds from '../../data/SpotlightIds';
import CategoryListItem from './CategoryListItem';
import * as Config from '../../data/Config';
import KeyCode from '../../data/KeyCode';
import * as ContentType from '../../data/ContentType';
import * as Utils from '../../utils/common';
import {$L} from '../../utils/common';
import { types } from '../../actions/actionTypes';

const SpottableComponent = Spottable('div');
const itemWidth= Utils.scaleW(362);
const itemMargin= 0;

const leftRightAutoScrollJob = new Job((eventCallback) => {
	eventCallback();
},Config.AUTO_SCROLL_DELAY);

const MAX_IN_ROW = 5;
const CategoryList = ({className, onListFocus, onListItemClick, onFocusedIndexChanged, restoreIndex, playingIndex=-1, ...rest}) => {
	const dispatch = useDispatch();
	const spotlightId= SpotlightIds.LIST_CATEGORY_TABLE;
	const viewList = useSelector(state => state.categoriesTable);
	const seasonId = useSelector(state => state.seasonId);
	const cursorVisible = useSelector(state => state.appStatus.cursorVisible, shallowEqual);
	const [visibleIndexes, setVisibleIndexes] = useState({firstVisibleIndex: -1, lastVisibleIndex: -1});
	const [nodeSize, setNodeSize] = useState({w:0, h:0});
	const [isListFull, setIsListFull] = useState(false);
	const [isFavFocused, setFavFocused] = useState(false);
	const [showingIndexs, setShowingIndexs] = useState([]);
	const [focusedIndex, setFocusedIndex] = useState(-1);
	const [savedFocusIndex, setSavedFocusIndex] = useState(-1);
	const [autoScrollState, setAutoScrollState] = useState("none"); //left, right
	const resetFocus = useRef(false);

	const convertedViewList = useMemo(() => {
		let list= viewList;
		return list;
	}, [viewList]);

	useEffect(() => {
		const node = document.querySelector(`[data-spotlight-id="${spotlightId}"]`);
		const nodeRect = node.getBoundingClientRect();
		setNodeSize({w:nodeRect.width, h:nodeRect.height});
		return () => leftRightAutoScrollJob.stop();
	}, [spotlightId]);

	useEffect(() => {
		let index = playingIndex >=0 ? playingIndex: restoreIndex;
		if(index >= convertedViewList.length){
			index = convertedViewList.length-1;
		}
		if(viewList && viewList.length >0){
			if(playingIndex >=0){
				calculateVisibleIndex(0, false, index, false);
			}else{
				calculateVisibleIndex(0, false, index);
			}
		}
	}, [convertedViewList]);

	useEffect(() => {
		if(viewList && viewList.length >0){
			if(visibleIndexes.firstVisibleIndex >= 0 && playingIndex >= 0 && showingIndexs.indexOf(playingIndex)<0 ){
				calculateVisibleIndex(playingIndex-visibleIndexes.firstVisibleIndex, false, null, false);
			}
		}
	  }, [playingIndex]);


	// only for scroll
	// withKey only horizontal
	const calculateVisibleIndex = useCallback((to, withKey, _restoreIndex, spot=true) => {
		let containerSize = nodeSize;
		let calWidth = 0, calHeight = 0,
			first = withKey ? focusedIndex+to : visibleIndexes.firstVisibleIndex+to,
			size= convertedViewList ? convertedViewList.length: 0,
			full = isListFull,
			last=visibleIndexes.lastVisibleIndex,
			i;
		if(first < 0)first=0;
		if(size > 0)first%=size;

		if(to===0){ //list changed
			const node = document.querySelector(`[data-spotlight-id="${spotlightId}"]`);
			containerSize = {w:node.offsetWidth, h:node.offsetHeight};
			setNodeSize(containerSize);
			full = false;
			for(i=0; i< size; i++){
				calWidth += (itemWidth+itemMargin);
				last = i;
				if(calWidth>node.offsetWidth){
					full=true;
					break;
				}
			}
			setIsListFull(full);
			if(_restoreIndex >=0){
				first = _restoreIndex;
			}
			if(!full)first = 0;
		}

		if(full){
			calWidth = 0;
			for(i=first; calWidth<containerSize.w; i++){
				last=i;
				calWidth += (itemWidth+itemMargin);
			}
			if(!withKey && to === 1 && spot){
				setFocusedIndex((last-1)%size);
			}else if(to!==0 && spot){
				setFocusedIndex(first); //todo for mouse
			}
		}else{ // list is too short
			const index = (focusedIndex+to) < 0 ? 0 : (focusedIndex+to) >= size ? size-1 : (focusedIndex+to);
			if(withKey && spot){
				setFocusedIndex(index);
			}
			first = 0;
		}
		const showingindexs=[];
		if(size>0){
			setVisibleIndexes({firstVisibleIndex: first, lastVisibleIndex: last%size});
		}else{
			setVisibleIndexes({firstVisibleIndex: -1, lastVisibleIndex: -1});
		}
		if(size>0){
			for(let k=first; k<=last; k++){
				showingindexs.push(k%size);
			}
		}
		setShowingIndexs(showingindexs);
		//case remove favorite
		if(to===0 && focusedIndex >=0 && showingindexs.indexOf(focusedIndex)<0 && spot){
			setFocusedIndex(showingindexs[0]);
		}
		if(_restoreIndex >=0 && (showingindexs.indexOf(_restoreIndex)>=0) && spot){
			console.log('restore...',_restoreIndex);
			setFocusedIndex(_restoreIndex);
		}
	}, [nodeSize, focusedIndex, visibleIndexes, isListFull, convertedViewList, spotlightId]);

	const moveLeft = useCallback((withKey) => {
		calculateVisibleIndex(-1, withKey);
	}, [calculateVisibleIndex]);
	const moveRight = useCallback((withKey) => {
		calculateVisibleIndex(1, withKey);
	}, [calculateVisibleIndex]);

	const moveUp = useCallback((withKey) => {
		if(withKey){ //reset focusedIndex
			resetFocus.current = true;
		}
		if(withKey){
			const currentNode = document.querySelector(`[data-spotlight-id="${spotlightId + '_' + focusedIndex}"]`);
			if(currentNode){
				const node = Utils.getNearestTarget('up', currentNode);
				if(node){
					setFocusedIndex(parseInt(node.getAttribute('index')));
					return true;
				}
			}
		}
	}, [spotlightId, focusedIndex]);

	const moveDown = useCallback((withKey) => {
		if(withKey){ //reset focusedIndex
			resetFocus.current = true;
		}
		if(withKey){
			const currentNode = document.querySelector(`[data-spotlight-id="${spotlightId + '_' + focusedIndex}"]`);
			if(currentNode){
				const node = Utils.getNearestTarget('down', currentNode);
				if(node){
					setFocusedIndex(parseInt(node.getAttribute('index')));
					return true;
				}
			}
		}

	}, [spotlightId, focusedIndex]);

	const doLeftRightAutoScroll = useCallback(() => {
		if(!cursorVisible){
			setAutoScrollState("none");
			return;
		}
		switch(autoScrollState){
			case 'left': moveLeft(); break;
			case 'right': moveRight(); break;
		}
	}, [autoScrollState, cursorVisible,moveLeft,moveRight]);

	useEffect(() => {
		leftRightAutoScrollJob.stop();
		if(autoScrollState !== 'none'){
			leftRightAutoScrollJob.start(doLeftRightAutoScroll);
		}
	}, [autoScrollState, visibleIndexes, cursorVisible]);

	const onItemClick = useCallback((ev) => {
		console.log('CategoryList onItemClick', focusedIndex);
		// dispatch(CandyActions.getEpisodeList(seasonId));
		let item;
		if(ev && ev.currentTarget){ //mouse click
			const index = parseInt(ev.currentTarget.getAttribute('index'));
			item = convertedViewList[index];

			if(!cursorVisible && platform.touchscreen && focusedIndex !== index){
				Spotlight.setPointerMode(false);
				Spotlight.focus(spotlightId);
			}else{
				// dispatch(CommonActions.setPlayLaunchPath(logHint ? logHint : spotlightId));
				// dispatch(CommonActions.handlePlayItem(item, convertedViewList));
			}
			setFocusedIndex(index);
		}else if(focusedIndex >=0){
			item = convertedViewList[focusedIndex];
			// dispatch(CommonActions.setPlayLaunchPath(logHint? logHint: spotlightId));
			// dispatch(CommonActions.handlePlayItem(item, convertedViewList));
		}
		if(item){
			dispatch(CandyActions.handleCategoriTableSelected(item.id));
		}
	}, [dispatch,seasonId, focusedIndex, convertedViewList, onListItemClick, cursorVisible, spotlightId, viewList]);

	const _onKeyDown = useCallback((ev) => {
		resetFocus.current = false;
		//todo fav button
		if(ev.key === 'ArrowLeft'){
			setFavFocused(false);
			moveLeft(true);
			ev.stopPropagation();
			ev.preventDefault();
		}else if(ev.key === 'ArrowRight'){
			setFavFocused(false);
			moveRight(true)
			ev.stopPropagation();
			ev.preventDefault();
		}else if (ev.key === 'Enter') {
			onItemClick();
			ev.stopPropagation();
			ev.preventDefault();
			return true;
		}else if(ev.keyCode === KeyCode.PLAY){
			onItemClick();
			ev.stopPropagation();
			ev.preventDefault();
			return true;
		}
		if(rest.onKeyDown){
			if(rest.onKeyDown(ev)){
				console.log('stop prop...');
				setFavFocused(false);
				ev.stopPropagation();
				ev.preventDefault();
				return true;
			}
		}
	}, [moveLeft, moveRight, moveDown, moveUp, onItemClick, isFavFocused, setFavFocused, rest, convertedViewList, focusedIndex]);
	const onFocus = useCallback((ev) => {
		resetFocus.current = false;
		if(savedFocusIndex !== -1 && showingIndexs.includes(savedFocusIndex)){
			setFocusedIndex(savedFocusIndex);
		}else if(!showingIndexs.includes(focusedIndex)){
			setFocusedIndex(visibleIndexes.firstVisibleIndex);
		}
		if(onListFocus){
			onListFocus(ev);
		}
	}, [focusedIndex, savedFocusIndex, showingIndexs, onListFocus, visibleIndexes]);

	const onBlur = useCallback((ev) => {
		if(resetFocus.current || (ev&&ev.type==='mouseleave')){
			setSavedFocusIndex(-1);
		}else{
			setSavedFocusIndex(focusedIndex);
		}
		setFocusedIndex(-1);
		setFavFocused(false);
	}, [focusedIndex, setFocusedIndex, setSavedFocusIndex,setFavFocused]);

	const onMouseOver = useCallback((ev) => {
		if(cursorVisible){
			for(let i in showingIndexs){
				const itemNode = document.querySelector(`[data-spotlight-id="${spotlightId + '_' + showingIndexs[i]}"]`);
				if(itemNode && itemNode.contains(ev.target)){
					setFocusedIndex(showingIndexs[i]);
					break;
				}
			}
		}
	}, [setFocusedIndex, showingIndexs, spotlightId, cursorVisible]);

	const onLeftScrollAreaOver = useCallback(() => {
		if(cursorVisible){
			moveLeft();
			setAutoScrollState("left");
		}
	}, [moveLeft, cursorVisible]);

	const onLeftScrollAreaClick = useCallback(() => {
		if(platform.touchscreen){
			moveLeft();
		}
	}, [moveLeft]);

	const onRightScrollAreaOver = useCallback(() => {
		if(cursorVisible){
			moveRight();
			setAutoScrollState("right");
		}
	}, [moveRight, cursorVisible]);

	const onRightScrollAreaClick = useCallback(() => {
		if(platform.touchscreen){
			moveRight();
		}
	}, [moveRight]);

	const onScrollAreaOut = useCallback(() => {
		setAutoScrollState("none");
	}, []);

	const onVoiceHandle = useCallback((ev) => {
		const
			index = parseInt(ev.currentTarget.getAttribute('index')),
			item = convertedViewList[index],
			evType = ev.detail.intent;

		switch (evType) {
			case 'Select':
				Spotlight.focus(spotlightId);
				if(index !== focusedIndex){
					calculateVisibleIndex(index-focusedIndex);
				}else{
					setFocusedIndex(index);
				}
				if(item){
					dispatch(CandyActions.handleCategoriTableSelected(item.id));
				}
				ev.preventDefault();
				break;
		}
	}, [convertedViewList, dispatch, spotlightId, focusedIndex, calculateVisibleIndex, onListItemClick, viewList]);

	const renderItem = useCallback(({index, order}) => {
		let
			voiceLabelIndex = null,
			voiceLabel = null,
			voiceIntent = null;
		const itemInfo = convertedViewList && convertedViewList[index];

		let caption = itemInfo && itemInfo.title;
		const forceFocus = index===focusedIndex;
		// const forceFocus = index===0;
		let additionalStyle={};
		if(order === -1){
			//(listItemWidth - list Left padding + itemMargin) * (-1) = (332 - 80 + 10)*(-1) = 262
			// const leftPosition = Utils.scaleW(-262)+'px';
			const leftPosition = Utils.scaleW(-266)+'px';
			additionalStyle = {position: "absolute", left: leftPosition, opacity: 0.6};
		}else if(order !== 0){
			// additionalStyle = {marginLeft: '-5px'};
		}
		if(order < 0 || order >= MAX_IN_ROW){
			//do nothing
		}else{
			voiceIntent = 'Select';
			voiceLabel = caption;
			voiceLabelIndex = order;
		}

		if(caption){
			caption = Utils.convertUtf8ToUni(caption);
		}
		return (
			<CategoryListItem
				style={additionalStyle}
				key={index}
				index={index}
				itemInfo={itemInfo}
				listspotlightid={spotlightId}
				data-webos-voice-intent={voiceIntent}
				data-webos-voice-label={voiceLabel}
				data-webos-voice-label-index={voiceLabelIndex}
				onItemClick={onItemClick}
				// onItemFocus={onItemFocus}
				spotlightId={spotlightId + '_' + index}
				onVoice={onVoiceHandle}
				forceFocus={forceFocus}
				caption={caption}
			/>
		);
	}, [spotlightId, onItemClick, onVoiceHandle, convertedViewList, focusedIndex, isFavFocused, playingIndex]);

	return (
		<SpottableComponent
			{...rest}
			data-focused-index={focusedIndex}
			spotlightId={spotlightId}
			className={classNames(className, css.listContainer)}
			onKeyDown={_onKeyDown}
			onFocus={onFocus}
			onBlur={onBlur}
			onMouseLeave={onBlur}
		>
			<div className={classNames(css.listMain)} onMouseOver={onMouseOver}>
			{
				showingIndexs.length> 0 && showingIndexs.map((item, order) => {
					return renderItem({index:item, order: order});
				})
			}
			{/* left dummy item */}
			{showingIndexs && showingIndexs[0] > 0 &&
				renderItem({index: (showingIndexs[0]-1), order: -1})
			}
			</div>
			{
				<div className={classNames(css.leftRightScrollArea, css.leftScrollArea, !Config.SHOW_TOUCH_GUIDE ? css.hide: null)} onMouseOver={onLeftScrollAreaOver} onMouseOut={onScrollAreaOut} onClick={onLeftScrollAreaClick}/>
			}
			{
				<div className={classNames(css.leftRightScrollArea, css.rightScrollArea, !Config.SHOW_TOUCH_GUIDE ? css.hide: null)} onMouseOver={onRightScrollAreaOver} onMouseOut={onScrollAreaOut}  onClick={onRightScrollAreaClick}/>
			}
		</SpottableComponent>
	);
};
const listPropsAreEqual = (prev, next) => {
	const keys = Object.keys(prev);
	const nextKeys = Object.keys(next);
	if(keys.length !== nextKeys.length){
		return false;
	}
	for(let i=0; i<keys.length; i++){
		if(prev[keys[i]] !== next[keys[i]]){
			return false;
		}
	}
	return true;
}
export default React.memo(CategoryList, listPropsAreEqual);
