import classNames from 'classnames';
import css from './CameraSettingPopup.module.less';
import {$L} from '../../../utils/common';
import {useState, useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Alert from '@enact/sandstone/Alert';
import Marquee from '@enact/sandstone/Marquee';
import TCheckBox from '../../../components/TCheckBox';
import TButton from '../../../components/TButton/TButton';
import TSwitchItem from '../../../components/TSwitchItem/TSwitchItem';
import * as CommonActions from '../../../actions/commonActions';
import Spotlight from '@enact/spotlight';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';


const Container = SpotlightContainerDecorator({enterTo: 'last-focused', preserveId: true}, 'div');

const CameraSettingPopup = ({open, onClose, onClickDone,...rest}) => {
	const dispatch = useDispatch();
    const cameraDeviceName = useSelector(state => state.appStatus.cameraDeviceName);
    const cameraDetected = useSelector(state => state.appStatus.cameraDetected);
	const localSettings = useSelector(state => state.localSettings);
	const [activated, setActivated] = useState(localSettings.cameraActivated);
	const [size, setSize] = useState(localSettings.cameraSize);
	const [position, setPosition] = useState(localSettings.cameraPosition);
	const [isBtnDisabled, setIsBtnDisabled] = useState(true);
	const [focusedPosition, setFocusedPosition] = useState([]);

	useEffect(() => {
		if(open){
			setTimeout(() => {
				Spotlight.focus('switch');
			}, 1);
		}
    },[open]);

	const spotSwitch = useCallback((ev)=>{
		if(Spotlight.focus('switch')){
			ev.stopPropagation();
		}
	},[]);

	const _onClose = useCallback((ev) => {
		if(onClose){
			onClose(ev);
		}
    }, [onClose]);

	const onState = useCallback((ev) => {
		setActivated(ev.selected);
		setIsBtnDisabled(false);
	},[]);

	const onSelecSize = useCallback((_size) => (ev) => {
		if(ev.selected){
			setSize(_size);
			setIsBtnDisabled(false);
		}
	},[]);
	const onSelecPosition = useCallback((_position) => (ev) => {
		if(ev.selected){
			setPosition(_position);
			setIsBtnDisabled(false);
		}
	},[]);

	const connectionText = useMemo(() => {
		let text=$L('Not Connected');
		if(cameraDeviceName){
			text = $L('{Model Name} Connected.').replace('{Model Name}', cameraDeviceName);
        } else if (cameraDetected) {
            text=$L('Connected');
        }
		return text;
	}, [cameraDeviceName, cameraDetected]);

	const onSave = useCallback(() => {
		dispatch(CommonActions.changeLocalSettings({cameraActivated:activated, cameraSize:size, cameraPosition: position}));
		if(onClickDone){
			onClickDone();
		}
	  }, [dispatch, activated, size, position, onClickDone]);

	const onFocusPosition = useCallback((type) => () =>{
		if(focusedPosition.indexOf(type) < 0){
			focusedPosition.push(type);
		}
		setFocusedPosition([...focusedPosition]);
	},[focusedPosition]);
	const onBlurPosition = useCallback((type) => () =>{
		const index = focusedPosition.indexOf(type);
		if(index >= 0){
			focusedPosition.splice(index,1);
		}
		setFocusedPosition([...focusedPosition]);
	},[focusedPosition]);
	console.log('focusedPosition ', focusedPosition);
	return (
		<Alert {...rest}
			className={classNames(css.infopopup)}
			open={open}
			type={"overlay"}
			onClose={_onClose}>
			<div className={css.titlelayer}>
				<TButton className={css.backBtn} type={"back"} onClick={onClose} onSpotlightDown={spotSwitch}/>
				<Marquee  className={css.title} marqueeOn="render">
					{$L('Camera Settings')}
				</Marquee>
			</div>
			<div className={classNames(css.row, css.firstrow)}>
				<div className={css.title}>{$L('Connection')}</div>
				<div className={css.item}>{connectionText}</div>
			</div>

			<div className={css.row}>
				<div className={css.title}>{$L('Camera Access')}</div>
				<Container className={css.onoffswitch}>
					<TSwitchItem spotlightId={'switch'} data-webos-voice-label={$L('Camera')} onToggle={onState} selected={activated}/>
					<div>{activated ? $L('On') : $L('Off')}</div>
				</Container>
			</div>
			<div className={classNames(css.row)}>
				<div className={css.title}>{$L('Screen Size')}</div>
				<Container className={css.cameraArea}>
					<TCheckBox selected={size==='big'} className={css.simpleCheckBox} onToggle={onSelecSize('big')}>
						{$L({key:"setting_Big_screen", value: "Big"})}
					</TCheckBox>
					<TCheckBox selected={size==='medium'} className={css.simpleCheckBox} onToggle={onSelecSize('medium')}>
						{$L({key:"setting_Medium_screen", value: "Medium"})}
					</TCheckBox>
					<TCheckBox selected={size==='small'} className={css.simpleCheckBox} onToggle={onSelecSize('small')}>
						{$L({key:"setting_Small_screen", value: "Small"})}
					</TCheckBox>
				</Container>
			</div>
			<div className={classNames(css.row, css.position)}>
				<div className={css.title}>{$L('Position')}</div>
				<Container className={css.positionArea}>
					<div className={css.cmBox}>
						<div className={classNames(css.bgBox, focusedPosition.indexOf('right')>=0 ? css.focused: "")}>
							<div className={classNames(css.miniBox, css.subboxTopRight)}/>
						</div>
						<TCheckBox selected={position==='rt'} className={css.checkBox} onToggle={onSelecPosition('rt')} onFocus={onFocusPosition('right')} onBlur={onBlurPosition('right')}>
							{$L("Right")}
						</TCheckBox>
					</div>
					<div className={css.cmBox}>
						<div className={classNames(css.bgBox, focusedPosition.indexOf('left')>=0 ? css.focused: "")}>
							<div className={classNames(css.miniBox, css.subboxTopLeft)}/>
						</div>
						<TCheckBox selected={position==='lt'} className={css.checkBox} onToggle={onSelecPosition('lt')} onFocus={onFocusPosition('left')} onBlur={onBlurPosition('left')}>
							{$L("Left")}
						</TCheckBox>
					</div>
				</Container>
			</div>
			<div className={css.buttonlayer}>
				<TButton size="small" spotlightId={'saveBtn'} /*disabled={isBtnDisabled}*/ onClick={onSave}>{$L("Save")} </TButton>
			</div>
		</Alert>
	);
};

export default CameraSettingPopup;
