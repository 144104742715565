import LS2Request from './LS2Request';

export const getConnectionStatus = ({onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.connectionmanager',
		method: 'getStatus',
		subscribe: true,
		parameters:{},
		onSuccess,
		onFailure,
		onComplete
	});
};
export const cancelGetVideoThumbnail = ({onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.tnm',
		method: 'cancel',
		subscribe: false,
		parameters: {
			type: 'all'
		},
		onSuccess,
		onFailure,
		onComplete
	});
};
export const getVideoThumbnail = (sourceUri, time, {onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.tnm',
		method: 'getThumbnail',
		subscribe: false,
		parameters: {
			sourceUri: sourceUri,
			mediaType: 'video',
			targetWidth: 250,
			targetHeight: 141,
			requestId: 'thumb' + time,
			scaleType: 'crop',
			priority: 1,
			times: [time],
			count: 1
		},
		onSuccess,
		onFailure,
		onComplete
	});
};

export const alertToast = (parameters, {onSuccess, onFailure, onComplete}) => {
	console.log('lunasend alertToast');
	return new LS2Request().send({
		service: 'palm://com.webos.notification',
		method: 'createToast',
		subscribe: false,
		parameters:{message: parameters},
		onSuccess,
		onFailure,
		onComplete
	});
};
