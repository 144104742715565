import {useState, useCallback, useEffect, useMemo } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import css from './SearchedCandy.module.less';
import {$L} from '../../../../../../../utils/common';
import TButton from '../../../../../../../components/TButton/TButton';
import TScroller from '../../../../../../../components/TScroller/TScroller';
import TAlert from '../../../../../../../components/TAlert/TAlert';
import { types } from '../../../../../../../actions/actionTypes';
import * as BleActions from '../../../../../../../actions/bleActions';
import Spotlight from '@enact/spotlight';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';

const Container = SpotlightContainerDecorator({enterTo: 'last-focused', preserveId: true}, 'div');

const SearchedCandy = ({onConnectionFailed, ...rest}) => {
    const [connectingBleAddr, setConnectingBleAddr] = useState('');
    const gattConnectingStatus = useSelector(state => state.gattConnectingStatus);
    const bleDeviceList = useSelector(state => state.bleDeviceList);
    const dispatch = useDispatch();
    const [alreadyConnectPopup, setAlreadyConnectPopup] = useState(false);

    const connectCandy = useCallback((address) => () =>{
        const deviceName = address.substr(12).toUpperCase();
        dispatch({type: types.GATT_CONNECT_STATUS, payload:{name: 'CANDY-'+deviceName}});
        setConnectingBleAddr(address);
        dispatch(BleActions.gattConnect(address));
    }, [dispatch]);

    useEffect(()=>{
        if(bleDeviceList.length > 0){
            setTimeout(()=>{
                Spotlight.focus("bleitem0");
            }, 0);
        }
    },[]);

    useEffect(()=>{
       if(gattConnectingStatus.clientId === 0 && gattConnectingStatus.connectStatus === BleActions.CONNECTION_STATUS.failed){
            setAlreadyConnectPopup(true);
        }
    },[gattConnectingStatus])

	const retryConnect = useCallback((ev) => {
        setAlreadyConnectPopup(false);
        if(ev === 0){
            if(onConnectionFailed){
                onConnectionFailed();
            }
        }else if(ev === 1){
            dispatch(BleActions.scan());
            dispatch(BleActions.gattConnect(gattConnectingStatus.address));
        }
	}, [dispatch, gattConnectingStatus, onConnectionFailed]);

    const candyBand = useMemo(() => {
        return $L('연결할 <span>Candy Band</span>를 선택해주세요.');
    }, [])

	return (
        <div>
            <Container className={css.container} {...rest}>
                <TScroller>
                    <div className={css.title} dangerouslySetInnerHTML={{__html: candyBand}}/>
                    {bleDeviceList.map((item, index) => {
                        return (
                            <TButton
                                className={classNames(css.button, connectingBleAddr===item.address && css.selected)}
                                spotlightId={"bleitem"+index}
                                key={index}
                                type={'candyBandBtn'}
                                onClick={connectCandy(item.address, item.name, index)}
                                selected={item.address}
                            >
                                <span className={css.candyName}>{item.name}</span>
                                    {
                                        connectingBleAddr===item.address &&
                                        <div className={css.loading}>
                                            <span /><span /><span />
                                        </div>
                                    }
                                <span className={css.connect}>{
                                    connectingBleAddr===item.address ?
                                    $L("연결중"): $L("연결")}</span>
                            </TButton>
                        )
                    })}
                </TScroller>
            </Container>
            {alreadyConnectPopup &&
                <TAlert {...rest}
                    className={css.popup}
                    message={
                        <>
                            <div>{gattConnectingStatus.connectStatus === BleActions.CONNECTION_STATUS.failed ? $L("연결에 실패하였습니다.") : $L('해당 Candy Band는 사용 중입니다.')}</div>
                            <div>{gattConnectingStatus.name}</div>
                            <div>{$L('연결을 해제한 뒤 다시 시도해주세요.')}</div>
                        </>
                    }
                    button1text={$L('취소')}
                    button2text={$L("다시 시도")}
                    onClick={retryConnect}
                />
            }
        </div>
    )
};

export default SearchedCandy;