import classNames from 'classnames';
import React,{useMemo, useEffect } from 'react';
import css from './PlayPauseIcon.module.less';
import Spottable from '@enact/spotlight/Spottable';


const SpottableComponent = Spottable('div');
const PlayPauseIcon = ({type, className, onClick, disabled, spotlightId, ...rest}) => {
	return (
		<SpottableComponent
			{...rest}
			className={classNames(css.playpauseicon, className ? className: null, disabled ? css.disabled: null)}
			data-webos-voice-intent={'Select'}
			spotlightId={spotlightId}
			onClick={!disabled ? onClick : undefined}
		>
			<div className={classNames(css[type])}/>
		</SpottableComponent>
	);
};

export default PlayPauseIcon;
