import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from './CalorieSetting.module.less';
import {$L} from '../../../../../../../utils/common';
import { InputField } from '@enact/sandstone/Input';
import Alert from '@enact/sandstone/Alert';
import TButton from '../../../../../../../components/TButton/TButton';
import Spotlight from '@enact/spotlight';
import Spottable from '@enact/spotlight/Spottable';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import * as CommanActions from '../../../../../../../actions/commonActions'
import * as CandyActions from '../../../../../../../actions/candyActions'

const Container = SpotlightContainerDecorator({enterTo: "last-focused", preserveId: true}, Spottable('div'));

const CalorieSetting = ({open, onClose}) => {
	const dispatch = useDispatch();
	const userPreference = useSelector(state => state.userPreference);
	const [changeGoalCalorie, setChangeGoalCalorie] = useState(0);

	useEffect(() => {
	    Spotlight.focus("BackBtn");
	},[]);

	const adjustGoalCalorie = useCallback((value)=>{
		let isValid = true;
		let numValue = Number(value);
		if(numValue < 10){
			isValid = false;
		}else if(numValue > 9999){
			numValue = 9999;
			isValid = false;
		}
		setChangeGoalCalorie(numValue);
		return isValid;
	},[])

	useEffect(()=>{
		const goalCalorie = userPreference.goalCalorie;
		adjustGoalCalorie(goalCalorie);
	},[userPreference.goalCalorie, open]);

    const onCancle = useCallback((e) => {
        console.log('onClose', e);
		if (onClose) {
			onClose();
		}
	}, [onClose]);

    const onCalorieChange = useCallback((e)=>{
		setChangeGoalCalorie(Math.floor(e.value));
	},[]);


	const onDeactivateInput = useCallback(() => {
		let isValid = true;
		isValid = adjustGoalCalorie(changeGoalCalorie);
		if(!isValid){
			dispatch(CommanActions.alertToast($L("칼로리를 10kcal 이상 10,000kcal 미만으로 입력해주세요.")));
		}else{
			Spotlight.focus('CompleteBtn');
		}
	}, [adjustGoalCalorie, changeGoalCalorie]);

	const onComplete = useCallback(() => {
		if( changeGoalCalorie > 9 && changeGoalCalorie < 10000){
			let _changeGoalCalorieZero = Number(changeGoalCalorie);
			dispatch(CandyActions.updateUserPreferences({goalCalorie:  _changeGoalCalorieZero}));
			if (onClose) {
				onClose();
			}
		}else{
			dispatch(CommanActions.alertToast($L("칼로리를 10kcal 이상 10,000kcal 미만으로 입력해주세요.")));
		}
	}, [dispatch, onClose, changeGoalCalorie]);

	return (
		<Alert open={open} onClose={onCancle} type={"overlay"} className={css.popup}>
			<Container>
				<div className={css.title}>{$L('칼로리 설정')}</div>
                <div className={css.inputBox}>
                    <InputField
						className={css.inputField}
                        spotlightId={"calorieInput"}
						autoFocus
						dismissOnEnter
						type={"number"}
						onChange={onCalorieChange}
						value={changeGoalCalorie}
						onDeactivate={onDeactivateInput}
					/>
                </div>
				<div className={css.bottomBtnLayer}>
					<TButton className={css.nomalBtn} spotlightId={"BackBtn"} size="small" onClick={onCancle}>{$L('취소')}</TButton>
                    <TButton className={css.nomalBtn} spotlightId={"CompleteBtn"} size="small" color="dark" onClick={onComplete}>{$L('완료')}</TButton>
				</div>
			</Container>
		</Alert>
	);
};

export default CalorieSetting;