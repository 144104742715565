/**
 * DashboardsView
 *
 * @module DashboardsView
 */
import css from './DashboardsView.module.less';
import React, {useState, useCallback, useEffect, useMemo} from 'react';
import Spotlight from '@enact/spotlight';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import SpotlightIds from '../../data/SpotlightIds';
import * as Utils from '../../utils/common';
import MyInfo from './MyInfo';
import MyGoal from './MyGoal/MyGoal';
import History from './History';
import {useDispatch, useSelector} from 'react-redux';
import * as CandyActions from '../../actions/candyActions';
import TCalendar from './TCalendar';
import classNames from 'classnames';

const Container = SpotlightContainerDecorator(
    {
        enterTo: 'default-element',
    },
    'div'
);

const DashboardsView = () => {
    const dispatch = useDispatch();
    const candyActivities = useSelector((state) => state.candyActivities);
    const [monthStr, setMonthStr] = useState('');
    const [selectedDateStr, setSelectedDateStr] = useState('');

    useEffect(() => {
        Spotlight.focus(SpotlightIds.MAIN_DASHBOARD_BTN);
    }, []);

    const onChangeDate = useCallback(
        (year, month, selectedDate) => {
            setMonthStr(Utils.convertDateToString(year, month));
            setSelectedDateStr(selectedDate);
            dispatch(CandyActions.getCandyActivities(year, month));
        },
        [dispatch]
    );

    const activitiyHistory = useMemo(() => {
        console.log('DashboardsView activitiyHistory ', candyActivities, monthStr, selectedDateStr);
        const ret = [];
        if (selectedDateStr.startsWith(monthStr)) {
            if (candyActivities[selectedDateStr]) {
                const keys = Object.keys(candyActivities[selectedDateStr]);
                keys.forEach((element) => {
                    ret.push(candyActivities[selectedDateStr][element]);
                });
            }
        }
        return ret;
    }, [candyActivities, monthStr, selectedDateStr]);

    return (
        <Container className={classNames(css.bodyarea, css.dashboardBg)}>
            <MyInfo />
            <MyGoal className={css.goalLayer} />
            <TCalendar className={css.calendarLayer} onChange={onChangeDate} candyActivities={candyActivities} />
            <History className={css.historyLayer} activities={activitiyHistory} dateStr={selectedDateStr} />
        </Container>
    );
};

export default DashboardsView;
