import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {on, off} from '@enact/core/dispatcher';
import ThemeDecorator from '@enact/sandstone/ThemeDecorator';
import {Job} from '@enact/core/util';
import platform from '@enact/core/platform';
import MainView from '../views/MainView';
import css from './App.module.less';

import {MAIN_INDEX} from '../actions/panelActions';
import * as CandyActions from '../actions/candyActions';
import * as PanelActions from '../actions/panelActions';
import {PANEL_NAME} from '../actions/panelActions';
import * as CommonActions from '../actions/commonActions';
import * as Config from '../data/Config';
import {getLaunchParams} from '../utils/common';
import appinfo from '../../webos-meta/appinfo.json';

let cameraListRetryCount = 0;
const CAMERACHECK_MAX_COUNT = 3;

const updateCameraListJob = new Job((dispatch) => {
	if(typeof window === 'object' && window.navigator.mediaDevices){
		window.navigator.mediaDevices.enumerateDevices()
		.then(function(devices) {
			let cameraDeviceName = "", cameraDetected = false;
			for(let i=0; i<devices.length;i++){
				const device = devices[i];
				if(device.kind === 'videoinput'){
					console.log('ondevicechange camera...',device);
					cameraDeviceName = device.label;
					cameraDetected = true;
					break;
				}
			}
			dispatch(CommonActions.changeAppStatus({cameraDeviceName: cameraDeviceName, cameraDetected: cameraDetected}));
			console.log('updateCameraList cameraDetected', cameraDetected, cameraListRetryCount);
			cameraListRetryCount = 0;
		})
		.catch(function(err) {
			console.log('updateCameraList exception cameraListRetryCount', cameraListRetryCount);
			console.log('updateCameraList exception err',err);
			dispatch(CommonActions.changeAppStatus({cameraDeviceName: "", cameraDetected: false}));
			if(cameraListRetryCount < CAMERACHECK_MAX_COUNT){
				cameraListRetryCount++;
				updateCameraListJob.startAfter(cameraListRetryCount*500, dispatch);
			}
		});
	}
}, 500);


let foreGroundChangeTimer = null;

const AppBase = () => {
	const dispatch = useDispatch();
	const [fontLoaded, setFontLoaded] = useState(false);
	const isAppForeground = useSelector(state => state.appStatus.isAppForeground);
	const localSettings = useSelector(state => state.localSettings);

	const initService = useCallback(async (haveyInit = true) => {
		console.log('<<<<<<<<<<<<<        appinfo      >>>>>>>>>>>> ',appinfo, haveyInit);
		//Todo : Call Service
		// setTimeout(() => {
		// 	dispatch(CommonActions.scan());
		// }, 5000);
		if(haveyInit){
			dispatch(CommonActions.initLoadingStatus());
			if(!fontLoaded){
				dispatch(CommonActions.addLoadingStatus("fontLoaded"));
			}
			try {
				const promises = [
					new Promise((resolve) => (dispatch(CommonActions.getSystemSettings(resolve)))),
					new Promise((resolve) => (dispatch(CommonActions.getSystemInfo(resolve)))),
					new Promise((resolve) => (dispatch(CommonActions.getDeviceId(resolve)))),
				];
				await Promise.all(promises);
			} catch (e) {
				console.error("initService Error");
			}
			//get captionEnable
			dispatch(CommonActions.getSystemSettings2());
			const loginCallback = (success) => {
				dispatch(CommonActions.removeLoadingStatus("login"));
				if(success){
					// dispatch(CommonActions.alertToast($L("자동로그인 성공")));
					setTimeout(()=>{
						dispatch(CandyActions.getCommonLists());
						dispatch(CandyActions.getUserInfo());
						dispatch(CandyActions.getUserPreferences());
						dispatch(CandyActions.getUserAgreement());
					}, 0);
				}else{
					dispatch(PanelActions.resetPanels());
					dispatch(PanelActions.pushPanel(localSettings.loginType === 'code' ? PANEL_NAME.codelogin : PANEL_NAME.emaillogin));
				}
			}
			if(localSettings.email !== '') {
				dispatch(CommonActions.addLoadingStatus("login"));
				dispatch(CandyActions.forceTokenRefresh(true, loginCallback));
			}else{
				if(!localSettings.isFirstLaunched){
					dispatch(PanelActions.resetPanels());
				}
				dispatch(PanelActions.pushPanel(localSettings.loginType === 'code' ? PANEL_NAME.codelogin : PANEL_NAME.emaillogin));
			}
		}
		//localStorage에 email, password 있으면 자동로그인 하고, 성공 > home, 실패 > EmailLoginPage
		//localStorage에 email, password 없으면, EmailLoginPage...
	}, [dispatch, fontLoaded, localSettings]);

	useEffect(() => {
		if(fontLoaded){
			dispatch(CommonActions.removeLoadingStatus("fontLoaded"));
		}

	}, [fontLoaded]);

	const handleExitApp = useCallback(() => {
		// TODO: 추가적인 close 함수 구현 (다른 앱 launch 등)
	// #IF_COMMERCIAL
	}, [dispatch]);

	const activateApp = useCallback(() => {
		if (typeof window === 'object' && window.PalmSystem) {
			window.PalmSystem.activate();
		}
	}, []);

	// luna-send -n 1 -f luna://com.palm.applicationManager/launch '{ "id": "com.twin.app.homegym", "params":{"panel": "home", "ignoreInit": true}}'
	// action should be called if app is on foreground.
	// luna-send -n 1 -f luna://com.palm.applicationManager/launch '{ "id": "com.twin.app.homegym", "params":{"action": {"type":"pause10sec"}, "ignoreInit": true}}'
	// luna-send -n 1 -f luna://com.webos.applicationManager/launch '{"id":"com.twin.app.homegym","params":{"storeCaller":"search"},"replaceParams":{"deeplinkingParams":{"target":"$CONTENTID","value":"50"}},"autoInstallation":true,"checkUpdateOnLaunch":true,"reason":"searchResult"}'

	const playFromIntent = useCallback((content)=> (success) => {
		if(success){
			dispatch(CommonActions.handlePlayItem(content, null));
		}
	}, [dispatch]);

	const handleLaunchEvent = useCallback((isRelaunch) => {
		const launchParams = getLaunchParams();
		console.log('getLaunchParams...', launchParams, isRelaunch);

		if(launchParams.intent === 'deepLink' || launchParams.contentTarget){
			launchParams.panel = 'deepLink';
			if(launchParams.contentTarget){
				launchParams.contentId = launchParams.contentTarget;
			}
		}
		if(localSettings.isFirstLaunched || Config.DEMO){
			dispatch(PanelActions.pushPanel(PANEL_NAME.startpage));
		}
		if(launchParams.panel){
			dispatch(PanelActions.resetPanels(true));
			switch(launchParams.panel){
				case 'home':
					dispatch(PanelActions.setMainPanelIndex(MAIN_INDEX.HOME_VIEW));
					break;
				case 'category':
					dispatch(PanelActions.setMainPanelIndex(MAIN_INDEX.CATEGORIES_VIEW));
					break;
				case 'dashboard':
					dispatch(PanelActions.setMainPanelIndex(MAIN_INDEX.DASHBOARDS_VIEW));
					break;
				//admin preview
				case 'termsDetail':
					dispatch(PanelActions.pushPanel(PANEL_NAME.termsConditionsDetail));
					break;
				case 'termsModification':
					dispatch(PanelActions.pushPanel(PANEL_NAME.termsModification));
					break;
				case 'deepLink':
					if(launchParams.storeCaller==='search'){
						dispatch(CommonActions.setPlayLaunchPath("integratedSearch"));
					}else if(launchParams.storeCaller === 'Nudge'){
						dispatch(CommonActions.setPlayLaunchPath("nudge"));
					}else{
						dispatch(CommonActions.setPlayLaunchPath("deepLink"));
					}
					dispatch(PanelActions.popPanel(PANEL_NAME.player));
					// if(launchParams.contentId){
					// 	setTimeout(() => {
					// 		dispatch(CmsActions.getContentInfo(Number(launchParams.contentId), true,
					// 			playFromIntent({contentId: Number(launchParams.contentId), contentType: ContentType.SINGLE})));
					// 	}, isRelaunch ? 10: 2000);
					// }
					break;
				case 'noticeList':
					dispatch(PanelActions.pushPanel(PANEL_NAME.notice));
					break;
			}
		}
		//todo remove this
		if(launchParams.action){
			dispatch(CommonActions.setLaunchAction(launchParams.action));
		}
		setTimeout(() => {
			initService(!isRelaunch);
		}, 100);

		clearTimeout(foreGroundChangeTimer);
			foreGroundChangeTimer = setTimeout(() => {
			console.log('visibility changed !!! ==> set to foreground');	// eslint-disable-line no-console
			dispatch(CommonActions.changeAppStatus({isAppForeground: true}));
		}, 1000);
		if (isRelaunch) {
			activateApp();
		}
		cameraListRetryCount = 0;
		updateCameraListJob.start(dispatch);
	}, [activateApp, dispatch, initService, playFromIntent, localSettings]);

	const handleRelaunchEvent = useCallback(() => {
		console.log('handleRelaunchEvent');
		handleLaunchEvent(true);
	}, [handleLaunchEvent]);

	const visibilityChanged = useCallback(() => {
		console.log('visibility changed ', !document.hidden);
		if (document.hidden) {	// change to background
			console.log('visibility changed !!! ==> set to background');	// eslint-disable-line no-console
			clearTimeout(foreGroundChangeTimer);
			dispatch(CommonActions.changeAppStatus({isAppForeground: false, cameraDeviceName: "", cameraDetected: false}));
			updateCameraListJob.stop();
			cameraListRetryCount = CAMERACHECK_MAX_COUNT;
		} else { 		// change to foreground
			// set foreground flag using delay time.
			clearTimeout(foreGroundChangeTimer);
			foreGroundChangeTimer= setTimeout(() => {
				console.log('visibility changed !!! ==> set to foreground');	// eslint-disable-line no-console
				if(platform.platformName !== 'webos'){//for debug
					dispatch(CommonActions.changeAppStatus({isAppForeground: true, cursorVisible: true}));
				}else{
					if(typeof window == 'object'){
						dispatch(CommonActions.changeAppStatus({isAppForeground: true, cursorVisible: window.cursorEvent && window.cursorEvent.visibility}));
					}
				}
			}, 1000);
			cameraListRetryCount = 0;
			updateCameraListJob.start(dispatch);
			setTimeout(() => {
				initService(false);
			}, 100);
		}
	}, [dispatch, initService]);

	const ondevicechange = useCallback((event) => {
		console.log('ondevicechange....',event);
		if(isAppForeground){
			updateCameraListJob.startAfter(1500, dispatch);
		}
	}, [dispatch, isAppForeground]);

	useEffect(() => {
		document.fonts.ready.then(() => {
			setFontLoaded(true);
		});
		handleLaunchEvent();
		if (typeof window === 'object') {
			if (window.PalmSystem && Object.prototype.hasOwnProperty.call(window.PalmSystem, 'onclose')) {
				window.PalmSystem.onclose = handleExitApp;
			}else{
				window.onclose = handleExitApp;
			}
			if(window.PalmSystem){
				dispatch(CommonActions.getConnectionStatus());
			}
		}
		return () => {
			cameraListRetryCount = CAMERACHECK_MAX_COUNT;
			updateCameraListJob.stop();
		};
	}, [dispatch]);

	useEffect(() => {
		on('webOSRelaunch', handleRelaunchEvent);
		on('visibilitychange', visibilityChanged);
		if (typeof window === 'object') {
			if(window.navigator.mediaDevices){
				window.navigator.mediaDevices.addEventListener('devicechange', ondevicechange, false);
			}
		}
		return () => {
			off('webOSRelaunch', handleRelaunchEvent);
			off('visibilitychange', visibilityChanged);
			if (typeof window === 'object') {
				if(window.navigator.mediaDevices){
					window.navigator.mediaDevices.removeEventListener('devicechange', ondevicechange);
				}
			}
		};
	}, [dispatch,handleRelaunchEvent, visibilityChanged, ondevicechange]);

	return     (
		<MainView className={css.app} initService={initService}/>
	);
};

const App = ThemeDecorator({noAutoFocus: true}, AppBase);

export default App;
export {
	App,
	AppBase
};
