import {useCallback, useEffect} from 'react';
import css from './TestPanel.module.less';
import Button from '@enact/sandstone/Button';
import {useDispatch} from 'react-redux';
import Spotlight from '@enact/spotlight';
import SpotlightIds from '../../../data/SpotlightIds';
import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/TPanel/THeader';
import * as PanelActions from '../../../actions/panelActions';
import * as BleActions from '../../../actions/bleActions';
import * as CommonActions from '../../../actions/commonActions';
import {$L} from '../../../utils/common';
import {types} from '../../../actions/actionTypes';

const TestPanel = props => {
	const dispatch = useDispatch();

	useEffect(() => {
		if(!props.hideChildren){
			Spotlight.focus('testpanel');
		}
	}, [props]);

	const onBack = useCallback((ev) => {
		console.log('onClick ev...', ev);
		dispatch(PanelActions.popPanel());
		dispatch(BleActions.cancelScan());
	}, [dispatch]);

	const showTerms = useCallback((ev) => {
		console.log('showTerms ev...', ev);
		const matchedTerms = [];
		const termsData = {
			version: "1.0",
			importantChanges: "캔디 21일 테스트",
			// termsId: $L("이용약관"),
			termsId: 'TERMS_OF_USE',
			effectiveDatetime: "2022-09-21T17:06:07+09:00",
			reconsentYesNo: "yes",
			contents: "",
			mandatoryYesNo: "no",
			createDatetime: "2022-09-21T17:06:07+09:00",
			modifyDatetime: "2022-09-21T17:06:07+09:00",
		}
		const privacyPolicy = {...termsData};
		privacyPolicy.version = "1.0";
		// privacyPolicy.termsId = $L("개인정보 처리방침");
		privacyPolicy.termsId = 'TERMS_OF_USE';
		privacyPolicy.contents = "ㅇ니마ㅓ링 마니ㅓㅇ리ㅏㅓ ㅁ나ㅣㅇ러ㅣㅏㄴㅁ얼 ㅣㅏㅓ ㄴㅇ밀";
		const collectPrivacyInfo = {...termsData};
		collectPrivacyInfo.version = "1.0";
		// collectPrivacyInfo.termsId = $L("개인정보 수집 및 이용 동의서");
		collectPrivacyInfo.termsId = 'TERMS_OF_USE';
		collectPrivacyInfo.contents = "ㅇ니마ㅓ링 마니ㅓㅇ리ㅏㅓ ㅁ나ㅣㅇ러ㅣㅏㄴㅁ얼 ㅣㅏㅓ ㄴㅇ밀";
		const collectSensitiveInfo = {...termsData};
		collectSensitiveInfo.version = "1.0";
		// collectSensitiveInfo.termsId = "민감 정보의 수집 및 이용";
		collectSensitiveInfo.termsId = 'TERMS_OF_USE';
		collectSensitiveInfo.contents = "ㅇ니마ㅓ링 마니ㅓㅇ리ㅏㅓ ㅁ나ㅣㅇ러ㅣㅏㄴㅁ얼 ㅣㅏㅓ ㄴㅇ밀";
		const marketingInfo = {...termsData};
		marketingInfo.version = "1.0";
		// marketingInfo.termsId = "[선택] 마케팅정보 수신동의";
		marketingInfo.termsId = 'TERMS_OF_USE';
		marketingInfo.contents = "ㅇ니마ㅓ링 마니ㅓㅇ리ㅏㅓ ㅁ나ㅣㅇ러ㅣㅏㄴㅁ얼 ㅣㅏㅓ ㄴㅇ밀";
		matchedTerms.push(termsData);
		matchedTerms.push(privacyPolicy);
		matchedTerms.push(collectPrivacyInfo);
		matchedTerms.push(collectSensitiveInfo);
		matchedTerms.push(marketingInfo);
		dispatch({type: types.GET_TERMS_DOC, payload: matchedTerms});
		dispatch(CommonActions.changeAppStatus({showTermsPopup: true}));
	}, [dispatch]);

	const onClickPanelTest = useCallback((ev) => {
		console.log('onClickPanelTest ev...', ev);
		dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.extendedlist, {title: $L('My Favorite'), listId:SpotlightIds.LIST_MYFAVORITE}));
		dispatch(PanelActions.popPanel(PanelActions.PANEL_NAME.testpanel));
	}, [dispatch]);
	const onClickPanelTest2 = useCallback((ev) => {
		console.log('onClickPanelTest ev...', ev);
		dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.codelogin));
		dispatch(PanelActions.popPanel(PanelActions.PANEL_NAME.testpanel));
	}, [dispatch]);

	return (
		<TPanel {...props} spotlightId={'testpanel'}>
			<THeader title={'Test Panel...'} onClick={onBack}/>
			<div className={css.test}>
				<Button onClick={onClickPanelTest}>{'나의 운동'}</Button>
				<Button	onClick={onClickPanelTest2}>{'코드 로그인'}</Button>
				<Button	onClick={showTerms}>{'약관'}</Button>
			</div>
		</TPanel>
	);
};

export default TestPanel;
