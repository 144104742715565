/**
 * PIPCamera
 *
 * @module PIPCamera
 */
import classNames from 'classnames';
import css from './PIPCamera.module.less';
import React, {useState, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

const constraints = (typeof window === 'object') ? window.constraints = {
	audio: false,
	video: {
		width: { min: 1024, ideal: 1280, max: 1920 },
		height: { min: 576, ideal: 720, max: 1080 }
		// width: {max: 706 },
		// height: {max: 402 }
	}
} : {};
//size big, medium, small
//position lt, rt, lb, rb
const PIPCamera = ({size="small", position="rt"}) => {
	const dispatch = useDispatch();
	const appStatus = useSelector(state => state.appStatus);
	const [hideVideo, setHideVideo] = useState(true);

	const stopStreamedVideo = useCallback(() => {
		setHideVideo(true);
		if( typeof window === 'object' && window.stream){
			const tracks = window.stream.getTracks();
			tracks.forEach(function(track) {
			track.stop();
			});
			window.stream = null;

			const video = document.querySelector(`[data-spotlight-id="pipcamera"]`);
			if(video){
				video.srcObject = null;
			}
		}
	}, []);

	useEffect(() => {
		stopStreamedVideo();
		if(appStatus.cameraPermissionRequired === undefined || appStatus.cameraPermissionRequired){
			if(typeof window === 'object'){
				window.navigator.mediaDevices.getUserMedia(constraints).then(handleSuccess).catch(handleError);
			}
		}
		return () => {
			stopStreamedVideo();
			// dispatch(CmsActions.appLog(LogTypes.Types.CAMERASHOWN, false));
		};
	}, [dispatch, appStatus.cameraPermissionRequired]);

	const handleSuccess = useCallback((stream) => {
		const video = document.querySelector(`[data-spotlight-id="pipcamera"]`);
		console.log('handleSuccess',video);
		if(typeof window === 'object'){
			window.stream = stream; // make variable available to browser console
		}
		if(video){
			console.log('Got stream with constraints:', constraints);
			video.srcObject = stream;
			setHideVideo(false);
			// dispatch(CmsActions.appLog(LogTypes.Types.CAMERASHOWN, true));
		}else{
			stopStreamedVideo();
		}
	}, [stopStreamedVideo, dispatch]);

	const handleError = useCallback((error) => {
		console.log('handleError',error);
		stopStreamedVideo();
	}, [stopStreamedVideo]);

	return (
		<div className={classNames(css.covercam, (hideVideo) ? css.hide: null, css[size], css[position])}>
			<video data-spotlight-id="pipcamera" className={css.camera} playsInline autoPlay/>
			<canvas style={{display:"none"}} width="320" height="240"></canvas>
		</div>
	);
};

export default PIPCamera;
