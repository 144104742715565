/**
 * TotalHistoryData
 *
 * @module TotalHistoryData
 */
import React from 'react';
import IStiring from 'ilib/lib/IString';
import $L from '@enact/sandstone/internal/$L';
import classNames from 'classnames';
import {shallowEqual} from 'react-redux';
import * as Utils from '../../../utils/common';
import css from './TotalHistoryData.module.less';

const TotalHistory = ({dateStr, totalHistory, className}) => {
    return (
        <div className={classNames(css.totalData, className)}>
            <div className={css.date}>{dateStr}</div>
            <div className={css.totalInner}>
                <div className={css.dataDetail}>
                    <div className={css.dataCotent}>{$L('전체 운동 시간')}</div>
                    <div className={css.totalDataDetail}>{Utils.transSecToText(totalHistory.workoutTime)}</div>
                </div>
                <div className={css.dataDetail}>
                    <div className={css.dataCotent}>{$L('전체 소모 칼로리')}</div>
                    <div className={css.totalDataDetail}>{Number(totalHistory.calorieConsumption).toLocaleString('en') + ' kcal'}</div>
                </div>
                <div className={css.dataDetail}>
                    <div className={css.dataCotent}>{$L('전체 걸음 수')}</div>
                    <div className={css.totalDataDetail}>{new IStiring($L('{walk} steps')).format({walk: Number(totalHistory.totalWalk).toLocaleString('en')})}</div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(TotalHistory, shallowEqual);
