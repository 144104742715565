/**
 * DetailPopup
 *
 * @module DetailPopup
 */

import {useCallback, useMemo, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {FixedPopupPanels, Header, Panel} from '@enact/sandstone/FixedPopupPanels';
import { SpotlightContainerDecorator } from "@enact/spotlight/SpotlightContainerDecorator";
import Spotlight from '@enact/spotlight';
import {$L} from '../../utils/common';
import css from './DetailPopup.module.less';
import TButton from '../../components/TButton';
import TIconButton from '../../components/TIconButton';
import TScroller from '../../components/TScroller/TScroller';
import ProductList from './ProductList';
import SpotlightIds from '../../data/SpotlightIds';
import * as CommonActions from '../../actions/commonActions';
import * as CandyActions from '../../actions/candyActions';
import * as ContentType from '../../data/ContentType';

const Container = SpotlightContainerDecorator({enterTo: "last-focused", preserveId: true}, 'div');

const DetailPopup = ({open, onClosePopup, seasonId, ...rest}) => {
	const dispatch = useDispatch();
	const seasonInfos = useSelector(state => state.seasonInfos);
	const seasonProducts = useSelector(state => state.seasonProducts);
	const contentsMyFavorites = useSelector(state => state.contentsMyFavorites);
	const seasonPreferences = useSelector(state => state.seasonPreferences);

	useEffect(() => {
		setTimeout(() => {
			Spotlight.focus('detailplay');
		}, 0);
	}, []);

	const isMyFavoriteSeason = useMemo(() => {
		let hasKey = false;
		contentsMyFavorites.forEach(element => {
			if(element.seasonId === seasonId){
				hasKey = true;
			}
		});
		return hasKey;
	}, [contentsMyFavorites, seasonId]);

	const products = useMemo(() => {
		if(seasonProducts[seasonId]){
			return seasonProducts[seasonId];
		}
		return [];
	}, [seasonProducts, seasonId]);

	const seasonInfo = useMemo(() => {
		let showId="", bgThumbnail = "", style = {}, title="", description="", number="";
		if(seasonInfos[seasonId]){
			bgThumbnail = seasonInfos[seasonId].seasonThumbnailUrl;
			style = {backgroundImage: `url(${bgThumbnail})`};
			title = seasonInfos[seasonId].seasonTitle;
			description = seasonInfos[seasonId].seasonDescription;
			number = $L('Season {num}').replace('{num}', seasonInfos[seasonId].seasonNumber);
			showId = seasonInfos[seasonId].showId;
		}
		if( title.indexOf('\n') >=0){
			title = title.replace('\n', "");
		}
		if( description.indexOf('\n') >=0){
			description = description.replace('\n', "");
		}
		return {showId, style, title, description, number};
	}, [seasonInfos, seasonId]);

	const preferenceState = useMemo(() => {
		if(seasonPreferences[seasonId]){
			return seasonPreferences[seasonId].preference;
		}
		return "";
	}, [seasonPreferences, seasonId]);

	const onClickPlay = useCallback(() => {
		const season = seasonInfos[seasonId];
        dispatch(CommonActions.handlePlayItem(season, ContentType.SEASON));
	}, [dispatch, seasonInfos, seasonId]);

	const onAddRemoveFavorite = useCallback(() => {
		if(isMyFavoriteSeason){
			dispatch(CandyActions.removeFavorite(seasonId));
		}else{
			dispatch(CandyActions.addFavorite(seasonId));
		}
	}, [dispatch, isMyFavoriteSeason, seasonId]);

	const onUpdatePreference = useCallback((state)=>() => {
		if(seasonInfo.showId && seasonId){
			if(preferenceState !== state){
				dispatch(CandyActions.updateSeasonPreference(seasonInfo.showId, seasonId, state));
			}else{
				dispatch(CandyActions.removeSeasonPreference(seasonId));
			}
		}else{
			console.log('invalid state');
		}
	}, [dispatch, seasonInfo, seasonId, preferenceState]);

	const onClose = useCallback((ev) => {
		console.log('onClose', ev);
		if (onClosePopup) {
			onClosePopup();
		}
	}, [onClosePopup]);

	const onSpotlightDown = useCallback((id)=>(ev) => {
		if(id === SpotlightIds.MAIN_EXITBTN && Spotlight.focus('detailButtonLayer')){
			ev.stopPropagation();
		}
	}, []);

	return (
		<FixedPopupPanels width="half" open={open} onClose={onClose} position="center" className={css.detailpopupPanel}>
			<Panel {...rest} className={css.panel}>
				<Header title={seasonInfo.number} subtitle={seasonInfo.title} className={css.header}>
					<div className={css.bgImage} style={seasonInfo.style}/>
					<TIconButton spotlightId={SpotlightIds.MAIN_EXITBTN} className={css.exitBtn} onSelect={onClose}
						onSpotlightDown={onSpotlightDown(SpotlightIds.MAIN_EXITBTN)}/>
					<Container className={css.btnWrap} spotlightId='detailButtonLayer'>
						<TButton
							type='normal'
							size='small'
							spotlightId="detailplay"
							className={css.buttonArea}
							onClick={onClickPlay}
						>
							{$L('운동시작')}
						</TButton>
						<TIconButton
							spotlightId={SpotlightIds.ICON_FAVORITE}
							onSelect={onAddRemoveFavorite}
							selected={isMyFavoriteSeason}
						/>
						<TIconButton
							spotlightId={SpotlightIds.ICON_LIKE}
							onSelect={onUpdatePreference('good')}
							selected={preferenceState === 'good'}
						/>
						<TIconButton
							spotlightId={SpotlightIds.ICON_DISLIKE}
							onSelect={onUpdatePreference('bad')}
							selected={preferenceState === 'bad'}
						/>
					</Container>
				</Header>
				<Container className={css.description}>
					<TScroller>
						<div className={css.text}>
							{seasonInfo.description}
						</div>
					</TScroller>
				</Container>
				<ProductList className={css.list} products={products}/>
			</Panel>
		</FixedPopupPanels>
	);
};

export default DetailPopup;
