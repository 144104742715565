import LS2Request from './LS2Request';
import * as Config from '../data/Config';

export const getSystemSettings = (parameters, {onSuccess, onFailure, onComplete}) => {
	if (typeof window === 'object' && window.PalmSystem) {
		return new LS2Request().send({
			service: 'luna://com.webos.settingsservice',
			method: 'getSystemSettings',
			subscribe: true,
			parameters: parameters,
			onSuccess,
			onFailure,
			onComplete
		});
	}else{
		const res = {
			"settings": {
				"smartServiceCountryCode2": Config.DEBUG_WINDOW_COUNTRY,
				"captionEnable": true
			},
			"returnValue": true
		};
		onSuccess(res);
		onComplete(res);
	}
};
export const getSystemInfo = (parameters, {onSuccess, onFailure, onComplete}) => {
	if (typeof window === 'object' && window.PalmSystem) {
		return new LS2Request().send({
			service: 'luna://com.webos.service.tv.systemproperty',
			method: 'getSystemInfo',
			subscribe: false,
			parameters: parameters,
			onSuccess,
			onFailure,
			onComplete
		});
	}else{
		onSuccess({returnValue: true, sdkVersion: 'local'});
		onComplete();
	}
};
export const getDeviceId = (parameters, {onSuccess, onFailure, onComplete}) => {
	if (typeof window === 'object' && window.PalmSystem) {
		return new LS2Request().send({
			service: 'luna://com.webos.service.sm',
			method: 'deviceid/getIDs',
			subscribe: false,
			parameters: parameters,
			onSuccess,
			onFailure,
			onComplete
		});
	}else{
		onSuccess({returnValue: true, idList: [{idValue: Config.DEBUG_WINDOW_DEVICEID, idtype:"LGUDID"}]});
		onComplete();
	}
};
