export const types = {
	SHOW_HIDE_LOADING_SPINNER: 'SHOW_HIDE_LOADING_SPINNER',

	// Panel Actions
	PUSH_PANEL: 'PUSH_PANEL',
	UPDATE_PANEL: 'UPDATE_PANEL',
	SET_PANEL_RESULT: 'SET_PANEL_RESULT',
	CLEAR_PANEL_RESULT: 'CLEAR_PANEL_RESULT',
	POP_PANEL: 'POP_PANEL',
	RESET_PANELS: 'RESET_PANELS',
	SET_MAINPANEL_INDEX: 'SET_MAINPANEL_INDEX',
	SET_MAINPANEL_CURRENTSPOT: 'SET_MAINPANEL_CURRENTSPOT',

	INIT_LOADING_STATUS: 'INIT_LOADING_STATUS',
	ADD_LOADING_STATUS: 'ADD_LOADING_STATUS',
	REMOVE_LOADING_STATUS: 'REMOVE_LOADING_STATUS',

	// Cms Actions
	GET_ACCOUNT_INFO: 'GET_ACCOUNT_INFO',
	GET_CATEGORIES_TABLE: 'GET_CATEGORIES_TABLE',
	GET_ACTIVE_BANNER: 'GET_ACTIVE_BANNER',
	GET_CATEGORIES_CONTENTS: 'GET_CATEGORIES_CONTENTS',
	GET_CONTENTS_CATEGORY: 'GET_CONTENTS_CATEGORY',
	GET_HOME_GROUP: 'GET_HOME_GROUP',
	GET_CONTENTS_RECOMMENDED: 'GET_CONTENTS_RECOMMENDED',
	GET_CONTENTS_NEWS: 'GET_CONTENTS_NEWS',
	GET_CONTENTS_SPECIAL: 'GET_CONTENTS_SPECIAL',
	GET_CONTENTS_POPULAR: 'GET_CONTENTS_POPULAR',
	GET_CONTENTS_RECENTLY_WATCHED: 'GET_CONTENTS_RECENTLY_WATCHED',
	GET_CONTENTS_RECENTLY_ADDED: 'GET_CONTENTS_RECENTLY_ADDED',
	GET_CONTENTS_MY_FAVORITES: 'GET_CONTENTS_MY_FAVORITES',
	GET_CONTENTS_WATCHING: 'GET_CONTENTS_WATCHING',
	GET_ORDERED_TAG_LIST: 'GET_ORDERED_TAG_LIST',
	GET_CONTENTS_TAG: 'GET_CONTENTS_TAG',
	GET_CONTENT_INFO: 'GET_CONTENT_INFO',
	GET_CONTENTS_RELATED: 'GET_CONTENTS_RELATED',
	CLEAR_CONTENTS_RELATED: 'CLEAR_CONTENTS_RELATED',
	GET_CONTENTS_KEYWORD: 'GET_CONTENTS_KEYWORD',
	GET_CONTENTS_KEYWORD_RESET: 'GET_CONTENTS_KEYWORD_RESET',
	GET_WORKOUT_SUMMARY: 'GET_WORKOUT_SUMMARY',
	GET_WORKOUT_SUMMARY_WEEK: 'GET_WORKOUT_SUMMARY_WEEK',
	GET_GOAL_ACHIEVEMENT: 'GET_GOAL_ACHIEVEMENT',
	GET_GOAL_ACHIEVEMENT_WEEK: 'GET_GOAL_ACHIEVEMENT_WEEK',
	GET_CONTENT_USAGES: 'GET_CONTENT_USAGES',
	SELECT_CONTENT_INFO: 'SELECT_CONTENT_INFO',
	GET_TERMS_MODIFICATION: 'GET_TERMS_MODIFICATION',
	GET_NOTICE_LIST: 'GET_NOTICE_LIST',
	GET_CANDY_ACTIVITIES_BY_MONTH: 'GET_CANDY_ACTIVITIES_BY_MONTH',
	GET_RELATED_KEYWORDS: 'GET_RELATED_KEYWORDS',
	GET_SHOW_SEARCH: 'GET_SHOW_SEARCH',
	GET_POPULAR_SHOWS: 'GET_POPULAR_SHOWS',
	GET_SHOW_SEARCH_RESET: 'GET_SHOW_SEARCH_RESET',

	// list Actions
	GET_CATEGORIES_TABLE_SELECTED: 'GET_CATEGORIES_TABLE_SELECTED',
	GET_WORKOUTSTYLE_TABLE_SELECTED: 'GET_WORKOUTSTYLE_TABLE_SELECTED',
	GET_FOCUSED_BANNER_ITEM: 'GET_FOCUSED_BANNER_ITEM',
	GET_PLAYER_INFO: 'GET_PLAYER_INFO',

	// Common Actions
	GET_TERMS_STATUS: 'GET_TERMS_STATUS',
	GET_TERMS_DOC: 'GET_TERMS_DOC',
	PUT_TERMS_DOC: 'PUT_TERMS_DOC',
	// Common Actions types
	CHANGE_APP_STATUS: 'CHANGE_APP_STATUS',
	CHANGE_LOCAL_SETTINGS: 'CHANGE_LOCAL_SETTINGS',
	RECEIVE_GET_EFFECTIVE_BROADCAST_TIME: 'RECEIVE_GET_EFFECTIVE_BROADCAST_TIME',
	SET_LAUNCHING_MODE: 'SET_LAUNCHING_MODE',

	// device Actions
	GET_LIST_DEVICES: 'GET_LIST_DEVICES',
	GET_DEVICE_INFORMATION_UDNS: 'GET_DEVICE_INFORMATION_UDNS',
	DEVICE_CHANGED: 'DEVICE_CHANGED',

	// video actions
	GET_VIDEO_PROGRESS_THUMBNAIL: 'GET_VIDEO_PROGRESS_THUMBNAIL',
	CLEAR_VIDEO_PROGRESS_THUMBNAIL: 'CLEAR_VIDEO_PROGRESS_THUMBNAIL',

	// launch action
	GET_LAUNCH_ACTION: 'GET_LAUNCH_ACTION',
	CLEAR_LAUNCH_ACTION: 'CLEAR_LAUNCH_ACTION',

	// Candy Aciton
	GET_ACCOUNT_STATUS: 'GET_ACCOUNT_STATUS',
	GET_SEASONID: 'GET_SEASONID',
	UPDATE_SHOW_INFOS: 'UPDATE_SHOW_INFOS',
	UPDATE_SEASON_INFOS: 'UPDATE_SEASON_INFOS',
	UPDATE_SEASON_LIST: 'UPDATE_SEASON_LIST',
	UPDATE_EPISODE_LIST: 'UPDATE_EPISODE_LIST',
	UPDATE_EPISODE_PRODUCTS: 'UPDATE_EPISODE_PRODUCTS',
	UPDATE_SEASON_PRODUCTS: 'UPDATE_SEASON_PRODUCTS',
	GET_SEASON_PREFERENCE: 'GET_SEASON_PREFERENCE',
	GET_USER_INFO: 'GET_USER_INFO',
	GET_USER_PREFERENCES: 'GET_USER_PREFERENCES',
	GET_USER_PAYMENT_INFO: 'GET_USER_PAYMENT_INFO',

	// BLE Action
	GET_BLE_DEVICE_LIST: 'GET_BLE_DEVICE_LIST',
	GATT_CONNECT_STATUS: 'GATT_CONNECT_STATUS',
	GATT_EXERCISE_RESULT_VALUE: 'GATT_EXERCISE_RESULT_VALUE'
};
