/**
 * TopButton
 *
 * @module TopButton
 */

import {useState, useCallback, useEffect, useMemo} from 'react';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {setContainerLastFocusedElement} from '@enact/spotlight/src/container';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import Group from '@enact/ui/Group';
import {Job} from '@enact/core/util';
import Spotlight from '@enact/spotlight';
import TopButton from './TopButton';
import TIconButton from '../../../components/TIconButton';
import SpotlightIds from '../../../data/SpotlightIds';
import { types } from '../../../actions/actionTypes';
import * as Utils from '../../../utils/common';
import * as PanelActions from '../../../actions/panelActions';
import * as BleActions from '../../../actions/bleActions';
import css from './TopLayer.module.less';

const MainContainer = SpotlightContainerDecorator({enterTo: 'last-focused', defaultElement: [`.${css.topgroup}`],preserveId: true}, 'div');
const Container = SpotlightContainerDecorator({enterTo: 'last-focused', preserveId: true}, 'div');
const GroupContainer = SpotlightContainerDecorator({preserveId: true, enterTo:'last-focused'}, Group);

const extendChangeJob = new Job((func, value) => {
	func(value);
},100);

const TopLayer = ({isOnTop=true, mainIndex, extended}) => {
	const dispatch = useDispatch();
	const localSettings = useSelector(state => state.localSettings);
	const [isHover, setHover] = useState(false);
	const [isFocused, setFocused] = useState(false);
	const [showExtendedView, setShowExtendedView] = useState(false);
	useEffect(() => {
		let value = false;
		if(extended || isHover || isFocused){
			value=true;
		}
		extendChangeJob.start(setShowExtendedView, value);
	}, [extended, isHover, isFocused]);

	const topButtonClicked = useCallback((name, index) => {
		// console.log('topButtonClicked ev...', name, index);
		dispatch(PanelActions.setMainPanelIndex(index));
	}, [dispatch]);

	const onClickExit = useCallback(() => {
		console.log('onClickExit');
		Utils.windowClose();
	}, []);

	const onMouseOver = useCallback(() => {
		setHover(true);
	}, [setHover]);

	const onMouseOut = useCallback(() => {
		setHover(false);
	}, [setHover]);

	const onFocus = useCallback(() => {
		setFocused(true);
	}, [setFocused]);

	const onBlur = useCallback(() => {
		setFocused(false);
	}, [setFocused]);

	const onFocusTopButtonLayer = useCallback(() => {
		setContainerLastFocusedElement(null, ['topRightButtonLayer']);
	}, []);

	const topButtons = [
		{key: 'Categories', isOnTop:isOnTop, extended: showExtendedView, spotlightId: SpotlightIds.MAIN_CATEGORY_BTN, onSelect: topButtonClicked},
		{key: 'Home', isOnTop:isOnTop, extended: showExtendedView, spotlightId: SpotlightIds.MAIN_HOME_BTN, onSelect: topButtonClicked},
		{key: 'Dashboards', isOnTop:isOnTop, extended: showExtendedView, spotlightId: SpotlightIds.MAIN_DASHBOARD_BTN, onSelect: topButtonClicked}
	];
	const onClickSearch = useCallback(() => {
		dispatch({ type: types.GET_SHOW_SEARCH_RESET });
		dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.search, {"init": true }));
	},[dispatch])
	const onClickSetting = useCallback(() => {
		dispatch(BleActions.gattAutoConnect());
		dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.settingPanel))
	},[dispatch])

	const onSpotlightDown = useCallback(() => {
		Spotlight.move('down', '[data-spotlight-id=topButtonLayer]');
	},[])

	const debugString = useMemo(() => {
		let str = "";
		if(localSettings.useQAServerURL === 'qa'){
			str = "QA";
		}
		if(localSettings.useQAServerURL === 'dev'){
			str = "DEV";
		}
		if(localSettings.useForceCamera){
			str += str ? "/Cam": "Cam";
		}
		return str;
	}, [localSettings]);

	const bgClass = useMemo(() => {
		let c = css.left;
		if(mainIndex === 1){
			c = css.center;
		}else if(mainIndex === 2){
			c = css.right;
		}
		return c;
	}, [mainIndex]);

	return (
		<MainContainer className={classNames(css['top-title-layer'], bgClass)}  onMouseOver={onMouseOver} onMouseOut={onMouseOut}
			onFocus={onFocus} onBlur={onBlur}>
			<Container className={css.globalbutton} spotlightId="topRightButtonLayer">
				<TIconButton isOnTop={isOnTop} spotlightId={SpotlightIds.MAIN_SEARCHBTN} onSelect={onClickSearch} onSpotlightDown={onSpotlightDown}/>
				<TIconButton isOnTop={isOnTop} spotlightId={SpotlightIds.MAIN_SETTINGSBTN} onSelect={onClickSetting} onSpotlightDown={onSpotlightDown}/>
				<TIconButton isOnTop={isOnTop} spotlightId={SpotlightIds.MAIN_EXITBTN} onSelect={onClickExit} onSpotlightDown={onSpotlightDown}/>
			</Container>
			<GroupContainer
				spotlightId="topButtonLayer"
				onFocus={onFocusTopButtonLayer}
				className={classNames(css.topgroup, css.extended)}
				// className={classNames(css.topgroup, showExtendedView ? css.extended: null)}
				childComponent={TopButton}
				itemProps={{inline: false}}
				select="radio"
				selectedProp="selected"
				selected={mainIndex}
			>
				{topButtons}
			</GroupContainer>
			{/* <div className={classNames(css.logo, !showExtendedView ? css.hide: null)}/> */}
			{/* todo */}
			<div className={classNames(css.logo)}></div>
			{/* <div className={classNames(css.logo, !showExtendedView ? css.hide: null)}>Candy</div> */}
			{debugString &&
				<div className={css.isQAServer}>{debugString}</div>
			}
			<div className={css.blue}/><div className={css.pink}/><div className={css.green}/>
		</MainContainer>
	);
};

export default TopLayer;
