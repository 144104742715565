import {useEffect, useCallback} from 'react';
import css from './SearchingFailed.module.less';
import {$L} from '../../../../../../../utils/common';
import TButton from '../../../../../../../components/TButton/TButton';
import Spotlight from '@enact/spotlight';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';

const Container = SpotlightContainerDecorator({enterTo: 'last-focused', preserveId: true}, 'div');

const SearchingFailed = ({onSearch, ...props}) => {
    const searchCandyBand = useCallback(()=>{
        if(onSearch){
            onSearch();
        }
    }, [onSearch]);

    useEffect(() => {
        Spotlight.focus('searchDeviceBtn');
    }, []);

	return (
        <div>
            <Container className={css.container} {...props}>
                <div className={css.title}>{$L('Candy Band를 찾을 수 없습니다.')}</div>
                <div className={css.detail}>{$L('Candy Band 전원을 켜고, 다시 시도해주세요.')}</div>
                <div className={css.imgStyle} />
                <TButton size="small" spotlightId={'searchDeviceBtn'} onClick={searchCandyBand}>{$L('장치검색')}</TButton>
            </Container>
        </div>
    )
};

export default SearchingFailed;