/**
 * TKeywordItemList
 *
 * @module TKeywordItemList
 */
import { useCallback, useState } from 'react';
import classNames from 'classnames';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import css from './TKeywordItemList.module.less';
import Item from '@enact/sandstone/Item';

const Container = SpotlightContainerDecorator({
	enterTo: 'default-element',
}, 'div');

const TKeywordItemList = ({type='searchRecents', children, spotlightId, onClickSearch, onClickDelete}) => {
	const [onFocus, setFocus] = useState(false);

	const onFocusItem = useCallback(()=>{
		setFocus(true);
	}, [])

	const onBlurItem = useCallback(()=>{
		setFocus(false);
	}, [])
	return (
		<div className={css.resentSearchsContainer} >
				<div className={classNames(css.listItem, onFocus && css.onFocus)}>
					<Item
						spotlightId={spotlightId}
						className={classNames(css.searchResult, onFocus && css.onFocusItem)}
						onClick={onClickSearch}
						onFocus={onFocusItem}
						onBlur={onBlurItem}
						centered
					>
						{children}
					</Item>
					{ type !== 'autoSearchWord' &&
						<Item
							className={classNames(css.deletebutton, onFocus && css.onFocusItem)}
							onClick={onClickDelete}
							onFocus={onFocusItem}
							onBlur={onBlurItem}
							centered
						>
							x
						</Item>
					}
				</div>
		</div>

	);
};

export default TKeywordItemList;
