import DateFactory from 'ilib/lib/DateFactory';
import DateFmt from 'ilib/lib/DateFmt';
import * as Config from '../data/Config';
import StringReSourceKO from '../../resources/ko/strings.json';
import Enact_$L from '@enact/i18n/$L';
import JSEncrypt from 'jsencrypt';

const fillZero = (str, length) => {
	return ("00000000"+str).slice(-length);
}

export const $L = (str) => {
	if (typeof window === 'object' && window.PalmSystem){
		return Enact_$L(str).replace(/{br}/g, '\n');
	}else{
		const resource = Config.DEBUG_WINDOW_COUNTRY === 'KR' ? StringReSourceKO : null;
		if(typeof str === 'object'){
			if(resource &&resource[str.key]){
				return resource[str.key].replace(/{br}/g, '\n');
			}else{
				str = str.value;
			}
		}else if(resource && resource[str]){
			return resource[str].replace(/{br}/g, '\n');
		}
	}
	return str && str.replace(/{br}/g, '\n');
};

export const getLanguageCode = (country) => {
	return Config.HOMEGYM_SUPPORT_COUNTRYS[country] ? Config.HOMEGYM_SUPPORT_COUNTRYS[country] : 'en';
};

/**
 * Generates a query string from the key/values of a JSON object
 * @param object The json object
 * @returns A value representing a URL compatible query string
 */
export const createQueryString = (object) => {
	const parts = [];
	for (const key of Object.getOwnPropertyNames(object)) {
		if(object[key]!==null && object[key] !== undefined && object[key] !== ""){
			parts.push(`${key}=${encodeURIComponent(object[key])}`);
		}
	}
	return parts.join('&');
};

export const createStringfyParams = (object) => {
	const parts ={};
	for (const key of Object.getOwnPropertyNames(object)) {
		if(object[key]!==null && object[key] !== undefined && object[key] !== ""){
			parts[key] = object[key].toString();
		}
	}
	return parts;
};
let localLaunchParams = {};
// let localLaunchParams = {
// 	intent:'PlayContent',intentParam: 'punch'
// };
export const getLaunchParams = () => {
	let params = {};

	if (typeof window === 'object' && window.PalmSystem && window.PalmSystem.launchParams) {
		try {
			params = JSON.parse(window.PalmSystem.launchParams);
			if (params['x-webos-app-container-launch'] === true) {
				params = params.details;
			}
		} catch (e) {
			params = {};
		}
		return params;
	}else{
		return localLaunchParams;
	}
};

export const clearLaunchParams = () => {
	console.log('common.clearLaunchParams');
	if (typeof window === 'object' && window.PalmSystem && window.PalmSystem.launchParams) {
		window.PalmSystem.launchParams = '';
	}else{
		localLaunchParams = {};
	}
};

export const transDateText = (date) => {
	if (!date) {
		return '';
	}
	const year = date.slice(0, 4);
	const month  = date.slice(4, 6);
	const day  = date.slice(6, 8);

	try {
		const factory = DateFactory({
			type: 'gregorian',
			year: year,
			month: month,
			day: day
		});
		const fmt = new DateFmt({
			length: 'medium',
			type: 'date',
			date: 'dmy',
			useNative: false
		});
		return fmt.format(factory) || '';
	} catch (e) {
		return '';
	}
};

export const transSecToText = (sec, withHour) => {
	if(isNaN(sec)){
		sec = 0;
	}
	const hh = Math.floor(sec/3600);
	const mm = Math.floor(Math.round(sec-hh*3600)/60);
	const ss = Math.round(sec)%60;
	const strHH =  String(hh).length === 1 ? "0"+String(hh) : String(hh);
	const strMM =  String(mm).length === 1 ? "0"+String(mm) : String(mm);
	const strSS =  String(ss).length === 1 ? "0"+String(ss) : String(ss);
	return (hh > 0 || withHour)? (strHH+":"+strMM+":"+strSS) : (strMM+":"+strSS);
};
export const getNearestTarget= (direction, node) => {
	const parentNode = node.parentNode;
	const children = parentNode.children;
	const currentRect = node.getBoundingClientRect();
	const currentCenter = {x: (currentRect.left+currentRect.right)/2, y: (currentRect.top+currentRect.bottom)/2};

	const minorGap = 50;
	let nearestNode = null;
	let minDistance = 1920;
	for (let child of children) {
		if(child !== node){
			const targetRect = child.getBoundingClientRect();
			const targetCenter = {x: (targetRect.left+targetRect.right)/2, y: (targetRect.top+targetRect.bottom)/2};
			if((direction==='down' && currentCenter.y + minorGap < targetCenter.y)
				|| (direction==='up' && currentCenter.y - minorGap > targetCenter.y)
				|| (direction==='left' && currentCenter.x - minorGap> targetCenter.x)
				|| (direction==='right' && currentCenter.x + minorGap < targetCenter.x)
				){
				if((direction === 'left' || direction==='right') && Math.abs(currentCenter.y - targetCenter.y) > minorGap ){
					continue;
				}
				const distance = Math.sqrt(Math.pow((targetCenter.y - currentCenter.y),2) + Math.pow((targetCenter.x - currentCenter.x),2));
				if(minDistance > distance){
					minDistance = distance;
					nearestNode = child;
				}
			}
		}
	}
	if(nearestNode){
		return nearestNode;
	}else{
		return null;
	}
  };

export const transTimeToText = (hour, minutes) => {
	if (hour < 10) {
			hour = '0' + hour;
	}
	if (minutes < 10) {
		minutes = '0' + minutes;
	}
	return hour+":"+ minutes;
};

export const durationText = (sec) => {
	const hh = Math.floor(sec/3600);
	const mm = Math.floor(Math.round(sec-hh*3600)/60);
	const ss = Math.round(sec)%60;
	let res = "";
	if(ss > 0){
		res = $L("{ss}s").replace('{ss}',ss);
	}
	if(mm >0){
		res = $L("{mm}m").replace('{mm}',mm)+" "+res;
	}
	if(hh >0){
		res = $L("{hh}h").replace('{hh}',hh)+" "+res;
	}
	return res.trimEnd();
};

//"0:00:10" --> 10
export const stringTimetoSec = (str)=> {
	let sec = 0;
	if(typeof(str) === 'string'){
		const sp = str.split(':');
		if(sp.length === 3){
			sec = Number(sp[0])*3600 + Number(sp[1])*60+Number(sp[2]);
		}else if(sp.length === 2){
			sec = Number(sp[0])*60+Number(sp[1]);
		}else if(sp.length ===1){
			sec = Number(sp[1]);
		}else{
			sec = Number(str);
		}
	}
	return sec;
};
let DomParser;
if (typeof window === 'object'){
	DomParser = new window.DOMParser();
}
export const replaceHtmlEntites = (s) => {
	if(!s){
		return s;
	}
	if (DomParser) {
		const finalResult = DomParser.parseFromString(s, "text/html");
		return finalResult.body.innerText;
	}else{
		return s;
	}
};
export const strTime = (s) => {
	return String(s).length === 1 ? "0"+String(s) : String(s);
};
//'2022-08-22'
export const timeToISO8601DateStr = (date) => {
	return date.getFullYear()+'-'+strTime(date.getMonth()+1)+'-'+strTime(date.getDate());
};
// "2021-10-06T13:44:00"
export const timeToISO8601Str = (date) => {
	return date.getFullYear()+'-'+strTime(date.getMonth()+1)+'-'+strTime(date.getDate())+"T"+strTime(date.getHours())
	+":"+strTime(date.getMinutes())+":"+strTime(date.getSeconds());
};
// "2021-10-29T13:18:27+09:00"
export const timeToISO8601StrWithTimeZone = (date) => {
	let timeZone = date.getTimezoneOffset();
	let isMinusTimeZone = false;
	if(timeZone < 0){
		timeZone = timeZone * -1;
		isMinusTimeZone = true;
	}
	let zoneInfo = fillZero(Math.floor(timeZone/60), 2)+":"+fillZero(Math.round(timeZone%60), 2);
	if(isMinusTimeZone){
		zoneInfo = "+"+zoneInfo;
	}else{
		zoneInfo = "-"+zoneInfo;
	}
	return date.getFullYear()+'-'+strTime(date.getMonth()+1)+'-'+strTime(date.getDate())+"T"+strTime(date.getHours())
	+":"+strTime(date.getMinutes())+":"+strTime(date.getSeconds())+zoneInfo;
};
export const readLocalStorage = (key, defaultValue) => {
	const value = (typeof window === 'object') ? window.localStorage.getItem(key) : null;
	if(!value && defaultValue !== undefined){
		return defaultValue;
	}
	return value === 'undefined' ? null : JSON.parse(value);
};

export const writeLocalStorage = (key, value) => {
	if(typeof window === 'object'){
		window.localStorage.setItem(key, JSON.stringify(value));
	}
};
export const convertNormalStr = (str) => {
	if(!str)return str;
	let ret =  str.replace(/(\\n)/gm,"\n");
	ret = ret.replace(/(\\t)/gm,"\t");
	ret = ret.replace(/(\\")/gm,"\"");
	return ret;
};

export const windowClose = () => {
	if (typeof window === 'object' && window.PalmSystem) {
		window.close();
	}else if(typeof window === 'object'){
		window.location.reload();
	}
}

export const convertUniToUtf8 = (str) => {
	try{
		return unescape(encodeURIComponent(str));
	}catch{
		return str;
	}
}
export const convertUtf8ToUni = (str) => {
	try{
		return decodeURIComponent(escape(str));
	}catch{
		return str;
	}
}
export const jsonConcat = (o1, o2) => {
	for (let key in o2) {
		o1[key] = o2[key];
	}
	return o1;
}
export const scaleW = (value) => {
	if (typeof window === 'object'){
		return value * (window.innerWidth/1920);
	}
	return value;
}
export const scaleH = (value) => {
	if (typeof window === 'object'){
		return value * (window.innerHeight/1080);
	}
	return value;
}

export const convertLocalTimetoUTC = (date) => {
	if(date instanceof Date){
		return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
	}
	return null;
}
export const convertUTCtoLocalTime = (date) => {
	if(date instanceof Date){
		return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
	}
	return null;
}

// return "2022-04-29" or "2022-04"
export const convertDateToString = (fullyear, month, date) => {
	let str = fullyear+"-"+fillZero(month,2);
	if(date){
		str += "-"+fillZero(date,2)
	}
	return str;
}

const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
  MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAKCLAaU0vExTCYpEwe0gaQ/ZPg7faMTP
  R0hz6cnkIFW5Vkeo3gLEaajIxNiOZm5bCWPT83zWBhVeYLjBYM4sMT8CAwEAAQ==
  -----END PUBLIC KEY-----`;

export const encryptPassword = (password) => {
	if(typeof window === 'object'){
		const jsencrypt = new JSEncrypt();
		jsencrypt.setPublicKey(PUBLIC_KEY);
		// Perform our encryption based on our public key - only private key can read it!
		const encrypted = jsencrypt.encrypt(password);
		return encrypted;
	}
	return "";
}
//전체, 19세이하, 20대, 30대, 40대, 50대, 60대, 70대, 80이상

const KOSIS_WEIGHT_DATA = {
	age: 	[-1,	1,		20,		30,		40,		50,		60,		70,		80],
	total:  [66.55,	68.08,	66.72,	71.09,	68.86,	65.68,	63.62,	61.58,	57.26],
	male :  [73.80, 73.23,	75.91,	78.73,	76.37,	72.39,	69.22,	66.35,	62.76],
	female: [58.51,	59.36,	57.84,	59.21,	59.38,	58.83,	58.37,	57.18,	52.91]
}
const MET_PER_KCAL = 3.5* 0.005;
//gender male, female
export const calculateLocalCalory = (mets, sec, weight, gender, age) => {
	if(isNaN(mets)){
		console.warn('calculateLocalCalory mets is not valid');
		return 0;
	}
	if(!weight || isNaN(weight)){
		let ageData = KOSIS_WEIGHT_DATA.age;
		let targetData = KOSIS_WEIGHT_DATA[gender];
		if(!targetData){
			targetData = KOSIS_WEIGHT_DATA.total;
		}
		if(!age || isNaN(age)){
			weight = targetData[0];
		}else{
			for(let i=0; i<targetData.length;i++){
				if(age >= ageData[i]){
					weight = targetData[i];
				}else{
					break;
				}
			}
		}
	}
	return Math.round(mets * MET_PER_KCAL * (sec/60) * weight);
}

/**
 * await Utils.wait(10000);
 * @param {*} time
 * @returns
 */
export const wait = (time) => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, time);
    });
}