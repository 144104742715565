/**
 * MyInfo
 *
 * @module MyInfo
 */
import {Image} from '@enact/sandstone/Image';
import React, {useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {$L} from '../../../utils/common';
import video_default from '../../../../assets/list/video_default.png';
import css from './MyInfo.module.less';

const MyInfo = () => {
    const userInfo = useSelector((state) => state.userInfo);
    const age = useMemo(() => {
        if(userInfo.age){
            return userInfo.age;
        }else{
            return "--";
        }
    }, [userInfo]);

    const thumbnail = useMemo(() => {
        return userInfo.profileImageUrl ? userInfo.profileImageUrl : video_default;
    }, [userInfo]);

    return (
        <div className={css.myInfo}>
            <Image className={css.profileImage} src={thumbnail} />
            <div className={css.infoLayer}>
                <div className={css.name}>{userInfo.nickName}</div>
                <div className={css.userInfo}>
                    <div className={css.title}>{$L('나이')}</div>
                    <div className={css.detail}>{$L('만 {age}세').replace('{age}', age)}</div>
                    <div className={css.title}>{$L('키')}</div>
                    <div className={css.detail}>{userInfo.height + 'cm'}</div>
                    <div className={css.title}>{$L('몸무게')}</div>
                    <div className={css.detail}>{userInfo.weight + 'kg'}</div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(MyInfo, shallowEqual);
