/**
 * HomeBanner
 *
 * @module HomeBanner
 */
import React, {useCallback, useEffect, useState, useMemo} from 'react';
import classNames from 'classnames';

import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import Spotlight from '@enact/spotlight';
import Marquee from '@enact/sandstone/Marquee';
import * as CommonActions from '../../actions/commonActions';
import * as PanelActions from '../../actions/panelActions';
import {PANEL_NAME} from '../../actions/panelActions';
import {$L} from '../../utils/common';
import * as Config from '../../data/Config';
import TButton from '../../components/TButton';
import Group from '@enact/ui/Group';
import BannerPagination from './BannerPagination';
import CustomImage from '../CustomImage/CustomImage';
import css from './HomeBanner.module.less';
import dummyImage from '../../../assets/dummyImage.png';

const Container = SpotlightContainerDecorator({
	enterTo: 'default-element',
}, 'div');
const GroupContainer = SpotlightContainerDecorator({
	preserveId: true, enterTo:'none'
}, Group);


const HomeBanner = ({isOnTop, spotlightId, mainIndex, ...rest}) => {
	const dispatch = useDispatch();
	const contentsNews = useSelector(state => state.contentsNews);
	const homeBannerSize = useSelector(state => state.appStatus.homeBannerSize);
	const [selectedPage, setSelectedPage] = useState(0);


	const bannerContent = useMemo(()=>{
		let content = {};
		if(contentsNews && contentsNews.length > selectedPage){
			content= {};
			content.title = contentsNews[selectedPage].title;
			content.subTitle = contentsNews[selectedPage].contents;
			content.thumbnail = contentsNews[selectedPage].thumbnail;
			if(Config.DEMO && content.thumbnail && content.thumbnail.indexOf('dummyImage')>=0){
				content.thumbnail = dummyImage;
			}
		}
		return content;
	}, [contentsNews, selectedPage]);

	const paginationClicked = useCallback((name, index) => {
		setSelectedPage(index);
	}, [selectedPage]);

	const paginations = useMemo(() => {
		const pages = [];
		for(let i=0; i<contentsNews.length; i++){
			pages.push({key: i, onSelect: paginationClicked});
		}
		return pages;
	}, [contentsNews]);

	const onFocus = useCallback(() => {
		const current = Spotlight.getCurrent();
		//only keyboard move
		if(!Spotlight.getPointerMode() && current){
			dispatch(CommonActions.changeAppStatus({homeBannerSize: 'medium'}));
			dispatch(CommonActions.clearFocusedBannerItem());
		}
	}, [dispatch]);

	const onClickGoToDetail = useCallback(() => {
		const item = contentsNews[selectedPage];
		if(item){
			dispatch(PanelActions.pushPanel(PANEL_NAME.seasonDetailPanel, {seasonId: item.seasonId, showId: item.showId}));
		}
	}, [dispatch, contentsNews, selectedPage]);

	return (
		<Container {...rest}
			spotlightId={spotlightId}
			className={classNames(css.homebanner, homeBannerSize === 'small' ? css.small : css.medium)}
			onFocus={onFocus}
			//onBlur={onBlur}
		>
			<div className={classNames(css.infoArea)}>
				<div>
					<Marquee  className={css.subtitle} marqueeOn={"render"} alignment='center'>
					{bannerContent.subTitle}
					</Marquee>
					<Marquee  className={css.title} marqueeOn={"render"} alignment='center'>
						{bannerContent.title}
					</Marquee>
				</div>
			</div>
			{bannerContent && bannerContent.thumbnail &&
				<CustomImage className={css.preview} src={bannerContent.thumbnail} animationSpeed="normal"/>
			}
			{(homeBannerSize !== 'small') &&
				<div>
					<TButton
						spotlightId="skipBanner"
						className={classNames(css.buttonArea)}
						onClick={onClickGoToDetail}
					>
						{$L('바로가기')}
					</TButton>
				</div>
			}
			<GroupContainer
				spotlightId="paginationLayer"
				className={classNames(css.paginationArea)}
				childComponent={BannerPagination}
				select="radio"
				selected={selectedPage}
				selectedProp="selected"
			>
				{paginations}
			</GroupContainer>
		</Container>
	);
};
export default React.memo(HomeBanner,shallowEqual);
