import classNames from 'classnames';
import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {on, off} from '@enact/core/dispatcher';
import platform from '@enact/core/platform';
import Scroller from '@enact/sandstone/Scroller';
import {Job} from '@enact/core/util';
import usePrevious from '../../utils/usePrevious';
import VerticalScrollGuide from '../VerticalScrollGuide';
import MediaList from './MediaList';
import * as Utils from '../../utils/common';
import * as Config from '../../data/Config';
import css from './MediaList.module.less';

const calculateVisibleListJob = new Job((func) => {
	func();
},2000);
const itemMargin = 10;
const VerticalMediaList = ({contentsList=[], listSpotlightId="", contentType, className, withOutScrollLength=5, onFocusedIndexChanged, restoreIndex}) => {
	const scrollTo = useRef(null);
	const scrollTop = useRef(0);
	const preScrollTop = useRef(0);
	const panels = useSelector(state => state.panels);
	const cursorVisible = useSelector(state => state.appStatus.cursorVisible);
	const prevPanels = usePrevious(panels);

	const [scrollOnBottom, setScrollOnBottom] = useState(false);
	const [scrollOnTop, setScrollOnTop] = useState(true);
	const [visibleList, setVisibleList] = useState([]);

	const calculateVisibleList= useCallback(() => {
		const scroller = document.querySelector(`[data-spotlight-id="${listSpotlightId+"_scroller"}"]`);
		const listView = document.querySelector(`[data-spotlight-id="${listSpotlightId}"]`);
		let listArray = listView.children[0].children;
		const itemList = [];
		if(scroller && typeof window == 'object' && window.getComputedStyle(scroller).visibility === 'visible'){
			for(let index in listArray){
				if(!isNaN(index)){
					const item = listArray[index];
					if(item.offsetTop >= scrollTop.current && (item.offsetTop+item.offsetHeight)<= scrollTop.current+scroller.offsetHeight){
						itemList.push(item.getAttribute('data-spotlight-id'));
					}
				}
			}
		}
		setVisibleList(itemList);
	}, []);

	useEffect(() => {
		if (scrollTo && scrollTo.current) {
			scrollTo.current({ position: { y: 0 }, animate: true });
		}
		calculateVisibleListJob.start(calculateVisibleList);
	}, [contentsList]);

	useEffect(() => {
		if (panels && panels.length > 0) {
			preScrollTop.current = scrollTop.current
		}
		setTimeout(() => {
			if (scrollTo && scrollTo.current) {
				scrollTo.current({ position: { y: preScrollTop.current }, animate: true });
			}
		}, 0);
	}, [panels]);

	useEffect(() => {
		on('wheel', handleWheel);
		return () => {
			off('wheel', handleWheel);
			calculateVisibleListJob && calculateVisibleListJob.stop();
		};
	}, []);

	const getScrollTo = useCallback((cbScrollTo) => {
		scrollTo.current = cbScrollTo;
	}, [scrollTo]);

	const onScrollStop = useCallback((ev) => {
		scrollTop.current = Math.round(ev.scrollTop);
		const scroller = document.querySelector(`[data-spotlight-id="${listSpotlightId+"_scroller"}"]`);
		if(scrollTop.current + scroller.offsetHeight + 10/*dummy*/ > scroller.children[0].scrollHeight){
			setScrollOnBottom(true);
		}else{
			setScrollOnBottom(false);
		}
		if(scrollTop.current <=0){
			setScrollOnTop(true);
			setScrollOnBottom(false);
		}else{
			setScrollOnTop(false);
		}
		calculateVisibleList();
	}, [scrollTop, setScrollOnBottom, setScrollOnTop, calculateVisibleList]);

	const moveBottom = useCallback((wheel = false) => {
		if(scrollTo.current !== null){
			const scroller = document.querySelector(`[data-spotlight-id="${listSpotlightId+"_scroller"}"]`);
			const listView = document.querySelector(`[data-spotlight-id="${listSpotlightId}"]`);
			let listArray = listView.children[0].children;
			if(!wheel){
				const focusedIndex = listView.getAttribute('data-focused-index');
				const currentItem = listArray[focusedIndex];
				const nextItem = currentItem && Utils.getNearestTarget('down', currentItem);
				if(currentItem && nextItem){
					const offsetBottom = nextItem.offsetTop+nextItem.offsetHeight;
					if(offsetBottom < (scrollTop.current+scroller.offsetHeight)){ //contain
						return true;
					}else{
						const targetOffset = nextItem.offsetTop - Utils.scaleW(itemMargin);
						scrollTop.current = targetOffset;
						scrollTo.current({position:{y: targetOffset}, animate: true});
						return true;
					}
				}
				return false;
			}
			for(let i=0; i<listArray.length;i++){
				if(scrollTop.current < (listArray[i].offsetTop- Utils.scaleW(itemMargin))){
					//check last line
					const lastLine = Math.ceil(listArray.length/5); // 5 items per line
					if(Math.ceil((i+1)/5) <= lastLine){
						scrollTop.current = listArray[i].offsetTop - Utils.scaleW(itemMargin);
						scrollTo.current({position:{y: scrollTop.current}, animate: true});
					}
					return true;
				}
			}
		}
	}, [listSpotlightId]);
	const moveTop = useCallback((wheel = false) => {
		if(scrollTo.current !== null){
			const listView = document.querySelector(`[data-spotlight-id="${listSpotlightId}"]`);
			let listArray = listView.children[0].children;
			if(!wheel){
				const focusedIndex = listView.getAttribute('data-focused-index');
				const currentItem = listArray[focusedIndex];
				const nextItem = currentItem && Utils.getNearestTarget('up', currentItem);
				if(currentItem && nextItem){
					if(nextItem.offsetTop === (scrollTop.current- Utils.scaleW(itemMargin))){ //contain
						return true;
					}else{
						if(nextItem.offsetTop < (scrollTop.current + Utils.scaleW(itemMargin))){
							scrollTop.current = nextItem.offsetTop - Utils.scaleW(itemMargin); //apply margin
							scrollTo.current({position:{y: scrollTop.current}, animate: true});
						}
						return true;
					}
				}
				return false;
			}
			for(let i=listArray.length-1; i>=0;i--){
				if((listArray[i].offsetTop- Utils.scaleW(itemMargin)) < scrollTop.current){
					scrollTop.current = listArray[i].offsetTop - Utils.scaleW(itemMargin); //apply margin
					scrollTo.current({position:{y:scrollTop.current},animate: true});
					return true;
				}
			}
		}
	}, [listSpotlightId]);

	const onClickTopGuide = useCallback(() => {
			moveTop(true);
	}, [moveTop]);

	const onClickBottomGuide = useCallback(() => {
			moveBottom(true);
	}, [moveBottom]);

	const onKeyDown = useCallback((ev) => {
		if(ev.key === 'ArrowDown' ||ev.key === 'PageDown'){
			return moveBottom(false);
		}else if(ev.key === 'ArrowUp' || ev.key === 'PageUp'){
			return moveTop(false);
		}
		return false;
	}, [moveTop, moveBottom]);

	const handleWheel = useCallback((ev) => {
		const scroller = document.querySelector(`[data-spotlight-id="${listSpotlightId+"_scroller"}"]`);
		if(scroller && typeof window == 'object' && window.getComputedStyle(scroller).visibility !== 'visible'){
			// console.log('handleWheel not visiblie ',listSpotlightId);
			return;
		}
		if(ev.deltaY < 0){
			onClickTopGuide();
		}else{
			onClickBottomGuide()
		}
	}, [prevPanels, onClickTopGuide, onClickBottomGuide]);

	return (
		<>
		<Scroller
			cbScrollTo={getScrollTo}
			className={classNames(css.verticalmedialistlayer, className)}
			spotlightId={listSpotlightId+"_scroller"}
			direction="vertical"
			horizontalScrollbar="hidden"
			verticalScrollbar="hidden"
			scrollMode="translate"
			noScrollByWheel
			noScrollByDrag
			onScrollStop={onScrollStop}
			overscrollEffectOn={{
				arrowKey: false,
				drag: false,
				pageKey: false,
				track: false,
				wheel: false
			}}
		>
			<MediaList visibleList={visibleList} orientation={'vertical'}
				contentType={contentType}
				spotlightId={listSpotlightId} viewList={contentsList} onKeyDown={onKeyDown}
				onFocusedIndexChanged={onFocusedIndexChanged}
				restoreIndex={restoreIndex}/>
			<div className={css.dummy}/>
		</Scroller>
		{(cursorVisible || platform.touchscreen) && contentsList.length > withOutScrollLength && !scrollOnTop &&
			<VerticalScrollGuide type={"top"} className={classNames(css.topBottomScrollArea, css.topScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide: null)} onClick={onClickTopGuide}/>
		}
		{ (cursorVisible || platform.touchscreen) && contentsList.length > withOutScrollLength && !scrollOnBottom &&
			<VerticalScrollGuide type={"bottom"} className={classNames(css.topBottomScrollArea, css.bottomScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide: null)} onClick={onClickBottomGuide}/>
		}
		</>
	);
};

export default VerticalMediaList;