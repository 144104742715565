/**
 * HLSVideoPlayer
 *
 * @module HLSVideoPlayer
 */
import classNames from 'classnames';
import { useEffect, useRef, useCallback, useState } from 'react';
import css from './HLSVideoPlayer.module.less';
import {memoize} from '@enact/core/util';
import { useDispatch, useSelector } from 'react-redux';
import {on, off} from '@enact/core/dispatcher';
import {Spottable} from '@enact/spotlight/Spottable';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import {Job} from '@enact/core/util';
import ReactHlsPlayer from 'react-hls-player';
import Touchable from '@enact/ui/Touchable';
import DurationFmt from 'ilib/lib/DurationFmt';
import Marquee from '@enact/sandstone/Marquee';
import SpotlightIds from '../../data/SpotlightIds';
import Times from './Times';
import MediaSlider from './MediaSlider';
import BurningItem from './BurningItem';
import AcquiredGraph from '../AcquiredGraph';
import PlayPauseIcon from './PlayPauseIcon';
import Overlay from './Overlay';
import {$L} from '../../utils/common';
import * as Utils from '../../utils/common';
import * as Config from '../../data/Config';
import * as BleActions from '../../actions/bleActions';
import { types } from '../../actions/actionTypes';


const SpottableDiv = Touchable(Spottable('div'));
const RootContainer = SpotlightContainerDecorator(
	{
		enterTo: 'default-element',
		defaultElement: [`.${css.controlsHandleAbove}`, `.${css.controlsFrame}`]
	},
	'div'
);
const Container = SpotlightContainerDecorator({enterTo: 'last-focused'},'div');
const ControlsContainer = SpotlightContainerDecorator(
	{
		enterTo: '',
		straightOnly: true
	},
	'div'
);
const MEDIAINFO_LIST = ['loadeddata', 'loadedmetadata', 'ended', 'durationchange', 'timeupdate', 'error', 'waiting'];

const memoGetDurFmt = memoize((/* locale */) => new DurationFmt({
	length: 'medium', style: 'clock', useNative: false
}));

const getDurFmt = () => {
	if (typeof window === 'undefined') return null;

	return memoGetDurFmt();
};

const DUR_FMT = getDurFmt();
const AUTO_CLOSE_TIMEOUT = 5000;
const HLS_CONFIG = {
	debug: false,
	lowLatencyMode: true,
	maxBufferSize: 30 * 1000 * 1000
  };
const HLSVideoPlayer = ({ setApiProvider, src, currentItem, title, className,
		onUpdate, exitButton, spotlightDisabled,
		showCameraSettingsBtn, autoPlayButton, onClickCameraSettings,
		goalActivity, historyActivity, isCoolDownMode = false, cameraPosition}) => {
	const dispatch = useDispatch();
	const localSettings = useSelector(state => state.localSettings);
	const [updatedState, setUpdatedState] = useState({workoutTime:0, calorie: 0, steps: 0, heartRate:0, averageHeartRate:0});
	const [mediaControlsVisible, setMediaControlsVisible] = useState(true);
	const [miniFeedbackVisible, setMiniFeedbackVisible] = useState(false);
	const prevSpotlightDisabled = useRef(spotlightDisabled);
	const userInfo = useSelector((state) => state.userInfo);
	const gattConnectingStatus = useSelector(state => state.gattConnectingStatus);
	const gattExerciseResultValue = useSelector(state=>state.gattExerciseResultValue);
	const autoCloseJob = useRef(new Job((func) => {
		func();
	}, AUTO_CLOSE_TIMEOUT));

	const playerRef = useRef(null);
	useEffect(() => {
		if(typeof window === 'object'){
			on('keydown', activityDetected, window);
			on('mousemove', activityDetected, window);
			on('wheel', activityDetected, window);
			on('touchmove', activityDetected, window);
		}
		return () => {
			if(typeof window === 'object'){
				off('keydown', activityDetected, window);
				off('mousemove', activityDetected, window);
				off('wheel', activityDetected, window);
				off('touchmove', activityDetected, window);
			}
			stopAutoCloseTimeout();
			//todo ble: stop
			dispatch(BleActions.workoutStop());
		}
	}, []);
	useEffect(() => {
		setUpdatedState({workoutTime:0, calorie: 0, steps: 0, heartRate: 0, averageHeartRate: 0});
	}, [src]);
	useEffect(() => {
		prevSpotlightDisabled.current = spotlightDisabled;
	}, [spotlightDisabled]);
	useEffect(() => {
		setApiProvider({play: play, pause: pause, seek: seek,
			hideControls: hideControls, showControls: showControls,
			state: updatedState
		});
	}, [setApiProvider, updatedState]);

	const play  = useCallback(() => {
		playerRef.current.play();
		//todo ble: resume
		if(gattConnectingStatus.connectStatus === BleActions.CONNECTION_STATUS.connected){
			dispatch(BleActions.workoutResume(gattConnectingStatus.clientId));
		}
	}, [dispatch, gattConnectingStatus]);

	const pause  = useCallback(() => {
		playerRef.current.pause();
		//todo ble: pause
		if(gattConnectingStatus.connectStatus === BleActions.CONNECTION_STATUS.connected){
			dispatch(BleActions.workoutPause(gattConnectingStatus.clientId));
		}
	}, [dispatch, gattConnectingStatus]);

	const playPause  = useCallback(() => {
		if(updatedState.paused){
			playerRef.current.play();
		}else{
			playerRef.current.pause();
		}
	}, [updatedState]);
	const seek  = useCallback((timeIndex) => {
		if(playerRef.current){
			if (!isNaN(playerRef.current.duration) && !updatedState.sourceUnavailable) {
				playerRef.current.currentTime = timeIndex;
				setUpdatedState({...updatedState, loading: true});
			} else {
				console.warn('HLSVideoPlayer seekFailed');
				// forward('onSeekFailed', {}, this.props);
			}
		}
	}, [updatedState]);
	const _mediaInfoHandler = useCallback((ev) => {
		const el = playerRef.current;
		const _updatedState = {
			// Standard media properties
			currentTime: el.currentTime,
			duration: el.duration,
			paused: el.playbackRate !== 1 || el.paused,
			playbackRate: el.playbackRate,

			// Non-standard state computed from properties
			error: el.error || ev.type === 'error',
			loading: el.loading || el.readyState !==4,
			proportionLoaded: el.proportionLoaded,
			proportionPlayed: 0,
			sliderTooltipTime: el.currentTime,
			// note: `el.loading && this.state.sourceUnavailable == false` is equivalent to `oncanplaythrough`
			sourceUnavailable: el.loading && updatedState.sourceUnavailable || el.error || ev.type === 'error'
		};
		// If there's an error, we're obviously not loading, no matter what the readyState is.
		if (_updatedState.error) _updatedState.loading = false;

		// const isRewind = this.prevCommand === 'rewind' || this.prevCommand === 'slowRewind';
		// const isForward = this.prevCommand === 'fastForward' || this.prevCommand === 'slowForward';
		// if (this.props.pauseAtEnd && (el.currentTime === 0 && isRewind || el.currentTime === el.duration && isForward)) {
		// 	this.pause();
		// }
		if(el.duration){
			_updatedState.proportionPlayed = el.currentTime/el.duration;
		}
		if(ev.type === 'ended'){
			_updatedState.currentTime = 0;
			el.currentTime = 0;
		}
		_updatedState.workoutTime = updatedState.workoutTime;
		_updatedState.calorie = updatedState.calorie;
		_updatedState.steps = updatedState.steps;
		_updatedState.heartRate = updatedState.heartRate;
		// _updatedState.averageHeartRate = updatedState.averageHeartRate;
		if(ev.type === 'timeupdate'&& Math.floor(_updatedState.currentTime) !== Math.floor(updatedState.currentTime)
				&& !_updatedState.sourceUnavailable && !_updatedState.paused && !_updatedState.loading && !_updatedState.error){
			_updatedState.workoutTime = _updatedState.workoutTime+1;
			if(_updatedState.workoutTime === Config.START_WATCH_MIN_TIME){
				if(onUpdate){
					onUpdate({type: 'startWatch'});
				}
			}
			// _updatedState.heartRate = gattExerciseResultValue.heartRate;
			if(gattConnectingStatus.connectStatus === BleActions.CONNECTION_STATUS.connected){
				_updatedState.calorie = gattExerciseResultValue.calorie;
				_updatedState.steps = gattExerciseResultValue.steps;
				_updatedState.heartRate = gattExerciseResultValue.heartRate;
			}else{
				_updatedState.calorie = Utils.calculateLocalCalory(Number(currentItem.mets), _updatedState.workoutTime, Number(userInfo.weight), userInfo.gender, userInfo.age);
			}
			//average HeartRate (not used)
			// const totalHeartRate = gattExerciseResultValue.totalHeartRate;
			// totalHeartRate.push(_updatedState.heartRate);
			// for(let i = 0; i < totalHeartRate.length; i++) {
			// 	if(totalHeartRate[i] === 0)  {
			// 		totalHeartRate.splice(i, 1);
			// 	  i--;
			// 	}
			// }
			// dispatch({type: types.GATT_EXERCISE_RESULT_VALUE, payload: {totalHeartRate: totalHeartRate}});
			// let totalHeartRateSum = totalHeartRate.reduce((totalHeartRateSum, currValue)=>{
			// 	return totalHeartRateSum + currValue;
			// }, 0)
			// _updatedState.averageHeartRate = Math.round(totalHeartRateSum / totalHeartRate.length);
			// dispatch({type: types.GATT_EXERCISE_RESULT_VALUE, payload: {averageHeartRate: _updatedState.averageHeartRate}})
		}
		setUpdatedState(_updatedState);
		if(onUpdate && ev.type !== 'timeupdate'){
			onUpdate(ev);
		}
	}, [updatedState, onUpdate, dispatch, userInfo, currentItem]);

	useEffect(() => {
		MEDIAINFO_LIST.forEach(element => {
			playerRef.current.addEventListener(element, _mediaInfoHandler);
		});
		return () => {
			MEDIAINFO_LIST.forEach(element => {
				playerRef.current.removeEventListener(element, _mediaInfoHandler);
			});
		}
	}, [_mediaInfoHandler]);

	const stopAutoCloseTimeout = useCallback(() => {
		autoCloseJob.current.stop();
	},[]);

	const showControls = useCallback(() => {
		startAutoCloseTimeout();
		setMediaControlsVisible(true);
		setMiniFeedbackVisible(false);
	}, []);

	const hideControls = useCallback(() => {
		stopAutoCloseTimeout();
		setMediaControlsVisible(false);
		if(!isCoolDownMode){
			setMiniFeedbackVisible(true);
		}
	}, [isCoolDownMode, stopAutoCloseTimeout]);

	 const toggleControls = useCallback(() => {
		if (mediaControlsVisible) {
			hideControls();
		} else {
			showControls();
		}
	}, [mediaControlsVisible]);

	const startAutoCloseTimeout= useCallback(() => {
		autoCloseJob.current.start(hideControls);
	},[hideControls]);

	const activityDetected = useCallback((ev) => {
		if(prevSpotlightDisabled.current){
			ev.stopPropagation();
			ev.preventDefault();
			return;
		}
		if(ev && ev.keyCode){
			showControls();
		}
		startAutoCloseTimeout();
	},[startAutoCloseTimeout, showControls]);

	const onVideoClick = useCallback(() => {
		toggleControls();
	}, [toggleControls]);

	const handleKnobMove = () => {
		// this.sliderScrubbing = true;
		// prevent announcing repeatedly when the knob is detached from the progress.
		// TODO: fix Slider to not send onKnobMove when the knob hasn't, in fact, moved
		// if (this.sliderKnobProportion !== ev.proportion && this.video) {
		// 	this.sliderKnobProportion = ev.proportion;
		// 	const seconds = Math.floor(this.sliderKnobProportion * this.video.duration);

		// 	if (!isNaN(seconds)) {
		// 		const knobTime = secondsToTime(seconds, getDurFmt(this.props.locale), {includeHour: true});

		// 		forward('onScrub', {...ev, seconds}, this.props);

		// 		this.announce(`${$L('jump to')} ${knobTime}`, true);
		// 	}
		// }
	};
	const onSliderChange = useCallback( ({value}) => {
		const time = value * updatedState.duration;

		// if (this.preventTimeChange(time)) return;

		seek(time);
		// this.sliderScrubbing = false;
	}, [updatedState, seek]);

	const perTime = ((updatedState.workoutTime+historyActivity.workoutTime)/goalActivity.workoutTime)*100;
	const perCalorie = ((updatedState.calorie+historyActivity.calorie)/goalActivity.calorie)*100;
	const perSteps = ((updatedState.steps+historyActivity.steps)/goalActivity.steps)*100;
	 return (
		<RootContainer
			className={classNames(css.videoPlayer,'enact-fit', className ? className : '', isCoolDownMode ? css.cooldown: "")}
			onClick={activityDetected}
			// ref={this.setPlayerRef}
				spotlightDisabled={spotlightDisabled}
			// spotlightId={spotlightId}
			// style={style}
		>
				<ReactHlsPlayer
					playerRef={playerRef}
					controls={false} src={src}
					hlsConfig={HLS_CONFIG}/>
				<Overlay
					bottomControlsVisible={mediaControlsVisible}
					onClick={onVideoClick}
				>
					{(updatedState.loading/* || loading - manual*/) ? <div className={css.spinner} /> : null}
				</Overlay>
				<div className={css.fullscreen}>
					{mediaControlsVisible &&
						<PlayPauseIcon className={css.playPauseButton}
							aria-label={updatedState.paused ? $L('Play') : $L('Pause')}
							disabled={updatedState.loading}
							type={updatedState.paused ? 'play' : 'pause'}
							onClick={playPause}
							spotlightId={SpotlightIds.PLAYER_CONTROL}
						/>
					}
					{mediaControlsVisible &&
						<div className={css.exitBtnLayer}>
							{exitButton}
						</div>
					}
					<ControlsContainer
						className={classNames(css.bottom, mediaControlsVisible ? "" : css.hidden)}
						spotlightDisabled={spotlightDisabled ||!mediaControlsVisible}
					>
						{!isCoolDownMode &&
							<Container className={css.burningInfoContainer}>
								<BurningItem type="time" value={updatedState.workoutTime}/>
								{localSettings.checkCalory &&
									<BurningItem type="kcal" value={updatedState.calorie}/>
								}
								{localSettings.checkStep && gattConnectingStatus.connectStatus === BleActions.CONNECTION_STATUS.connected && updatedState.steps > 0 &&
									<BurningItem type="steps" value={updatedState.steps}/>
								}
								{localSettings.checkHeartRate && gattConnectingStatus.connectStatus === BleActions.CONNECTION_STATUS.connected && updatedState.heartRate > 0 &&
									<BurningItem type="bpm" value={updatedState.heartRate}/>
								}
								{showCameraSettingsBtn &&
									<SpottableDiv className={css.cameraButton} onClick={onClickCameraSettings}/>
								}
							</Container>
						}
						<Container className={css.titleContainer}>
							<Marquee className={css.title} marqueeOn="render" alignment={isCoolDownMode ? 'center':'left'}>
								{title}
							</Marquee>
							{!isCoolDownMode && autoPlayButton}
						</Container>
						<div className={css.sliderContainer}>
							<Times noCurrentTime total={updatedState.duration} formatter={DUR_FMT} />
							<Times noTotalTime current={updatedState.currentTime} formatter={DUR_FMT} />
							<MediaSlider
								backgroundProgress={updatedState.proportionLoaded}
								disabled={/*disabled ||*/ updatedState.sourceUnavailable}
								// forcePressed={this.state.slider5WayPressed}
								// onBlur={this.handleSliderBlur}
								onChange={onSliderChange}
								// onFocus={this.handleSliderFocus}
								// onKeyDown={this.handleSliderKeyDown}
								onKnobMove={handleKnobMove}
								// onSpotlightUp={this.handleSpotlightUpFromSlider}
								spotlightId={SpotlightIds.PLAYER_SLIDER}
								// selection={proportionSelection}
								spotlightDisabled={spotlightDisabled || !mediaControlsVisible}
								value={updatedState.proportionPlayed}
								visible
							/>
						</div>
					</ControlsContainer>
					{miniFeedbackVisible &&
						<div className={css.miniFeedback}>
							<div className={classNames(css.inforLayer, cameraPosition === 'lt' ? css.right: "")}>
								<AcquiredGraph className={css.graph} data={[perSteps,perCalorie,perTime]}/>
								<BurningItem type="time" orientation={'horizontal'} value={updatedState.workoutTime}/>
								{localSettings.checkCalory &&
									<BurningItem type="kcal" orientation={'horizontal'} value={updatedState.calorie}/>
								}
								{localSettings.checkStep && gattConnectingStatus.connectStatus === BleActions.CONNECTION_STATUS.connected && updatedState.steps > 0 &&
									<BurningItem type="steps" orientation={'horizontal'} value={updatedState.steps}/>
								}
								{localSettings.checkHeartRate && gattConnectingStatus.connectStatus === BleActions.CONNECTION_STATUS.connected && updatedState.heartRate > 0 &&
									<BurningItem type="bpm" orientation={'horizontal'} value={updatedState.heartRate}/>
								}
							</div>
							{/* <div className={css.timeLeft}>{Utils.transSecToText(updatedState.duration-updatedState.currentTime)}</div> */}
						</div>
					}
				</div>
				<SpottableDiv
					// This captures spotlight focus for use with 5-way.
					// It's non-visible but lives at the top of the VideoPlayer.
					className={css.controlsHandleAbove}
					// holdConfig={controlsHandleAboveHoldConfig}
					// onDown={this.handleControlsHandleAboveDown}
					// onHoldPulse={this.handleControlsHandleAboveHoldPulse}
					// onKeyDown={this.handleControlsHandleAboveKeyDown}
					// onKeyUp={this.handleControlsHandleAboveKeyUp}
					onSpotlightDown={showControls}
					// selectionKeys={controlsHandleAboveSelectionKeys}
					spotlightDisabled={mediaControlsVisible || spotlightDisabled}
				/>
		</RootContainer>
	 );
 };

 export default HLSVideoPlayer;
