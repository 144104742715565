/**
 * HistoryData
 *
 * @module HistoryData
 */
import React from 'react';
import {shallowEqual} from 'react-redux';
import classNames from 'classnames';
import {$L} from '../../../utils/common';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import * as Utils from '../../../utils/common';
import css from './HistoryData.module.less';

const Container = SpotlightContainerDecorator({enterTo: 'default-element'}, 'div');

const HistoryData = ({className, time = 0, kcal = 0, step = 0}) => {
    return (
        <Container>
            <div className={classNames(css.goalContainer, className)}>
                <div className={css.goalBox}>
                    <div className={css.playtime}>{$L('운동 시간')}</div>
                    <div>{Utils.transSecToText(time)}</div>
                </div>
                <div className={css.goalBox}>
                    <div className={css.calorie}>{$L('소모 칼로리')}</div>
                    <div>{Number(kcal).toLocaleString('en') + ' kcal'}</div>
                </div>
                <div className={css.goalBox}>
                    <div className={css.walk}>{$L('걸음 수')}</div>
                    <div>{Number(step).toLocaleString('en') + ' ' + 'steps'}</div>
                </div>
            </div>
        </Container>
    );
};

export default React.memo(HistoryData, shallowEqual);
