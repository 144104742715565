import LS2Request from './LS2Request';

export const scan = ({onSuccess, onFailure, onComplete}) => {
		return new LS2Request().send({
			service: 'luna://com.webos.service.bluetooth2',
			method: 'le/startScan',
			subscribe: true,
			parameters:{},
			onSuccess,
			onFailure,
			onComplete
		});
	};

export const getDevice = ({onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.bluetooth2',
		method: 'device/getStatus',
		subscribe: true,
		parameters: {},
		onSuccess,
		onFailure,
		onComplete
	});
};

export const gattConnect = (address, {onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.bluetooth2',
		method: 'gatt/connect',
		parameters: {"address": address},
		onSuccess,
		onFailure,
		onComplete
	});
};

export const gattDiscoverServices = (address, {onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.bluetooth2',
		method: 'gatt/discoverServices',
		parameters: {"address": address},
		onSuccess,
		onFailure,
		onComplete
	});
};

export const gattWriteCharacteristicValue  = (gattConnectedClientId, {onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.bluetooth2',
		method: 'gatt/writeCharacteristicValue',
		parameters: {
			"clientId": gattConnectedClientId,
			"service": "0783b03e-8535-b5a0-7140-a304d2495cb7",
			"characteristic":"0783b03e-8535-b5a0-7140-a304d2495cb9",
			"value":{"bytes":[1]}
		},
		onSuccess,
		onFailure,
		onComplete
	});
}

export const workoutStart  = (gattConnectedClientId, {onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.bluetooth2',
		method: 'gatt/writeCharacteristicValue',
		parameters: {
			"clientId": gattConnectedClientId,
			"service": "0783b03e-8535-b5a0-7140-a304d2495cb7",
			"characteristic":"0783b03e-8535-b5a0-7140-a304d2495cba",
			"value": {"bytes":[32,1,1,2,36]}
		},
		onSuccess,
		onFailure,
		onComplete
	});
}

export const gattWriteDescriptorValue  = (gattConnectedClientId, {onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.bluetooth2',
		method: 'gatt/writeDescriptorValue',
		parameters: {
			"clientId": gattConnectedClientId,
			"service": "0783b03e-8535-b5a0-7140-a304d2495cb7",
			"characteristic":"0783b03e-8535-b5a0-7140-a304d2495cb8",
			"descriptor": "00002902-0000-1000-8000-00805f9b34fb",
			"value":{"bytes":[1, 0]}
		},
		onSuccess,
		onFailure,
		onComplete
	});
}

export const gattWriteDescriptorValueOff  = (gattConnectedClientId, {onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.bluetooth2',
		method: 'gatt/writeDescriptorValue',
		parameters: {
			"clientId": gattConnectedClientId,
			"service": "0783b03e-8535-b5a0-7140-a304d2495cb7",
			"characteristic":"0783b03e-8535-b5a0-7140-a304d2495cb8",
			"descriptor": "00002902-0000-1000-8000-00805f9b34fb",
			"value":{"bytes":[0, 0]}
		},
		onSuccess,
		onFailure,
		onComplete
	});
}

export const gattMonitorCharacteristics = (gattConnectedClientId, {onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.bluetooth2',
		method: 'gatt/monitorCharacteristics',
        subscribe: true,
		parameters: {
			"clientId": gattConnectedClientId,
			"service": "0783b03e-8535-b5a0-7140-a304d2495cb7",
			"characteristics":["0783b03e-8535-b5a0-7140-a304d2495cb8"]
		},
		onSuccess,
		onFailure,
		onComplete
	});
}

export const workoutPause  = (gattConnectedClientId, {onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.bluetooth2',
		method: 'gatt/writeCharacteristicValue',
		parameters: {
			"clientId": gattConnectedClientId,
			"service": "0783b03e-8535-b5a0-7140-a304d2495cb7",
			"characteristic":"0783b03e-8535-b5a0-7140-a304d2495cba",
			"value": {"bytes":[32,4,1,2,39]}
		},
		onSuccess,
		onFailure,
		onComplete
	});
}

export const workoutResume = (gattConnectedClientId, {onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.bluetooth2',
		method: 'gatt/writeCharacteristicValue',
		parameters: {
			"clientId": gattConnectedClientId,
			"service": "0783b03e-8535-b5a0-7140-a304d2495cb7",
			"characteristic":"0783b03e-8535-b5a0-7140-a304d2495cba",
			"value": {"bytes":[32,3,1,2,38]}
		},
		onSuccess,
		onFailure,
		onComplete
	});
}

export const workoutStop = (gattConnectedClientId, {onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.bluetooth2',
		method: 'gatt/writeCharacteristicValue',
		parameters: {
			"clientId": gattConnectedClientId,
			"service": "0783b03e-8535-b5a0-7140-a304d2495cb7",
			"characteristic":"0783b03e-8535-b5a0-7140-a304d2495cba",
			"value": {"bytes":[32,2,0,34]}
		},
		onSuccess,
		onFailure,
		onComplete
	});
}

export const lunaTest = (service, method, subscribe, parameters) => {
	console.log('lunaTest');
	const onSuccess = (res) => {
		console.log('LunaTest onSuccess ',service, method, res);
	}
	const onFailure = (res) => {
		console.log('LunaTest onFailure ',service, method, res);
	}
	const onComplete = (res) => {
		console.log('LunaTest onComplete ',service, method, res);
	}
	return new LS2Request().send({
		service: service,
		method: method,
		subscribe: subscribe,
		parameters:parameters,
		onSuccess:onSuccess,
		onFailure:onFailure,
		onComplete: onComplete
	})
}