import classNames from 'classnames';
import {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import {Panel} from '@enact/sandstone/Panels';
import {useDispatch, useSelector} from 'react-redux';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import {MediaControls} from '../../components/MediaPlayer';
import {VoiceControlDecorator} from '@enact/webos/speech';
import Skinnable from '@enact/sandstone/Skinnable';
import {Cancelable} from '@enact/ui/Cancelable';
import SpotlightIds from '../../data/SpotlightIds';
import {Job} from '@enact/core/util';
import PIPCamera from '../../components/PIPCamera';
import TIconButton from '../../components/TIconButton';
import {VideoPlayer} from '../../components/VideoPlayer';
import HLSVideoPlayer from '../../components/HLSVideoPlayer';
import CameraSettingPopup from './CameraSettingPopup';
import TAlert from '../../components/TAlert/TAlert';
import TSwitchItem from '../../components/TSwitchItem';
import {$L} from '../../utils/common';
import * as Utils from '../../utils/common';
import * as CommonActions from '../../actions/commonActions';
import * as CandyActions from '../../actions/candyActions';
import * as Config from '../../data/Config';
import * as PanelActions from '../../actions/panelActions';
import * as BleActions from '../../actions/bleActions';
import subtitleVTT from '../../../assets/mock/video.vtt';
import css from './PlayerPanel.module.less';
import Spotlight from '@enact/spotlight';
import VideoPlayerSubtitle from '../../components/VideoPlayerSubtitle/VideoPlayerSubtitle';

const Container = SpotlightContainerDecorator({leaveFor: {down:'', up:'', left:'', right:''}, enterTo: '', restrict: 'self-only'}, 'div');
const CancelableDiv = Cancelable({modal: true, onCancel: 'handleCancel'}, Skinnable(Container));
const VoiceMain = VoiceControlDecorator(CancelableDiv);

const testJob = new Job((voiceEventCallback) => {
	const pd = function(){console.log('preventDefault');};
	// ["skipIntro", "forward", "backward", "move", "play","pause","stop"]
	// const detail = {type: "action", intent: "ControlMedia", offset:"30", control:"forward"};
	// const detail = {type: "action", intent: "ControlMedia", offset:"30", control:"backward"};
	// const detail = {type: "action", control:"next"};
	// const detail = {type: "action", control:"previous"};
	// const detail = {type: "action", intent: "ControlMedia", offset:"120", control:"play"};
	// const detail = {type: "action", intent: "ControlMedia", control:"skipIntro"};
	const detail = {type: "action", intent: "ControlMedia", control:"move", time:{"Minutes":"0","Seconds":"0","Hour":"0"}};
	voiceEventCallback({detail: detail, preventDefault: pd});
},10000);

let endedTimer = null;

const unableToPlayJob = new Job((callback) => {
	callback();
},50000);

const PlayerPanel = ({hideChildren, panelInfo, ...props}) => {
	const dispatch = useDispatch();
	const playListInfo  = useSelector(state => state.playListInfo);
	const webOSVersion  = useSelector(state => state.appStatus.webOSVersion);
	const launchAction = useSelector(state => state.launchAction);
	const videoPreviewThumbs = useSelector(state => state.videoPreviewThumbs);
	const appStatus = useSelector(state => state.appStatus);
	const supportPIPCamera  = useSelector(state => state.supportPIPCamera);
	const localSettings = useSelector(state => state.localSettings);
	const candyActivities = useSelector(state => state.candyActivities);
	const gattConnectingStatus = useSelector(state => state.gattConnectingStatus);
	//goal
	const userPreference = useSelector(state => state.userPreference);
	const [subtitle, setSubtitle] = useState('');

	const [playingIndex, setPlayingIndex] = useState(-1);
	const [showingCameraSetting, setShowingCameraSetting] = useState(false);
	const [bleSettingPopup, setBleSettingPopup] = useState(false);
	const [thumbnailUnavailable, setThumbnailUnavailable] = useState(true);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [previewThumb, setPreviewThumb] = useState('');
	const videoPlayer = useRef(null);
	const _knobSec = useRef(null);


	const onReadSubtitle = useCallback(() => {
		const player = document.querySelector("video");
		console.log('onReadSubtitle player.textTracks[0]', player.textTracks[0]);
		let tracks = player.textTracks[0];
		setSubtitle("");
		if (tracks) {
			tracks.oncuechange = function() {
				let cue = tracks.activeCues[0];
				if (cue) {
					setSubtitle(cue.text);
				}else{
					setSubtitle("");
				}
			}
		}
	},[]);

	const currentItem = useMemo(() => {
		if(panelInfo.cooldownTitle){
			return {episodeId: 'cooldown', episodeTitle: panelInfo.cooldownTitle,episodeUrlHlsNonDrm: panelInfo.cooldownUrl};
		}
		let itemInfo = (playListInfo && playingIndex >= 0) ? playListInfo.playList[playingIndex]: {};
		if(!itemInfo) itemInfo = {};
		return itemInfo;
	}, [playListInfo, playingIndex, panelInfo]);

	useEffect(() => {
		return () => {
			if(unableToPlayJob){
				unableToPlayJob.stop();
			}
		}
	},[]);

	useEffect(() => {
		if(videoPreviewThumbs["thumb"+_knobSec.current]){
			setPreviewThumb(videoPreviewThumbs["thumb"+_knobSec.current]);
			setThumbnailUnavailable(false);
		}
	}, [videoPreviewThumbs]);

	useEffect(() => {
		if(!showingCameraSetting && !bleSettingPopup){
			if(appStatus.isAppForeground && videoPlayer.current && !videoPlayer.current.state.sourceUnavailable && videoPlayer.current.state.paused){
				console.log('PlayerPanel resume....');
				videoPlayer.current.play();
			}
		}
		if(!appStatus.isAppForeground){
			if(videoPlayer.current && !videoPlayer.current.state.paused){
				videoPlayer.current.pause();
			}
		}
	}, [appStatus.isAppForeground, showingCameraSetting, bleSettingPopup]);

	const delayedPlayJob = new Job(useCallback(() => {
		console.log('delayedPlay');
		if(videoPlayer.current){
			videoPlayer.current.play();
		}
	},[]), 10000);

	useEffect(() => {
		console.log('launchAction changed',launchAction);
		if(launchAction.type === 'pause10sec'){
			if(!videoPlayer.current.paused){
				videoPlayer.current.pause();
			}
			delayedPlayJob.start();
			dispatch(CommonActions.clearLaunchAction());
		}
	}, [dispatch, launchAction]);

	useEffect(() => {
		if(!hideChildren){
			setTimeout(() => {
				Spotlight.focus(SpotlightIds.PLAYER_TITLE_LAYER);
				Spotlight.focus("videoPlayer_mediaControls");
			}, 100);
		}
	}, [hideChildren]);

	const SpotToControl = useCallback((ev) => {
		if(Spotlight.focus(SpotlightIds.PLAYER_CONTROL)){
			ev.stopPropagation();
		}
	}, []);

	const onClickCameraSettings = useCallback((ev) => {
		console.log('onClickCameraSettings ev...', ev);
		if(videoPlayer && videoPlayer.current){
			videoPlayer.current.hideControls();
			videoPlayer.current.pause();
		}
		setShowingCameraSetting(true);
	}, []);

	const onCloseCameraSetting = useCallback((changed)=>() => {
		console.log('onCloseCameraSetting changed...', changed);
		setShowingCameraSetting(false);
	}, []);

	const initializePlayer = useCallback(() => {
		_knobSec.current = 0;
		delayThumbJob.stop();
		delayedPlayJob.stop();
		if(dataLoaded){
			unableToPlayJob.stop();
		}
		setPreviewThumb('');
		if (typeof window === 'object' && window.PalmSystem) {
			dispatch(CommonActions.cancelGetVideoThumbnail());
		}
		dispatch(CommonActions.clearVideoThumbnails());
		setShowingCameraSetting(false);
		setDataLoaded(false);
		if(videoPlayer.current){
			videoPlayer.current.pause();
			videoPlayer.current.seek(0);
			videoPlayer.current.play();
			videoPlayer.current.showControls();
		}

	},[dispatch, dataLoaded, panelInfo, localSettings]);

	useEffect(() => {
		if(videoPlayer.current){
			videoPlayer.current.hideControls();
		}
		if(playListInfo){
			setPlayingIndex(playListInfo.playIndex);
		}
	}, [playListInfo]);
	useEffect(() => {
		initializePlayer();
		if(currentItem.episodeId){
			console.log('currentItem ', currentItem);
			const date = new Date();
			dispatch(CandyActions.getCandyActivities(date.getFullYear(), date.getMonth()+1, date.getDate()));
		}
	}, [currentItem]);

	// useEffect(() => {
	// 	testJob.start(handleCustomVoiceEvent);
	// }, [contentsRelated]);

	const onClickBleSetting = useCallback((btnIndex, ev, checked)=>{
		if(btnIndex === 1){
			dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.settingPanel, {selectedTabId:'exerciseSetting'}))
			dispatch(PanelActions.updatePanel(PanelActions.PANEL_NAME.player, {preventBlePopup: true}));
        }
		if(checked){
			dispatch(CommonActions.changeLocalSettings({"preventBleNotiPopup": checked}));
		}
		setBleSettingPopup(false);
	},[dispatch])

	const onControlsAvailable = useCallback(({available}) => {
		console.log('onControlsAvailable...', available);
	},[]);

	const historyActivity = useMemo(()=> {
		let workoutTime = 0, steps = 0, calorie = 0;
		const today = Utils.timeToISO8601DateStr(new Date());
		if(candyActivities[today]){
			const keys = Object.keys(candyActivities[today]);
			keys.forEach(element => {
				const item = candyActivities[today][element];
				workoutTime += Number(item.workoutTime);
				steps += Number(item.candy);
				calorie += Number(item.calorieConsumption);
			});
		}
		return {workoutTime, steps, calorie};
	}, [candyActivities]);
	const goalActivity = useMemo(()=> {
		let workoutTime = 0, steps = 0, calorie = 0;
		workoutTime = userPreference.goalWorkoutTime ? userPreference.goalWorkoutTime: 0;
		steps = userPreference.goalStepCount ? userPreference.goalStepCount: 0;
		calorie = userPreference.goalCalorie ? userPreference.goalCalorie: 0;
		return {workoutTime, steps, calorie};
	}, [userPreference]);

	const finishWatching = useCallback(() => {
		if(currentItem && currentItem.episodeId){
			let workoutTime = 0, candy = 0, calorieConsumption = 0, averageHeartRate = 0;
			workoutTime = videoPlayer.current.state.workoutTime;
			calorieConsumption = videoPlayer.current.state.calorie;
			candy = videoPlayer.current.state.steps;
			averageHeartRate = videoPlayer.current.state.averageHeartRate;
			let dailyLifitTime = (historyActivity.workoutTime+workoutTime)/goalActivity.workoutTime;
			let dailyLifitCandy = (historyActivity.steps+candy)/goalActivity.steps;
			let dailyLifitCalorie = (historyActivity.calorie+calorieConsumption)/goalActivity.calorie;
			if(dailyLifitTime <=0){
				dailyLifitTime = dailyLifitTime.toFixed(1);
			}
			if(dailyLifitCandy <=0){
				dailyLifitCandy = dailyLifitCandy.toFixed(1);
			}
			if(dailyLifitCalorie <=0){
				dailyLifitCalorie = dailyLifitCalorie.toFixed(1);
			}
			const data = {workoutTime, candy, calorieConsumption, averageHeartRate, dailyLifitTime, dailyLifitCandy, dailyLifitCalorie};
			dispatch(CandyActions.finishWatching(currentItem.episodeId, data));
			return data;
		}
	}, [currentItem, dispatch, goalActivity, historyActivity]);

	const autoConnectCallback = useCallback((connectStatus, cliendId)=>{
		if(connectStatus === BleActions.CONNECTION_STATUS.connected || localSettings.preventBleNotiPopup || panelInfo.preventBlePopup){
			setBleSettingPopup(false);
		}else if(connectStatus !== BleActions.CONNECTION_STATUS.connected){
			videoPlayer.current.hideControls();
			videoPlayer.current.pause();
			setBleSettingPopup(true);
		}
		if(connectStatus === BleActions.CONNECTION_STATUS.connected){
			dispatch(BleActions.workoutStart(cliendId));
		}
	},[dispatch, localSettings, panelInfo]);
	//https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video
	const mediainfoHandler = useCallback((ev) => {
		const type = ev.type;
		if(type !== 'timeupdate'){
			console.log('mediainfoHandler.....',type);
		}
		switch(type){
			case 'loadeddata': {
				onReadSubtitle();
				setDataLoaded(true);
				if(currentItem.episodeId !== 'cooldown'){
					dispatch(BleActions.gattAutoConnect(autoConnectCallback));
				}
			}break;
			case 'startWatch':{
				console.log('mediainfoHandler startWatch');
				if(currentItem.episodeId !== 'cooldown'){
					dispatch(CandyActions.postStartWatching(currentItem.seasonId, currentItem.episodeId));
				}
			}break;
			case 'durationchange':{
				setDataLoaded(true);
			}break;
			case 'ended':{
				if(currentItem.episodeId === 'cooldown'){
					dispatch(PanelActions.popPanel());
				}else{
					clearTimeout(endedTimer);
					const data = finishWatching();
					endedTimer = setTimeout(() => {
						dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.playerCompletePanel, data));
					}, 300);
				}
			}break;
		}
	}, [onReadSubtitle, dispatch, finishWatching, currentItem, autoConnectCallback]);
	const getPlayer = useCallback((ref) => {
		videoPlayer.current = ref;
		// console.log('getPlayer ',ref);
		// videoPlayer.current.play();
	}, []);

	const backKeyHandler = useCallback((ev) => {
		if (ev && ev.stopPropagation) {
			ev.stopPropagation();
		}
		if(currentItem.episodeId === 'cooldown'){
			dispatch(PanelActions.popPanel());
		}else{
			const data = finishWatching();
			dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.playerCompletePanel, data));
		}
	},[dispatch, finishWatching, currentItem]);

	const onToggleAutoPlay = useCallback(({selected}) => {
		dispatch(CommonActions.changeLocalSettings({"videoAutoPlay": selected}));
	},[dispatch]);

	const exitButton = useCallback(() => {
		return (<TIconButton className={classNames(css.exitBtnPosition, css[localSettings.cameraPosition])} spotlightId={SpotlightIds.MAIN_EXITBTN} onSelect={backKeyHandler} onSpotlightDown={SpotToControl}/>);
	},[backKeyHandler, SpotToControl, localSettings]);

	const autoPlayButton = useCallback(() => {
		return (
		<div className={css.autoSwitch}>
			<div>{$L('Autoplay')}</div>
			<TSwitchItem data-webos-voice-label={$L('Autoplay')} onToggle={onToggleAutoPlay} selected={localSettings.videoAutoPlay}/>
		</div>
		);
	},[localSettings, onToggleAutoPlay]);

	const getPreviewThumb = useCallback((sourceUri, time) => {
		console.log('getPreviewThumb: ' + time );	// eslint-disable-line no-console
		dispatch(CommonActions.cancelGetVideoThumbnail());
		dispatch(CommonActions.getVideoThumbnail(sourceUri, time));
	},[dispatch]);

	const requestThumb = useCallback((sourceUri, time) => {
		if (typeof window === 'object' && !window.PalmSystem) {
			return;
		}
		getPreviewThumb(sourceUri, time);
	},[getPreviewThumb]);

	const delayThumbJob = new Job(requestThumb);

	const isActive = useMemo(() => {
		return playListInfo && currentItem.episodeUrlHlsNonDrm;
	}, [ playListInfo, currentItem]);


	const toastUnableToPlay = useCallback(()=> {
		// if(Config.DEMO){
		// 	dispatch(PanelActions.pushPanel(PanelActions.PANEL_NAME.playerCompletePanel));
		// }else{
			dispatch(CommonActions.alertToast($L("An unknown error has occured.")));
			backKeyHandler();
		// }
	},[dispatch, backKeyHandler]);

	useEffect(() => {
		if(!dataLoaded){
			unableToPlayJob.start(toastUnableToPlay);
		}else{
			unableToPlayJob.stop();
		}
	},[dataLoaded]);

	const onPlay = useCallback((ev) => {
		console.log('PlayerPanel onPlay', ev);
	},[]);

	const onPause = useCallback((ev) => {
		console.log('PlayerPanel onPause', ev);
	},[]);

	const handleCustomVoiceEvent = useCallback((ev) => {
		const direction = ev.detail.control;
		console.log('handleCustomVoiceEvent', JSON.stringify(ev.detail), playingIndex);

		if (!currentItem.episodeId || !videoPlayer.current || showingCameraSetting || bleSettingPopup) {
			ev.preventDefault();
			console.log('handleCustomVoiceEvent return without action', currentItem);
			return false;
		}
		switch (direction) {
			case 'forward':{
				videoPlayer.current.seek(videoPlayer.current.state.currentTime + Number(ev.detail.offset));
				ev.preventDefault();
			} break;
			case 'backward':{
				videoPlayer.current.seek(videoPlayer.current.state.currentTime - Number(ev.detail.offset));
				ev.preventDefault();
			} break;
			case 'move':{
				let offset = ev.detail.offset;
				if(ev.detail.time){
					offset = Number(ev.detail.time.Hour)*3600+Number(ev.detail.time.Minutes)*60+Number(ev.detail.time.Seconds);
				}
				videoPlayer.current.seek(offset);
				ev.preventDefault();
			} break;
			case 'error': {
				toastUnableToPlay();
			} break;
		}
	},[currentItem, playingIndex, showingCameraSetting, bleSettingPopup, toastUnableToPlay]);

	const onListItemClick = useCallback((item, index, force) => {
		if(!force && playingIndex === index){
			return;
		}
		if(playListInfo.playList[index] && playListInfo.playList[index].episodeId === item.episodeId){
			dispatch(CommonActions.setPlayLaunchPath(SpotlightIds.LIST_PLAYER));
			initializePlayer();
			setPlayingIndex(index);
		}
	}, [playListInfo, playingIndex, initializePlayer, dispatch]);

	const onLogListItemClick = useCallback((item, index)=>{
		onListItemClick(item, index, true);
	}, [onListItemClick]);

	const showPIPCamera = useMemo(() => {
		if(!appStatus.isAppForeground || !supportPIPCamera){
			return false;
		}
		const ret = localSettings.cameraActivated && !showingCameraSetting && !bleSettingPopup && appStatus.cameraDetected;
		return ret;
	}, [appStatus, localSettings, showingCameraSetting, bleSettingPopup, supportPIPCamera]);

	// const poster = (currentItem.contentId) ? currentItem.episodeThumbnailUrl  ? currentItem.episodeThumbnailUrl: currentItem.thumbnailImageUrl: "";
	const voiceIntent = 'PlayListControl ControlMedia:{"control": ["forward", "backward", "move"]}';

	return (
		<Panel {...props}>
			<VoiceMain className={isActive ? css.videoMain : classNames(css.videoMain, css.hide)} data-webos-voice-intent={isActive ?voiceIntent:null} handleCancel={backKeyHandler} onVoice={handleCustomVoiceEvent}>
			{Config.USE_HLS_VIDEO_PLAYER ?
				<HLSVideoPlayer
					setApiProvider={getPlayer}
					src={isActive ? currentItem.episodeUrlHlsNonDrm: ""}
					title={currentItem.episodeTitle}
					currentItem={currentItem}
					onUpdate={mediainfoHandler}
					exitButton={exitButton()}
					autoPlayButton={autoPlayButton()}
					onClickCameraSettings={onClickCameraSettings}
					showCameraSettingsBtn={supportPIPCamera}
					spotlightDisabled={showingCameraSetting || bleSettingPopup}
					goalActivity={goalActivity}
					historyActivity={historyActivity}
					cameraPosition={localSettings.cameraPosition}
					isCoolDownMode={currentItem.episodeId === 'cooldown'}
				/>
			:
			<VideoPlayer
				setApiProvider={getPlayer}
				autoCloseTimeout={7000}
				disabled={undefined}
				feedbackHideDelay={3000}
				initialJumpDelay={400}
				jumpDelay={200}
				miniFeedbackHideDelay={2000}
				// onScrub={onScrub}
				onPlay={onPlay}
				onPause={onPause}
				thumbnailSrc={previewThumb}
				thumbnailUnavailable={thumbnailUnavailable}
				title={currentItem.episodeTitle}
				titleHideDelay={4000}
				onEnded={mediainfoHandler}
				onLoadedData={mediainfoHandler}
				onLoadedMetadata={mediainfoHandler}
				onDurationChange={mediainfoHandler}
				onControlsAvailable={onControlsAvailable}
				onTimeUpdate={mediainfoHandler}
				noAutoPlay={false}
				exitButton={exitButton()}
				jumpBy={10}
				spotlightDisabled={showingCameraSetting || bleSettingPopup}
				no5WayJump={showingCameraSetting || bleSettingPopup}
				isHLSPlayer={webOSVersion==='local'}
				hlsUrl={isActive ? currentItem.episodeUrlHlsNonDrm: ""}
			>
				<source
					src={isActive ? currentItem.episodeUrlHlsNonDrm: ""}
					type={/*webOSVersion==='local' ? "video/mp4": */"application/mpegurl"}
				/>
				{/* "https://d28ymb5cwyyibz.cloudfront.net/uploads/8aad0bcf53a140f1b848a2dd23559401.vtt" */}
				{ (Config.DEBUG_VIDEO_SUBTITLE_TEST || currentItem.subtitlePath) && appStatus.captionEnable &&
					<track kind="subtitles" src={Config.DEBUG_VIDEO_SUBTITLE_TEST ? subtitleVTT : currentItem.subtitlePath} default/>
				}
				<MediaControls
					actionGuideAriaLabel={$L("Scroll down or press the Down key")}
					actionGuideLabel={$L("Scroll down or press the Down key")}
					jumpBackwardIcon="jumpbackward"
					jumpBackwardDisabled={playingIndex<=0}
					jumpForwardIcon="jumpforward"
					jumpForwardDisabled={playingIndex+1 >= playListInfo.playList.length}
					moreActionDisabled={true}
					noJumpButtons={true}
					pauseIcon="pause"
					playIcon="play"
					playPauseButtonDisabled={undefined}
					rateChangeDisabled
				/>
			</VideoPlayer>
			}
			{isActive && showPIPCamera && currentItem.episodeId !== 'cooldown' &&
				<PIPCamera size={localSettings.cameraSize} position={localSettings.cameraPosition}/>
			}
			<VideoPlayerSubtitle subtitle={subtitle} />
			{showingCameraSetting &&
				<CameraSettingPopup open onClose={onCloseCameraSetting(false)} onClickDone={onCloseCameraSetting(true)}/>
			}
			</VoiceMain>
			{bleSettingPopup &&
                <TAlert
                    className={css.bleNotiPopup}
                    message={
                        <>
                            <div className={css.title}>{$L('연결된 기기가 없습니다.')}</div>
                    		    <div className={css.detailLimited}>{$L('기기를 연결하지 않으면 심박수 측정 등 일부 기능은 제약이 있을 수 있습니다.')}</div>
                        </>
                    }
                    button1text={$L('운동 진행')}
                    button2text={$L("기기 설정")}
                    tcheckbox1text={$L("다시 보지 않기")}
                    onClick={onClickBleSetting}
                />
            }
		</Panel>
	);
};

export default PlayerPanel;