import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from './AccountPopup.module.less';
import Alert from '@enact/sandstone/Alert';
import TButton from '../../../../../../components/TButton/TButton';
import {$L} from '../../../../../../utils/common';
import { InputField } from '@enact/sandstone/Input';
import Spotlight from '@enact/spotlight';
import Spottable from '@enact/spotlight/Spottable';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import * as CandyActions from '../../../../../../actions/candyActions' ;
import * as CommonActions from '../../../../../../actions/commonActions';

const Container = SpotlightContainerDecorator({enterTo: "last-focused", preserveId: true}, Spottable('div'));

const AccountPopup = ({open, onClose}) => {
	const dispatch = useDispatch();
	const userInfo = useSelector(state => state.userInfo);
	const [changeUserNick, setChangeUserNick] = useState('');

	useEffect(() => {
	    Spotlight.focus("BackBtn");
	},[]);

	const adjustNickName = useCallback((value)=>{
		let StringValue = String(value);
		setChangeUserNick(StringValue);
	},[])

	useEffect(()=>{
		const changeNickName = userInfo.nickName;
		adjustNickName(changeNickName);
	},[userInfo.nickName, open]);

    const onCancle = useCallback((e) => {
        console.log('onClose', e);
		if (onClose) {
			onClose();
		}
	}, [onClose]);

    const onNameChange = useCallback((e)=>{
		console.log(e.value);
		setChangeUserNick(e.value);
	},[]);

	const onDeactivateInput = useCallback(() => {
		adjustNickName(changeUserNick);
		Spotlight.focus('CompleteBtn');
	}, [adjustNickName, changeUserNick]);

	const onComplete = useCallback(() => {
		if(changeUserNick === userInfo.nickName){
			if (onClose) {
				onClose();
			}
		}else if(changeUserNick.length < 21){
			dispatch(CandyActions.updateUserInfo({nickName: changeUserNick}))
			if (onClose) {
				onClose();
			}
		}else{
			dispatch(CommonActions.alertToast($L("이름은 20자 내로 입력해주세요.")));
		}
	}, [dispatch, onClose, changeUserNick, userInfo.nickName]);

	return (
		<Alert open={open} onClose={onCancle} type={"overlay"} className={css.popup}>
			<Container>
				<div className={css.title}>{$L('이름 변경')}</div>
                <div className={css.inputBox}>
                    <InputField
						className={css.inputField}
                        spotlightId={"nameInput"}
						type={"text"}
						autoFocus
						dismissOnEnter
						onChange={onNameChange}
                        value={changeUserNick}
						onDeactivate={onDeactivateInput}
					/>
                </div>
				<div className={css.bottomBtnLayer}>
					<TButton className={css.nomalBtn} spotlightId={"BackBtn"} size="small" onClick={onCancle}>{$L('취소')}</TButton>
                    <TButton className={css.nomalBtn} spotlightId={"CompleteBtn"} size="small" color="dark" onClick={onComplete}>{$L('완료')}</TButton>
				</div>
			</Container>
		</Alert>
	);
}
export default AccountPopup;