import classNames from 'classnames';
import { useCallback, useEffect, useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Spotlight from '@enact/spotlight';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import css from './PlayerCompletePanel.module.less';
import TPanel from '../../components/TPanel/TPanel';
import TButton from '../../components/TButton';
import {$L} from '../../utils/common';
import CustomImage from '../../components/CustomImage';
import AcquiredGraph from '../../components/AcquiredGraph';
import CountDownTimer from '../../components/CountDownTimer';
import Marquee from '@enact/sandstone/Marquee';
import {PANEL_NAME} from '../../actions/panelActions';
import * as PanelActions from '../../actions/panelActions';
import * as CommonActions from '../../actions/commonActions';
import * as CandyActions from '../../actions/candyActions';
import * as BleActions from '../../actions/bleActions';
import * as Utils from '../../utils/common';

const Container = SpotlightContainerDecorator({enterTo: 'last-focused'}, 'div');

const ActivityItem = ({type, value=0, className}) => {
	const info = useMemo(() => {
		let c = "", message="", title="";
		switch(type){
			case 'time':
				c = css.time;
				message = Utils.transSecToText(value);
				title = Utils.$L('운동 시간');
				break;
			case 'kcal':
				c = css.kcal;
				message = value+" kcal";
				title = Utils.$L('소모 칼로리');
				break;
			case 'steps':
				c = css.steps;
				message = value+" steps";
				title = Utils.$L('걸음수');
				break;
		}
		return {iconClass: c, message, title};
	}, [type, value]);

	return (
		<div className={classNames(css.activityItem, className ? className: "")}>
			<div className={classNames(css.title)}>{info.title}</div>
			<div className={classNames(css.data, info.iconClass)}>
				{info.message}
			</div>
		</div>
	);
};

//panelInfo :{workoutTime, candy, calorieConsumption, averageHeartRate, dailyLifitTime, dailyLifitCandy, dailyLifitCalorie}
const PlayerCompletePanel = ({hideChildren, panelInfo, ...props}) => {
	const dispatch = useDispatch();
	const [showButtons, setShowButtons] = useState(false);
	const playListInfo  = useSelector(state => state.playListInfo);
	// const showInfos = useSelector(state => state.showInfos);
	// const seasonInfos = useSelector(state => state.seasonInfos);
	const episodeProducts = useSelector(state => state.episodeProducts);
	// const contentsMyFavorites = useSelector(state => state.contentsMyFavorites);
	const localSettings = useSelector(state => state.localSettings);
	const [autoPlay, setAutoPlay] = useState(localSettings.videoAutoPlay);
	const gattConnectingStatus = useSelector(state => state.gattConnectingStatus);

	useEffect(() => {
		if(!hideChildren){
			setTimeout(() => {
				setShowButtons(true);
			}, 1000);
        }
	}, [hideChildren]);
	useEffect(() => {
		if(showButtons){
      		Spotlight.focus('finishBtn');
        }
	}, [showButtons]);
	const currentItem = useMemo(() => {
		let itemInfo = (playListInfo && playListInfo.playIndex >= 0) ? playListInfo.playList[playListInfo.playIndex]: {};
		if(!itemInfo) itemInfo = {};
		return itemInfo;
	}, [playListInfo, episodeProducts]);

	// const isMyFavoriteSeason = useMemo(() => {
	// 	let hasKey = false;
	// 	contentsMyFavorites.forEach(element => {
	// 		if(element.seasonId === currentItem.seasonId){
	// 			hasKey = true;
	// 		}
	// 	});
	// 	return hasKey;
	// }, [contentsMyFavorites, currentItem]);

	// const showTitle = useMemo(() => {
	// 	if(seasonInfos[currentItem.seasonId]){
	// 		const showId = seasonInfos[currentItem.seasonId].showId;
	// 		if(showInfos[showId]){
	// 			return showInfos[showId].showTitle;
	// 		}
	// 	}
	// 	return '';
	// }, [currentItem, seasonInfos, showInfos]);
	const coolDownInfo = useMemo(() => {
		let url="", title = "";
		if(currentItem && currentItem.cooldownUrlHlsNonDrm){
			url = currentItem.cooldownUrlHlsNonDrm;
			title = currentItem.cooldownTitle;
		}
		return {url, title};
	}, [currentItem]);

	const hasNext = useMemo(() => {
		if(playListInfo && playListInfo.playIndex+1 < playListInfo.playList.length){
			return true;
		}
		return false;
	}, [playListInfo]);

	const perData = useMemo(()=> {
		let perSteps = 0, perCalorie = 0, perTime = 0;
		if(panelInfo){
            perSteps = panelInfo.dailyLifitCandy*100;
            perCalorie = panelInfo.dailyLifitCalorie*100;
            perTime = panelInfo.dailyLifitTime*100;
		}
		return [perSteps, perCalorie, perTime];
	}, [panelInfo]);
	useEffect(() => {
		if(currentItem.episodeId){
			dispatch(CandyActions.getEpisodeProducts(currentItem.episodeId));
		}
	}, [currentItem]);

	const countDownCanceled = useCallback(() => {
		console.log('countDownCanceled.......');
		setAutoPlay(false);
	}, []);
	const playNext = useCallback(() => {
		if(hasNext){
			playListInfo.playIndex +=1;
			dispatch(PanelActions.updatePanel(PANEL_NAME.player, {preventBlePopup: true}));
			dispatch(CommonActions.changePlayInfo(playListInfo));
			dispatch(PanelActions.popPanel(PANEL_NAME.playerCompletePanel));
		}else{
			console.log('PlayerCompletePanel invalid case.............');
		}
	}, [hasNext, playListInfo]);
	const playCoolDown = useCallback(() => {
		dispatch(PanelActions.updatePanel(PANEL_NAME.player,
			{cooldownTitle: coolDownInfo.title, cooldownUrl: coolDownInfo.url, preventBlePopup: true}));
		dispatch(PanelActions.popPanel(PANEL_NAME.playerCompletePanel));
	}, [dispatch, coolDownInfo]);

	// const onAddRemoveFavorite = useCallback(() => {
	// 	if(isMyFavoriteSeason){
	// 		dispatch(CandyActions.removeFavorite(currentItem.seasonId));
	// 	}else{
    //     	dispatch(CandyActions.addFavorite(currentItem.seasonId));
	// 	}
	// }, [dispatch, isMyFavoriteSeason, currentItem]);

	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		dispatch(PanelActions.popPanel(PANEL_NAME.player));
		dispatch(PanelActions.popPanel(PANEL_NAME.playerCompletePanel));
	}, [dispatch]);

	return (
		<TPanel {...props} spotlightId={PANEL_NAME.playerCompletePanel}  handleCancel={onBack}>
			<div className={css.panel}>
				<div className={css.leftSide}>
					<div className={css.guide}>{$L("완료한 운동")}</div>
					<Marquee className={css.episodeTitle} marqueeOn="render" alignment='center'>
						{currentItem.episodeTitle}
					</Marquee>
					{currentItem && currentItem.episodeThumbnailUrl &&
						<CustomImage className={css.image} src={currentItem.episodeThumbnailUrl}/>
					}
				</div>
				<div className={css.rightSide}>
					<div className={css.title}>{$L("Good Work")}</div>
					<div className={css.message}>{$L("잠시 숨을 고르고 몸을 풀어 주세요.")}</div>
					<div className={css.dataLayer}>
						<div className={css.values}>
							<ActivityItem type="time" value={panelInfo.workoutTime}/>
							<ActivityItem type="kcal" value={panelInfo.calorieConsumption}/>
							{ gattConnectingStatus.connectStatus === BleActions.CONNECTION_STATUS.connected &&
								<ActivityItem type="steps" value={panelInfo.candy}/>
							}
						</div>
						<AcquiredGraph className={css.graph} data={perData}/>
					</div>
				</div>
				{showButtons &&
					<Container className={css.bottomSide}>
						{coolDownInfo.url &&
							<TButton className={css.cooldown} size="huge" onClick={playCoolDown}>{$L("쿨다운")}</TButton>
						}
						<Container className={css.normalBtns}>
							{hasNext &&
								<TButton size="huge" fillDuration={autoPlay ? 7:0} onClick={playNext}>{$L("바로 재생")}</TButton>
							}
							<TButton spotlightId={'finishBtn'} size="huge" color="dark" onClick={onBack}>{$L("운동종료")}</TButton>
						</Container>
						{hasNext && autoPlay &&
							<CountDownTimer className={css.autoGuide} onFire={playNext} onCanceled={countDownCanceled}/>
						}
					</Container>
				}
			</div>
		</TPanel>
	);
};

export default PlayerCompletePanel;
