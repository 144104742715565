import {useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import css from './TAlert.module.less';
import Alert from '@enact/sandstone/Alert';
import TButton from '../TButton/TButton';
import Spottable from '@enact/spotlight/Spottable';
import TCheckBox from '../TCheckBox/TCheckBox';
import Spotlight from '@enact/spotlight';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';

const Container = SpotlightContainerDecorator({enterTo: "default-element", preserveId: true}, Spottable('div'));

const TAlert = ({className, title, children, message, button1text, button2text, tcheckbox1text, onClick, onClose, ...rest}) => {
	const [checked, setChecked] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			Spotlight.focus('tcheckbox1');
			Spotlight.focus('button2');
			Spotlight.focus('button1');
		}, 0);
	}, []);

	const onToggle = useCallback((ev) => {
		if(ev.selected){
			setChecked(true);
		}else{
			setChecked(false);
		}
    }, []);

	const onClickBtn = useCallback((index)=>(ev) => {
		if(onClick){
			onClick(index, ev, checked);
		}
    }, [onClick, checked]);

    const _onClose = useCallback((ev) => {
        console.log('onClose', ev);
		if(onClose){
			onClose(ev);
		}
    }, [onClose]);

	const hasButton = button1text || button2text || tcheckbox1text;

	return (
		<Alert {...rest}
			className={classNames(css.infopopup, css.logoutPopup, className)}
			open
			onClose={_onClose}
			type={"overlay"}
		>
			<Container>
				{message &&
					<div className={css.messageLayer}>
						<div className={css.message}>{message}</div>
					</div>
				}
				{children}
				{hasButton &&
					<div className={css.bottomBtnLayer}>
						{button1text &&
							<TButton spotlightId={'button1'}  size="small" onClick={onClickBtn(0)}>{button1text} </TButton>
						}
						{button2text &&
							<TButton spotlightId={'button2'}  size="small" color="dark" onClick={onClickBtn(1)}>{button2text} </TButton>
						}
						{tcheckbox1text &&
							<TCheckBox spotlightId={'checkbox1'} className={css.checkbox} selected={checked} onToggle={onToggle}>{tcheckbox1text}</TCheckBox>
						}
					</div>
				}
			</Container>
		</Alert>
	);
};

export default TAlert;
