/**
 * HistoryDetail
 *
 * @module HistoryDetail
 */
import React from 'react';
import {shallowEqual} from 'react-redux';
import classNames from 'classnames';
import {$L} from '../../../utils/common';
import * as Utils from '../../../utils/common';
import css from './HistoryDetail.module.less';

const HistoryDetail = ({className, time = 0, kcal = 0, step = 0}) => {
    return (
        <div className={css.wrapper}>
            <div className={classNames(css.goalContainer, className)}>
                <div className={css.goalBox}>
                    <span className={css.goalBoxItemLeft}>{$L('운동 시간')}</span>
                    <span className={css.goalBoxItemRight}>
                        <p className={css.value}>{Utils.transSecToText(time)}</p>
                    </span>
                </div>
                <div className={css.line} />
                <div className={css.goalBox}>
                    <span className={css.goalBoxItemLeft}>{$L('소모 칼로리')}</span>
                    <span className={css.goalBoxItemRight}>
                        <p className={css.value}>{Number(kcal).toLocaleString('en')}</p>
                        <p className={css.text}>kcal</p>
                    </span>
                </div>
                <div className={css.line} />
                <div className={css.goalBox}>
                    <span className={css.goalBoxItemLeft}>{$L('걸음 수')}</span>
                    <span className={css.goalBoxItemRight}>
                        <p className={css.value}>{Number(step).toLocaleString('en')}</p>
                        <p className={css.text}>steps</p>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default React.memo(HistoryDetail, shallowEqual);
