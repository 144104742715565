/**
 * VerticalScrollGuide
 *
 * @module VerticalScrollGuide
 */
import classNames from 'classnames';
import css from './VerticalScrollGuide.module.less';
import {useState, useCallback} from 'react';

/**
 *
 * type : "top", "bottom"
 * @returns
 */
const VerticalScrollGuide = ({type="top", ...rest}) => {
	const [isHover, setHover] = useState(false);
	const onMouseOver = useCallback(() => {
		setHover(true);
	}, [setHover]);
	const onMouseOut = useCallback(() => {
		setHover(false);
	}, [setHover]);
	return (
		<div {...rest} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
			<div className={classNames(css.arrow, type==="top" ? css.top: css.bottom, isHover ? css.hover: null)}/>
		</div>
	);
};

export default VerticalScrollGuide;
