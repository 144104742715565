import classNames from 'classnames';
import {useMemo} from 'react';
import css from './BurningItem.module.less';
import * as Utils from '../../utils/common';

const BurningItem = ({type, value=0, orientation='vertical', className}) => {
	const info = useMemo(() => {
		let c = "", message="", title="";
		switch(type){
			case 'time':
				c = css.time;
				message = Utils.transSecToText(value);
				title = Utils.$L('시간');
				break;
			case 'kcal':
				c = css.kcal;
				message = value+" kcal";
				title = Utils.$L('칼로리');
				break;
			case 'steps':
				c = css.steps;
				message = value+" steps";
				title = Utils.$L('걸음수');
				break;
			case 'bpm':
				c = css.bpm;
				message = value+" bpm";
				title = Utils.$L('심박수');
				break;
		}
		return {iconClass: c, message, title};
	}, [type, value]);

	return (
		<div className={classNames(css.burningItem, css[orientation], className ? className: "")}>
			<div className={classNames(css.burningIcon, info.iconClass)}/>
			{orientation === 'horizontal' &&
				<div className={classNames(css.info, info.iconClass)}>{info.title}</div>
			}
			<div className={classNames(css.info, info.iconClass)}>
				{info.message}
			</div>
		</div>
	);
};

export default BurningItem;
