/**
 * CategoriesView
 *
 * @module CategoriesView
 */
import css from './CategoriesView.module.less';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as CandyActions from '../../actions/candyActions';
import * as CommonActions from '../../actions/commonActions';
import VerticalMediaList from '../../components/MediaList/VerticalMediaList';
import CategoryList from '../../components/CategoryList';
import SpotlightIds from '../../data/SpotlightIds';
import Spotlight from '@enact/spotlight';

const CategoriesView = () => {
	const dispatch = useDispatch();
	const categoriesTableSelected = useSelector(state => state.categoriesTableSelected);
	const contentsOfCategory = useSelector(state => state.contentsOfCategory);

	useEffect(() => {
		dispatch(CommonActions.changeAppStatus({showCategoryDetail: false}));
		Spotlight.focus(SpotlightIds.MAIN_CATEGORY_BTN);
		setTimeout(() => {
			Spotlight.focus(SpotlightIds.LIST_CATEGORY_TABLE);
		}, 0);
	}, [dispatch]);

	useEffect(() => {
		if(categoriesTableSelected !== -1){
			dispatch(CandyActions.getContentsOfCategory(categoriesTableSelected));
			// if (scrollTo && scrollTo.current) {
			// 	scrollTo.current({ position: { x: 0, y: 0 }, animate: true });
			// }
			// calculateVisibleListJob.start(calculateVisibleList);
		}
	}, [dispatch, categoriesTableSelected]);

	return (
		<div className={css.bodyarea}>
			<CategoryList spotlightId={SpotlightIds.LIST_CATEGORY_TABLE}/>
			<VerticalMediaList className={css.medialistlayer} contentsList={contentsOfCategory}
				listSpotlightId={SpotlightIds.LIST_CATEGORY} withOutScrollLength={5}/>
		</div>
	);
};

export default CategoriesView;
