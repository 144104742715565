/**
 * BannerPagignation
 *
 * @module BannerPagination
 */

import {Job} from '@enact/core/util';
import css from './BannerPagination.module.less';
import classNames from 'classnames';
import React, {useState, useCallback, useMemo} from 'react';
import Spottable from '@enact/spotlight/Spottable';
import * as Config from '../../data/Config';
import SpotlightIds from '../../data/SpotlightIds';
import Spotlight from '@enact/spotlight';
import { useDispatch } from 'react-redux';

const SpottableComponent = Spottable('div');
// const dispatch = useDispatch();
const BannerPagination = ({isOnTop=true, className, onSelect, spotlightId, selected, ...rest}) => {
	const buttonInfo = useMemo(() => {
		const info = {css: css.pagination};
		switch(spotlightId){
			case SpotlightIds.HOME_BANNER_PAGE_01:
				break;
			case  SpotlightIds.HOME_BANNER_PAGE_02:
				break;
			case SpotlightIds.HOME_BANNER_PAGE_03:
				break;
		}
		return info;
	}, [spotlightId]);

	const onClick = useCallback((ev) => {
		new Job(() => {
			if (onSelect) {
				onSelect(spotlightId, rest['data-index']);
			}
		}, 200).start();
	}, [spotlightId, onSelect, rest]);

	const childProps = { ...rest };
	delete childProps.onClick;
	return (
		<SpottableComponent
			{...childProps}
			spotlightId={spotlightId}
			className={classNames(css.buttonContainer, buttonInfo.css, selected && css.selected)}
			onClick={onClick}
		>
			<div className={css.button}/>
		</SpottableComponent>
	);
};

export default BannerPagination;
