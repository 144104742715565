import {useState, useCallback, useEffect, useMemo } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import css from './DeviceSetting.module.less';
import {Job} from '@enact/core/util';
import {$L} from '../../../../../../utils/common';
import TButton from '../../../../../../components/TButton/TButton';
import Spotlight from '@enact/spotlight';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import * as BleActions from '../../../../../../actions/bleActions'
import Spottable from '@enact/spotlight/Spottable';
import ConnectSuccess from './ConnectDevice/ConnectSuccess';
import SearchedCandy from './ConnectDevice/SearchedCandy'
import SearchingCandy from './ConnectDevice/SearchingCandy'
import SearchingFailed from './ConnectDevice/SearchingFailed'

const Container = SpotlightContainerDecorator({enterTo: 'last-focused', preserveId: true}, Spottable('div'));

let delayedUpdateCandyStatusJob = null;
const DeviceSetting = ({...props}) => {
    const dispatch = useDispatch();
    const bleDeviceList = useSelector(state => state.bleDeviceList);
    const gattConnectingStatus = useSelector(state => state.gattConnectingStatus);
    const [deviceFound, setDeviceFound] = useState(false);
    const [candyStatus, setCandyStatus] = useState('');

    useEffect(() => {
		Spotlight.focus('searchDevice');
	}, []);

    const searchCandyBand = useCallback(()=>{
        setCandyStatus('searchingCandy');
        dispatch(BleActions.scan());
    }, [dispatch]);

    useEffect(() => {
        if(gattConnectingStatus.connectStatus ===BleActions.CONNECTION_STATUS.connected){
            setCandyStatus('connected')
            setDeviceFound(true);
        }else if(gattConnectingStatus.connectStatus ===BleActions.CONNECTION_STATUS.searchingFailed){
            setCandyStatus('searchingFailed');
            setDeviceFound(false);
        }
    }, [gattConnectingStatus]);

    useEffect(()=>{
        if(!delayedUpdateCandyStatusJob){
            delayedUpdateCandyStatusJob = new Job((func1, func2, status, _deviceFound)=>{
                // console.log('delayedUpdateCandyStatusJob throttle status _deviceFound : ', status, _deviceFound);
                func1(status);
                func2(_deviceFound);
            }, 2000);
        }
        // console.log('useEffect candyStatus bleDeviceList connectStatus: ', candyStatus, bleDeviceList, gattConnectingStatus.connectStatus);
        if(candyStatus ==='searchingCandy'){
            if(gattConnectingStatus.connectStatus === BleActions.CONNECTION_STATUS.idle) {
                delayedUpdateCandyStatusJob.throttle(setCandyStatus, setDeviceFound,'SearchedCandy', true);
            }
        }else{
            if(delayedUpdateCandyStatusJob){
                delayedUpdateCandyStatusJob.stop();
            }
        }
    }, [gattConnectingStatus, bleDeviceList, candyStatus])

    const setInitialView = useCallback(()=>{
        setCandyStatus('');
        setDeviceFound(false);
        setTimeout(() => {
			Spotlight.focus('searchDevice');
		}, 0);
    }, []);

    const candyBand = useMemo(()=>{
        return $L('연결된 <span>Candy Band</span>가 없습니다.');
    },[])

    return (
        <div>
            {!deviceFound &&
            <Container className={css.container} {...props}>
                <div className={css.empty} dangerouslySetInnerHTML={{__html: candyBand}}/>
                <div className={css.detail}>{$L('* Candy Band의 전원을 켜서 연결을 준비해주세요.')}</div>
                <div className={css.detail}>{$L('* 모바일에 연결된 경우 Candy Band에서 연결을 해제해주세요')}.</div>
                <div className={css.scan}>{$L('연결할 준비가 되었다면, 장치를 스캔해주세요.')}</div>
                <TButton size="small" spotlightId="searchDevice" onClick={searchCandyBand}>{$L('장치검색')}</TButton>
            </Container>
            }
            {
                candyStatus === 'SearchedCandy' && <SearchedCandy onConnectionFailed={setInitialView}/>
            }
            {
                candyStatus === 'connected' && <ConnectSuccess onDisconnected={setInitialView}/>
            }
            {
                candyStatus === 'searchingCandy' && <SearchingCandy/>
            }
            {
                candyStatus === 'searchingFailed' && <SearchingFailed onSearch={searchCandyBand}/>
            }
        </div>
    )
};

export default DeviceSetting;